<template>
  <div class="map-modal" @click.self="$emit('close')">
    <!-- Split Layout -->
    <div class="split-container">
      <!-- Left Section -->
      <div class="side-container left" @click="navigateTo('en')">
        <div class="header">
          <font-awesome-icon :icon="['fas', 'users-line']" alt="Login" style="margin-right: 0;" />
          <font-awesome-icon :icon="['fas', 'comment-dots']" alt="Login" style="margin-left: 0; margin-bottom: 18px; font-size: 14px;" />
          <span style="margin-left: 15px; margin-right: 15px;">English Crowdsourced Association Network</span>
          <font-awesome-icon :icon="['fas', 'comment-nodes']" alt="Login" class="header-icon" />
        </div>
        <img class="step-image" src="../../assets/English_language_distribution.svg" alt="Step 1 Image" />
        <div class="body">
          <button class="body-button" @click="navigateTo('en')">
            Click to go to <strong>CAS-EN</strong> NETWORK
          </button>
        </div>
      </div>

      <!-- Right Section -->
      <div class="side-container right" @click="navigateTo('nl')">
        <div class="header">
          <font-awesome-icon :icon="['fas', 'users-line']" alt="Login" style="margin-right: 0;" />
          <font-awesome-icon :icon="['fas', 'comment-dots']" alt="Login" style="margin-left: 0; margin-bottom: 18px; font-size: 14px;" />
          <span style="margin-left: 15px; margin-right: 15px;">Dutch Crowdsourced Association Network</span>
          <font-awesome-icon :icon="['fas', 'comment-nodes']" alt="Login" class="header-icon" />
        </div>
        <img class="step-image" src="../../assets/Map_of_the_Dutch_World.svg" alt="Step 2 Image" />
        <div class="body">
          <button class="body-button" @click="navigateTo('nl')">
            Click to go to <strong>CAS-NL</strong> NETWORK
          </button>
        </div>
      </div>
    </div>

    <!-- Close Button -->
    <button @click="$emit('close')" class="close-btn">Close</button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "WorldMapModal",
  emits: ["close"],
  methods: {
    ...mapActions(['setSelectedNavigation']),
    navigateTo(lang) {
      this.setSelectedNavigation(`CAS-${lang.toUpperCase()} Network`);
      localStorage.setItem('manualLangSelection', lang);
      // Check the current route to decide whether to redirect to /login or /respondent_login
      const currentPath = this.$route.path;
      const isRespondentRoute = currentPath === '/respondent_login';

      if (lang === 'en') {
        // If currently on respondent_login, go to the EN respondent path, else login
        window.location.href = isRespondentRoute
          ? 'https://www.mindspeller.com/#/respondent_login'
          : 'https://www.mindspeller.com/#/login';
      } else if (lang === 'nl') {
        // If currently on respondent_login, go to the NL respondent path, else login
        window.location.href = isRespondentRoute
          ? 'https://cas-nl.mindspeller.com/#/respondent_login'
          : 'https://cas-nl.mindspeller.com/#/login';
      }

      this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Map Modal Styling */
.map-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Split Layout Styling */
.split-container {
  display: flex;
  align-items: stretch;
  height: 75%;
  margin: 20px auto;
  width: 80%;
  position: relative;
  z-index: 1000;
}

.side-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 4px solid #000;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.side-container:hover {
  background-color: #8585e7ad;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  z-index: 1005;
}

/* Headers Styling */
.header {
  background-color: #7878e9;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #000;
}

/* Body Styling */
.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* Button Styling */
.body-button {
  display: inline-block;
  margin-top: auto;
  padding: 10px 20px;
  background-color: #7878e9;
  color: white;
  text-align: center;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  margin-bottom: 10px; /* Push the button to the bottom */
}

.body-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Close Button Styling */
.close-btn {
  margin-top: 10px;
  background-color: #dc3545;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1002;
  position: relative;
}

.close-btn:hover {
  background-color: #c82333;
}

.step-image {
  width: 100%;
  transform: scale(1.3);
  object-fit: contain;
  margin-top: 10%;
  border-radius: 8px;
}
</style>