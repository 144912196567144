<template>
  <div class="milestone">
    <div class="milestone-header">
      <h3>
        <slot name="title">{{ title }}</slot>
      </h3>
    </div>

    <!-- Table Structure -->
    <table class="milestone-table">
      <tbody>
        <!-- Row 1: Description -->

        <tr>
          <td class="milestone-description">
            <slot name="dropdown" />
          </td>
          <td class="divider-cell"></td> <!-- Single Divider -->
          <td class="milestone-checks">
            <font-awesome-icon
              icon="check-circle"
              class="check-icon"
              :class="{ completed: category !== '' }"
            />
          </td>
        </tr>
        <tr class="divider-row"></tr> <!-- Single Divider -->
                         <!-- Row 2: Dropdown -->
        <tr>
          <td class="milestone-description">
            <p>{{ description }}</p>
          </td>
          <td class="divider-cell"></td> <!-- Single Divider -->
          <td class="milestone-checks">
            <font-awesome-icon
              icon="check-circle"
              class="check-icon"
              :class="{ completed: currentChips >= 10000 }"
            />
          </td>
        </tr>
        <tr class="divider-row"></tr> <!-- Single Divider -->



        <!-- Row 3: Invite Friends Button -->
        <tr>
          <td class="milestone-description">
            <slot name="button1" />
          </td>
          <td class="divider-cell"></td> <!-- Single Divider -->
          <td class="milestone-checks">
            <font-awesome-icon
              icon="check-circle"
              class="check-icon"
              :class="{ completed: friendsCount == 13 }"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    category: { type: String, required: true },
    friendsCount: { type: Number, required: true },
    currentChips: { type: Number, required: true },
  },
};
</script>

<style scoped>
/* Milestone Box */

@media screen and (max-width: 1023px) {
  .check-icon {
  font-size: 24px;
  color: #6c757d; /* Default Gray */
}

}
@media only screen and (max-width: 769px) {
  .milestone-description {
  padding: 15px 0px !important;
  font-size: 12px !important;
  text-align: left;
  width: 70%;
}

.milestone{
  border-radius: 15px 15px 15px 15px !important ;

}
}

@media only screen and (max-width: 480px) {
  .milestone-header h3 {
  background-color: #6469ce;
  border-radius: 15px;
  color: white;
  font-size: 15px !important;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 2.125rem !important;
}
.milestone {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  max-width: 560px;
  padding: 20px;
  margin-top: 10%;
  background-color: #c9c9f0b2;
  border-radius: 15px 15px 15px 15px !important ;
  text-align: center;
}
p{
  line-height: normal !important;
  margin: 0px !important;
}
}
@media only screen and (max-width: 376px) {
  .milestone {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  margin-top: 10%;
  background-color: #c9c9f0b2;
  border-radius: 15px 15px 15px 15px !important ;
  text-align: center;
}
}

.milestone {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  margin-top: 10%;
  background-color: #c9c9f0b2;
  border-radius: 15px 0 0 15px ;
  text-align: center;
}

/* Header */
.milestone-header h3 {
  background-color: #6469ce;
  border-radius: 15px;
  color: white;
  font-size: 20px;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
}

/* Table Layout */
.milestone-table {
  width: 100%;
  border-collapse: collapse; /* Merges adjacent borders */
}

/* Table Cells */
.milestone-description {
  padding: 15px;
  font-size: 16px;
  text-align: left;
  width: 70%;
  border: 1px solid transparent; /* Prevents unnecessary borders */
}

.milestone-checks {
  width: 20%;
  text-align: center;
}

/* ✅ Fixed Divider Cell (Avoids Double Borders) */
.divider-cell {
  border-right: 2px solid #6469ce; /* Divider only appears on the right */
  width: 2px;
}

.divider-row {
  border: 2px solid #6469ce; /* Divider only appears on the right */
}

/* Check Icons */
.check-icon {
  font-size: 34px;
  color: #6c757d; /* Default Gray */
}

.check-icon.completed {
  color: #28a745; /* Green for completed */
}
</style>
