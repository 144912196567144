
<template>
  <div class="search-bar" style="display: flex; justify-content: flex-start; align-items: baseline">
    <div style="width:40%;" v-if="this.libraryKey === 'my_library' && totalAssets!==0">
      <h6 v-if="totalAssets===1">Showing {{ totalAssets}} Concept</h6>
      <h7 v-if="totalDictWords===1">and {{ totalDictWords}} Dictionary word <font-awesome-icon :icon="['fas', 'info-circle']" title="Scroll down to view the Dictionary words." style="color: #7878e9; cursor: pointer; font-size: 18px;" /></h7>
      <h6 v-if="totalAssets>1">Showing {{ totalAssets }} Concepts</h6>
      <h7 v-if="totalDictWords>1">and {{ totalDictWords}} Dictionary words <font-awesome-icon :icon="['fas', 'info-circle']" title="Scroll down to view the Dictionary words." style="color: #7878e9; cursor: pointer; font-size: 18px;" /></h7>
    </div>
    <div style="width:40%;" v-if="this.libraryKey === 'my_library' && totalAssets==0">
      <h6>No Concepts</h6>
    </div>
    <div style="width:40%;" v-if="this.libraryKey === 'mindspeller'">
      <h6>Showing {{ totalAssets}} Concepts</h6>
    </div>
    <div style="display: flex; justify-content: flex-end; gap:10px; width:58%;">
      <!-- add scraping tool -->
      <div v-if="this.libraryKey === 'my_library' && !this.standalone">
        <input style="border-color: #0A00FF; font-size: 16px; width:310px; text-align: center;" type="search" id="form1" class="actions"
          placeholder="Paste website URL here" aria-label="Search" v-model="search_text" @input="handleInput" />
      </div>
      <!-- origin search bar -->
      <div>
        <word-picker v-if="this.libraryKey === 'mindspeller' && this.standalone" @wordSelected="handleWordSelected" />
      </div>
      <div v-if="this.libraryKey === 'mindspeller'">
        <input style="width:250px; font-size: 12px; text-align: center;" autocomplete="off" v-model="text" id="form1" class="actions" aria-label="Search" type="search"
          :placeholder="$t('message.find_an_asset')" @keyup.enter="emitSearch" />
      </div>
      <div v-else>
        <input style="width:220px; font-size: 16px; text-align: center;" autocomplete="off" v-model="text" id="form1" class="actions" aria-label="Search" type="search"
          :placeholder="$t('message.find_all_asset')" @keyup.enter="emitSearch" />
      </div>
    </div>
  </div>
  <div class="img-col-btn-blue">
    <div class="library-drop-down">
      <ul>
        <li>
          <filter-bar ref="filter-bar" :allowed_types="allowed_types" :positioned_assets_only="positioned_assets_only"
            :disable_tracking="disable_tracking" @typeChanged="onTypeChanged" @sortChanged="onSortChanged"
            @monitoringStatusChanged="onMonitoringStatusChanged" @assetStateChanged="onAssetStateChanged"
            @brandChanged="onBrandChanged">
          </filter-bar>
        </li>
      </ul>
    </div>
  </div>
  <!-- here we display the scraping assets in a modal -->
  <q-dialog v-model="dialogVisible">
    <q-card style="width: 700px;">
      <q-card-section>
        <div class="image-container">
          <div v-for="(image, index) in images" :key="index" class="image-item">
            <q-img :src="image.src"
              :style="{ width: '80px', cursor: 'pointer', position: 'relative', filter: isSelected(image.src) ? 'grayscale(100%)' : 'none' }"
              @click="selectImage(image.src)">
              <q-icon v-if="isSelected(image.src)" name="done" size="md" color="green" />
            </q-img>
          </div>
        </div>
      </q-card-section>

      <q-card-actions>
        <div style="display: flex; justify-content: space-between; font-size: large;">
          <div style="font-size: small;">
            Selected assets: {{selectedImages.length}}
          </div>
          <div style="font-size: small;">
            Total Mindcoins: {{mindcoinTotal}}
          </div>
          <div>
            <button style="background-color:#92a0b3 !important; margin-right: 1vw;" class="button_pay" color="primary"
              @click="closeDialog">Cancel</button>
            <button class="button_pay" color="primary" @click="addAsset">Add Concept</button>
          </div>
        </div>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <div v-if="loading">
    <loading-app-wide />
  </div>
</template>

<script>
import FilterBar from '../asset_library/FilterBar.vue';
import PageTitle from '../common/PageTitle';
import axios from 'axios';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import WordPicker from './WordPicker.vue';

import {
  alert_error,
  genericDialog,
  toast_success
} from '@/helpers/alert_helper.js';

export default {
  components: {
    PageTitle,
    FilterBar,
    LoadingSpinner,
    LoadingAppWide,
    WordPicker,
  },

  emits: ['search','wordSelected'],

  props: {
    allowed_types: {
      required: true,
      type: Array,
    },
    positioned_assets_only: {
      type: Boolean,
      default: false,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
    // Cancels instructional video
    suppressVideo: {
      type: Boolean,
      default: false,
    },
    totalAssets: {
      type: String,
      default: 0
    },
    totalDictWords: {
      type: String,
      default: 0
    },
    disable_tracking: {
      type: Boolean,
      default: false,
    },
    is_myLibrary:{
      type:Boolean,
    },
    libraryKey: {
      type: String,
      default: false,
    },
  },

  data: function () {
    return {
      search_text: '',
      text: '',
      type: '',
      sortBy: '',
      monitoringStatus: '',
      assetState: '',
      brand: '',
      delayingSearch: false,
      assets: null,
      images: '',
      loading: false,
      dialogVisible: false,
      selectedImages: [],
      mindcoinTotal: 0,
    };
  },
  computed: {
    // we create a filter object in the format that the API expects to
    // receive
    filter: function () {
      return {
        search_string: this.text,
        asset_type: this.type,
        sort_by: this.sortBy,
        monitoring_status: this.monitoringStatus,
        asset_state: this.assetState,
        brand: this.brand,
        selectedImages: [],
      };
    },
  },
  methods: {
    async addAsset() {
      //we need to upload selectedimages to the libary
      try {
        const dialog_options = {
          title: this.$t('new_asset.upload_selected_asset'),
          text: this.$t('new_asset.this_will_cost', {
            price:  this.mindcoinTotal
          }),
          confirmButtonText: this.$t("message.asset_creator_add"),
          cancelButtonText: this.$t("message.cancel"),
        };
        const dialog_result = await genericDialog(dialog_options);
        if (!dialog_result.isConfirmed) {
          return;
        }

        // next, check if the user has enough credits
        await this.$store.dispatch('get_mindcoin_balance');
        if (this.$store.state.mindcoins < this.mindcoinTotal) {
          alert_error(this.$t('new_asset.insufficient_credits'));
          return;
        }
        try {
          this.loading = true;
          const response = await axios.post('/api/cas/assets/scraping', { "images": this.selectedImages });
          this.loading = false;
          await this.$store.dispatch('get_mindcoin_balance');
          this.$emit("resetSearch");
          toast_success(`Concept(s) uploaded, you will be notified when your concept(s) is/are analysed`);
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
    },
    selectImage(image) {
      const selectedImageIndex = this.selectedImages.indexOf(image);
      let img_price = this.$store.getters.get_price('private_image_asset')
      if (selectedImageIndex === -1) {
        this.selectedImages.push(image);
      } else {
        this.selectedImages.splice(selectedImageIndex, 1);
      }
      this.mindcoinTotal = img_price * this.selectedImages.length;
    },
    isSelected(image) {
      return this.selectedImages.includes(image);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.selectedImages = [];
      this.mindcoinTotal = 0;
      this.dialogVisible = false;
    },
    handleInput() {
      if (this.isValidUrl(this.search_text)) {
        this.scraping_img(this.search_text);
      }
    },
    isValidUrl(url) {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return urlPattern.test(url);
    },
    async scraping_img(url) {
      // encode the url for the api call
      let encode_url = encodeURIComponent(url)
      this.loading = true;
      // extract the images from the website
      const res = await axios.get(
        `https://opengraph.io/api/1.1/site/${encode_url}?app_id=1dd62d51-a120-4be8-a422-cc1b1b362c0c`
      );
      this.images = res.data.htmlInferred.images
      //first we remove the svg images
      this.images = this.images.filter(image => !image.endsWith('.svg'));
      //then we format the images
      this.images = this.images.map(image => {
        return { src: image };
      });

      this.openDialog()
      this.loading = false;
    },

    emitSearch: function () {
      this.$emit('search', this.filter);
    },
    onTypeChanged: function (type) {
      this.type = type;
      this.emitSearch();
    },
    onSortChanged: function (sortBy) {
      this.sortBy = sortBy;
      this.emitSearch();
    },
    onMonitoringStatusChanged: function (monitoringStatus) {
      this.monitoringStatus = monitoringStatus;
      this.emitSearch();
    },
    onAssetStateChanged: function (assetState) {
      this.$refs['filter-bar'].assetState = assetState;
      this.assetState = assetState;
      this.emitSearch();
    },
    onBrandChanged: function (brand) {
      this.brand = brand;
      this.emitSearch();
    },
    handleWordSelected(selectedWord) {
      this.$emit('wordSelected', selectedWord);
      
    },
    
  },
};
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
}

.top-part {
  display: flex;
  flex-basis: 100%;
}

.bottom-part {
  flex-basis: 100%;
}

.search-bar {
  margin: 40px 0 0 10px;
}

.search-form {
  display: flex;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 6px #0000007c;
  border: 1px solid #ffffff;
  border-radius: 41px;
  min-width: 350px;
}

.search-form input {
  width: calc(100% - 80px);
  padding-left: 32px;
  font-size: 1.5rem;
  font-family: 'Open Sans';
  font-weight: 300;
  color: $mdspblue_ui;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.circle-search {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mdspblue_ui;
  border-radius: 50%;
}

.search-button {
  background-color: $mdspblue_ui;
}

@media (min-width: 1024px) {
  .search-bar .search-form input {
    min-height: 50px;
  }

  .search-button {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1600px) {
  .search-bar .search-form input {
    min-height: 60px;
  }

  .search-button {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 1920px) {
  .search-bar .search-form input {
    min-height: 80px;
  }

  .search-button {
    width: 80px;
    height: 80px;
  }
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay i {
  font-size: 24px;
  color: green;
}

.button_pay {
  padding: 0.4vw 0.7vw;
  background: #0A00FF;
  border-radius: 2vw;
  font-style: normal;
  font-weight: 1000;
  font-size: 0.9vw;
  color: #ffffff !important;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 1s;
}

.button_pay a {
  color: #ffffff !important;
}

.button_pay:hover {
  background-color: #92a0b3;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.image-item {
  position: relative;
  margin-bottom: 10px;
}

.search-bar .actions {
    border-color: #0A00FF;
    background-image: url(../../assets/search-icon.png);
    background-position: 5px center;
    background-repeat: no-repeat;
    padding: 10px 10px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 10px;
    background-size: 25px;
    border: 1px solid #0A00FF;
    border-radius: 10px;
}

.compact-search {
    padding: px px;
    margin-right: 5px;
    font-size: 14px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
