<template>
  <main>
    <section style="height: 100vh;">
      <div class="container-fluid full-width-pg">
        <div class="row">
          <div class="col-md-7 p-0">
            <div style="background-color: blue;padding: 40px; height: 100vh;">
              <Brand></Brand>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-center">
            <div class="row">
              <div class="col-12">
                <div
                  class=" d-flex justify-content-center align-items-center margin"
                >
                  <img
                    src="../../assets/logo-with-text.png"
                    alt=""
                    style="width: 80%;"
                  />
                </div>
              </div>
              <div class="col-12 p-lg-5 p-4">
                <form class="row g-3 mt-5">
                  <div class="textbx m-0">Forget Password</div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email:</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email Address"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-flex justify-content-center">
                      <button
                        type="button"
                        class=" btn-primary btn-round rounded-1 w-25"
                        @click="handleForgotPwd"
                      >
                        Send
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-round w-25"
                        style="margin-left: 5px; background-color: white; border-color: blue; border-style: solid; color:black"
                        @click="handleLoginRedirection"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  <loading-app-wide v-if="loading" />
</template>

<script>
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
import MapBrand from "./Common/MapBrand.vue";
import Brand from "./Common/Brand.vue";

import { alert_error } from "@/helpers/alert_helper.js";

export default {
  name: "ForgotPassword",
  components: {
    MapBrand,
    LoadingAppWide,
    Brand,
  },
  data() {
    return {
      email: "",
      loading: false,
      showPopupFlag: false,
    };
  },
  methods: {
    showNotif() {
      setTimeout(() => {
        this.$router.push("/login");
      }, 3000);
      this.$q.notify({
        progress: true,
        message: "Email sent successfully!",
        timeout: 3000,
        icon: "mail",
        color: "white",
        textColor: "primary",
        position: "top",
        actions: [{ label: "Dismiss", handler: () => {} }],
        classes: "my-custom-notification",
      });
    },

    handleForgotPwd: async function() {
      try {
        this.loading = true;
        await this.$store.dispatch("handleForgotPwd", this.email);
        this.showNotif();
      } catch (error) {
        alert_error(this.$t("message.general_server_error"));
      }
      this.loading = false;
    },
    handleLoginRedirection: function() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style>
.my-custom-notification .q-notification__progress {
  background-color: #002cf0;
  border-color: #2730ae;
}

@media only screen and (max-width:320px) {
  .col-md-5{
    display: flex !important;
    align-items: center !important;
  }
  .textbx{
    font-size: 20px !important;
  }
  .margin{
    margin-top: 70px !important;
  }
}

@media only screen and (max-width:426px) {
  .col-md-5{
    
    width: 100%;
  }
  .textbx{
    font-size: 20px !important;
  }
  .margin{
    margin-top: 70px !important;
  }
}
</style>
