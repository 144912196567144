<template>
  <div class="credits-box" @click="handleSubscribe">
    <font-awesome-icon :icon="['fas', 'coins']" class="coins-logo" />
    <div class="credit-no"><span>{{ mindcoin_count }}</span> Mindcoins</div>
  </div>
</template>

<script>
import { genericDialog } from '@/helpers/alert_helper.js'; // Assuming the import path

export default {
  name: 'Credits',
  data() {
    return {};
  },
  computed: {
    mindcoin_count () {
      return this.$store.state.mindcoins;
    }
  },
  methods: {
    async handleSubscribe() {
      if (
        this.$store.state.current_license.monthly_fee > 0 &&
        this.$store.state.current_license.name !== "150 credits per month per user"
      ) {
        // Show the generic dialog for subscription confirmation
        const dialogOptions = {
          title: "Subscription Confirmation",
          text: "You are currently under a subscription model.",

          confirmButtonText: "Contact",
          cancelButtonText: "Cancel",
        };

        const dialogResult = await genericDialog(dialogOptions);
        if (dialogResult.isConfirmed) {
          // Redirect to the library if confirmed
          window.open("https://ads.mindspeller.com/contact/", "_blank")
        }
      } else {
        // Redirect to subscription plans if the user isn't under a subscription
        window.open(this.$router.resolve('/subscription_plans').href, '_blank');
      }
    }
  }
};
</script>

<style scoped>
.coins-logo{
  color: #FFFFFF;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.credit-no {
  font-size: 16px;
  color: white;
}

.credits-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
