
const add_new_asset_translation = {
  en: {
    new_asset: {
      yes: 'Yes',
      no: 'No',
      public: 'PUBLIC',
      add: 'ADD',
      continue: 'CONTINUE',
      option: 'TRACK',
      thank_you: 'THANK YOU!',
      coming_soon: 'Coming soon..',
      add_new_item: 'Add New Concept(s)',
      choose_track: 'Choose your track',
      concept_upload: 'Concept(s) upload',
      dictionary_words: 'Dictionary words',
      upload_image: 'upload image/illustrations',
      upload_multimedia: 'upload videos/audio',
      add_words: 'Add keywords/slogans',
      my_values: 'My Values',
      networkmainheader: 'Breaking Routine, Connecting Minds',
      networkheader1: 'Think You Know Brands?',
      networkheader2: 'Think Again!',
      read_me: 'Read Me!',
      signUp_for: 'Sign up for:',
      start_here: 'Start Here',
      already_account: 'Already have an account?',
      no_account: 'Don’t have an account?',
      login_advertiser: 'Looking for the Advertiser Hub?',
      login_consumer: 'Looking for the Respondent/Consumer Hub?',
      login_here: 'Log in here',
      earn_rewards: 'Earn Rewards',
      order_headset: 'Order Headset',
      explore_results: 'EXPLORE RESULTS',
      add_results_brand: "Add Your Brand Here",
      enter_associations: "Enter 3 <strong>SPONTANEOUS</strong> associations",
      concepts_loading: 'Your Concepts are loading...',
      select_value: 'Select your value to discover hidden gem brands!',
      number_concepts: 'Number of Concepts',
      free_trial_header: 'Welcome to the Mindspeller Advertiser Hub!',
      free_trial_subheader: 'Start adding your concepts to the network by signing into your region of interest below.',
      respondent_signup_header: 'Welcome to the Mindspeller Consumer Hub!',
      respondent_signup_subheader: 'Start earning rewards by signing into your region of interest below.',
      choose_language: 'Choose Region',
      load_more: 'Load More...',
      no_recommendations: 'No recommendations available at the moment.',
      choose_network_condition: 'Choose Network to Continue',
      brand_tag: 'This concept was tagged as a brand during the upload process.',
      no_brand_tag: 'This concept was not tagged as a brand during the upload process.',
      public_tag: 'This concept was tagged as public during the upload process.',
      no_public_tag: 'This concept was tagged as confidential during the upload process.',
      change_to_confidential_tag: 'State changes (Public ↔ Confidential) take effect after the Mindspeller Network monthly update (see calendar in header).',
      change_to_public_tag: 'Toggle to make this concept public',
      asset_grid_concept_title: 'Explore the concepts you’ve added. Scroll down to view your Mindspeller Dictionary words.',
      asset_grid_note: 'You can add this as a concept only through Mindspeller Library or the "Search Keyword" box in every tool',
      asset_grid_title: 'Explore your Mindspeller Dictionary words below. Use the Dictionary search to add more',
      asset_grid_note: 'You can add this as a concept only through Mindspeller Library or the "Search Keyword" box in every tool',
      library_scroll_down: 'Scroll down to explore the Mindspeller Dictionary words you’ve purchased',
      calendar_title: 'Mindspeller Omnibus Survey Schedule',
      calendar_footer: 'Highlighted dates mark the release schedule for the Mindspeller Omnibus Survey. Submit your concepts by 16:00 CET to secure a spot—available on a first-come, first-served basis.',
      type_to_create_asset: 'Type to create keyword / slogan concept',
      word_exists_in_network:
        'This word has already been mapped before and is available for analysis in the apps!',
      duplicate_order_attempt: 'You have already ordered this specific word.',
      mapping_required: 'mapping required',
      total_mindcoins: 'Total: {total} Mindcoins',
      insufficient_credits:
        'You do not have enough Mindcoins to upload ' +
        'your uploaded concept(s). You can try removing some from the list.',
      upload_from_computer:
        'Please select the concepts you want to map. Our proprietary mapping process involves real people and their spontaneous associations with your concepts. The mapping process will be completed over the weekend and you will receive an email notification as soon as your concpets have been mapped.',
      short_notification_text:
        'You will receive an automatic e-mail notification as soon as your concept has been mapped.',
      answer_image_questions: 'Please complete all image classification tags',
      accepted_types: 'Accepted file types are: { types }.',
      drop_here: 'You can also drag and drop files here.',
      unacceptable_type: 'UNACCEPTABLE FILE TYPE. ONLY { types } ARE ALLOWED.',
      image_too_small: 'IMAGE IS TOO SMALL',
      is_item_branded: 'Is this representing a brand?',
      type_of_item: 'Type of item',
      brand_url_optional: 'Related brand/product landing page URL (optional)',
      upload_selected_asset: 'Upload selected concepts?',
      create_selected_asset: 'Order selected concepts?',
      this_will_cost: 'This will cost {price} Mindcoins.',
      assets_created: 'Concept(s) {assets} uploaded, you will be notified when your ordered concepts are ready for analysis',
      public_concept: 'Make your concept(s) public with each 10% discount?',
      public_concept_info: 'If your concept is already published and you want to share its positioning within the Mindspeller community, click "Yes" for extra exposure and receive a 10% discount!',
      results_asap: 'Want to see results ASAP?',
      results_asap_info: 'Mindspeller omnibus surveys are conducted bi-weekly. Are you in a hurry or want to see results faster? Click "Yes" to explore our express options.',
      results_asap_eta: 'Need faster results? Choose the timeline that fits your needs:',
      surcharge: 'Surcharge',
      select: 'SELECT',
      final_checkout_1: 'Thank you for the upload!',
      final_checkout_2: 'Click "ADD" to checkout your asset to Mindspeller survey',
      final_checkout_no_mindcoins: 'You do not have enough Mindcoins to checkout your concept.',
      back: 'BACK',
      next: 'NEXT',
      checkout: 'CHECKOUT',
      table_public_concept_title: 'Public Concept',
      add_new_asset_tooltip: 'Load your concepts to crowdsource human associations',
      concept_tag: 'Please make sure to fill the details and correct if necessary',
      unsupported_format: 'Please upload supported formats as mentioned above',
      eta_info: 'Simply select your preferred option at checkout and let us accelerate your journey to actionable insights!',
      eta_plan_1: 'Standard Delivery',
      eta_plan_2: 'Weekend Rush',
      eta_plan_3: 'Midweek Boost',
      eta_plan_4: 'Lightning Fast',
      eta_description_1: 'Results delivered by next omnibus survey.',
      eta_description_2: 'Get your results by next week',
      eta_description_3: 'Results within the same week',
      eta_description_4: 'Insights ready within 2 days',
      track_1_info_a: 'Start crowdsourcing associations with your concepts using our bi-weekly “omnibus” survey. ',
      track_1_info_b: 'This survey provides a statistically stable positioning of your concept within the network, based on 100 to 200 online respondents tasked with entering 3 keyword associations for approximately fifteen randomized concepts (first come, first served).',
      track_1_info_c: 'Targeting attributes:',
      track_1_info_d: '- Language: English (US)',
      track_1_info_e: '- Gender: 50% male, 50% female',
      track_1_info_f: '- Age: 33% [18-36], 33% [37-45], 33% [46-64]',
      track_1_info_g: 'Check the upcoming omnibus deadline via the calendar icon in the header.',
      track_2_info: 'Click on your preferred crowd-sourcing channel',
      track_3_info: 'Do you want to crowdsource associations for your concept from a specific panel? Share the sociodemographic characteristics of your target panel to receive a tailored quote.',
      track_2_submit: "Thank you for providing information about your preferred crowd-sourcing channel. We will get back to you soon.",
      track_2_title:"Click on your preferred crowd-sourcing channel",
      dollar_tooltip1: "This concept is auto-tagged as a Brand Asset and eligible for tracking.  You can modify the tag anytime. Learn how by clicking the 'Track Concept' button. Mindspeller tracks this Concept to provide insights on order.",
      dollar_tooltip2: "This concept is marked as 'refrained from tracking.' Tracking has been paused, but you can resume tracking if desired. Click to view tracking settings.",
      dollar_tooltip3: "This concept is NOT auto-tagged as a Brand Asset and is not eligible for tracking.  You can modify the tag anytime. Learn how by clicking the 'Track Concept' button.",
      dollar_tooltip_overridden1: "Auto-tagging was overridden to enable tracking.",
      dollar_tooltip_overridden2: "Auto-tagging was overridden to disable tracking.",
      track_concept_tooltip: "<strong>Effortlessly Track Your Brand Assets!</strong><br> Mindspeller tags your Concepts considered as Brand Assets with a $ icon. This 'Track Concept' button starts tracking your Brand Assets in the Network.",
      test_concept_tooltip: "<strong>Effortlessly Test Your Concept!</strong> <br>Mindspeller evaluates your concepts using human associations and crowd wisdom. This 'Test Concept' button analyzes and positions your concept within the Network.",
      tracking_popup_1: "<strong>Brand Tracker:</strong> Concepts in ‘My Library’ with the Brand Asset tag enabled will be periodically tracked  (see below). This will allow you to load and monitor your tracked Brand Assets in the Brand Tracker tool. <strong>There are four states your Concepts can be in:</strong>",
      tracking_popup_2: "<strong>ENABLED</strong>- This concept is auto-tagged as a Brand Asset. Eligible for tracking.",
      tracking_popup_3: "<strong>DISABLED</strong>- Overridden to opt out of tracking.",
      tracking_popup_4: "<strong>DISABLED</strong>- This concept is not auto-tagged as a Brand Asset. Not eligible for tracking.",
      tracking_popup_5: "<strong>ENABLED</strong>- Overridden to opt into tracking.",
      tracking_popup_6: "<strong>Below find an overview of your current Brand Assets:</strong>",
      tracking_popup_7: "to toggle the Brand Asset tag of your concepts.",
      tracking_popup_8: "You have no Brand assets to checkout. Click the €/$ icon for desired concepts to make it a Brand Asset or the €/$ icon enabled Brand Assets are already in tracking (Click on the concept to view Tracking Status).",
      tracking_popup_9: "Track periodically for:",
      tracking_popup_10: "Selected Duration",
      tracking_table_1: "Cost per Concept",
      tracking_summary_1: "<strong>Thank you for your order!</strong>",
      tracking_summary_2: "All your Brand Assets will be tracked monthly for",
      tracking_summary_3: "<strong>NOTE:</strong> You can update Brand Asset tags anytime during tracking. Simply reorder to opt in again.",
      tracking_summary_table1: "Brand Assets to be Tracked",
      month: 'Month',
      months: 'Months',
    },
  },
  nl: {
    new_asset: {
      yes: 'Ja',
      no: 'Nee',
      add: 'TOEVOEGEN',
      continue: 'VERDERGAAN',
      public: 'OPENBAAR',
      option: 'TRAJECT',
      thank_you: 'Bedankt!',
      coming_soon: 'Binnenkort beschikbaar..',
      add_new_item: 'Nieuwe Concept(en) Toevoegen',
      choose_track: 'Kies je traject',
      concept_upload: 'Concept(s) uploaden',
      dictionary_words: 'Woordenboekwoorden',
      upload_image: 'Afbeelding/illustraties uploaden',
      upload_multimedia: 'upload multimedia',
      add_words: 'voeg woord(en) toe',
      number_concepts: 'Aantal Concepten',
      choose_language: 'Kies Regio',
      my_values: 'Mijn Idealen',
      read_me: 'Lees mij!',
      networkmainheader: 'Breaking Routine, Connecting Minds',
      networkheader1: 'Merkenkenner?',
      networkheader2: 'Daag Mindspeller uit!',
      order_headset: 'Bestel Headset',
      earn_rewards: 'Verdien beloningen',
      explore_results: 'RESULTATEN ONTDEKKEN',
      load_more: 'Meer laden...',
      signUp_for: 'Registreer voor:',
      start_here: 'Start hier',
      already_account: 'Heb je al een account?',
      no_account: 'Heb je nog geen account?',
      login_advertiser: 'Op zoek naar de Adverteerders Hub?',
      login_consumer: 'Op zoek naar de Respondenten/Consumenten Hub?',
      login_here: 'Log in Hier',
      concepts_loading: 'Je Concepten worden geladen...',
      enter_associations: "Voer 3 <strong>SPONTANE</strong> associaties in",
      free_trial_header: 'Welkom bij de Mindspeller Advertiser Hub!',
      free_trial_subheader: 'Begin met het toevoegen van je concepten aan het netwerk door in te loggen op je regio van interesse hieronder.',
      respondent_signup_header: 'Welkom bij de Mindspeller Consumer Hub!',
      respondent_signup_subheader: "Registreer via uw voorkeursregio en profiteer van exclusieve kortingen!",
      no_recommendations: 'Geen aanbevelingen beschikbaar op dit moment.',
      add_results_brand: "Voeg je merk hier toe",
      select_value: 'Selecteer je waarde om verborgen merken te ontdekken!',
      choose_network_condition: 'Kies de voorwaarden van het Netwerk',
      brand_tag: 'Dit concept werd tijdens het uploadproces getagd als een merk.',
      no_brand_tag: 'Dit concept werd tijdens het uploadproces niet getagd als een merk.',
      public_tag: 'Dit concept werd tijdens het uploadproces getagd als openbaar.',
      no_public_tag: 'Dit concept werd tijdens het uploadproces getagd als vertrouwelijk.',
      change_to_confidential_tag: 'Staat wijzigingen (Openbaar ↔ Vertrouwelijk) worden van kracht na de maandelijkse update van het Mindspeller Netwerk (zie kalender in de header).',
      change_to_public_tag: 'Schakel om dit concept openbaar te maken',
      asset_grid_concept_title: 'Verken de concepten die je hebt toegevoegd. Scroll naar beneden om je Mindspeller-woordenboekwoorden te bekijken',
      asset_grid_title: 'Verken hieronder je Mindspeller-woordenboekwoorden. Gebruik de woordenboekzoekfunctie om meer toe te voegen',
      asset_grid_note: 'Je kunt dit alleen als concept toevoegen via de Mindspeller-bibliotheek of het "Search Keyword" in elke tool',
      library_scroll_down: 'Scroll naar beneden om de woorden te bekijken die je hebt gekocht uit het Mindspeller Woordenboek',
      calendar_title: 'Schema voor de Mindspeller Omnibus-enquête',
      calendar_footer:'Gemarkeerde data geven het releaseschema aan voor de Mindspeller Omnibus Survey. Dien je concepten in vóór 16:00 CET om een plaats te reserveren—beschikbaar op basis van het "first come, first served" principe.',
      type_to_create_asset: 'Type om keyword/slogan concept te creeren',
      word_exists_in_network:
        'Dit woord werd al eerder gemapped en is beschikbaar voor analyse in de apps',
      duplicate_order_attempt: 'Je hebt dit woord al besteld.',
      mapping_required: 'mapping survey nodig',
      total_mindcoins: 'Totaal: {total} Mindcoins',
      insufficient_credits:
        'Je hebt onvoldoende Mindcoins om al deze' +
        'Concepten toe te voegen. Probeer om enkele concepten te verwijderen van de lijst.',
      upload_from_computer:
        'Gelieve uw Concept te selecteren voor mapping. Gezien we uw Concept mappen door bevraging van spontane associaties bij echte mensen, kan het mappen even duren.\n U ontvangt een automatische e-mail notificatie zodra uw Concept gemapt werd.',
      short_notification_text:
        'U ontvangt een automatische e-mail notificatie zodra uw concept gemapt werd.',
      answer_image_questions:
        'Gelieve alle gevraagde labels m.b.t. de afbeeldingen te vervolledigen',
      accepted_types: 'Aanvaarde bestand types zijn: { types }.',
      drop_here: 'Je kan ook bestanden naar hier slepen.',
      unacceptable_type:
        'ONAANVAARDBAARE BESTAND TYPE. ENKEL { types } TOEGESTAAN.',
      image_too_small: 'Afbeelding te klein',
      is_item_branded: 'Vertegenwoordigt dit een merk?',
      type_of_item: 'Item type',
      brand_url_optional: 'Gerelateerde merk/product landingspagina URL (optioneel)',
      upload_selected_asset: 'Geselecteerde concepten opladen?',
      create_selected_asset: 'Geselecteerde concepten bestellen?',
      this_will_cost: 'Dit bedraagt {price} Mindcoins.',
      assets_created: 'Gecreëerde concepten: {assets}.',
      public_concept: 'Maak uw concept(en) openbaar met 10% korting per stuk?',
      public_concept_info: 'Is je concept al gepubliceerd en wil je de positionering ervan delen binnen de Mindspeller-community? Klik dan op "Ja" voor extra exposure en ontvang een korting van 10%!',
      results_asap: 'Wil je zo snel mogelijk resultaten zien?',
      results_asap_info: 'Mindspeller-omnibusenquêtes worden tweewekelijks uitgevoerd. Heb je haast of wil je sneller resultaten? Klik op "Ja" om onze expressopties te bekijken.',
      results_asap_eta: 'Snellere resultaten nodig? Kies de tijdlijn die bij uw behoeften past:',
      surcharge: 'Toeslag',
      select: 'SELECTEER',
      final_checkout_1: 'Bedankt voor het uploaden!',
      final_checkout_2: 'Klik op "TOEVOEGEN" om uw asset aan de Mindspeller-enquête toe te voegen.',
      final_checkout_no_mindcoins: 'U heeft niet genoeg Mindcoins om uw concept af te rekenen.',
      back: 'TERUG',
      next: 'VOLGENDE',
      checkout: 'AFREKENEN',
      table_public_concept_title: 'Openbaar concept',
      add_new_asset_tooltip: 'Laad uw concepten om menselijke associaties te crowdsourcen',
      concept_tag: 'Zorg ervoor dat u de gegevens invult en corrigeer ze indien nodig',
      unsupported_format: 'Gelieve ondersteunde formaten te uploaden zoals hierboven vermeld',
      eta_info: 'Selecteer eenvoudig uw voorkeursoptie bij het afrekenen en laat ons uw reis naar bruikbare inzichten versnellen!',
      eta_plan_1: 'Standaard levering',
      eta_plan_2: 'Weekend spoedlevering',
      eta_plan_3: 'Midweekversnelling',
      eta_plan_4: 'Bliksemsnel',
      eta_description_1: 'Resultaten geleverd door volgende omnibusonderzoek.',
      eta_description_2: 'Ontvang uw resultaten volgende week',
      eta_description_3: 'Resultaten binnen dezelfde week',
      eta_description_4: 'Inzichten gereed binnen 2 dagen',
      track_1_info_a: 'Start met het crowdsourcen van associaties met jouw concepten via onze tweewekelijkse "omnibus"-enquête.',
      track_1_info_b: 'Deze enquête zorgt voor een statistisch stabiele positionering van jouw concept in het netwerk, gebaseerd op 100 tot 200 online respondenten die de opdracht krijgen om 3 trefwoordassociaties in te voeren voor een vijftiental gerandomiseerde concepten (first come, first served).',
      track_1_info_c: 'Targeting-kenmerken:',
      track_1_info_d: '- Taal: Dutch (Belgian)',
      track_1_info_e: '- Geslacht: 50% man, 50% vrouw',
      track_1_info_f: '- Leeftijd: 33% [18-36], 33% [37-45], 33% [46-64]',
      track_1_info_g: 'Controleer de deadline voor de komende omnibus via het kalender icoon in de header.',
      track_2_info: 'Klik op uw voorkeurskanaal voor crowdsourcing',
      track_3_info: 'Wil je je concept laten associëren door een specifiek panel? Deel de sociodemografische kenmerken van je doelpanel voor een op maat gemaakte offerte.',
      track_2_submit: ' Bedankt voor het verstrekken van informatie over uw voorkeurskanaal voor crowdsourcing. We nemen binnenkort contact met u op.',
      track_2_title: 'Klik op uw favoriete crowdsourcing-kanaal',
      dollar_tooltip1: "Dit concept is automatisch getagd als een merk en is geschikt voor tracking. Je kunt de tag op elk moment wijzigen. Leer hoe door op de 'Concept volgen' knop te klikken. Mindspeller volgt dit Concept om inzichten te bieden over de bestelling",
      dollar_tooltip2: "Dit concept is gemarkeerd als 'gestopt met volgen'. Het volgen is gepauzeerd, maar je kunt het volgen hervatten indien gewenst. Klik om de volginstellingen te bekijken.",
      dollar_tooltip3: "Dit concept is NIET automatisch getagd als een merk en is niet geschikt voor tracking. Je kunt de tag op elk moment wijzigen. Leer hoe door op de 'Concept volgen' knop te klikken.",
      dollar_tooltip_overridden1: "Automatisch taggen werd overruled om tracking mogelijk te maken.",
      dollar_tooltip_overridden2: "Automatisch taggen werd overruled om tracking te voorkomen.",
      track_concept_tooltip: "<strong>Volg je merkactiva moeiteloos!</strong><br> Mindspeller tagt je Concepten die als Merkactiva worden beschouwd met de € icon. Deze 'Concept volgen' knop start het volgen van je Merkactiva in het Netwerk.",
      test_concept_tooltip: "<strong>Test je concept moeiteloos!</strong> <br>Mindspeller evalueert je concepten met behulp van menselijke associaties en crowd-wijsheid. Deze 'Concept testen' knop analyseert en positioneert je concept binnen het Netwerk.",
      tracking_popup_1: "<strong>Merktracker:</strong> Concepten in ‘Mijn Bibliotheek’ met de Brand Asset-tag ingeschakeld, worden periodiek gevolgd (zie hieronder). Hiermee kunt u uw gevolgde Merkactiva in de Brand Tracker-tool laden en volgen. <strong>Er zijn vier staten waarin uw Concepten kunnen zijn:</strong>",
      tracking_popup_2: "<strong>INGESCHAKELD</strong>- Dit concept is automatisch getagd als een Merkactivum. Geschikt voor tracking.",
      tracking_popup_3: "<strong>UITGESCHAKELD</strong>- Overruled om niet te volgen.",
      tracking_popup_4: "<strong>UITGESCHAKELD</strong>- Merkactivum niet geschikt voor tracking.",
      tracking_popup_5: "<strong>INGESCHAKELD</strong>- Overruled om te volgen.",
      tracking_popup_6: "<strong>Hieronder vindt u een overzicht van uw huidige Merkactiva:</strong>",
      tracking_popup_7: "om de Brand Asset-tag van uw concepten te wijzigen.",
      tracking_popup_8: "Je hebt geen Brand assets om af te rekenen. Klik op het €/$ icoon voor gewenste concepten om het een Brand Asset te maken of het €/$ icoon ingeschakelde Brand Assets zijn al in tracking (Klik op het concept om de Tracking Status te bekijken).",
      tracking_popup_9: "Volg periodiek voor:",
      tracking_popup_10: "Geselecteerde Duur",
      tracking_table_1: "Kosten per Concept",
      tracking_summary_1: "<strong>Bedankt voor uw bestelling!</strong>",
      tracking_summary_2: "Al uw Merkactiva worden maandelijks gevolgd voor",
      tracking_summary_3: "<strong>OPMERKING:</strong> U kunt de Brand Asset-tags op elk moment tijdens het volgen bijwerken. Bestel opnieuw om opnieuw in te schrijven.",
      tracking_summary_table1: "Merkactiva om te volgen",
      month: 'Maand',
      months: 'Maanden',
    },
  },
};

export { add_new_asset_translation };
