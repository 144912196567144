const dashboard_translation = {
    en: {
        message: {
            ml: 'Add your concepts to the map or use MoodSearch™ to find public Assets',
            aw: 'Discover the true perception of your brand',
            mb: 'Discover how implicit human associations often differ significantly from AI simulations.',
            rp: 'Earn Mindchips and access exclusive rewards',
            cp: 'Uncover the distinguishing connections among your concepts',
            ie: 'Discover your guiding (re)branding motif',
            sc: 'Craft a campaign outline leveraging implicit connections',
            ct: 'Refine your campaigns to shape your brand',
            bt: 'Monitor the impact of your campaigns',
            cc: 'Distinguish brand assets from liabilities',
            hello: 'Hello',
            welcome_to_mdsp: 'Welcome to Mindspeller!',
            we_augument: 'We help augment your creative intuition. Find the hidden gem associations that resonate the most.',
            free_demo: 'Free demo',
            your_license: 'Your license',
            will_expire: ' will expire in ',
            days: 'days',
            start_full_version: 'UNLOCK ALL APPS',
            locked_app_unlock: 'Unlock',
            locked_app_cancel: 'Cancel',
            locked_app_video: 'App video',
            locked_app_description: 'This app is locked under a trial license.',
            dashboard_guidance: 'Start by uploading your Asset for mapping (we map an Asset by asking real people for their spontaneous associations with it) or looking at some existing mapped Assets. You can do this in My Library.',
            website_link_drive: 'Drive',
            website_link_decode: 'Decode',
            website_link_develop: 'Develop',
            library_tutorial: 'Tutorial'
        },
    },
    nl: {
        message: {
            ml: 'Voeg je concepten toe aan de kaart of gebruik MoodSearch™ om publieke Assets te verkennen',
            aw: 'Discover the true perception of your brand',
            mb: 'Ontdek hoe impliciete menselijke associaties vaak significant afwijken van AI simulaties.',
            rp: 'Earn Mindchips and access exclusive rewards',
            cp: 'Uncover the distinguishing connections among your concepts',
            ie: 'Discover your guiding (re)branding motif',
            sc: 'Craft a campaign outline leveraging implicit connections',
            ct: 'Refine your campaigns to shape your brand',
            bt: 'Monitor the impact of your campaigns',
            cc: 'Distinguish brand assets from liabilities',
            hello: 'Hallo',
            welcome_to_mdsp: 'Welkom bij Mindspeller!',
            we_augument: 'Valideer en verfijn je creative intuitie. Verken verborgen emoties en gedeelde passies die resoneren bij je doelgroep.',
            free_demo: 'Gratis demo',
            your_license: 'Jouw licentie',
            will_expire: ' zal vervallen binnen',
            days: 'dagen',
            start_full_version: 'ONTGRENDEL ALLE APPS',
            locked_app_unlock: 'Ontgrendel',
            locked_app_cancel: 'Annuleer',
            locked_app_video: 'App video',
            locked_app_description: 'Deze app is vergrendeld onder een gratis trial licentie.',
            dashboard_guidance: 'Start met het opladen van Assets voor mapping (we vragen echte mensen naar spontane associaties om je Asset op de kaart te zetten) of verken enkele publieke, reeds gemapte Assets. Ga hiervoor naar My Library.',
            website_link_drive: 'Drive',
            website_link_decode: 'Decode',
            website_link_develop: 'Develop',
            library_tutorial: 'Tutorial'
        },
    },
};

export { dashboard_translation };
