<template>
  <div class="popup-container" @click.self="$emit('close')">
    <div class="popup-content">
      <q-btn style="margin-left: 10%;" icon="close" flat round dense @click="$emit('close')" />
      
      <!-- Updated Title -->
      <h2 style="font-weight: bold;">{{ translatedText.title }}</h2>
      <img src="../../assets/mindsearchperson.png" alt="Step 1 Image" style="max-width: 165px; max-height: 200px; margin: auto;" />

      <!-- Benefits Section -->
      <ul class="highlight bullet-points">
        <strong>{{ translatedText.benefitsTitle }}</strong><br>
        <li>
          {{ translatedText.benefitsDescription }}
        </li>
        <li>{{ translatedText.benefitItem1 }}</li>
        <li>{{ translatedText.benefitItem2 }}</li>
        <li>{{ translatedText.benefitItem3 }}</li>
      </ul>
      
      <!-- Investment Section -->
      <p class="highlight">
        <strong>{{ translatedText.investmentTitle }}</strong><br><br>
        {{ translatedText.investmentLine1 }} <small><em>{{ translatedText.investmentLine1Small }}</em></small><br>
        {{ translatedText.investmentLine2 }}<br><br>
      </p>
      
      <!-- Call-to-Action Button -->
      <button class="cta-button" @click="scrollToForm">{{ translatedText.cta }}</button>

      <!-- Form Section -->
      <div class="form-container" ref="formContainer">
        <input type="text" v-model="name" :placeholder="translatedText.inputName" />
        <input type="tel" v-model="email" :placeholder="translatedText.inputEmail" />
        <input type="tel" v-model="phone" :placeholder="translatedText.inputPhone" />
        <textarea v-model="address" :placeholder="translatedText.inputAddress"></textarea>
        <button class="submit-button mx-auto" @click="submitForm">{{ translatedText.submit }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
import axios from 'axios';
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      address: "",
      currentlang: ""
    };
  },
  emits: ["close"],
  computed: {
    translatedText() {
      if (this.currentlang === 'nl') {
        return {
          title: "Mindspeller: Zoek Verder Dan Woorden",
          benefitsTitle: "Jouw Voordelen",
          benefitsDescription:
            "Wat als je gedachten je zoekopdracht zouden kunnen aandrijven? Met Mindspeller’s revolutionaire EEG-aangedreven zoekmachine kun je het web verkennen door simpelweg te denken. Hoe meer je het gebruikt, hoe beter het je gedachten begrijpt en resultaten levert die zijn afgestemd op jouw unieke denkwijze.",
          benefitItem1: "Ontgrendel een nieuw tijdperk van zoeken—sneller, intuïtiever en moeitelozer.",
          benefitItem2: "Verdien je investering in hardware terug in beloningen en kortingen op je favoriete merken door je gedachten te delen.",
          benefitItem3: "Vul het formulier in om meer te weten te komen over ons EEG-headset en wees een van de eersten die handsfree merkassociatie decodeert!",
          investmentTitle: "Jouw Investering",
          investmentLine1: "🔹 Haal nu je EEG-headset voor slechts €259 vooraf*",
          investmentLine1Small: "Exclusief verzending en belastingen",
          investmentLine2: "🔹 Zet inzichten om in inkomen voor slechts €9/maand met Pro Respondent toegang!",
          cta: "🔽 Bestel vandaag nog je EEG-headset en begin met zoeken met je geest! 🔽",
          inputName: "Naam",
          inputEmail: "E-mailadres",
          inputPhone: "Telefoonnummer",
          inputAddress: "Verzendadres",
          submit: "Verzenden"
        };
      } else {
        return {
          title: "Mindspeller: Search Beyond Words",
          benefitsTitle: "Your Benefits",
          benefitsDescription:
            "What if your thoughts could power your search? With Mindspeller’s revolutionary EEG-powered search engine, you can explore the web just by thinking. The more you use it, the better it understands your mind, delivering results tailored to your unique way of thinking.",
          benefitItem1: "Unlock a new era of search—faster, intuitive, and effortless.",
          benefitItem2: "Earn back your hardware investment in rewards and discounts on your favorite brands by sharing your thoughts.",
          benefitItem3: "Fill out the form to learn more about our EEG headset and be among the first to experience hands-free brand association decoding!",
          investmentTitle: "Your Investment",
          investmentLine1: "🔹 Get your EEG headband now for just €259 upfront*",
          investmentLine1Small: "Excludes shipping and taxes",
          investmentLine2: "🔹 Turn insights into income for just €9/month with Pro Respondent access!",
          cta: "🔽 Order your EEG headset today and start searching with your mind! 🔽",
          inputName: "Name",
          inputEmail: "Email Address",
          inputPhone: "Phone Number",
          inputAddress: "Shipping Address",
          submit: "Submit"
        };
      }
    }
  },
  methods: {
    scrollToForm() {
      const formContainer = this.$refs.formContainer;
      formContainer.scrollIntoView({ behavior: 'smooth' });
    },
    async submitForm() {
      if (!this.name || !this.phone || !this.email || !this.address) {
        alert("Please fill in all the fields.");
        return;
      }

      const formData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        address: this.address,
      };

      try {
        const response = await axios.post('/api/cas/preorder_prorespondent', formData);

        if (response.status === 200) {
          toast_success("You will be contacted shortly to confirm your pre-order. Thank you!");
        } else {
          alert("Failed to submit the form. Please try again.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
        console.error(error);
      } finally {
        this.$emit("close");
      }
    },
  },
  created() {
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  }
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  border-radius: 0%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #ffffff;
  padding: 30px;
  padding-right: 38px;
  border-radius: 15px;
  max-width: 60%;
  width: 90%;
  max-height: 85%;
  overflow-y: auto;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

h2 {
  background-color: #7878e9;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 85%;
  color: white;
  margin: auto;
  margin-bottom: 3%;
  padding: 5px;
  font-size: 22px;
  text-align: center;
}

.popup-content::-webkit-scrollbar {
  width: 10px;
  margin-right: 10%;
}

.popup-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.popup-content::-webkit-scrollbar-thumb {
  background: #d9e1ec;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #b8c7d9;
}

.popup-content .highlight {
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px;
  line-height: 1.6;
  background-color: #f9f9f9;
  padding: 15px;
  text-align: left;
  border-left: 5px solid #7878e9;
  border-radius: 5px;
}
.bullet-points {
  list-style: none;
  background-color: #f9f9f9;
  padding: 15px;
  border-left: 5px solid #7878e9;
  border-radius: 5px;
}

.bullet-points li {
  margin: 10px 0;
  position: relative;
  padding-left: 25px;
}
.bullet-points li::before {
  content: "🔹";
  position: absolute;
  left: 0;
}

.cta-button {
  background-color: #6a5acd;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #5940b3;
}

/* Form Section */
.form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.form-container textarea {
  resize: none;
  height: 80px;
}

.submit-button {
  background-color: #28a745;
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #218838;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* =========================
   MOBILE MEDIA QUERY (<= 768px)
   ========================= */
@media only screen and (max-width: 768px) {
  .popup-content {
    max-width: 100% !important;
    width: 100%;
    padding: 20px;
    padding-right: 20px;
    border-radius: 10px;
    box-shadow: none;
  }

  h2 {
    padding: 0;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
  }

  .popup-content .highlight {
    font-size: 16px;
    padding: 10px;
    margin-top: 5px;
  }

  .cta-button {
    font-size: 14px;
    padding: 6px 16px;
    margin-top: 15px;
  }

  .form-container {
    gap: 10px;
  }
  .form-container input,
  .form-container textarea {
    font-size: 13px;
    padding: 8px;
  }
  .submit-button {
    font-size: 14px;
    padding: 8px;
  }
}
@media only screen and (max-width: 480px) {
  .cta-button {
    font-size: 11px !important;
  }
  h2 {
    font-size: 13px !important;
    line-height: 20px !important;
  }
}
</style>
