<template>
    <div class="popup-overlay">
        <loading-app-wide v-if="loading" />
      <div class="popup-container">
        <!-- Close Button -->
        <q-btn icon="close" flat round dense class="close-button" @click="$emit('exit')" />
  
        <!-- Header -->
        <div class="popup-header">
          <h2 class="popup-title">TRACKING BRAND ASSETS - SUMMARY</h2>
        </div>
  
        <div class="content-container">
          <!-- Left Panel: Summary -->
          <div class="left-panel">
            <div class="summary-details">
  
              <!-- Orders Section -->
              <div v-if="notEnoughFunds" class="order-box">
                <p>
                  <strong>You do not have enough Mindcoins to checkout your order.</strong>
                </p>
                <p>
                  <strong>
                    Click <button style="padding: 0.2vw 1vw" class="yes-button" @click.prevent="redirectToSubscription">
                      HERE
                    </button> 
                    to add more mindcoins.
                  </strong>
                </p>
              </div>
  
              <div v-if="!notEnoughFunds" class="order-box">
                <p v-html="$t('new_asset.tracking_summary_1')">
                </p>
                <p>
                  {{$t('new_asset.tracking_summary_2')}} {{ this.selectedDuration }} {{$t('new_asset.months')}}.
                </p>
                <p style="font-size: 10px;" v-html="$t('new_asset.tracking_summary_3')"></p>
              </div>
            </div>
            <!-- Asset Summary Section -->
            <div class="asset-summary">
                <h4>SUMMARY</h4>
                <div class="scrollable-container">
                <table class="asset-summary-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{$t('new_asset.tracking_summary_table1')}}</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asset, index) in assetDisplayNames" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ asset.display_name.toUpperCase()}}</td>
                      <td>{{ getTypeDescription(asset.type) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            
          </div>
  
          <!-- Right Panel: Checkout Section -->
          <div class="right-panel">
            <div class="checkout-box">
              <h3>CHECKOUT</h3>
              <!-- Duration Selection -->

              <div v-if="assetCount != 0" class="line-item">
                <span>Total Charge / {{$t('new_asset.month')}}</span>
                <span>+{{ totalCharge}}</span>
              </div>
              <div v-if="assetCount != 0" class="line-item">
                <span>{{$t('new_asset.tracking_popup_10')}}</span>
                <span>{{ this.selectedDuration}} {{$t('new_asset.months')}}</span>
              </div>
              <div v-if="assetCount != 0"class="line-item">
                <span>Total Charge ({{ this.selectedDuration }} {{$t('new_asset.months')}})</span>
                <span>+{{ totalMindcoins}}</span>
              </div>
              <div class="line-item total">
                <span>Total Mindcoins</span>
                <span>{{ totalMindcoins }}</span>
              </div>
              <button class="customize-button" :disabled="notEnoughFunds" @click="confirmOrder">ORDER</button>
              <p style="color: red; font-size: 10px; line-height: normal; margin-top: 5px;" v-if="assetCount == 0">{{ $t('new_asset.tracking_popup_8') }}</p>
            </div>
          </div>
        </div>
  
        <!-- Navigation Buttons -->
        <div class="popup-footer">
          <button class="back-button" @click="$emit('back')">Back</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
  import LoadingAppWide from '@/components/common/LoadingAppWide.vue';



  export default {
    name: "SummaryPopup",
    emits: ["exit", "back"],
    props: {
      assetCount: Number,
      totalMindcoins: Number,
      assetDisplayNames: Array,
      selectedDuration: Number,
      totalCharge: Number,
    },
    components: {LoadingAppWide },

    data() {
      return {
        showTooltip: false,
        loading: false,
      };
    },
    computed: {
      notEnoughFunds() {
        return this.totalMindcoins > this.$store.state.mindcoins;
      },

    },
    methods: {
        getTypeDescription(type) {
                switch (type) {
                    case 1:
                    return 'Word';
                    case 2:
                    return 'Image';
                    case 5:
                    case 7:
                    return 'Audio/Video';
                    default:
                    return 'Unknown';
                }
            },
        async redirectToSubscription() {
            if (
            this.$store.state.current_license.monthly_fee > 0 &&
            this.$store.state.current_license.name !== "150 credits per month per user"
            ) {
            // Show the generic dialog for subscription confirmation
            const dialogOptions = {
                title: "Subscription Confirmation",
                text: "You are currently under a subscription model. Please contact your customer success manager.",

                confirmButtonText: "Contact",
                cancelButtonText: "Cancel",
            };

            const dialogResult = await genericDialog(dialogOptions);
            if (dialogResult.isConfirmed) {
                // Redirect to the library if confirmed
                window.open("https://ads.mindspeller.com/contact/", "_blank")
            }
            } else {
            // Redirect to subscription plans if the user isn't under a subscription
            window.open(this.$router.resolve('/subscription_plans').href, '_blank');
            }
        },
        async checkLicense() {
            try {
                const response = await axios.post('/api/cas/assets/monitoring/check_license', {
                totalMindcoins: this.totalMindcoins
                });
                this.withinBudget = response.data.within_budget;
            } catch (error) {
                console.error('Error checking license:', error);
                alert_error(this.$t('message.general_server_error'));
            }
            },
        async confirmOrder() {
            await this.$store.dispatch('get_mindcoin_balance');
                if (this.$store.state.mindcoins < this.totalMindcoins) {
                alert_error(this.$t('new_asset.insufficient_credits'));
                return;
            }
            try {
                this.loading = true;
                const payload = {
                ids: this.assetDisplayNames.map(asset => asset.id), total_mindcoins: this.totalMindcoins, duration: this.selectedDuration
                };
                // this API endpoint will toggle monitoring, that's why we
                // only need to send asset id
                const response = await axios.post('/api/cas/assets/monitoring/toggle', payload);
            if (response.status === 200) {
                toast_success(`Your order has been placed. Your Brand Assets will be tracked every month.`);
                this.$store.dispatch('get_mindcoin_balance');
            }
            } catch (error) {
            console.error('Error processing order:', error);
            alert_error(this.$t('message.general_server_error'));
            } finally {
                this.load
            this.$emit('exit');
            }
        }
    }
  };
  </script>
  
  <style scoped>
  /* Background Overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Popup Container */
  .popup-container {
    background: white;
    width: 80%;
    max-width: 1000px;
    padding: 20px;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Header */
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    position: relative;
  }
  
  .popup-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  /* Content Layout */
  .content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Left Panel */
  .left-panel {
    flex: 2;
    display: flex;
    color: #000;
    flex-direction: column;
    gap: 10px;
  }
  
  .summary-details {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .summary-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .summary-list {
    list-style: none;
    padding: 0;
  }
  
  .summary-list li {
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  /* Orders Box */
  .order-box {
    background: white;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    margin-top: 15px;
  }

  .order-box p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .order-header {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Right Panel */
  .right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .checkout-box {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 20px;
    width: 300px;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .checkout-box h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  .line-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .line-item.total {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-top: 20px;
  }
  
  /* Buttons */
  .customize-button {
    background: #0000ff;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 15px;
    width: 100%;
    cursor: pointer;
  }
  
  .customize-button:hover {
    background: #0000cc;
  }
  
  .customize-button:disabled {
  background: #cccccc; /* Lighter grey background */
  color: #666666; /* Darker grey text */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Make the button more transparent */
}

  .yes-button {
  background: #0a00ff;
  color: #fff;
  border: none;
  border-radius: 2vw;
  padding: 0.5vw 2vw;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.yes-button:hover{
  background: #0800ff8f;
}

/* Tooltip */
.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    bottom: 130%;
    left: 40%;
    width: 200px;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    font-size: 12px;
    padding: 8px;
    border-radius: 6px;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
    z-index: 10;
  }
  
  .info-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #7878e9;
  }

  /* Navigation Buttons */
  .popup-footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .back-button {
    background: #000000;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background: #3d3d3d;
  }
  
  /* Icons */
  .check-icon {
    color: #5a67d8;
    margin-right: 8px;
  }
  
  .order-icon {
    margin-right: 8px;
  }

  .asset-summary {
  margin-top: 5px;
  text-align: left;
}

.asset-summary h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.scrollable-container {
  max-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 3px solid #ddd;
  border-radius: 6px;
}

/* Custom Scrollbar for WebKit Browsers */
.scrollable-container::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #d9e1ec; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar thumb */
  border: 2px solid transparent; /* Optional: Space around the thumb */
  background-clip: padding-box; /* Ensures thumb respects border */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #b8c7d9; /* Thumb color when hovered */
}

.asset-summary-table {
  width: 100%;
  border-collapse: collapse;
}

.asset-summary-table th,
.asset-summary-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.asset-summary-table th {
  background: #7878e9;
  position: sticky;
  font-weight: bold;
}

.asset-summary-table td {
  font-size: 14px;
}

.asset-summary-table thead th {
  position: sticky; /* Make the table headers stick */
  top: 0; /* Stick to the top */
  background: #7878e9; /* Header background color */
  color: white;
  z-index: 2; /* Ensure it stays above the scrolling body */
  border-bottom: 2px solid #ccc;
  text-align: center;
  padding: 10px;
}

.asset-summary-table tbody td {
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
}
  
  </style>
  