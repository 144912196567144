<template>
  <div>
    <div v-if="showPopup" class="overlay" @click.self="closePopup">
      <div class="instructions-popup">
        <!-- Header with title and close button -->
        <div class="popup-header">
          <h2>Instructions</h2>
          <button class="close-button" @click="closePopup">✖</button>
        </div>
        <!-- Introductory text -->
        <div class="popup-header secondary-header">
          <p>
            Explore this interactive visualization to uncover Mindspeller's B2C brand recommendations tailored to your brand category.
          </p>
        </div>
        <!-- Interactive Controls Step -->
        <div class="step-container">
          <h3>Interactive Controls</h3>
          <p><strong>"PAN": </strong>Right Mouse Button | <strong>"ROTATE": </strong>Left Mouse Button HOLD + Move |</p>
          <p><strong>"ZOOM": </strong>Mouse wheel | <strong>"RESET ZOOM/ROTATION": </strong>Zoom out icon on top right |</p>
        </div>
        <!-- Popup Content with multiple step instructions -->
        <div class="popup-content">
          <!-- Step 1 -->
          <div class="step-container">
            <h3>Step 1: Enter a keyword in the My Search box</h3>
            <img src="../../assets/step_1.png" alt="Step 1 Image" class="step-image" />
            <p>
              Use the My Search box to find a specific concept in the network. Suggestions will appear as you type.
              Click on the concept of interest from your search result list to proceed.
            </p>
          </div>
          <!-- Step 2 -->
          <div class="step-container">
            <h3>Step 2: Isolate your concept of interest</h3>
            <img src="../../assets/step_2.png" alt="Step 2 Image" class="step-image" />
            <p>
              Click the <strong>"ISOLATE"</strong> button to focus on the node. Adjust the "Neighbors" slider to refine your selection.
            </p>
          </div>
          <!-- Step 3 -->
          <div class="step-container">
            <h3>Step 3: Adjust the number of visible "Neighbors"</h3>
            <img src="../../assets/step_3.png" alt="Step 3 Image" class="step-image" />
            <p>
              To adjust the number of "Neighbors" in real-time after isolating, you can only reduce the value. To increase it, click the home button and repeat the isolation process.
            </p>
          </div>
          <!-- Step 4 -->
          <div class="step-container">
            <h3>Step 4: Explore Concept Details.</h3>
            <img src="../../assets/step_4_1.png" alt="Step 4 Image" class="step-image" />
            <p>
              Click on any concept in the network to view its mapping date, concept type, and URL.
            </p>
            <p>
              <strong>Caution:</strong> The concept you see here will drive the Mindspeller B2C brand recommendations.
              If you cannot see your keyword of interest, click the home button on the right-side bar and go back to Step 1.
            </p>
          </div>
          <!-- Step 5 -->
          <div class="step-container">
            <h3>Step 5: Select your Value</h3>
            <img src="../../assets/step_4.png" alt="Step 4 Image" class="step-image" />
            <p>
              Open the dropdown to select your desired value and uncover brand recommendations tailored to the combination of your keyword and selected value.
            </p>
            <p>
              <strong>Tip:</strong> Add new values to personalize your brand recommendations.
            </p>
          </div>
          <!-- Step 6 -->
          <div class="step-container">
            <h3>Step 6: Explore Mindspeller Associations</h3>
            <img src="../../assets/step_6.png" alt="Step 6 Image" class="step-image" />
            <p>
              Explore top ranking Mindspeller B2C brand recommendations related to the product category ('My Search' keyword)
              and the chosen Value. Click on the link icon to view the related brand website.
            </p>
          </div>
        </div>
        <!-- Footer with CTA button -->
        <div class="popup-footer">
          <button class="primary-button" @click="closePopup">Got it!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showPopup: false, // Control the visibility of the popup
    };
  },
  computed: {
    ...mapGetters(["showInstructionPopup"]),
  },
  methods: {
    ...mapActions(['initializeInstructionPopup', 'dismissInstructionPopup']),
    openPopup() {
      this.showPopup = true;
      this.$store.dispatch('dismissInstructionPopup');
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style scoped>
/* Popup Container */
.instructions-popup {
  width: 50%;
  max-height: 70%;
  background: #ffffff;
  border: 1px solid #000;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Header */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.popup-header h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* Secondary header for intro paragraph */
.secondary-header p {
  font-size: 1rem;
  color: #333;
  margin: 5px 0 15px;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000;
}

/* Content */
.popup-content {
  overflow-y: auto;
}

.step-container {
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  background: #f9fbfd;
}

.step-container h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.step-image {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.step-container p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Footer */
.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.primary-button {
  background: #4caf50;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.primary-button:hover {
  background: #45a049;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -55%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .instructions-popup {
    width: 90%;
    max-height: 80%;
    padding: 15px;
  }
  
  .popup-header h2 {
    font-size: 1.4rem;
  }
  
  .close-button {
    font-size: 1.2rem;
  }
  
  .secondary-header p {
    font-size: 0.9rem;
  }
  
  .step-container {
    padding: 10px;
  }
  
  .step-container h3 {
    font-size: 1.2rem;
  }
  
  .step-image {
    max-height: 300px;
  }
  
  .step-container p {
    font-size: 0.9rem;
  }
  
  .primary-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
</style>
