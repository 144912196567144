<template>
    <div class="popup-overlay">
      <div class="popup-container">
        <!-- Close Button -->
        <q-btn icon="close" flat round dense class="close-button" @click="$emit('exit')" />
  
        <!-- Header -->
        <div class="popup-header">
          <h2 class="popup-title">Pricing & Timing - CUSTOMIZATION</h2>
        </div>
  
        <div class="content-container">
          <!-- Left Panel: Pricing Details -->
          <div class="left-panel">
            <div class="pricing-details">
              <p><strong>Pricing:</strong> A fixed setup fee plus a variable charge based on the total number of positioned 'My Library' concepts.</p>
  
              <table class="pricing-table">
                <thead>
                  <tr>
                    <th>Your Concepts</th>
                    <th>Cost per Concept</th>
                    <th>Total Charge</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ assetCount }}</td>
                    <td>{{ perConceptFee }} Mindcoins</td>
                    <td>{{ totalCharge }} Mindcoins</td>
                  </tr>
                </tbody>
              </table>
  
              <p><strong>Estimated Processing Time ⏳</strong></p>
              <ul class="network-processing-info">
                <li><font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" /> Base Processing Time: Minimum 2 business days.</li>
                <li><font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" /> Additional Processing Time: ~5 minutes per concept.</li>
                <li><font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" /> You'll be notified when your network is ready!</li>
              </ul>
              <p>NOTE: Processing time begins once your order starts being processed, following a first-come, first-served basis.</p>
            </div>
          </div>
  
          <!-- Right Panel: Checkout Section -->
          <div class="right-panel">
            <div class="checkout-box">
              <h3>CHECKOUT</h3>
              <div class="line-item">
                <span class="tooltip-container">
                  Setup Fee (one-time)
                  <font-awesome-icon :icon="['fas', 'info-circle']" class="info-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false"/>
                  <span v-if="showTooltip" class="tooltip">
                    Setup Fee: Covers setup, customization, and secure integration of your private concepts with the Mindspeller network.
                  </span>
                </span>
                <span>+{{ setupFee }}</span>
              </div>
              <div class="line-item">
                <span>Per Concept Fee (one-time)</span>
                <span>+{{ totalCharge }}</span>
              </div>
              <hr />
              <div class="line-item total">
                <span>Total Mindcoins</span>
                <span>{{ totalMindcoins }}</span>
              </div>
              <button class="customize-button" disabled >ORDER</button>
              <div v-if="assetCount === 0" class="footnote">
                You have 0 positioned concepts in 'My Library'. You cannot place an order.
              </div>
            </div>
          </div>
        </div>
  
        <!-- Navigation Buttons -->
        <div class="popup-footer">
          <button class="back-button" @click="$emit('back')">Back</button>
          <button class="next-button" @click="openSummaryPopup" :disabled="isAssetCountZero">Next</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "PricingPopup",
    emits: ["exit", "back", "openSummaryPopup"],
    data() {
      return {
        assetCount: 0,
        setupFee: this.$store.getters.get_price('setupfee'),
      };
    },
    computed: {
      perConceptFee() {
        if (this.assetCount <= 50) return 20;
        if (this.assetCount <= 100) return 10;
        if (this.assetCount <= 500) return 5;
        if (this.assetCount <= 2000) return 2;
        return 1;
      },
      totalCharge() {
        return this.assetCount * this.perConceptFee;
      },
      totalMindcoins() {
        return this.setupFee + this.totalCharge;
      },
      isAssetCountZero() {
        return this.assetCount === 0;
      }
    },
    methods: {
      async fetchAssetCount() {
        try {
          const response = await axios.get("/api/cas/assets/mylibrary_count");
          this.assetCount = response.data.asset_count || 0;
        } catch (error) {
          console.error("Error fetching asset count:", error);
        }
      },
      openSummaryPopup(){
        if (!this.isAssetCountZero) {
          this.$emit('openSummaryPopup', {
            assetCount: this.assetCount,
            setupFee: this.setupFee,
            totalCharge: this.totalCharge,
            totalMindcoins: this.totalMindcoins
          });
        }
      }
    },
    mounted() {
      this.fetchAssetCount();
    }
  };
  </script>
  
  <style scoped>
  /* Background Overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Popup Container */
  .popup-container {
    background: white;
    width: 80%;
    max-width: 1000px;
    padding: 20px;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Header */
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    position: relative;
  }
  
  .popup-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  /* Content Layout */
  .content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Left Panel */
  .left-panel {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .pricing-details {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 14px;
    font-size: 14px;
  }
  
  .pricing-details p {
    font-size: 14px;
  }
  
  .pricing-table {
    width: 100%;
    border-collapse: separate; /* Change from collapse to separate */
    border-spacing: 0;
    margin-top: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden; /* Ensures rounded corners */
  }
  
  /* Target the first and last rows to round the top and bottom */
  .pricing-table thead tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .pricing-table thead tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .pricing-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  .pricing-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  
  /* Ensure each cell has a border */
  .pricing-table th,
  .pricing-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  /* Optional: Style the header */
  .pricing-table th {
    background: #7878e9;
    color: white;
  }
  
  .network-processing-info {
    list-style: none;
    padding: 0;
    text-align: left;
  }
  
  .network-processing-info li {
    margin-bottom: 8px;
    text-align: left;
  }
  
  /* Right Panel */
  .right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .checkout-box {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 20px;
    width: 300px;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .checkout-box h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  .customize-button {
    background: #7878e9;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 15px;
    width: 100%;
    cursor: not-allowed;
    opacity: 0.6;
  }

  .customize-button:disabled {
    background: #cccccc; /* Lighter grey background */
    color: #666666; /* Darker grey text */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
    opacity: 0.6; /* Make the button more transparent */
  }
  .line-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    position: relative;
  }
  
  .check-icon {
    color: #5a67d8;
    margin-right: 8px;
  }

  .line-item.total {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-top: 35px;
  }
  
  /* Tooltip */
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    bottom: 130%;
    left: 40%;
    width: 200px;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    font-size: 12px;
    padding: 8px;
    border-radius: 6px;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
    z-index: 10;
  }
  
  .info-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #7878e9;
  }
  
  /* Navigation Buttons */
  .popup-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .next-button {
    background: #5a5dbb;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
  }


  .back-button {
    background: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .back-button:hover, .next-button:hover {
    background: #444477;
  }

  .footnote {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
  }

  .footnote {
    margin-top: 10px;
    font-size: 0.9em;
    color: red;
  }
  </style>
  