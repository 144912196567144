<template>
  <main>
    <section style="height: 100vh;">
      <div class="container-fluid full-width-pg forget-pass-pg">
        <div class="row">
          <div
            class="col-md-7 p-2 d-flex align-items-center justify-content-center"
            style="height: 100vh; background-color: blue;"
          >
            <div
              class="respondent-img-box"
              style="width: 100%; height: 100%; overflow: hidden;"
            >
              <a
                href="https://www.youtube.com/watch?v=c66QqSHDSNI"
                target="_blank"
              >
                <img
                  src="../../assets/respondentmap.png"
                  style="width: 100%; height: 100%; object-fit: contain;"
                />
              </a>
            </div>
          </div>
          <div class="col-md-5 ">
              <div class="row">
                <div class="col-md-12 p-3">
                  <div class="user-icon-wrapper" @click="openNetworkPage">
                    <font-awesome-icon
                      :icon="['fas', 'project-diagram']"
                      alt="Login"
                      class="user-icon"
                    />
                    <span class="login-text">B2C Mindspeller Network</span>
                  </div>
                  <div
                    class=" d-flex justify-content-center align-items-center mt-4 margin"
                  >
                    <img
                      class="logo"
                      src="../../assets/logo-with-text.png"
                      alt=""
                      style="width: auto; height: auto;"
                    />
                  </div>
  
                  <form class="p-3 p-lg-5 form-spacing row">
                    <div class="textbx mb-1">
                    Set the password for your account
                  </div>
                  <ul class="set-passw-li">
                  <li>
                    <span v-if="hasLength">✅ </span
                    ><span :style="hasLengthColor">{{
                      $t("message.characters_requirement")
                    }}</span
                    ><br />
                  </li>
                  <li>
                    <span v-if="hasUppercase">✅ </span
                    ><span :style="hasUppercaseColor">{{
                      $t("message.uppercase_requirement")
                    }}</span
                    ><br />
                  </li>
                  <li>
                    <span v-if="hasLowercase">✅ </span
                    ><span :style="hasLowercaseColor">{{
                      $t("message.lowercase_requirement")
                    }}</span
                    ><br />
                  </li>
                  <li>
                    <span v-if="hasNumberAndWhitespace">✅ </span
                    ><span :style="hasNumberAndWhitespaceColor">{{
                      $t("message.number_or_whitespace_requirement")
                    }}</span
                    ><br />
                  </li>
                </ul>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{ $t("message.set_password") }}</label>
                        
                        <div
                          style="display: flex; gap: 5px; align-items: center;"
                        >
                        <input
                          class="form-control"
                          :type="passwordInputType"
                          v-model="passwordOne"
                          @blur="checkPasswordMatch"
                        />
                        <span
                          class="eye-icon1"
                          @click="this.showPassword = !this.showPassword"
                        >
                          <img
                            v-if="showPassword"
                            src="../../assets/password-eye-open.png"
                          />
                          <img v-else src="../../assets/password-eye.png" />
                        </span>
                        </div>
                      </div>
                    </div>
  
                    <div class="col-12">
                      <div class="form-group mt-3">
                        <label>{{ $t("message.repeat_password") }}</label>
                        <div
                          style="display: flex; gap: 5px; align-items: center;"
                        >
                        <input
                          class="form-control"
                          :type="passwordInputType"
                          v-model="passwordTwo"
                          @blur="checkPasswordMatch"
                        />
                        <span
                          class="eye-icon1"
                          @click="this.showPassword = !this.showPassword"
                        >
                          <img
                            v-if="showPassword"
                            src="../../assets/password-eye-open.png"
                          />
                          <img v-else src="../../assets/password-eye.png" />
                        </span>
                        </div>
                      </div>
                      <p class="matchingPasswordText" v-if="!passwordMatch">
                        {{ $t("message.passwords_dont_match") }}
                      </p>
                    </div>
  
                   
  
                    <div class="btn-col mb-0 mt-2">
                    <button
                      class=" h-auto p-2"
                      type="button"
                      v-bind:class="getClass()"
                      @click="proceedToOnboarding"
                    >
                      {{ $t("message.start_trial") }}
                    </button>
                  </div>
                    <div class="google-login text-center mt-3">
                      <p class="mb-lg-2 mb-2" style="line-height: normal;">
                    Already have an account?
                    <router-link :to="{ name: 'Login' }">Log In </router-link>
                  </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          
        </div>
        <!-- <div class="free-trial-pg">
          <Macrotellect />
          <div class="rgt-sec-bar">
            <div class="free-login-box">
              <div class="logo-imgbx">
                <img src="../../assets/logo-no-text.png" alt="" />
              </div>
              <div class="textbx">
                Set the password for your account
  
                <ul class="set-passw-li">
                  <li>
                    <span v-if="hasLength">✅ </span
                    ><span :style="hasLengthColor">{{
                      $t("message.characters_requirement")
                    }}</span
                    ><br />
                  </li>
                  <li>
                    <span v-if="hasUppercase">✅ </span
                    ><span :style="hasUppercaseColor">{{
                      $t("message.uppercase_requirement")
                    }}</span
                    ><br />
                  </li>
                  <li>
                    <span v-if="hasLowercase">✅ </span
                    ><span :style="hasLowercaseColor">{{
                      $t("message.lowercase_requirement")
                    }}</span
                    ><br />
                  </li>
                  <li>
                    <span v-if="hasNumberAndWhitespace">✅ </span
                    ><span :style="hasNumberAndWhitespaceColor">{{
                      $t("message.number_or_whitespace_requirement")
                    }}</span
                    ><br />
                  </li>
                </ul>
              </div>
              <div class="formbx set-pass-from">
                <form>
                  <div class="form-group" style="margin-bottom: 2vw;">
                    <label>{{ $t("message.set_password") }}</label>
                    <div style="display: flex; gap: 5px; align-items: center;">
                      <input
                        class="form-control"
                        :type="passwordInputType"
                        v-model="passwordOne"
                        @blur="checkPasswordMatch"
                      />
                      <span class="eye-icon1" @click="this.showPassword = !this.showPassword">
                          <img v-if="showPassword" src="../../assets/password-eye-open.png" />
                          <img v-else src="../../assets/password-eye.png" />
                      </span>
                    </div>
                  </div>
  
                  <div class="form-group">
                    <label>{{ $t("message.repeat_password") }}</label>
                    <div style="display: flex; gap: 5px; align-items: center;">
                      <input
                        class="form-control"
                        :type="passwordInputType"
                        v-model="passwordTwo"
                        @blur="checkPasswordMatch"
                      />
                      <span class="eye-icon1" @click="this.showPassword = !this.showPassword">
                          <img v-if="showPassword" src="../../assets/password-eye-open.png" />
                          <img v-else src="../../assets/password-eye.png" />
                      </span>
                    </div>
                  </div>
                  <p class="matchingPasswordText" v-if="!passwordMatch">
                    {{ $t("message.passwords_dont_match") }}
                  </p>
  
                  <button
                    type="button"
                    :disabled="buttonActive"
                    v-bind:class="getClass()"
                    style="margin-top: 10px;"
                    @click="proceedToOnboarding"
                  >
                    {{ $t("message.start_trial") }}
                  </button>
                </form>
                <div class="logindiv">
                  Already have an account?
                  <router-link :to="{ name: 'Login' }">Log In </router-link>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>
  </main>
  <loading-app-wide v-if="loading" />
</template>

<script>
import axios from "axios";
import Macrotellect from "./Common/Macrotellect.vue";
import { changeLanguage, alert_error } from "@/helpers/language_helper.js";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";

export default {
  name: "RespondentPasswordSetup",
  components: {
    Macrotellect,
    LoadingAppWide,
  },
  props: {
    userEmail: {
      required: true,
      type: String,
    },
    respondent: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      passwordOne: new String(),
      passwordTwo: new String(),
      acceptedTOS: false,
      showPassword: false,
      passwordMatch: Boolean,
      email: this.$props.userEmail,
      loading: false,
    };
  },
  computed: {
    // All password requirements have to be met to be able to proceed to the next screen.
    buttonActive: function() {
      return !(
        this.passwordOne != "" &&
        this.passwordTwo !== "" &&
        this.passwordOne == this.passwordTwo &&
        this.hasLowercase &&
        this.hasUppercase &&
        this.hasLength &&
        this.hasNumberAndWhitespace
      );
    },
    passwordInputType: function() {
      return this.showPassword ? "text" : "password";
    },
    hasUppercase: function() {
      return /[A-Z]/.test(this.passwordOne);
    },
    // Coloring UI element if password meets the requirement.
    hasUppercaseColor: function() {
      return this.hasUppercase ? "color: green;" : "color: black;";
    },
    hasLowercase: function() {
      return /[a-z]/.test(this.passwordOne);
    },
    hasLowercaseColor: function() {
      return this.hasLowercase ? "color: green;" : "color: black;";
    },
    hasNumberAndWhitespace: function() {
      return /[1-9]/.test(this.passwordOne) || this.passwordOne.includes(" ");
    },
    hasNumberAndWhitespaceColor: function() {
      return this.hasNumberAndWhitespace ? "color: green;" : "color: black;";
    },
    hasLength: function() {
      return this.passwordOne.length >= 8;
    },
    hasLengthColor: function() {
      return this.hasLength ? "color: green;" : "color: black;";
    },
  },
  methods: {
    getClass: function() {
      return {
        btn3: !this.buttonActive,
        "disabled-btn": this.buttonActive,
      };
    },

    // If user set up his email and account, we transfer him to Onboarding where the
    // rest of registration takes place. The data collected so far gets sent as a
    // prop.
    proceedToOnboarding: async function() {
      this.loading = true;
      const payload = {
        user_data: {
          user_email: this.email,
          user_password: this.passwordOne,
          respondent: this.respondent,
        },
      };
      console.log(payload);

      try {
        const result = await axios.post("/api/cas/register_demo", payload);

        if (result.status === 200) {
          const data = {
            email: this.email,
            password: this.passwordOne,
          };

          await this.$store.dispatch("loginWe", data);
          await this.$store.dispatch("load_user_data");
          changeLanguage(this.$i18n, this.$store.state.current_user.language);
          // this.$router.push({
          //   name: "Redirecting",
          //   params: {
          //     userEmail: this.email,
          //     userPassword: this.passwordOne,
          //   },
          // });
          console.log(this.$store.state.is_respondent);

          this.$router.push({
            path: "/respondentpanel",
            query: { active: "" },
          });
        }
      } catch (error) {
        alert_error(this.$t("message.general_server_error"));
      }
      this.loading = false;
    },
    checkPasswordMatch: function() {
      if (this.passwordOne.length > 0 && this.passwordTwo.length > 0) {
        if (this.passwordOne != this.passwordTwo) {
          this.passwordMatch = false;
          return;
        } else {
          this.passwordMatch = true;
          return;
        }
      } else {
        this.passwordMatch = Boolean;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@media only screen and (max-width:768px){
  span.eye-icon1 {
    width: 18px !important;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 40px !important;
  }
  .user-icon-wrapper{
    top:12px !important;
  }
}
@media only screen and (max-width: 426px) {
  span.eye-icon1 {
    width: 18px !important;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 40px !important;
  }
  .textbx {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 8vw !important;
    color: #000185;
    margin-bottom: 2vw;
}
.set-passw-li {
    color: #000;
    font-size: 8px !important;
    font-weight: normal;
    line-height: normal !important;
    text-align: left;
    margin-top: 2vw;
    list-style: disc;
    margin-left: 2vw;
}
}
.onboarding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.signup-dialog {
  width: 55%;
  min-height: 65%;
}

input:focus {
  outline: 1px solid transparent;
}

.password-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  margin: 20px !important;
}

.mdsp_link {
  color: $mdspblue_ui;
  text-decoration: underline solid transparent;
}

.mdsp_link:hover {
  color: $mdspblue_ui;
  text-decoration: underline solid transparent;
}

.tosText {
  cursor: pointer;
}

.no-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.no-padding-bottom {
  padding-top: 20px;
  padding-bottom: 0;
}

.text-center {
  text-align: center;
}

.matchingPasswordText {
  text-align: left;
  color: red;
  margin-top: 14px;
}
span.eye-icon1 {
  width: 22px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 70px;
}

span.eye-icon1 img {
  width: 100%;
}
</style>
