import $ from 'jquery';
import * as d3 from 'd3';
import moment from 'moment';

export default class Wheel {

    constructor(id, vue_vm) {
        this.vue_vm = vue_vm;
        this.id = id;
        this.cfg = {
            font_size: 18,          //Font size of the labels
            font_weight: 'bold',    //Font size of the labels
            font_color: '#000',     //Font color of the labels
            font_family: 'Open+Sans', //Font family of the labels
            color_scheme: 'default',  //The color scheme of the wheel
            margin: 30,               // The margin of the graph
            with_percentages: false, //Show percentages for first order association
            translate: false,        //Translate the text to english
            blur: false,           //Blur associations for demo purposes
            fas_percentage: 1,      // The cumulative fas percentage to show
            collapse_percentage: 0,  // The cumulative fas percentage to collapse
            show_n: -1,              // The total amount of associations to show (-1 to show all)
            display_image: true,     // Show image in wheel center if available
            display_info: true,      // Show extra wheel information (stability, nbResponses, ...)
            color_selections: [],   // Array of objects containing colors for specific words
            clicked: function (clicked_el) {
            }   // Function that gets executed on wheel click
        }
    }

    /*
    Get a color for each child in data. The color schemes are provided by D3.
    For more details see https://github.com/d3/d3-scale-chromatic#sequential-multi-hue.
    */
    getColorScheme(data, scheme = 'Viridis') {
        let result = [];
        let accumulator = 0;
        for (const child of data.children) {
            const position = 1 - (accumulator + child.percentViz) / 100;
            accumulator = accumulator + child.percentViz;
            result.push(d3[`interpolate${scheme}`](position));
        }
        return result;
    }

    init(data, options, translations) {
        let self = this;
        this.cfg.mode = 'wheel';
        this.data = data;

        this.updateOptions(options);

        const nr_colors = data.children.length - 1;
        let color = d3.scaleOrdinal().domain([1, nr_colors]).range(["#ccc", "#ccc"]);
        if (this.cfg.color_scheme !== "default") {
            color = d3.scaleOrdinal().range(d3.quantize(d3['interpolate' + this.cfg.color_scheme], nr_colors).reverse());
        }

        this.color = color;

        let container = $("#" + this.id);

        // clear container
        container.empty();

        // set the dimensions and margins of the graph
        var margin = this.cfg.margin;

        // let size = Math.min(container.width() - margin.left - margin.right, container.height() - margin.top - margin.bottom);
        let width = 1200;
        let height = 1200;

        this.wheel_size = width - margin;

        this.radius = this.wheel_size / 6;

        data = this.transformData(data);
        const root = this.partitionData(data);
        let data0 = root.descendants().slice(1);

        this.arc = d3.arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(this.radius * 1.5)
            .innerRadius(d => d.y0 * this.radius)
            .outerRadius(d => Math.max(d.y0 * this.radius, d.y1 * this.radius - 1));

        this.svg = d3.select('#' + this.id).append("svg")
            .attr("viewBox", "0 0 " + width + " " + height)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style("font", this.cfg.font_size + "px sans-serif");

        let bg_circle = this.svg.append('circle')
            .attr("r", this.radius)
            .attr("transform", `translate(${this.wheel_size / 2},${this.wheel_size / 2})`)
            .attr('fill', "#fff")


        this.g = this.svg.append("g")
            .attr("transform", `translate(${this.wheel_size / 2},${this.wheel_size / 2})`);


        this.g.style("opacity", 0)
            .transition()
            .duration(1000)
            .ease(d3.easeQuadInOut)
            .style("opacity", 1);

        // Wheel information (responses, date, number of responses)
        if (this.cfg.display_info) {
            let textContent = translations['stability'] + ": " + data['stability'].toFixed(2) +
                                ", " + translations['responses_no'] + ": " + data['nbResponses'] + 
                                " (" + data['proportion'] + "% no idea)";

            if (data['date'] !== "" && data['date'] !== "2100-01-01") {
                textContent += ', ' + translations['date'] + ': ' + data['date'];
            }

            this.svg.append("text")
                .attr("id", "wheel-description" + this.id)
                .style('font-size', "25px")
                .attr("x", (width / 2) - 90)
                .attr("y", height)
                .text(textContent);

            let wheel_description = d3.select("#wheel-description" + this.id);
            let wheel_description_width = wheel_description.node().getBoundingClientRect().width;
            wheel_description.attr('transform', 'translate(-' + wheel_description_width + ',' + '-5)')
        }

        let filter = this.svg.append("defs")
            .append("filter")
            .attr("id", "blur")
            .append("feGaussianBlur")
            .attr("stdDeviation", 4.6);


        if (data['image']) {
            if (this.cfg.display_image) {
                this.showImage(data);
            }
        } else {
            this.showWord(data);
        }

        this.arcs = this.g.selectAll(`#${this.id} path`)
            .data(data0, this.key)
            .enter()
            .append("path")
            .each(function (d, i) {
                this.prev_arc = self.findNeighborArc(i, data0, data0, self.key) || self.getArc(d);
            })
            .attr("stroke", "white")
            .attr("data-id", d => d.data.id)
            .attr("fill", d => {
                while (d.depth > 1)
                    d = d.parent;
                return color(d.data.word);
            })
            .attr("fill-opacity", d => this.arcVisible(d) ? (d.children ? 0.6 : 0.4) : 0)
            .style("cursor", "pointer")
            .attr("d", d => this.arc(d))
            .on("click", (d, self) => { this.clicked(d, self) });

        // this.slices.filter(d => d.children)
        //     .style("cursor", "pointer")
        //     .on("click", clicked);

        // this.slices.append("title")
        //     .attr("data-id", d => d.data.id)
        //     .text(d => `${d.ancestors().map(d => d.data.word).reverse().join("/")}\n${format(d.value.toFixed(2))}`);

        this.label_g = this.g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none");

        this.labels = this.label_g.selectAll(".text-label")
            .data(data0, this.key)
            .enter()
            .append("text")
            .attr('class', 'text-label')
            .attr("dy", "0.35em")
            .attr("fill-opacity", d => +this.labelVisible(d))
            .attr("transform", d => this.labelTransform(d))
            .style("font-weight", this.cfg.font_weight)
            .text(d => this.drawText(d, this.cfg));


        if (this.cfg.blur) {
            this.labels.selectAll("text")
                .filter(function (d, i) {
                    return i % 2 === 1;
                })
                .attr("filter", "url(#blur)");
        }

        const parent = this.g.append("circle")
            .datum(root)
            .attr("id", "root-circle")
            .attr("r", this.radius)
            .attr("fill", "#fff")
            .attr("pointer-events", "all")
            .attr('class', 'root-circle')
            .on("click", this.clicked);

        if (data['image']) {
            parent.attr("fill", "url(#pattern-id-" + data['word_id'] + ")")
        }


        function formatDate(date) {
            let formatted_date = "";
            if (date !== "") formatted_date = moment(date).format("MMM/DD/YYYY");
            return formatted_date;
        }

        // Init Color selections
        this.cfg.color_selections.forEach(function (color_selection) {
            this.colorSelection(color_selection);
        });

        // Init Text style
        this.updateText();
    }

    updateOptions(options) {
        //Put all of the options into a variable called cfg
        if ('undefined' !== typeof options) {
            for (let i in options) {
                if ('undefined' !== typeof options[i]) {
                    this.cfg[i] = options[i];
                }
            }//for i
        }//if
    }





















    //~ update: function (data, id, options) {
    //~ this.updateOptions(options);
    //~ let transformed_data = this.transformData(data);
    //~ let new_root = this.partitionData(transformed_data);
    //~ let arc = this.arc;
    //~ let key = this.key;

    //~ let data0 = this.arcs.data();
    //~ let data1 = new_root.descendants().slice(1);

    //~ let arcs = this.g.selectAll('path').data(data1, key);
    //~ let labels = this.label_g.selectAll('text.text-label').data(data1, key);

    //~ arcs.enter()
    //~ .append("path")
    //~ .each(function (d, i) {
    //~ this.prev_arc = assocWheel.findNeighborArc(i, data0, data1, key) || assocWheel.getArc(d);
    //~ })
    //~ .attr("stroke", "white")
    //~ .attr("data-id", d => d.data.id)
    //~ .attr("fill", d => {
    //~ while (d.depth > 1)
    //~ d = d.parent;
    //~ return assocWheel.color(d.data.word);
    //~ })
    //~ .attr("fill-opacity", d => assocWheel.arcVisible(d) ? (d.children ? 0.6 : 0.4) : 0)
    //~ .style("cursor", "pointer")
    //~ .attr("d", d => this.arc(d))
    //~ .on("click", assocWheel.clicked);

    //~ arcs.exit()
    //~ .datum(function (d, i) {
    //~ return assocWheel.findNeighborArc(i, data1, data0, key) || assocWheel.getArc(d);
    //~ })
    //~ .transition()
    //~ .duration(750)
    //~ .attrTween("d", arc2Tween)
    //~ .remove();

    //~ arcs.transition()
    //~ .duration(750)
    //~ .attrTween("d", arc2Tween);


    //~ labels.enter()
    //~ .append("text")
    //~ .attr('class', 'text-label')
    //~ .attr("dy", "0.35em")
    //~ .attr("fill-opacity", d => +assocWheel.labelVisible(d))
    //~ .attr("transform", d => assocWheel.labelTransform(d))
    //~ .style("font-weight", assocWheel.cfg.font_weight)
    //~ .text(d => assocWheel.drawText(d, assocWheel.cfg));

    //~ labels.exit()
    //~ .remove();

    //~ labels.transition()
    //~ .duration(750)
    //~ .attrTween("transform", arcTweenText)
    //~ .attr("fill-opacity", d => +assocWheel.labelVisible(d));

    //~ assocWheel.cfg.color_selections.forEach(function (color_selection) {
    //~ assocWheel.colorSelection(color_selection);
    //~ });

    //~ assocWheel.updateText();


    //~ function arc2Tween(d) {
    //~ let current_arc = {"x0": d.x0, "x1": d.x1, "y0": d.y0, "y1": d.y1};
    //~ let interp = d3.interpolate(d.prev_arc, current_arc);
    //~ d.prev_arc = current_arc;

    //~ return function (t) {
    //~ let tmp = interp(t);
    //~ return arc(tmp);
    //~ }
    //~ }


    //~ function arcTweenText(d) {
    //~ let current_text = {"x0": d.x0, "x1": d.x1, "y0": d.y0, "y1": d.y1};
    //~ let oi = d3.interpolate(d.prev_text, current_text);
    //~ d.prev_text = current_text;

    //~ function tween(t) {
    //~ let b = oi(t);
    //~ return "translate(" + arc.centroid(b) + ")rotate(" + computeTextRotation(b) + ")";
    //~ }

    //~ return tween;
    //~ }

    //~ function computeTextRotation(d) {
    //~ let angle = (d.x0 + d.x1) / Math.PI * 90;
    //~ // Avoid upside-down labels
    //~ return (angle < 180) ? angle - 90 : angle + 90;  // labels as spokes
    //~ }

    //~ },


    key(d) {
        return d.data.arc_id;
    }

    findNeighborArc(i, data0, data1, key) {
        let d;
        return (d = this.findPreceding(i, data0, data1, key)) ? d
            : (d = this.findFollowing(i, data0, data1, key)) ? d
                : null;
    }

    findPreceding(i, data0, data1, key) {
        let m = data0.length;
        while (--i >= 0) {
            var k = key(data1[i]);
            for (var j = 0; j < m; ++j) {
                if (key(data0[j]) === k) return this.getArc(data0[j]);
            }
        }
    }

    findFollowing(i, data0, data1, key) {
        let n = data1.length, m = data0.length;
        while (++i < n) {
            let k = key(data1[i]);
            for (let j = 0; j < m; ++j) {
                if (key(data0[j]) === k) return this.getArc(data0[j]);
            }
        }
    }

    getArc(d) {
        return {
            "x0": d.x0,
            "x1": d.x1,
            "y0": d.y0,
            "y1": d.y1,
            "data": { "arc_id": d.data.arc_id },
            "prev_arc": d.prev_arc,
            "prev_text": d.prev_text
        };
    }

    labelVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.06;
    }

    arcVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    labelTransform(d) {
        const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
        const y = (d.y0 + d.y1) / 2 * this.radius;
        return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }

    //~ clicked(p) {
    //~ if (p.depth !== 0) {
    //~ let selected_id = p.data.word;
    //~ $("#selection-color").css("top", d3.event.clientY + "px");
    //~ $("#selection-color").css("left", d3.event.clientX + "px");
    //~ section_color_selector.show();
    //~ section_color_selector._eventListener.save = []
    //~ section_color_selector.on('save', color => {
    //~ let selected_color = "#CCC";
    //~ if (color !== null) selected_color = color.toRGBA().toString(0);
    //~ let color_selection = {"id": selected_id, "color": selected_color, "depth": p.depth};
    // wheel_controller.addColorSelection(color_selection);
    //~ this.colorSelection(color_selection);
    //~ this.cfg.clicked({id: selected_id, color: selected_color, depth: p.depth});
    //~ section_color_selector.hide();
    //~ $("#selection-color").css("top", "-9999px");
    //~ $("#selection-color").css("left", "-9999px");
    //~ })
    //~ }
    //~ }

    clicked(path, self) {
        if (path.depth !== 0) {
            this.vue_vm.wordClicked(path.data.word, path.depth);
        }
    }

    //~ colorSelection(color_selection) {
    //~ let abc = d3.selectAll("path");

    //~ if (color_selection.depth === 1) {
    //~ d3.selectAll("path")
    //~ .filter(function (d) {
    //~ if (d.depth === 1) {
    //~ return d.data.word === color_selection.id;
    //~ } else if (d.depth > 1) {
    //~ return d.parent.data.word === color_selection.id;
    //~ }
    //~ })
    //~ .attr("fill", color_selection.color);
    //~ } else if (color_selection.depth === 2) {
    //~ d3.selectAll("path")
    //~ .filter(function (d) {
    //~ if (d.depth > 0) {
    //~ return d.data.word === color_selection.id;
    //~ }
    //~ })
    //~ .attr("fill", color_selection.color);
    //~ }
    //~ }

    clearAllColors() {
        let paths = d3.selectAll(`#${this.id} path`);
        paths.attr("fill", "#ccc");
    }

    setWordColor(word, color, depth) {
        // all d3 elements belonging to this chart
        let paths = d3.selectAll(`#${this.id} path`);
        // if we're setting the color for a top-level (depth==1) word we need
        // to set its color, as well as all of its childrens' (depth==2) colors 
        if (depth === 1) {
            paths.filter(function (d) {
                if (d.depth === 1) {
                    return d.data.word === word;
                } else if (d.depth > 1) {
                    return d.parent.data.word === word;
                }
            })
                .attr("fill", color);
        }
        // 
        else if (depth === 2) {
            paths.filter(function (d) {
                if (d.depth > 0) {
                    return d.data.word === word;
                }
            })
                .attr("fill", color);
        }
    }

    transformData(data) {
        // List of word_ids that should be adjusted
        const wordIdsToDivide = [3861, 8957, 19965, 98557, 117770, 141506, 262332, 269484, 290899, 326230, 327011, 327012, 327013, 327014];
        // If this data's word_id is in the list, divide nbResponses and proportion by 3
        if (wordIdsToDivide.includes(data.word_id) && data.currentlang == 'nl') {
            data.nbResponses = data.nbResponses / 3;
            data.proportion = data.proportion / 3;
        }
        data['total_fas'] = 100;
        data = this.cutOffFasPercentage(data, this.cfg.fas_percentage);
        data = this.cutOffFasPercentageReverse(data, this.cfg.collapse_percentage);
        if (this.cfg.show_n > 0) {
            data = this.keepN(data, this.cfg.show_n);
        }
        return data;
    }

    partitionData(data) {
        const partition = data => {
            const root = d3.hierarchy(data)
                .sum(d => d.fas)
                .sort((a, b) => b.fas - a.fas);
            //root.each(d=> d.value = +d.data.fas);
            root.each(d => {
                d.value = this.calculatePercentages(d, data['total_fas'])
            });
            root.value = 100;
            return d3.partition()
                .size([2 * Math.PI, root.height + 1])
                (root);
        };

        const root = partition(data);
        return root;
    }


    drawText(d, cfg) {
        let word = d.data.word;
        if (cfg.translate) word = d.data['word_en'];
        if (d.data.fas && cfg.with_percentages && d.depth === 1) {
            return word + ' ' + (d.data.fas * 100).toFixed(1) + "%"
        } else {
            return word
        }
    }

    calculatePercentages(d, total) {
        if (d.depth === 1) {
            return d.data.fas * (100 / total)
        } else if (d.depth === 2) {
            return (d.parent.data.fas * d.data.fas) * (100 / total)
        }
        else return 100
    }

    cutOffFasPercentage(data, percentage) {
        let cut_foas = [];
        let cut_off_percentage = 0;
        let foa_idx = 0;
        while (cut_off_percentage < percentage && foa_idx < data['children'].length) {
            let foa_fas = data['children'][foa_idx]['fas']
            cut_off_percentage += foa_fas;
            cut_foas.push(data['children'][foa_idx])
            foa_idx++;
        }
        let new_data = JSON.parse(JSON.stringify(data));
        new_data['children'] = cut_foas;
        new_data['total_fas'] = cut_off_percentage;
        return new_data;

    }

    cutOffFasPercentageReverse(data, percentage) {
        percentage = 1 - percentage;
        data['children'].reverse();
        let cut_foas = [];
        let cut_off_percentage = 0;
        let foa_idx = 0;
        while (cut_off_percentage < percentage && foa_idx < data['children'].length) {
            let foa_fas = data['children'][foa_idx]['fas']
            cut_off_percentage += foa_fas;
            cut_foas.push(data['children'][foa_idx])
            foa_idx++;
        }
        cut_foas.reverse();
        let new_data = JSON.parse(JSON.stringify(data));
        new_data['children'] = cut_foas;
        new_data['total_fas'] = cut_off_percentage;
        return new_data;

    }

    keepN(data, n) {
        let kept_foas = [];
        let foa_idx = 0;
        while (foa_idx < n && foa_idx < data['children'].length) {
            kept_foas.push(data['children'][foa_idx])
            foa_idx++;
        }
        let new_data = JSON.parse(JSON.stringify(data)); // trick to clone data
        new_data['children'] = kept_foas;
        return new_data;
    }

    calculateImageSize(image_width, image_height) {
        let margin = 30;
        let circle_width = $("[id=root-circle]").get(-1).getBBox().width - margin;
        let scale_factor = Math.sqrt(Math.pow(circle_width, 2) / (Math.pow(image_width, 2) + Math.pow(image_height, 2)));

        let scaled_width = image_width * scale_factor;
        let scaled_height = image_height * scale_factor;

        let x = (circle_width - scaled_width) / 2;
        let y = (circle_width - scaled_height) / 2

        let image_spec = {
            width: scaled_width,
            height: scaled_height,
            x: x + margin / 2,
            y: y + margin / 2
        }

        return image_spec
    }
    //~ ,

    //~ // Util functions

    setPercentage(value) {
        let drawText = this.drawText;
        let cfg = this.cfg;
        this.cfg.with_percentages = value;
        d3.selectAll(`#${this.id} svg .text-label`).each(function (d, i) {
            d3.select(this).text(
                d => drawText(d, cfg)
            );
        });
    }

    //~ clearColors: function () {
    //~ d3.selectAll("text")
    //~ .attr("font-weight", "bold")
    //~ .attr("fill", 'black');
    //~ if (assocWheel.cfg.mode === "wheel") {
    //~ d3.selectAll("path")
    //~ .attr("fill", '#CCC');
    //~ }
    //~ }
    //~ ,

    //~ decreaseFontSize: function () {
    //~ assocWheel.cfg.font_size--;
    //~ this.updateText();
    //~ }
    //~ ,

    //~ increaseFontSize: function () {
    //~ assocWheel.cfg.font_size++;
    //~ this.updateText();
    //~ }
    //~ ,

    setFontSize(font_size) {
        this.cfg.font_size = font_size;
        this.updateText();
    }

    //~ setFontFamily: function (font_family) {
    //~ assocWheel.cfg.font_family = font_family;
    //~ this.updateText();
    //~ },

    //~ setFontColor: function (font_color) {
    //~ assocWheel.cfg.font_color = font_color
    //~ this.updateText(font_color)
    //~ },

    //~ toggleBold: function () {
    //~ if (assocWheel.cfg.font_weight === "bold") {
    //~ assocWheel.cfg.font_weight = '400';
    //~ } else {
    //~ assocWheel.cfg.font_weight = 'bold';
    //~ }
    //~ this.updateText();
    //~ }
    //~ ,

    updateText() {
        d3.selectAll(".wheel-container svg g text")
            .style("font-size", this.cfg.font_size + "px")
            .style("font-weight", this.cfg.font_weight)
            .style("fill", this.cfg.font_color)
            .attr("font-family", this.cfg.font_family);
    }

    //~ colorWheel(color_scheme, nr_colors) {
    //~ let color = d3.scaleOrdinal().domain([1, nr_colors]).range(["#ccc", "#ccc"])
    //~ if (color_scheme !== "default") {
    //~ color = d3.scaleOrdinal().range(d3.quantize(d3['interpolate' + color_scheme], nr_colors).reverse());
    //~ }
    //~ if (this.cfg.mode === "wheel") {
    //~ d3.selectAll(".wheel-container path")
    //~ .attr("fill", d => {
    //~ while (d.depth > 1)
    //~ d = d.parent;
    //~ return color(d.data.word);
    //~ })
    //~ }
    //~ }

    showImage(data) {
        let calculateImageSize = this.calculateImageSize;
        var defs = this.svg.append('svg:defs');
        const image = defs.append("svg:pattern")
            .attr("id", "pattern-id-" + data['word_id'])
            .attr("x", "0")
            .attr("y", "0")
            .attr("width", "1")
            .attr("height", "1")
            .append("svg:image")
            .attr("id", "word-image-" + data['word_id'])
            .attr('xlink:href', data['url'])
            .attr("opacity", "0")
            .on("load", function (d) {
                let word_image = new Image();
                word_image.onload = function () {
                    let image_spec = calculateImageSize(this.width, this.height);
                    d3.select("#word-image-" + data['word_id'])
                        .attr("width", image_spec.width)
                        .attr("height", image_spec.height)
                        .attr("x", image_spec.x)
                        .attr("y", image_spec.y)
                        .attr("opacity", "1")
                };
                word_image.src = data['url'];
            });
    }

    showWord(data) {
        let dynamic_font_size = "";
        let word_text = this.svg.append("text")
            .classed("ms-wheel-word", true)
            .attr("transform", `translate(${this.wheel_size / 2},${this.wheel_size / 2})`)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .attr('fill', 'black')
            .attr("dy", '0.5rem')
            .attr("pointer-events", "all")
            .text(data['word'])
            .style("font-size", "30px")
            .each(getSize)
            .style("font-size", getFontSize());

        function getSize() {
            let bbox = this.getBBox(),
                cbbox = this.parentNode.getBBox(),
                scale = Math.min(cbbox.width / bbox.width, cbbox.height / bbox.height);
            dynamic_font_size = scale + "px";
        }

        function getFontSize() {
            let font_size = Math.min(Math.round(400 / data['word'].length), 80);
            return font_size + "px";
        }
    }
};

