<template>
  <LoadingAppWide v-if="loading" />
  <div v-if="showSurveyPopup" class="survey-popup-overlay">
    <div class="survey-popup-container">
      <!-- Main Content -->
      <div class="survey-content-container">
        <!-- Header Section -->
        <div class="survey-header-container">
          <div class="lives-container" v-if="gameStarted">
            <game-lives-survey :remaining-lives="lives" />
          </div>
          <div class="button-group">
            <button class="survey-coins-button">
              <div class="survey-coins-logo">
                <img src="../../assets/mindchips.png" alt="Mindchips Icon" />
              </div>
              {{ mindchips }} Mindchips
            </button>
            <button class="survey-close-button" @click="closePopup">
              <font-awesome-icon
                style="color: #525251; font-size: 20px;"
                :icon="['fas', 'xmark']"
              />
            </button>
          </div>
        </div>
        <h2 v-html="$t('new_asset.enter_associations')"></h2>
        
        <div
          v-if="!currentStimulus && !showLostLifeWarning"
          class="placeholder-container"
        >
          <img
            src="../../assets/mindchips.png"
            alt="Placeholder Image"
            class="placeholder-image"
          />
          <p class="placeholder-text">{{ $t('new_asset.concepts_loading') }}</p>
        </div>
        <!-- Brand Text, Image, or Video -->
        <div v-if="currentStimulus && !showLostLifeWarning">
          <!-- Display Text if type is "text" -->
          <div
            v-if="currentStimulus.type === 'text'"
            class="stimulus-container"
          >
            {{ currentStimulus.text }}
          </div>
          <!-- Display Image if available -->
          <div
            v-else-if="currentStimulus.image && !showLostLifeWarning"
            class="survey-image-container"
          >
            <img
              :src="currentStimulus.image"
              alt="Step Image"
              class="brand-image"
            />
          </div>
          <!-- Display Video if available -->
          <div
            v-else-if="currentStimulus.video && !showLostLifeWarning"
            class="video-container"
          >
            <video autoplay muted playsinline controls class="brand-video">
              <source :src="currentStimulus.video" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <!-- Input Fields -->
        <div
          v-if="currentStimulus && !showLostLifeWarning"
          class="input-container"
        >
          <div
            v-for="(thought, index) in currentStimulus.associations"
            :key="index"
            class="input-wrapper"
          >
            <input
              type="text"
              v-model="currentStimulus.associations[index]"
              :placeholder="getPlaceholderText(index)"
              :disabled="isInputDisabled[index]"
              class="input-field"
              @blur="handleInputValidation($event, index)"
              @focus="enableNextInput(index + 1)"
            />
            <div
              v-if="currentStimulus.associations[index] !== ''"
              class="survey-coins-logo"
            >
              <img
                v-if="currentStimulus.associations[index] !== ''"
                src="../../assets/mindchips.png"
                alt="Mindchips Icon"
              />
            </div>
          </div>
        </div>
        <!-- Footer -->
        <div
          v-if="currentStimulus && !showLostLifeWarning"
          class="survey-footer-container"
        >
        <button class="submit-button" @click="handleSubmit">
          {{ currentlang === 'nl' ? 'Verzenden' : 'Submit' }}
        </button>        
      </div>
        <div v-if="!currentStimulus" class="survey-footer-container">
          <button
            class="submit-button"
            style="background-color: red; color: white;"
            @click="closePopup"
          >
          {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
        </button>
        </div>
      </div>
      <!-- Footer
      <div v-if="currentStimulus && !showLostLifeWarning" class="survey-footer-container">
        <button class="submit-button" @click="handleSubmit">Submit</button>
      </div>
      <div v-if="!currentStimulus" class="survey-footer-container">
        <button class="submit-button" style="background-color: red; color: white;" @click="closePopup">Close</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import GameLivesSurvey from "../b2cgame/GameLivesSurvey.vue";
import axios from "axios";
import {
  toast_success,
  alert_error,
  genericDialog,
} from "@/helpers/alert_helper.js";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";

export default {
  name: "ShareThoughtsSurveyPopup",
  components: {
    GameLivesSurvey,
    LoadingAppWide,
  },
  props: {
    showSurveyPopup: Boolean,
  },
  emits: ["survey-submitted", "close"],
  data() {
    return {
      showCoinPopup: false,
      gameStarted: true,
      currentStep: 0,
      lives: 3,
      isInputDisabled: [false, true, true],
      steps: [],
      loading: false,
      showLostLifeWarning: false,
      currentStimulus: null,
      invalidResponses: [],
      totalMindchipsAdded: 0,
      validatedInputs: [false, false, false],
      validAssociations: true,
      responseTimes: null,
      startTimes: null,
      currentlang: "",
    };
  },
  computed: {
    ...mapState(["mindchips"]),
    callMassWords() {
      if (this.currentStep === this.steps.length - 1) {
        // call the function that will populate the mass words
      }
    },
    // currentStimulus() {
    //   return this.steps.length > this.currentStep ? this.steps[this.currentStep] : null;
    // },
  },
  created() {
    this.currentlang =
      this.$store.getters.getEnvVar("VUE_APP_DEFAULT_LANGUAGE") || "nl";
  },
  watch: {
    currentStep(value) {
      this.updateCurrentStimulus(value);
    },
  },
  mounted() {
    this.fetchStimuli();
  },
  methods: {
    ...mapActions(["subtractMindchips", "updateMindchips", "addMindchips"]),

    async fetchStimuli() {
    try {
      this.loading = true;
      const response = await axios.get("/api/cas/survey/get_stimuli");
      let stimuliData = response.data.assets;
      // Ensure response is an array
      if (!Array.isArray(stimuliData)) {
        console.error("Unexpected API response format:", stimuliData);
        stimuliData = [];
      }
      if (stimuliData.length === 0) {
        // If no stimuli, start the mass words flow
        await this.fetchMassWords();
        return;
      }
      // Map stimuli correctly
      this.steps = stimuliData.map((stimulus) => ({
        type: stimulus.stimuli_asset_mime_type
          ? stimulus.stimuli_asset_mime_type.indexOf("image") === 0
            ? "image"
            : "video"
          : "text",
        word_id: stimulus.stimuli_id,
        asset_id: stimulus.asset_id,
        header: "Enter 3 spontaneous associations",
        text: stimulus.stimuli_word || "",
        image:
          stimulus.stimuli_asset_mime_type &&
          stimulus.stimuli_asset_mime_type.indexOf("image") === 0
            ? stimulus.stimuli_media_url
            : null,
        video:
          stimulus.stimuli_asset_mime_type &&
          stimulus.stimuli_asset_mime_type.indexOf("video") === 0
            ? stimulus.stimuli_media_url
            : null,
        isInputDisabled: [false, true, true],
        associations: ["", "", ""],
        completed: [false, false, false],
      }));
      this.loading = false;
      this.updateCurrentStimulus(0);
    } catch (error) {
      console.error("Error in fetchStimuli:", error);
      this.loading = false;
      this.steps = [];
    }
  },

  async fetchMassWords() {
    try {
      this.loading = true;
      const response = await axios.get("/api/cas/survey/get_mass_words_stimuli");
      let stimuliData = response.data.assets;
      // Ensure response is an array
      if (!Array.isArray(stimuliData)) {
        console.error("Unexpected API response format:", stimuliData);
        stimuliData = [];
      }
      if (stimuliData.length === 0) {
        // Retry after a delay if no mass words returned
        this.loading = false;
        setTimeout(() => { this.fetchMassWords(); }, 2000);
        return;
      }
      // Map mass word stimuli to steps
      this.steps = stimuliData.map((stimulus) => ({
        type: stimulus.stimuli_asset_mime_type
          ? stimulus.stimuli_asset_mime_type.indexOf("image") === 0
            ? "image"
            : "video"
          : "text",
        word_id: stimulus.stimuli_id,
        asset_id: stimulus.asset_id,
        header: "Enter 3 spontaneous associations",
        text: stimulus.stimuli_word || "",
        image:
          stimulus.stimuli_asset_mime_type &&
          stimulus.stimuli_asset_mime_type.indexOf("image") === 0
            ? stimulus.stimuli_media_url
            : null,
        video:
          stimulus.stimuli_asset_mime_type &&
          stimulus.stimuli_asset_mime_type.indexOf("video") === 0
            ? stimulus.stimuli_media_url
            : null,
        isInputDisabled: [false, true, true],
        associations: ["", "", ""],
        completed: [false, false, false],
      }));
      this.loading = false;
      // Reset the survey round with the new mass word(s)
      this.currentStep = 0;
      this.updateCurrentStimulus(0);
      this.isInputDisabled = [false, true, true];
      this.validatedInputs = [false, false, false];
    } catch (error) {
      console.error("Error in fetchMassWords:", error);
      this.loading = false;
      // In case of error, retry after a delay
      setTimeout(() => { this.fetchMassWords(); }, 2000);
    }
  },
    updateCurrentStimulus(step) {
      if (this.steps.length > step) {
        this.currentStimulus = { ...this.steps[step] };
        this.isInputDisabled = [false, true, true];
        this.validatedInputs = [false, false, false];
        this.responseTimes = null; // Reset response times for each step
        this.startTimes = null; // Reset timestamps
      } else {
        this.currentStimulus = null;
      }
    },

    /** Starts tracking response time when user clicks input */
    startTrackingTime() {
      this.startTimes = performance.now();
    },

    /** Stops tracking response time and calculates duration */
    stopTrackingTime() {
      if (this.startTimes) {
        this.responseTimes = (performance.now() - this.startTimes).toFixed(4); // Store in milliseconds
        this.startTimes = null; // Reset timer
      }
    },

    playCoinSound() {
      const coinSound = new Audio("/sounds/sonic-coin-sound.mp3"); // Ensure correct path
      coinSound.volume = 0.5;
      coinSound
        .play()
        .catch((error) => console.error("Audio play failed:", error));
    },

    enableNextInput(index) {
      this.startTrackingTime();
      if (index < this.isInputDisabled.length) {
        this.isInputDisabled[index] = false; // Only enable the next input
      }
    },

    handleLostLifeWarningClose() {
      this.showLostLifeWarning = false;
      this.invalidResponses = [];
    },

    async handleInputValidation(event, index) {
      if (
        event.relatedTarget &&
        !event.relatedTarget.classList.contains("input-field")
      ) {
        return;
      }
      this.enableNextInput(index + 1);
      this.isInputDisabled[index] = true;
      this.stopTrackingTime();
      if (index < 2 && !this.validatedInputs[index]) {
        this.loading = true;
        const userInput = this.currentStimulus.associations[index].trim();
        const payload = {
          ...this.currentStimulus,
          associations: [userInput], // Only include the current association
          responseTime: this.responseTimes,
        };
        const response = await axios.post(
          "/api/cas/survey/post_stimuli",
          payload
        );
        if (response.data.success) {
          let isValid;
          if (userInput === "") {
            isValid = "yes";
          } else {
            isValid = await this.checkBrandHint(userInput);
          }
          if (isValid.trim().toLowerCase() === "yes") {
            if (userInput !== "") {
              this.addMindchips(2);
              this.playCoinSound();
              this.totalMindchipsAdded += 2;
            }

            this.validAssociations = true;
          } else {
            this.validAssociations = false;
            this.lives -= 1;
            this.invalidResponses.push(userInput); // Store invalid response
          }
          this.validatedInputs[index] = true;
          this.loading = false;
          if (!this.validAssociations) {
            this.showLostLifeWarning = true;
            if (this.lives <= 0 && this.totalMindchipsAdded > 0) {
              this.subtractMindchips(this.totalMindchipsAdded);
              this.$emit("close");
            }
            const dialog_options = {
              title: "Invalid Responses",
              text: `Oops! The following responses are invalid: "${this.invalidResponses.join(
                ", "
              )}". Remember: proper nouns are CASE-SENSITIVE, and misspelled hints cost a life. You have 3 lives—use them wisely!`,
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.close"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
              this.$emit("close");
              this.loading = false;
            } else {
              this.showLostLifeWarning = false;
              this.loading = false;
              if (this.lives <= 0) {
                this.$emit("close");
                await axios.post(
                  "/api/cas/survey/respondent_asset_completes",
                  payload
                );
              }
            }
          }
        } else {
          this.loading = false;
        }
      }
    },

    async checkBrandHint(hint) {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [
              {
                role: "user",
                content: `Is this a valid keyword in English or Dutch: ${hint}. Return yes or no answer.`,
              },
            ],
            max_tokens: 10,
          }),
        }
      );
      const data = await response.json();
      return data.choices[0].message.content;
    },

    async handleSubmit() {
      const lastIndex = this.currentStimulus.associations.length - 1;
      let emptyAssociationFound = false;
      this.stopTrackingTime();
      // Check if any of the associations were empty from before
      for (let i = 0; i < this.currentStimulus.associations.length; i++) {
        if (this.currentStimulus.associations[i].trim() === "") {
          emptyAssociationFound = true;
          const payload = {
            ...this.currentStimulus,
            associations: [""],
            responseTime: this.responseTimes || [],
          };
          await axios.post("/api/cas/survey/post_stimuli", payload);
        }
      }
      if (!this.validatedInputs[lastIndex]) {
        this.loading = true;
        const lastInput = this.currentStimulus.associations[lastIndex].trim();
        const payload = {
          ...this.currentStimulus,
          associations: [lastInput], // Only include the current association
          responseTime: this.responseTimes,
        };
        const response = await axios.post(
          "/api/cas/survey/post_stimuli",
          payload
        );
        await axios.post("/api/cas/survey/respondent_asset_completes", payload);
        if (response.data.success) {
          let isValid;
          if (lastInput === "") {
            isValid = "yes";
          } else {
            isValid = await this.checkBrandHint(lastInput);
          }
          if (isValid.trim().toLowerCase() === "yes") {
            if (lastInput !== "") {
              this.addMindchips(2);
              this.playCoinSound();
              this.totalMindchipsAdded += 2;
            }

            this.validAssociations = true;
          } else {
            this.validAssociations = false;
            this.lives -= 1;
            this.invalidResponses.push(lastInput); // Store invalid response
          }
          this.validatedInputs[lastIndex] = true;
          this.loading = false;
          if (!this.validAssociations) {
            this.showLostLifeWarning = true;
            if (this.lives <= 0 && this.totalMindchipsAdded > 0) {
              this.subtractMindchips(this.totalMindchipsAdded);
            }
            const dialog_options = {
              title: this.currentlang === "nl" ? "Ongeldige Responsen" : "Invalid Responses",
              text: this.currentlang === "nl"
                ? `Oeps! De volgende reacties zijn ongeldig: "${this.invalidResponses.join(", ")}". Onthoud: eigennamen zijn HOFDLETTERGVOELIG, en verkeerd gespelde hints kosten je een leven. Je hebt 3 levens—gebruik ze verstandig!`
                : `Oops! The following responses are invalid: "${this.invalidResponses.join(", ")}". Remember: proper nouns are CASE-SENSITIVE, and misspelled hints cost a life. You have 3 lives—use them wisely!`,              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.close"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
              this.$emit("close");
              this.loading = false;
            } else {
              this.showLostLifeWarning = false;
              this.loading = false;
              if (this.lives <= 0) {
                this.$emit("close");
                await axios.post(
                  "/api/cas/survey/respondent_asset_completes",
                  payload
                );
              }
            }
          } else {
            this.loading = false;
          }
        }
        this.validatedInputs[lastIndex] = true;
      }

      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        this.updateCurrentStimulus(this.currentStep);
        this.isInputDisabled = [false, true, true];
        this.validatedInputs = [false, false, false];
      } else if (this.currentStep === this.steps.length - 1) {
        await this.fetchMassWords(); // call the function that will populate the mass words
      } else {
        this.$nextTick(() => {
          this.$emit("survey-submitted");
          this.closePopup();
        });
      }
    },

    closePopup() {
      this.$emit("close");
      this.totalMindchipsAdded = 0;
    },

    getPlaceholderText(index) {
    const placeholders = ["first", "second", "third"];
    if (this.currentlang === "nl") {
        return `Voer je ${placeholders[index]} spontane trefwoordassociatie in`;
    } else {
        return `Enter your ${placeholders[index]} spontaneous keyword association`;
    }
}
  },
};
</script>

<style>
/* General Styling */

@media screen and (max-width: 376px) {
  .survey-content-container h2 {
    font-size: 15px !important;
  }
  .survey-coins-button {
    width: 170px !important;
  }
  .game-lives-container{
    gap:4px !important;
  }
  .survey-popup-container{
    max-width: 100% !important;
  }
}

/* @media screen and (max-width: 480px) {

} */

@media screen and (max-width: 480px) {

  .button-group{
    width: 100%;
    justify-content: space-around;
  }

  .survey-popup-container {
    top: 55px;
  }

  /* .lives-container {
    padding: 4px !important;
  } */

  .survey-coins-button {
    width: 170px;
  }
  .game-lives-container {
    margin-right: 0px !important;
    padding: 0px;
  }
  .survey-header-container {
    flex-direction: column-reverse;
    gap: 4px;
  }
  .input-container {
    width: 100% !important;
  }
  .input-field {
    font-size: 10px !important;
  }
  .survey-content-container h2 {
    font-size: 16px !important;
    margin-top: 10px !important;
    line-height: normal;
  }
  .submit-button {
    width: 100px;
    padding: 0px !important;
  }
}
.survey-popup-overlay {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background: rgba(0, 0, 0, 0.911);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 40px;
  overflow: auto;
  height: 100vh;
}

.input-fields {
  width: 60%;
}

.survey-popup-container {
  /* position: relative; */
  flex: auto;
  background: #6c63ff;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  /* width: 70%; */
  max-width: 80%;
  /* height: fit-content; */
  /* height: 100%; */
  /* max-height: 100%; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.survey-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  width: 100%;
}

.survey-content-container h2 {
  flex-grow: 1;
  font-size: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  /* margin-left: 15%; */
}

.button-group {
  display: flex;
  gap: 10px;
  cursor: default;
}

.survey-coins-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ddd;
  cursor: default;
}

.survey-coins-button:hover {
  background-color: transparent;
}

.coins {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: #7878e9;
  border-radius: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.coins p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.coin-popup {
  width: 30%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}
.survey-coins-logo {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  /* margin-left: 10px; */
}

.survey-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.stimulus-container {
  width: auto;
  height: 250px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: no-wrap;
  border-radius: 10px;
  font-size: 1.5rem;
  color: black;
  text-align: center;
  white-space: no-wrap;
  padding: 10px;
}

.survey-image-container {
  /* This makes the container responsive but also prevents it from getting too huge */
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden; /* Prevents images from spilling outside */
}

.brand-image {
  width: 100%; /* Scale the image to fit the container’s width */
  height: 100%; /* Maintain aspect ratio automatically */
  object-fit: contain; /* Ensure the entire image is visible */
  border-radius: 14px; /* Matches the container’s rounded corners */
}
.brand-video {
  width: 50%;
  height: 50%;
  object-fit: fill;
}

.input-container {
  margin-top: 5px;
  /* margin-left: 3%; */
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.placeholder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.placeholder-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}

.placeholder-text {
  font-size: 1.2rem;
  line-height: 2vh;
  color: white;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.coin-icon {
  font-size: 1.5rem;
  color: gold;
}

.survey-footer-container {
  margin-top: 10px;
}

.submit-button {
  background: white;
  color: #6c63ff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background: #554fb8;
  color: white;
  transform: scale(1.05);
}

.survey-footer-text {
  text-align: center;
  font-size: 0.9rem;
  color: white;
  margin-top: 10px;
}

.alert-survey {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
  margin: 15px 0;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.survey-alert-warning {
  border-color: orange;
  background-color: #fff4e5;
  color: orange;
  padding: 0;
}

.lives-container {
  width: fit-content;
  white-space: nowrap;
  height: 35px;
  padding: 5px 10px;
  /* margin-top: 2%; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
}

.survey-close-button {
  width: 30px; /* Set the width of the circle */
  height: 30px; /* Set the height of the circle */
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 50%; /* Make it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.survey-close-button:hover {
  background-color: transparent;
}
</style>
