<template>
    <div class="progressbar-box">
        
      <div class="progress-container">
        <!-- Progress Bar -->

        <div class="progress-bar">
          <div class="progress" :style="{ width: rescaledProgress  + '%' }"></div>

        </div>
         <!-- Line Connecting the Current Value -->
         <div
            class="progress-line"
            :style="{ left: rescaledProgress  + '%' }"
          ></div>
        <div
                class="current-value"
                :style="{ left: (rescaledProgress  - 5) + '%' }"
            >
                <span><img src="../../assets/mindchips.png" alt="" style="width: 15px; height: 15px; margin-right: 2%;" />{{ currentChips }} Mindchips</span>
            </div>
      <!-- Milestones -->
      <div class="milestone" style="left: 2.25%; display: flex; flex-direction: row;">
        <span style="font-weight: 600;">Milestone 1</span>
        <img src="../../assets/flag-icon-1000.png" alt="Flag Icon" />
      </div>
      <div class="milestone" style="left: 61.85%; top: -50%; display: flex; flex-direction: row;">
        <span style="font-weight: 600;">Milestone 2</span>
        <img src="../../assets/flag-icon-10000.png" alt="Flag Icon" style=" bottom: 2%;" />
      </div>
        <!-- Scale Markers -->
        <div v-for="marker in scaleMarkers" :key="marker.value" class="scale-marker" :style="{ left: marker.position + '%' }">
          <div class="marker-line"></div>
          <span>{{ marker.value }}</span>
        </div>
      </div>
    </div>
  </template>
  
  
  
  
  <script>
  export default {
    props: {
      currentChips: {
        type: Number,
        required: true,
      },
    },
    computed: {
      // Progress scaled for 0 to 10,000 range but emphasizes small values
      rescaledProgress() {
        const minRange = 0; // Starting point for small values
        const maxRange = 15000; // End of the range
        const scaledValue = Math.max(this.currentChips - minRange, 0); // Shift for small values
        return Math.min((scaledValue / (maxRange - minRange)) * 100, 100); // Scale to 100%
      },
      scaleMarkers() {
        const markers = [];
        for (let i = 1000; i <= 14000; i += 1000) {
          markers.push({
            value: i,
            position: (i / 15000) * 100,
          });
        }
        return markers;
      },
    },
  };
  </script>
  
  
  <style scoped>
/* Outer Box Container */
@media only screen and (max-width: 768px) {
  .progressbar-box {

margin: 20px auto;
padding: 20px 0px !important;
text-align: center;
background-color: transparent;
}
.scale-marker {
    display: none; /* Hide scale markers on smaller screens */
  }
  .progress-line{
    height: 20px;
  }
  .current-value{
    font-size: 8px;
  }
}

.progressbar-box {

  margin: 20px auto;
  padding: 20px;
  text-align: center;
  background-color: transparent;
}

/* Title */
h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

/* Progress Container */
.progress-container {
  position: relative;
  height: 40px;
  width: 90%;
  margin: auto auto;
}

/* Progress Bar */
.progress-bar {
  position: relative;
  background-color: #babaf6;
  height: 20px;
  border-radius: 15px 0px 0px 15px;
  overflow: visible;
  margin: 10px auto;
  width: 100%;
}

.progress {
  background: linear-gradient(to right, #6a5acd, #4a90e2);
  height: 100%;
  transition: width 0.4s ease;
  border-radius: 15px 0 0 15px;
}

.progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0%;
  width: 0;
  height: 0;
  border-left: 10px solid #babaf6; /* Match progress bar color */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: translateX(100%);
}

/* Connecting Line */
.progress-line {
  position: absolute;
  top: 0px;
  height: 40px;
  width: 2px;
  background-color: #4a90e2;
  transform: translateX(-50%);
}

/* Current Value Display */
.current-value {
  position: absolute;
  top: 40px;
  background: linear-gradient(to right, #6a5acd, #4a90e2);
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  transition: transform 0.4s ease;
}

/* Milestones */
.milestone {
  position: absolute;
  top: -50%;
  width: 10%;
  transform: translateX(-50%);
  text-align: center;
}

.milestone img {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
}

.milestone span {
  font-size: 12px;
  white-space: nowrap;
  color: #6c63ff;
}

/* Scale Markers */
.scale-marker {
  position: absolute;
  top: 50%;
  transform: translateX(0%);
  text-align: center;
  font-size: 10px;
  color: #333;
}

.scale-marker span {
  display: block;
  margin-top: 5px;
}

.marker-line {
  position: absolute;
  top: -90%;
  height: 18px;
  width: 1px;
  background-color: #333;
  transform: translateX(-50%);
}
</style>

    