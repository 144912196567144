<template>
  <div>
  <main>
    <landing-page></landing-page>
    <div class="bg-trans" v-bind:style="{
      display:
        this.$store.state.current_user.onboarding_step <= 0 &&
          show === 'welcome'
          ? 'block'
          : 'none',
    }">
      <div class="hello-msg" style="display: block;">
        <strong>Welcome!</strong>
        In order to optimize your user experience, we invite you to complete your brand profile.
        <div style="display: flex; justify-content: space-between;">
          <span><a class="cursor-p" @click="show = ''">OK</a></span>
        </div>
      </div>
    </div>
    <section style="display: flex; flex-direction: column;">
        <div class="archetypes-header-box" style="display: flex; justify-content: flex-start; width: 90%; margin-top: 15px; gap:20px;">
          <div style="width: 65%;">
            <h3 style="display: flex; width: 100%;">
              How do you want your brand to be perceived?
                <span class="info-tooltip" @click="showTip">
                  <font-awesome-icon :icon="['fas', 'info-circle']" style="color: #7878E9; margin-left: 5px; cursor: pointer;" @click="showTip"/>
                  <div class="tooptip-text">
                    {{ this.$t('message.archetypes_tip_tooltip_content') }}
                  </div>
                </span>
            </h3>
            <span class="select-text">Tell us by ranking the top archetypes that best represent your brand</span>
          </div>
          <div style="width: 35%;">
            <h3>Click on an archetype to select it</h3>
            <div style="display: flex; align-items: center;">
              <span class="redstar">Click on the </span>
              <img style="width: 20px; height:20px; margin-left: 5px; margin-right: 5px;" src="../../assets/icon8.png" />
              <span>icon to expand it</span>
            </div>
          </div>
        </div>
        <div class="archetypes-header-box" style="display: flex; justify-content: flex-start; width: 90%; gap:20px;">
          <div style="width: 65%;">
            <div class="select-images-box">
              <div class="selectimg">
                <span><font-awesome-icon :icon="['fas', 'trophy']" style="color: #808080;" /></span>
                <div class="select-img">
                  <span v-if="archetypes.second_archetype" class="close-btncol" @click="handleClose('two')">X</span>
                  <img v-if="archetypes.second_archetype" v-bind:src="`${list.find((el) => el.id === archetypes.second_archetype)
                    .urls.big_medium
                    }`
                    " />
                </div>
              </div>
              <div class="selectimg">
                <span><font-awesome-icon :icon="['fas', 'trophy']" style="color: #FFD43B;" /></span>
                <div class="select-img">
                  <span v-if="archetypes.first_archetype" class="close-btncol" @click="handleClose('one')">X</span>
                  <img v-if="archetypes.first_archetype" v-bind:src="`${list.find((el) => el.id === archetypes.first_archetype).urls
                    .big_medium
                    }`
                    " />
                </div>
              </div>
              <div class="selectimg">
                <span><font-awesome-icon :icon="['fas', 'trophy']" style="color: #CD7F32;" /></span>
                <div class="select-img">
                  <span v-if="archetypes.third_archetype" class="close-btncol" @click="handleClose('three')">X</span>
                  <img v-if="archetypes.third_archetype" v-bind:src="`${list.find((el) => el.id === archetypes.third_archetype).urls
                    .big_medium
                    }`
                    " />
                </div>
              </div>
            </div>
          </div>
          <div style="width: 35%;">
            <div class="images-ur-brand">
              <div class="select-images-box scroll">
                <div class="images-gallery" v-if="isLoading">
                  <loading-spinner></loading-spinner>
                </div>
                <div class="images-gallery scroll" v-if="!isLoading && list.length">
                  <div class="brnd-img-bx cursor-p" v-for="items in list" v-bind:key="items">
                    <img v-bind:src="items.urls.small_medium" @click="handleSelectImg(items.id)" />
                    <img class="bigimg" src="../../assets/icon8.png" @click="handleZoomAsset(items)" />
                  </div>
                </div>
                <div class="images-gallery" v-if="!isLoading && !list.length">
                  <span>No Data Found!</span>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
    <div class="bottom-line">
      <div class="bottom-line">
            <div class="btn-col" @click="handleConfirm">
              <button style="margin-left: 3vw;" v-bind:class="getClass()" :disabled="!this.archetypes.first_archetype ||
                !this.archetypes.second_archetype ||
                !this.archetypes.third_archetype
                ">
                To step 2
              </button>
            </div>
          </div>
    </div>
    <OnboardingTipPopup 
      v-model="tipVisible" 
      :hasAccordion="true"
      :hasFooter="false"
      :dialogTitle="this.$t('message.archetypes_tip_title')"
      :dialogContent="this.$t('message.archetypes_tip_content')"
      :dialogExtendedContent="this.$t('message.archetypes_tip_extended')"
      :onboardingPhase="onboardingPhase"
      @update:tipVisible="tipVisible = $event">
    </OnboardingTipPopup>
  </main>
  <loading-app-wide v-if="display_loading_overlay" />
  <full-preview-archetypes :asset="asset" ref="fullscreen_preview"/>
</div>
</template>

<script>
import axios from 'axios';
import FullPreviewArchetypes from './common/FullPreviewArchetypes.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import { alert_error, alert_reminding } from '@/helpers/alert_helper';
import LandingPage from './LandingPage.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import { ref } from 'vue';
import OnboardingTipPopup from './common/OnboardingTipPopup.vue';


export default {
  setup() {
    return {
      selection: ref([])
    }
  },

  name: 'Archetypes',

  components: {
    FullPreviewArchetypes,
    LoadingSpinner,
    LandingPage,
    LoadingAppWide,
    OnboardingTipPopup
  },

  props: {
    userId: String,
  },

  data() {
    return {
      tipVisible: false,
      onboardingPhase:"archetypes",
      imagePreview: null,
      selectedFile: null,
      loading: false,
      assets: null,
      upload:false,
      slogans: [],
      images: '',
      search_text: '',
      inputValue: "",
      showTooltip: false,
      archetypes: {
        first_archetype: '',
        second_archetype: '',
        third_archetype: '',
      },
      show: 'welcome',
      asset: {},
      list: [],
      userId: this.$props.userId,
      isLoading: true,
      pageText: localStorage.getItem('pageText'),
      finish_status: localStorage.getItem('finish_status'),
      pageName: localStorage.getItem('pageName'),
      display_loading_overlay: false,
    };
  },

  mounted: async function () {
    try {
      const result = await axios.get('/api/cas/users/get_all_archetypes');
      if (result.status === 200) {
        this.list = result.data;
        this.isLoading = false;
      }
      const archetypes = await axios.get('/api/cas/users/get_archetypes');
      this.archetypes.first_archetype = archetypes.data.first_archetype.id;
      this.archetypes.second_archetype = archetypes.data.second_archetype.id;
      this.archetypes.third_archetype = archetypes.data.third_archetype.id;
    } catch (error) {
      console.log(error);
    }
    document.getElementById('mindspeller').style.overflow = 'auto';
  },

  methods: {
    // upload
    handleDrop(event) {
      this.selectedFile = event.dataTransfer.files[0];
      this.readImagePreview(this.selectedFile);
    },
    showTip(){
      this.tipVisible = true;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && this.isValidFileType(file)) {
        this.selectedFile = file;
        this.readImagePreview(this.selectedFile);
      } else {
        alert_reminding("Invalid file type. Please select a JPEG, JPG, or PNG image.");
        this.resetFileInput();
        this.assets = null;
      }
    },
    isValidFileType(file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      return allowedTypes.includes(file.type);
    },
    resetFileInput() {
      this.$refs.fileInput.value = "";
      this.selectedFile = null;
    },
    readImagePreview(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
        this.assets = {"auto":false,"image":this.imagePreview}
        this.upload = true;
      };
      reader.readAsDataURL(file);
    },

    handleInput() {
      if (this.isValidUrl(this.search_text)) {
        this.scraping_img(this.search_text);
      }
    },

    isValidUrl(url) {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return urlPattern.test(url);
    },

    async scraping_img(url) {
      // encode the url for the api call
      let encode_url = encodeURIComponent(url)
      this.loading = true;

      // extract the images from the website
      const res = await axios.get(
        `https://opengraph.io/api/1.1/screenshot/${encode_url}?app_id=1dd62d51-a120-4be8-a422-cc1b1b362c0c`
      );
      this.images = res.data.screenshotUrl //string object
      this.assets = {"auto":true,"image":this.images}

      this.loading = false;
    },

    getUniqueRandomItems(array, count) {
      if (count >= array.length) {
        return array;
      }
      const shuffledArray = array.sort(() => Math.random() - 0.5);
      const selectedValues = new Set();
      const uniqueArray = shuffledArray.filter(item => {
        if (!selectedValues.has(item.value)) {
          selectedValues.add(item.value);
          return true;
        }
        return false;
      });
      return uniqueArray.slice(0, count);
    },

    getRandomItems(array, count) {
      // if there's not enough slogans then just return the array
      if (count >= array.length) {
        return array;
      }
      const shuffledArray = array.sort(() => Math.random() - 0.5);
      return shuffledArray.slice(0, count);
    },

    async skipStep() {
      const step_update = await axios.post(
        `/api/cas/users/update_onboarding`,
        {
          completed_step: 'skip',
        }
      );
      await this.$store.dispatch('load_user_data');
      this.$router.push('/content_tuner');
    },

    getClass() {
      if (
        this.archetypes.first_archetype &&
        this.archetypes.second_archetype &&
        this.archetypes.third_archetype
      ) {
        return 'btn3';
      } else {
        return 'disabled-btn';
      }
    },

    handleZoomAsset(value) {
      this.asset = value;
      this.$refs['fullscreen_preview'].open();
    },

    handleConfirm: async function () {
      this.display_loading_overlay = true;
      try {
        const archetype = {
          first_archetype: this.archetypes.first_archetype,
          second_archetype: this.archetypes.second_archetype,
          third_archetype: this.archetypes.third_archetype,
        };
        if (
          this.archetypes.first_archetype &&
          this.archetypes.second_archetype &&
          this.archetypes.third_archetype
        ) {
          const data = await axios.post(
            '/api/cas/users/update_archetypes',
            archetype
          );
          if (data.status === 200) {
            if ((this.finish_status && !this.finish_status["archetype"]) || !this.pageText) {
              const step_update = await axios.post(
                `/api/cas/users/update_onboarding`,
                {
                  completed_step: 1,
                }
              );
            }
            await this.$store.dispatch('load_user_data');
            this.$router.push({name: 'Passions'})
          }
        }
        // only when the first time the user in the on boarding step
        if (this.$store.state.current_user.free_archetype === 0) {
          //upload the user status in the database
          await axios.put('/api/cas/users/update_free_archetype');
        }
        //we will reload the user data to make sure the extract website function can only be done once
        this.$store.dispatch('load_user_data');
        this.show = 'next';
        this.selection = ref([]);
        this.images = '';

        this.display_loading_overlay = false;
      } catch (error) {
        this.display_loading_overlay = false;
        alert_error(this.$t('message.general_server_error'));
      }
      this.$router.push({
        name: 'Passions',
        params: {
          value: 'passions',
        },
      });
    },

    handleSelectImg: function (value) {
      if (
        this.archetypes.first_archetype !== value &&
        this.archetypes.second_archetype !== value &&
        this.archetypes.third_archetype !== value
      ) {
        if (!this.archetypes.first_archetype) {
          this.archetypes.first_archetype = value;
        } else if (!this.archetypes.second_archetype) {
          this.archetypes.second_archetype = value;
        } else if (!this.archetypes.third_archetype) {
          this.archetypes.third_archetype = value;
        }
      }
    },

    handleClose(value) {
      if (value === 'one') {
        this.archetypes.first_archetype = '';
      } else if (value === 'two') {
        this.archetypes.second_archetype = '';
      } else if (value === 'three') {
        this.archetypes.third_archetype = '';
      }
    },
  },

  watch: {
    selection(newSelection) {
      if (newSelection.length > 5) {
        this.selection.pop();
        alert_reminding("You can choose a maximum of 5 assets");
      }
    },
  },
};
</script>

<style scoped>
.long-input {
  width: 10vw;
  height: 40px;
  font-size: 16px;
}

.images-gallery {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  height: 36vw;
}

.btn-col {
  text-align: center !important;
}

.tooltip-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-icon img {
  width: 20px;
  margin-left: 0.5vw;
}


.tooltip-content {
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9vw;
  color: white;
  position: absolute;
  background-color: rgb(120, 120, 226);
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  text-transform: none;
  transition: opacity 0.3s, visibility 0.3s;
  margin-left: 0.5vw;
}

.bordered-span {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  padding: 8px;
  display: inline-block;
  border-radius: 4px;
  background-color: #f4f4f4;
  color: #333;
  font-size: 14px;
}

/* below are the layout of the image display */
.image-item {
  height: 10vw;
  object-fit: cover;
}

.bordered-span {
  width: 100%;
  text-align: center;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
  row-gap: 2vw;
}

.cropped-image {
  width: 50%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: 50% 0;
  border: none;
  transition: border 0.3s ease;
}

.hidden-image {
  display: none;
}

.cropped-image:hover {
  border: 2px solid #3498db;
}

.upload-container {
  text-align: center;
  margin-top: 2vw;
}

.drop-area {
  border: 2px dashed #ccc;
  cursor: pointer;
  display: inline-block;
  padding:10px;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1F1FFF;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.upload-icon {
  font-size: 1.3vw;
}

.file-info {
  margin-top: 2vw;
  display: flex;
  justify-content: center;
}

.upload-button {
  background-color: #1F1FFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.images-ur-brand {
    padding-right: 1vw;

}
.select-images-box {
    display: flex;
    background: #FFFFFF;
    border: 0.1vw solid #F0F3F6;
    box-shadow: 0px 0.1vw 0.3vw rgba(16, 24, 40, 0.1), 0px 0.1vw 0.2vw rgba(16, 24, 40, 0.06);
    border-radius: 1.5vw;
    padding: 1.5vw;
    justify-content: center;
    align-items: flex-start;
    gap: 1vw;
    margin-top: 2vw;
    flex-direction: row;
    flex-wrap: wrap;
}
.archetypes-header-box{
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.archetypes-header-box h3 {
    font-weight: 700;
    font-size: 1.2vw;
    line-height: 2vw;
    width: 90%;
    margin-top: 15px;
}
.info-tooltip {
    transform: none;
    background: no-repeat;
    vertical-align: text-bottom;
    position: relative;
    cursor: pointer;
}

.info-tooltip:hover .tooptip-text {
    display: block
}
.tooptip-text {
    position: absolute;
    font-size: 1.1vw;
    line-height: 1.3vw;
    font-weight: normal;
    background: #7878E9;
    color: #fff;
    padding: 1vw;
    border-radius: 1vw;
    text-align: justify;
    left: calc(100% + 25px);
    top: -1vw;
    display: none;
    min-height: 150px;
    width: 400px;
    z-index: 999999;
}
</style>