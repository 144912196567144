<template>
  <div class="credits-box">
    <!-- Replace FontAwesome icon with inline SVG -->
    <div class="coins-logo">
      <img src="../../assets/mindchips.png" alt="" />
    </div>
    
    <div class="credit-no"><span class="font">{{ mindcoin_count }}</span> Mindchips</div>
  </div>
</template>

<script>
import { genericDialog } from '@/helpers/alert_helper.js'; // Assuming the import path

export default {
  name: 'Mindchips',
  data() {
    return {};
  },
  computed: {
    mindcoin_count() {
      return this.$store.state.mindchips;
    },
  },
  methods: {},
};
</script>

<style scoped>

@media only screen and (max-width: 768px) {
  .coins-logo {
  color: #FFFFFF; /* If the SVG inherits color */
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  margin-left: 10px;
}
.font{
  font-size: 15px;
  margin-bottom: 2px;
}
}
.coins-logo {
  color: #FFFFFF; /* If the SVG inherits color */
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.credit-no {
  font-size: 16px;
  color: white;
}

.credits-box {
  display: flex;
  align-items: center;
}
</style>
