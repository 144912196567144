<template>
    <div class="tooltip-container">
        <btn class="button_book_tour" @click="openRedirectRespondentPanel">To Respondent Hub</btn>
        <!-- <div class="tooltip" v-html="$t('new_asset.test_concept_tooltip')">
        </div> -->
    </div>

</template>
  
<script>
// import RedirectCasPopup from '@/components/respondent_panel/RedirectCasPopup.vue';
import { mapGetters, mapActions } from "vuex";
    export default 
    {   
        name: 'RedirecToRespondentPanel',
        data() {
            return {
                currentlang: '',
            };
        },
        created() {
            this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
        },
        computed: {
            ...mapGetters(["autoRedirection"]), // Access popup state and video URL from Vuex
        },
        methods: 
            {
                ...mapActions(['toggleAutoRedirection']),

                openRedirectRespondentPanel(){
                    localStorage.removeItem('jwt_token');
                    localStorage.removeItem('jwt_refresh_token');
                    localStorage.removeItem('is_anonymous');
                    this.$store.dispatch('toggleAutoRedirection',true);
                    // Redirect to the login page
                    this.$router.push({
                        name: 'RespondentLogin',
                    });
                },
            },
    };
</script>

<style scoped>
    .button_book_tour {
        padding: 0.5vw 2vw;
        border-radius: 2vw;
        font-weight: 600;
        font-size: 14px;
        font-style: normal;
        color: #000000;
        background: #ffffff;
        cursor: pointer;
        transition: all 1s;
    }

    .button_book_tour:hover {
        background-color: #ffffff;
        transform: scale(1.02);
    }

    .button_book_tour a {
        color: #ffffff !important;
    }
    .icon{
        width: 20px;
        margin-right: 10px;
        color: #74C0FC;
    }

    .tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .button_book_tour {
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #7878e9;
  color: #fff;
  text-align: center;
  border: solid 1px #000;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -150px; /* Use half of the tooltip width to center it */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>

  