<template>
  <div class="dropdown-menu-ul">
    <ul>
      <li>
        <font-awesome-icon :icon="['fas', 'wrench']" class="icon text"/>
        Tools 
        <font-awesome-icon :icon="['fas', 'angle-down']" class="icon"/>
        <ul>
          <li v-if="mindbias" class="sub-items" @click="handleClick('/mindbias')"><a>MindBias (Beta)</a></li>
          <hr v-if="mindbias" style="color: gray;">
          <li v-if="competitiveProfiler" class="sub-items" @click="handleClick('/competitive_profiler')"><a>Competitive Profiler</a></li>
          <hr v-if="competitiveProfiler" style="color: gray;">
          <li v-if="neuropositioning" class="sub-items" @click="handleClick('/connection_checker')"><a>Connection Checker</a></li>
          <hr v-if="neuropositioning" style="color: gray;">
          <li v-if="explorer" class="sub-items" @click="handleClick('/inspiration-explorer')"><a>Inspiration Explorer</a></li>
          <hr v-if="explorer" style="color: gray;">
          <li v-if="pathFinder" class="sub-items" @click="handleClick('/story_creator')"><a>Story Creator</a></li>
          <hr v-if="pathFinder" style="color: gray;">
          <li v-if="contentTuner" class="sub-items" @click="handleClick('/content_tuner')"><a>Content Tuner</a></li>
          <hr v-if="contentTuner" style="color: gray;">
          <li v-if="mindTracker" class="sub-items" @click="handleClick('/brand_tracker')"><a>Brand Tracker</a></li>
          <hr v-if="associationWheels" style="color: gray;">
          <li v-if="associationWheels" class="sub-items" @click="handleClick('/association-wheel')"><a>Association Wheel</a></li>
        </ul>
      </li>
    </ul>
  </div>
</template>
  
<script>
  export default {
    name: 'Tools',
    methods: {
      handleClick(navigateTo) {
        window.scrollTo(0, 0);
        window.open(this.$router.resolve(navigateTo).href, '_blank');
      },
  },
  computed:{
    contentTuner: function() {
      return this.$store.state.accessible_apps.includes(5);
    },
    associationWheels: function() {
      return this.$store.state.accessible_apps.includes(6);
    },
    pathFinder: function() {
      return this.$store.state.accessible_apps.includes(7);
    },
    explorer: function() {
      return this.$store.state.accessible_apps.includes(8);
    },
    neuropositioning: function() {
      return this.$store.state.accessible_apps.includes(10);
    },
    mindTracker: function() {
      return this.$store.state.accessible_apps.includes(12);
    },
    competitiveProfiler: function() {
      return this.$store.state.accessible_apps.includes(13);
    },
    mindbias: function() {
      return this.$store.state.accessible_apps.includes(14);
    },
  }
};
</script>
  
<style scoped>
    .sub-items{
        margin-left: 10px;
    }
    .icon {
        width: 20px;
        /* margin-right: 10px; */
        color: white;
        font-size: 1.2em;
    }

    @media only screen and (max-width: 768px) {
    .icon {
        font-size: 1.2em;
        margin-right: 0px !important;
    }.text {
        font-size: 12px;
        /* margin-right: 0px !important; */
    }
    .dropdown-menu-ul li {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-weight: 600;
    font-size: 12px !important;
    line-height: 1.5vw;
    position: relative;
    cursor: pointer;
}
    
}
</style>