<template>
  <LoadingAppWide v-if="loading" />
  <div class="mobile-word-picker" @click.stop>
    <!-- Input + Dropdown Container -->
    <div class="picker-input-container">
      <input
        type="text"
        class="picker-input"
        :placeholder="placeholder || computedTranslations.placeholder"
        v-model="inputValue"
        @focus="showDropdown = true"
        @input="onInput"
        @keyup.enter="onEnter"
        :disabled="!!selectedWord"
      />
      
      <!-- Dropdown Menu -->
      <div
        v-if="showDropdown && !selectedWord"
        class="picker-dropdown"
        :style="{ zIndex: 9999 }"
      >
        <!-- Loading State -->
        <div v-if="loading" class="dropdown-item dropdown-loading">
          <span>{{ computedTranslations.loading }}</span>
        </div>

        <!-- Loaded Options -->
        <div v-else>
          <div
            v-for="(option, index) in options"
            :key="index"
            class="dropdown-item"
            @click="selectOption(option)"
          >
            {{ option.label }}
          </div>
          <!-- No options found: show a button to open another popup -->

        </div>
        <div v-if="inputValue.trim() && options.length === 0 && this.$store.state.is_respondent" class="dropdown-item">
            <button v-if="inputValue.trim() && options.length === 0 && this.$store.state.is_respondent" @click="addTextAssetDirectly" class="no-match-button">
              {{ computedTranslations.noMatch }}
            </button>
          </div>
      </div>
    </div>

    <!-- Selected Word Display (below the search box) -->
    <div class="selected-word-container" v-if="selectedWord">
      <span class="selected-word">
        {{ selectedWord.label }}
      </span>
      <button class="remove-button" @click="removeSelectedWord">x</button>
    </div>
  </div>
</template>

  
  

<script>
import axios from 'axios';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';

export default {
  name: 'WordPickerMobileSidebar',
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  components: {
    LoadingAppWide
  },
  data() {
    return {
      inputValue: '',
      options: [],
      loading: false,
      showDropdown: false,
      selectedWord: null,
      currentlang: '',
    };
  },
  created() {
        this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
    },
  mounted() {
    // Hide dropdown when clicking outside
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // Clean up the event listener
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    computedTranslations() {
      return {
        placeholder: this.currentlang === 'nl' ? "Selecteer uw kernwaarde" : "Select your core value",
        loading: this.currentlang === 'nl' ? "Laden..." : "Loading...",
        noMatch: this.currentlang === 'nl' ? "Voeg toe aan het netwerk" : "Add to the Network"
      };
    }
  },
  methods: {
    // Called as user types
    async onInput() {
      this.showDropdown = true;
      await this.fetchWords(this.inputValue);
    },

    // Simple substring-based “closest match”
    onEnter() {
      if (!this.options.length) {
        this.$emit('nosearchresults');
        return;
      }
      const userInput = this.inputValue.trim().toLowerCase();

      // Find first option whose label contains userInput
      const found = this.options.find(opt =>
        opt.label.toLowerCase().includes(userInput)
      );
      if (found) {
        this.selectOption(found);
      } else {
        // No match found; optionally do something else here
        this.showDropdown = false;
        this.$emit('nosearchresults');
      }
    },

    async addTextAssetDirectly() {
      const payload = {
                    'word': this.inputValue.toUpperCase(),
                    'confidential': false,
                    'mindcoins': 0,
                    'expedite': 'Standard Delivery',
                    'brand_website': '',
                    'advertiser': false,
                };
                this.loading = true;
                try {
                    const response = await axios.post('/api/cas/assets/create_text_asset', payload);
                    if (response.status === 200) {
                      toast_success(`Thank you! Your word "${this.inputValue}" will be added to the network soon!`);
                    }
                } catch (error) {
                    const er = error.response;
                    if (er.status === 403 && er.data.insufficient_credits) {
                        alert_error(this.$t('new_asset.insufficient_credits'));
                        // since the user no longer has enough credits, we shouldn't try
                        // to upload the remaining files either
                        return;
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                } finally {
                    this.selectedWord = null;
                    this.inputValue = '';
                    this.loading = false;
                }    
              },
    async fetchWords(val) {
      if (!val) {
        this.options = [];
        this.$emit('nosearchresults');
        return;
      }
      this.loading = true;
      try {
        const payload = {
          draw: 1,
          search: { value: val.toLowerCase() },
          start: 0,
          length: 8
        };
        const response = await axios.post('/api/cas/words/network_search', payload);

        // Create label/value pairs
        this.options = response.data.data.map(w => ({
          value: w,         // w contains { word_id, mapped_on, word, ... }
          label: w.word     // display the 'word' in the dropdown
        }));
        if (this.options.length === 0) {
          this.$emit('nosearchresults');
        }
      } catch (error) {
        console.error('Fetch words error:', error);
      } finally {
        this.loading = false;
      }
    },

    selectOption(option) {
      // Store the selected option in the component
      this.selectedWord = option;
      this.inputValue = '';
      this.showDropdown = false;

      // Transform the new format -> original format
      // Fill missing fields with null or empty string
      const originalFormat = {
        word: option.value.word || '',
        asset_id: null,
        word_id: option.value.word_id || null,
        image_url: option.value.image_url || null,
        mapped: option.value.mapped_on || null, // rename "mapped_on" -> "mapped"
        type: option.value.type || 'TEXT',
        website: option.value.website || null,
        lang: option.value.lang || null,
        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false
                        },
      };

      // Emit the transformed object to the parent
      this.$emit('dictWordSelected', originalFormat);
    },

    removeSelectedWord() {
      this.selectedWord = null;
      this.inputValue = '';
      // Optionally reset dropdown or refetch
      // this.options = [];
      // this.showDropdown = false;

      this.$emit('wordRemoved');
    },

    handleClickOutside(event) {
      // If click is outside .mobile-word-picker, close dropdown
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    }
  }
};
</script>


  

<style scoped>
.mobile-word-picker {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin: 10px auto;
  z-index: 8999 !important;
}

.picker-input-container {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 0 auto; 
  text-align: center;
}

.picker-input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 14px;
  border: 1px solid #ccc;
  border-radius: 18px;
  font-size: 16px;
  outline: none;
}

.picker-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999 !important;
}

/* Dropdown items */
.dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f2f2f2;
  font-size: 14px;
}
.dropdown-item:last-child {
  border-bottom: none;
}
.dropdown-item:hover {
  background-color: #f7f7f7;
}
.dropdown-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 14px;
}
.no-option {
  color: #999;
  text-align: center;
}

/* Display the chosen word below the search box */
.selected-word-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.selected-word {
  background-color: #eaeaea;
  border-radius: 16px;
  padding: 8px 12px;
  font-size: 14px;
}
.remove-button {
  background-color: #ff4d4f;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.remove-button:hover {
  opacity: 0.9;
}

.no-match-button {
  background: #7878e9;
  border: none;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
}

.no-match-button:hover {
  background: #0056b3;
}

@media only screen and (max-width: 320px) {
  .picker-input-container {
    margin-bottom: 3px;
  }
}
@media only screen and (max-width: 480px) {
  .remove-button {
    padding: 0px !important;
  }
}

</style>



