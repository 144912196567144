<template>
    <div class="custom-purple-outline">
        <!-- Header with dropdown toggle -->
        <div class="info-header" @click="toggleDropdown">
            <font-awesome-icon :icon="['fas', 'lightbulb']" class="header-icon" style="color: black" />
            <span style="font-size: smaller; width: fit-content; align-items: center">{{ dropdownTitle }}</span>
            <font-awesome-icon :icon="isCollapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']"
                class="toggle-icon" />
        </div>
        <B2CLoginPopup :searchBox="searchBox" ref="b2cLoginPopup"/>
        <!-- Dropdown Content -->
        <div v-show="!isCollapsed">
            <!-- Conditionally render asset-checkbox or dropdown contents -->
            <div v-if="this.selected_assets_and_positionings.size > 0">
                <!-- Show asset-checkbox when there are selected assets -->
                <asset-checkbox v-for="ap in selected_assets_and_positionings" :key="ap" :asset_and_positioning="ap"
                    :show_positionings="show_positionings" @removeAsset="onRemoveAsset" />
            </div>
            <div v-else>
                <!-- Show dropdown content when no assets are selected -->
                <div class="picker-input-container" @click="openWordPopup">
                <input
                    type="text"
                    class="picker-input"
                    :placeholder="placeholder"
                    :disabled="!!selectedWord"
                />
                </div>
                <div v-show="!isCollapsed" class="default-words-list">
                    <div v-for="word in defaultWords" :key="word.word_id" class="default-word-item"
                        @click="selectDefaultWord(word)">
                        <q-icon name="check" class="circle-check-icon" />
                        <span class="default-word-text">{{ word.word }} (Gratis)</span>
                    </div>
                </div>
                <div v-show="!isCollapsed" class="lib-btn" style="margin-bottom: 20px;"><a class="lib-btn-small"
                        @click="openWordPopup">
                        <q-icon name="expand_more" class="lib-icon-circle" /><span class="dots">...</span></a></div>
                <div v-show="!isCollapsed" class="divider"></div>
                <p>Gratis om te proberen:</p>
                <div v-show="!isCollapsed" class="image-grid-container">


                    <div v-show="!isCollapsed" class="image-grid-container">
                        <div v-show="!isCollapsed" class="image-grid">
                            <div v-for="(payload, index) in payloads" :key="payload.key" class="image-grid-item"
                                @click="emitAddAsset(payload)">
                                <img :src="payload.asset.urls.thumb_medium" :alt="payload.asset.display_name"
                                    class="grid-image" />
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div v-show="!isCollapsed" class="lib-btn" style="margin-bottom: 20px;"><a class="lib-btn-small"
                        @click="openWordPopup">
                        <q-icon name="expand_more" class="lib-icon-circle" /><span class="dots">...</span></a></div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import AddNewAssetDialog from '@/components/add_asset/AddNewAssetDialog.vue';
import AssetCreator from '@/components/asset_library/AssetCreatorDashboard.vue';
import B2CLoginPopup from '../../b2c_network_visualisation/B2CLoginPopup.vue';
import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckboxDashboard.vue';

import {
    alert_error,
    genericDialog
} from '@/helpers/alert_helper.js';

export default {
    components: {
        AddNewAssetDialog, AssetCreator, AssetCheckbox, B2CLoginPopup
    },
    emits: ['wordSelected', 'wordChanged', 'assetClick', 'addNewAsset', 'addAsset'],
    props: {
        placeholder: {
        type: String,
        default: 'Search'
        },
        clearAfterSelection: {
            default: true,
            type: Boolean,
        },
        hint: {
            default: '',
            type: String,
        },
        standalone: {
            default: false,
            type: Boolean,
        },
        selected_assets_and_positionings: {
            type: Set,
            required: true,
        },
        show_positionings: {
            type: Boolean,
            required: true,
        },
        mobileScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        openAssetPicker: Function,
        onAddNewAsset: Function,
        onRemoveAsset: Function,
        show_positionings: { default: false, type: Boolean },
        allowed_types: { default: ['images', 'words', 'multimedia'], type: Array },
        isModal: {
            type: String,
        },
        background: {
            required: false,
            type: String,
            default: 'img-icon.png',
        },
    },
    data() {
        return {
            selectedWord: null,
            searchBox: true,
            options: new Array(),
            isCollapsed: true,
            loading: false,
            imageUrl: '',
            last_search: new String(),
            defaultWordData: {
                MOED: {
                    value: {
                        word: "MOED",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 3896,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false,
                        },
                        image_url: "",
                        has_access: false,
                    },
                    label: "MOED",
                },
                SMAAK: {
                    value: {
                        word: "SMAAK",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 6595,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false,
                        },
                        image_url: "",
                        has_access: false,
                    },
                    label: "SMAAK",
                },
                AUTHENTIEK: {
                    value: {
                        word: "AUTHENTIEK",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 1342,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: "",
                        has_access: false
                    },
                    label: "AUTHENTIEK"
                }
            },
            // List of default words to display in the UI
            defaultWords: [
                { word: 'AUTHENTIEK', word_id: 1342 },
                { word: 'SMAAK', word_id: 6595 },
                { word: 'MOED', word_id: 3896 },
            ],
            payloads: [
            {
                asset: {
                    id: 2182,
                    state: 2,
                    brand: 0,
                    display_name: "1033938352",
                    top_associations: "SPELEN, KIND, GELUK, PLEZIER, GEZIN, LIEFDE, MOEDER, SAMEN, TURNEN, YOGA, SPEELS, FAMILIE, SPEL, ONTSPANNING, LEUK, GENIETEN, MAMA, GEZELLIG, DOCHTER, MOEDER DOCHTER",
                    labels: null,
                    passion: 0,
                    link_word_id: null,
                    map_date: null,
                    intended_association: null,
                    blocked: 0,
                    lifecycle: 4,
                    expedite: null,
                    created_at: "2024-12-13T14:58:40",
                    tracking_auto_tag: 0,
                    tracking: 0,
                    brand_website: null,
                    clarity_score: 5.7416,
                    total_count: 9,
                    word: {
                    word: "1033938352",
                    created_at: "2021-04-13T13:33:31",
                    updated_at: "2021-04-13T13:33:31",
                    asset_mime_type: "image/jpeg",
                    file_identifier: "42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    external: 1,
                    word_id: 295678,
                    type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                    },
                    image_url: ""
                    },
                    candidate_word: [],
                    asset_mime_type: "image/jpeg",
                    file_identifier: "42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    type_: {
                    id: 2,
                    title: "image / logo",
                    created_at: "0000-00-00 00:00:00",
                    updated_at: "0000-00-00 00:00:00",
                    isMultimedia: true,
                    isImage: true,
                    isAudio: false,
                    isVideo: false
                    },
                    urls: {
                    thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_42971213-aeac-43a5-ad28-1b59cc8f2e5a"
                    }
                }
                },
                    {
                    asset: {
                        id: 2563,
                        state: 2,
                        brand: 0,
                        display_name: "1911329839",
                        top_associations: "LAPTOP, WERK, BUREAU, DRUK, SPREIDSTAND, WERKEN, KANTOOR, STRESS, FLEXIBEL, LENIG, MULTITASKEN, BUREAUSTOEL, COMPUTER, STOELEN, THUISWERK, JOB, BURN-OUT, FLEXIBILITEIT, SPLIT, MAN",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 5.35955,
                        total_count: 23,
                        word: {
                        word: "1911329839",
                        created_at: "2021-12-21T11:29:39",
                        updated_at: "2021-12-21T11:29:39",
                        asset_mime_type: "image/png",
                        file_identifier: "c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        external: 1,
                        word_id: 302358,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/png",
                        file_identifier: "c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9"
                        }
                    }
                    },

                    {
                    asset: {
                        id: 2533,
                        state: 2,
                        brand: 0,
                        display_name: "meditatie_605622212",
                        top_associations: "YOGA, RUST, ZEN, MEDITATIE, MEDITEREN, NATUUR, ONTSPANNEN, STILTE, RELAX, ONTSPANNING, ZON, JOGA, VROUW, MINDFULNESS, GENIETEN, BEZINNEN, KALMTE, STRAND, ALLEEN, RUSTGEVEND",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 8.08459,
                        total_count: 1,
                        word: {
                        word: "MEDITATIE_605622212",
                        created_at: "2021-11-30T15:51:00",
                        updated_at: "2021-11-30T15:51:00",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "de294953-92ac-43ff-90c4-01280a0c4b86",
                        external: 1,
                        word_id: 301941,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "de294953-92ac-43ff-90c4-01280a0c4b86",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_de294953-92ac-43ff-90c4-01280a0c4b86",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_de294953-92ac-43ff-90c4-01280a0c4b86",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_de294953-92ac-43ff-90c4-01280a0c4b86",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_de294953-92ac-43ff-90c4-01280a0c4b86"
                        }
                    }
                    },
                    {
                    asset: {
                        id: 2535,
                        state: 2,
                        brand: 0,
                        display_name: "mixdrankjes_635611256",
                        top_associations: "COCKTAIL, BAR, DRANK, ALCOHOL, LEKKER, DRINKEN, BARMAN, COCKTAILS, CAFÉ, APERITIEF, HORECA, OBER, UITGAAN, GENIETEN, GEZELLIG, PLEZIER, cocktailbar, VRIENDEN, BARTENDER, FEEST",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 6.93774,
                        total_count: 1,
                        word: {
                        word: "MIXDRANKJES_635611256",
                        created_at: "2021-11-30T15:51:00",
                        updated_at: "2021-11-30T15:51:00",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        external: 1,
                        word_id: 301943,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_17a3fd81-9c86-4735-842d-2de0dffe2a86"
                        }
                    }
                    },
                    {
                    asset: {
                        id: 2514,
                        state: 2,
                        brand: 0,
                        display_name: "Fietsen_558086152",
                        top_associations: "WIELRENNEN, SPORT, FIETSEN, KOERS, FIETS, WIELRENNER, BERG, BERGEN, SPORTIEF, NATUUR, KLIMMEN, WEG, WEDSTRIJD, INSPANNING, SPORTEN, COUREUR, HELM, ONTSPANNING, RENNER, BERGOP",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 6.69222,
                        total_count: 8,
                        word: {
                        word: "FIETSEN_558086152",
                        created_at: "2021-11-15T23:18:53",
                        updated_at: "2021-11-15T23:18:53",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        external: 1,
                        word_id: 301713,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_75e3688d-72af-4d1a-98e5-f10fb95603f2"
                        }
                    }
                    },
                    {
                    asset: {
                        id: 2534,
                        state: 2,
                        brand: 0,
                        display_name: "Verzorging van kamerplanten_1667571547",
                        top_associations: "PLANT, WATER, PLANTEN, GROEN, VERZORGEN, NATUUR, LEVEN, VERZORGING, GEZOND, BESPROEIEN, SPROEIEN, MOOI, WATER GEVEN, PLANTENSPUIT, ZUURSTOF, KAMERPLANT, ZORG, SPUIT, HOBBY, VERSTUIVEN",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 6.60729,
                        total_count: 57,
                        word: {
                        word: "VERZORGING VAN KAMERPLANTEN_1667571547",
                        created_at: "2021-11-30T15:51:00",
                        updated_at: "2021-11-30T15:51:00",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        external: 1,
                        word_id: 301942,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_1e99bb0d-4ca6-4e70-ad32-58937d9c564d"
                        }
                    }
                    },
            ],
        };
    },
    computed: {
        // we only want to show the store in the standalone version (i.e. in
        // the Library app)
        showAddAssetButton: function () {
            return this.standalone;
        },
        dropdownTitle() {
            // If selected assets exist, display the first asset's name, otherwise default to "Select your value"
            if (this.selected_assets_and_positionings.size > 0 && this.isCollapsed) {

                const firstAsset = this.selected_assets_and_positionings.values().next().value;
                if(!firstAsset.asset.file_identifier){
                    return "Value:  " + firstAsset.asset.display_name;
                }
                else{
                    return "Value: Image";
                }
            } else if(this.selected_assets_and_positionings.size > 0 && !this.isCollapsed){
                return "Geselecteerde waarde";
            } else {
                return "Selecteer uw waarde";
            }
        },

    },

    methods: {
        openWordPopup() {
        this.$refs.b2cLoginPopup.openPopup();
        },
        openLoginPage(){
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_refresh_token');
            localStorage.removeItem('is_anonymous');
            localStorage.removeItem('pageText');
            localStorage.removeItem('pageName');
            this.$router.push({name: 'Login'})
        },
        reset: function () {
            this.selectedWord = null;
        },
        toggleDropdown() {
            this.isCollapsed = !this.isCollapsed;
        },
        filter: async function (val, update, abort) {
            await update(this.fetchWords(val));
        },

        fetchWords: async function (val) {
            if (val === '') {
                this.options = [];
                this.last_search = val;
            } else {
                if (val !== this.last_search) {
                    this.loading = true;
                    const payload = {
                        draw: 1,
                        search: {
                            value: val.toLowerCase(),
                        },
                        start: 0,
                        length: 8,
                    };

                    const response = await axios.post('/api/cas/words/search', payload);
                    const options = response.data.data.map((w) => ({
                        value: w,
                        label: w.has_access ? `✔ ${w.word}` : w.word,
                    }));
                    this.options = options;
                    this.last_search = val;
                    this.$refs.select_widget.showPopup();
                    this.loading = false;

                }
            }
        },

        selectDefaultWord(word) {
            const wordData = this.defaultWordData[word.word];
            if (wordData) {
                // Directly call the watcher function with the entire `new_value` structure
                this.selectedWord = wordData; // This simulates triggering the watcher
                this.toggleDropdown();
            }
        },
        onAddNewAsset: function () {
            this.$refs['add-new-asset-dialog'].open();
        },
        emitAddAsset(payload) {
            this.$emit("addAsset", payload);
            this.toggleDropdown();
        },

        iaSelect: async function (word_object) {
            const wordKey = word_object.word;

            if (this.defaultWordData[wordKey]) {
                // Use embedded data for default words without API call
                this.$emit('wordSelected', word_object);
            } else {
                const payload = {
                    word_ids: [word_object.word_id],
                };
                // here we remove the charging part
                const access = await axios.post('/api/cas/words/check_access', payload);
                if (access.data[word_object.word_id]) {
                    this.$emit('wordSelected', word_object);
                } else {
                    const price = this.$store.getters.get_price('public_word_asset');
                    const dialog_options = {
                        title: this.$t('message.purchase_ia', {
                            word: word_object.word,
                        }),
                        text: this.$t('message.ia_cost', {
                            price: price,
                        }),
                        confirmButtonText: this.$t('message.add'),
                        cancelButtonText: this.$t('message.cancel'),
                    };
                    const dialog_result = await genericDialog(dialog_options);
                    if (dialog_result.isConfirmed) {
                        const url = `/api/cas/words/request_access_new`;
                        const response = await axios.post(url, {
                            word_ids: [word_object.word_id],
                        });
                        if (response.data.access_granted) {
                            this.$emit('wordSelected', word_object);
                            this.$store.dispatch('get_mindcoin_balance');
                        }
                        if (response.data.insufficient_credits) {
                            alert_error(this.$t('message.insufficient_credits_for_ia'));
                        }
                        if (response.data.read_only) {
                            alert_error(this.$t('message.read_only_user_no_ia'));
                        }
                    }
                }
            }
        },

    },
    watch: {
        selectedWord: function (new_value) {
            if (new_value !== null) {
                this.iaSelect(new_value.value);
                if (this.clearAfterSelection) {
                    this.reset();
                }
            } else {
                this.$emit('wordChanged', new_value);
            }
        },
    },
};
</script>

<style scoped>
@media only screen and (max-width: 480px) {
    .custom-purple-outline {
        margin-top: 0px !important;
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    margin-left: -18px;

}

.search-container {
    display: flex;
    align-items: center;
    position: relative;
}

.custom-purple-outline {
    background-color: #f9fbfd;
    border: 1px solid #7878e9;
    border-radius: 8px;
    padding: 5px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    transition: max-height 0.3s ease;
    margin-left: auto;
    margin-right: auto;
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 15px; */
    font-size: 1em;
    font-weight: bold;
    border: 1px solid #eef3fa;
    border-radius: 8px;
    color: #333;
    background-color: #eef3fa;
    cursor: pointer;
}

.toggle-icon {
  color: #000000;
  transition: transform 0.3s;
}

.info-header:hover {
    background-color: #a2c9ff;
}

.header-icon {
    color: #7878e9;
    transition: transform 0.3s;
}

::v-deep .q-field--outlined.q-field__control {
    border-color: #9a9a9f !important;
}

.lib-btn {
    position: relative;
    display: flex;
    margin-top: 10px;
    justify-content: left;
    width: 20%;
    
}

.divider {
    height: 1px;
    background-color: #ddd;
    width: 95%;
    margin: 15px;
    /* Space around the divider */
}


.lib-btn-small {
    /* background-color: #0000ff; */
    color: black;
    width: 15%;
    margin-left: 2%;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lib-btn:hover {
    transform: scale(1.05);
    border-radius: 15px;
    background-color: #a2c9ff;
}

.lib-icon-circle {
    position: absolute;
    left: 10px;
    margin-right: 10%;
    font-size: 16px;
    /* Adjust size as needed */
    color: black;
    /* Icon color */
    background-color: transparent;
    /* Transparent background */
    border: 2px solid black;
    /* Black border */
    border-radius: 50%;
    /* Circular shape */
    width: 16px;
    /* Circle size */
    height: 16px;
    /* Circle size */
    display: flex;
    align-items: center;
    justify-content: center;
}

.dots {
    margin-left: 10px;
    /* Adjust spacing as needed */
    color: black;
}

.search-icon {
    color: #9a9a9f;
    /* Adjust color to match the style */
    margin-right: auto;
    margin-left: 8px;
    margin-bottom: 15px;
    font-size: 20px;
    /* Space between the icon and placeholder */
}

/* Default words styling */

.default-words-list {
    display: flex;
    flex-direction: column;
}

.default-word-item {
    display: flex;
    align-items: center;
    padding: 8px;
    /* background-color: #f0f0f0; */
    border-radius: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
    width: 95%;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.default-word-item:hover {
    background-color: #a2c9ff;
    transform: scale(1.02);
}

.default-word-text {
    flex: 1;
    font-size: 10px;
    color: #333;
}

.circle-check-icon {
    background-color: black;
    /* Black circle */
    color: white;
    /* White checkmark */
    border-radius: 50%;
    /* Make it circular */
    padding: 2px;
    /* Space around the checkmark */
    font-size: 8px;
    /* Adjust size as needed */
    margin-right: 5%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.image-grid-container {
    display: flex;
    align-items: center;
    /* Align items at the top */
    justify-content: center;
    gap: 10px;
    margin-bottom: auto;
    z-index: 1;
    /* Space between grid and asset creator */
}

.asset-creator-container {
    display: flex;
    align-items: center; /* Aligns items vertically within the container */
    justify-content: center; /* Aligns items horizontally within the container */
    margin: 20px auto; /* Sets automatic margins to horizontally center the container */
    width: 100%;
}

.asset ::before {
    display: none;
    width: 125px;
    height: 125px;
}

.asset_img img {
    width: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.asset_img {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 1px 6px rgba(0,0,0,.1607843137254902);
    border: 1px solid black;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: white;
    padding: 10px;
    /* margin-top: 30%; */
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    font-size: 1vw;
    color: #000;
    width: 100px;
    height: 100px;
    text-align: center;
}

.asset_img:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  background-color: #a2c9ff;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(2, 80px);
    /* 3 columns */
    grid-template-rows: repeat(3, 50px);
    /* 2 rows */
    column-gap: 1px;
    row-gap: 18px;
    margin-top: 5px;
    /* margin-left: 8%; */
    justify-items: start;
    /* Align items to the left of the grid */
    align-items: start;
}

.image-grid-item {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;

    background-color: #f0f0f0;
    cursor: pointer;
    /* Makes the images look clickable */
    transition: transform 0.2s ease-in-out;
}

.image-grid-item:hover {
    transform: scale(1.55);
    /* Slight zoom effect on hover */
}

.grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    /* Ensures the image covers the grid item while maintaining aspect ratio */
}

.picker-input-container {
  position: relative;
  width: 100%;
}

.picker-input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 14px;
  border: 1px solid #ccc;
  border-radius: 18px;
  font-size: 14px;
  outline: none;
}

/* Media Queries */
@media screen and (min-width: 1600px) {
    .custom-purple-outline {
        width: 105%;
    }

    .search-icon {
        color: #9a9a9f;
        /* Adjust color to match the style */
        margin-right: -15px;
        margin-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        /* Space between the icon and placeholder */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 115px);
        /* 3 columns */
        grid-template-rows: repeat(3, 65px);
        /* 2 rows */
        column-gap: 5px;
        row-gap: 5px;
        margin-top: 5px;
        /* margin-left: 5%; */
        justify-items: start;
        /* Align items to the left of the grid */
        align-items: start;
    }

}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
    .custom-purple-outline {
        width: 105%;
    }

    .search-icon {
        color: #9a9a9f;
        /* Adjust color to match the style */
        margin-right: -15px;
        margin-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        /* Space between the icon and placeholder */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 110px);
        /* 3 columns */
        grid-template-rows: repeat(3, 60px);
        /* 2 rows */
        column-gap: 1px;
        row-gap: 8px;
        margin-top: 1px;
        /* margin-left: 5%; */
        justify-items: start;
        /* Align items to the left of the grid */
        align-items: start;
    }

}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
    .custom-purple-outline {
        width: 112%;
    }

    .search-icon {
        color: #9a9a9f;
        /* Adjust color to match the style */
        margin-right: -18px;
        margin-left: 8px;
        margin-bottom: 15px;
        font-size: 20px;
        /* Space between the icon and placeholder */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 90px);
        /* 3 columns */
        grid-template-rows: repeat(3, 40px);
        /* 2 rows */
        column-gap: 1px;
        row-gap: 8px;
        margin-top: 1px;
        margin-left: 5%;
        justify-items: start;
        /* Align items to the left of the grid */
        align-items: start;
    }
}
</style>
