<template>
  <div>
    <!-- Brand Game Popup -->
    <div v-if="showBrandGame" class="overlay" @click.self="closePopup">
      <div class="instructions-popup">
        <!-- Header with dynamic text translations -->
        <div class="popup-header sticky-header">
          <div v-if="betPlaced && currentRound == 11">
            <h2>
              {{ currentlang === 'nl' ? ('Je hebt ' + aggregateWinnings + ' chips gewonnen') : ('You won ' + aggregateWinnings + ' chips') }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 10">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (10/10)' : 'Enter hint (10/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 9">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (9/10)' : 'Enter hint (9/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 8">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (8/10)' : 'Enter hint (8/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 7">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (7/10)' : 'Enter hint (7/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 6">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (6/10)' : 'Enter hint (6/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 5">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (5/10)' : 'Enter hint (5/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 4">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (4/10)' : 'Enter hint (4/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 3">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (3/10)' : 'Enter hint (3/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 2">
            <h2>
              {{ currentlang === 'nl' ? 'Voer hint in (2/10)' : 'Enter hint (2/10)' }}
            </h2>
          </div>
          <div v-else-if="betPlaced && currentRound == 1" class="vertical-flex gap-1">
            <h2 class="heading-06 line-22 no-margin">
              {{ currentlang === 'nl' ? 'STAP 1: Bedenk een merk' : 'STEP 1: Think of a Brand' }}
            </h2>
            <h2 class="heading-06 line-22 no-margin">
              {{ currentlang === 'nl' ? 'STAP 2: Kies moeilijkheid & plaats je inzet' : 'STEP 2: : Select Difficulty & Place Your Bet' }}
            </h2>
            <h2 class="heading-12 line-22 no-margin">
              {{ currentlang === 'nl' ? 'STAP 3: Voer hint in (1/10)' : 'STEP 3: Enter hint (1/10)' }}
            </h2>
            <p class="line-22 no-margin p-height">
              {{ currentlang === 'nl' ? 'Behoud het merk dat in je opkwam tijdens de vorige stappen.' : 'Keep the brand that popped in your mind during the previous steps.' }}
            </p>
            <p class="p-height">
              {{ currentlang === 'nl'
                ? 'Geef per ronde één hint — beginnend met subtiele associaties en geleidelijk overgaand naar meer voor de hand liggende hints.'
                : 'Provide one hint per round—starting with subtle associations and gradually moving to more obvious ones.' }}
            </p>
          </div>
          <div v-else-if="roundReady" class="vertical-flex space-between">
            <h2 class="heading-06 no-margin line-22">
              {{ currentlang === 'nl' ? 'STAP 1: Bedenk een merk' : 'STEP 1: Think of a Brand' }}
            </h2>
            <h2 class="heading-12 no-margin line-22">
              {{ currentlang === 'nl' ? 'STAP 2: Kies moeilijkheid & plaats je inzet' : 'STEP 2: : Select Difficulty & Place Your Bet' }}
            </h2>
            <p class="line-22 no-margin">
              {{ currentlang === 'nl'
                ? 'Bepaal de inzet! Je inzet bepaalt de moeilijkheidsgraad en je potentiële beloning. Hoe hoger het risico, hoe groter de opbrengst terwijl je Mindspeller door je hints leidt in de volgende rondes.'
                : 'Set the stakes! Your wager determines the challenge level and your potential reward. The higher the risk, the greater the payoff as you guide Mindspeller through your hints in the next rounds.' }}
            </p>
          </div>
          <div v-else-if="!gameStarted && selectedMode && !roundReady" class="vertical-flex gap-1">
            <h2 class="heading-12">
              {{ currentlang === 'nl' ? 'STAP 1: Bedenk een merk' : 'STEP 1: Think of a Brand' }}
            </h2>
            <p>
              {{ currentlang === 'nl'
                ? 'Maak je klaar om te spelen! Je hebt 10 rondes om subtiele hints te geven — elk weerspiegelt een spontane associatie met het gekozen merk.'
                : 'Get ready to play! You have 10 rounds to drop subtle hints—each reflecting a spontaneous association with your chosen brand.' }}
            </p>
            <p>
              {{ currentlang === 'nl'
                ? 'Overleef ronde 5 zonder dat Mindspeller je merk raadt, en je begint je inzet met winst terug te verdienen!'
                : 'Survive past round 5 without Mindspeller guessing your brand, and you’ll start earning back your wager with a return!' }}
            </p>
          </div>
          <div v-else class="vertical-flex space-between">
            <h2 class="heading-12">
              {{ currentlang === 'nl' ? 'Kan Mindspeller je gedachten lezen?' : 'Can Mindspeller read your mind?' }}
            </h2>
            <h2 class="heading-12">
              {{ currentlang === 'nl'
                ? 'Test je intuïtie! Bedenk een merk, plaats je inzet en kijk of wij het in slechts drie simpele stappen kunnen raden.'
                : 'Put your intuition to the test! Think of a brand, place your bet, and see if we can guess it in just three simple steps.' }}
            </h2>
            <h2 class="heading-12">
              {{ currentlang === 'nl'
                ? 'Hoe subtieler je hints (associaties), hoe groter je beloning! Klaar om de AI uit te dagen?'
                : 'The more subtle your hints (associations), the bigger your reward! Ready to challenge the AI?' }}
            </h2>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <button class="close-button btn-black-outline mr-10" @click="handleInstructionClick">
              <font-awesome-icon :icon="['fas', 'question']" />
            </button>
            <button class="coins" @click="handleCoinClick">
              <p>
                <font-awesome-icon :icon="['fas', 'coins']" size="1xl" class="font-awesome-icon" />
                {{ coinTotal }} {{ currentlang === 'nl' ? 'Chips' : 'Chips' }}
              </p>
            </button>
            <button class="close-button btn-red ml-10" @click="closePopup">✖</button>
          </div>
        </div>
        <div class="popup-content">
          <div class="game-cta-container">
            <div class="cta-section">
              <button class="button" v-if="!play" @click="startGame">
                {{ currentlang === 'nl' ? 'Speel' : 'Play' }}
              </button>
              <div class="round-counter" v-if="gameStarted && currentRound <= maxRounds">
                <h2>
                  {{ currentlang === 'nl' ? 'RONDE ' : 'ROUND ' }}{{ currentRound }}
                </h2>
              </div>
              <button
                v-if="betPlaced"
                class="button"
                
                @click="resetGame"
              >
                {{ currentlang === 'nl' ? 'Opnieuw starten' : 'Restart' }}
              </button>
              <button
                v-if="!gameStarted && !betPlaced && selectedMode"
                class="button btn-fixed-bottom-left"
                @click="backGame"
              >
                {{ currentlang === 'nl' ? 'Terug' : 'Back' }}
              </button>
            </div>
            <div class="game-lives-container" v-if="gameStarted">
              <game-lives :remaining-lives="lives" />
            </div>
            <div class="game-container">
              <div v-if="loading" class="loader-overlay">
                {{ currentlang === 'nl' ? 'Bezig met nadenken...' : 'Thinking...' }}
              </div>
              <div v-if="showSameHintWarning" class="alert alert-error">
                <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <p>
                      {{ currentlang === 'nl'
                        ? 'Je hebt deze associatie al gegeven!'
                        : 'You already gave this association!' }}
                    </p>
                    <p>
                      {{ currentlang === 'nl'
                        ? 'Geef alstublieft een nieuwe hint...'
                        : 'Please provide a new one...' }}
                    </p>
                  </div>
                  <button class="close-button" @click="handleSameHintWarningClose">✖</button>
                </div>
              </div>
              <div v-if="showBetAmountWarning" class="alert alert-error">
                <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <p style="line-height: 2vh;">
                      {{ currentlang === 'nl'
                        ? 'Je hebt niet genoeg chips om in te zetten!'
                        : 'You do not have enough chips to bet!' }}
                    </p>
                  </div>
                  <button class="close-button" @click="handleBetAmountWarningClose">✖</button>
                </div>
              </div>
              <div v-if="showLostLifeWarning" class="alert alert-warning">
                <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <p  style="line-height: 2vh;">
                      {{ currentlang === 'nl'
                        ? 'Oeps! Dat lijkt op een typfout of een niet-Engels trefwoord. Onthoud: eigen namen zijn hoofdlettergevoelig, en verkeerd gespelde hints kosten een leven. Je hebt 3 levens—gebruik ze verstandig!'
                        : 'Oops! That looks like a typo or non-English keyword. Remember: proper nouns are CASE-SENSITIVE, and misspelled hints cost a life. You have 3 lives—use them wisely!' }}
                    </p>
                  </div>
                  <button class="close-button" @click="handleLostLifeWarningClose">✖</button>
                </div>
              </div>
              <div v-if="!gameStarted && selectedMode && !roundReady">
                <button class="button btn-full-width-large" @click="step3Ready">
                  {{ currentlang === 'nl' ? 'Volgende' : 'Next' }}
                </button>
              </div>
              <div v-if="roundReady && !gameStarted && play" class="place-bet-container">
                <div class="bet-input">
                  <label for="betAmount">
                    {{ currentlang === 'nl' ? 'Vaste inzet:' : 'Fixed bet amount:' }}
                    <font-awesome-icon
                      :icon="['fas', 'info-circle']"
                      title="Your bet amount will become variable once you have reached a minimum level of Mindchips."
                      style="color: #7878e9; cursor: pointer; font-size: 18px; width: fit-content; line-height: 2vh;"
                    />
                  </label>
                  <input id="betAmount" value="5" type="number" class="bet-field" disabled />
                </div>
                <div class="difficulty-select">
                  <label for="difficulty">
                    {{ currentlang === 'nl' ? 'Selecteer moeilijkheid:' : 'Select Difficulty:' }}
                  </label>
                  <select id="difficulty" v-model="difficulty" class="difficulty-field">
                    <option value="Starter">Starter</option>
                    <option value="Medium">Medium</option>
                    <option value="Pro">Pro</option>
                  </select>
                </div>
                <button class="button" @click="placeBet">
                  {{ currentlang === 'nl' ? 'Plaats inzet' : 'Place Bet' }}
                </button>
              </div>
              <div class="play-game-container" v-if="gameStarted">
                <div class="round-tracker" v-if="currentRound <= maxRounds">
                  <div class="mobile" style="margin: 20px auto; width:100%; text-align: center;">
                    <label for="hintInput">
                      {{ currentlang === 'nl'
                        ? 'Voer je merkassociatie (hint) in:'
                        : 'Enter your brand association (hint):' }}
                    </label>
                    <input id="hintInput" v-model="lastHint" type="text" class="hint-field" />
                    <button class="button" style="padding: 3.5px; border-radius: 4px; margin-left: 2px;" @click="submitHint">
                      {{ currentlang === 'nl' ? 'Verzenden' : 'Submit' }}
                    </button>
                    <label style="width: 70%; font-size: smaller">
                      {{ currentlang === 'nl'
                        ? 'Hoe subtieler je hints (associaties), hoe groter je beloning!'
                        : 'The more subtle your hints (associations), the bigger your reward!' }}
                    </label>
                  </div>
                  <!-- <details class="round-tracker-instructions" style="margin-top: 10px; text-align: center;">
                    <summary class="round-tracker-instructions-button">
                      {{ currentlang === 'nl' ? 'Instructies' : 'Instructions' }}
                    </summary>
                  </details> -->
                  <p v-if="hintMessage">{{ hintMessage }}</p>
                </div>
                <div v-if="isModalVisible" class="dialog">
                  <div class="dialog-content">
                    <h3>
                      {{ currentlang === 'nl'
                        ? 'Is dit het merk waar je aan denkt?'
                        : "Is this the brand you're thinking of?" }}
                    </h3>
                    <p><strong>{{ currentGuess }}</strong></p>
                    <div class="dialog-actions">
                      <button class="button" @click="closeGuessConfirmationDialog">
                        {{ currentlang === 'nl' ? 'Nee' : 'No' }}
                      </button>
                      <button class="button" @click="confirmGuessConfirmationDialog">
                        {{ currentlang === 'nl' ? 'Ja' : 'Yes' }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="currentRound == maxRounds + 1 && tenthround" class="dialog">
                  <div class="dialog-content">
                    <h3>
                      {{ currentlang === 'nl' ? 'Je hebt niets gewonnen' : 'You did not win anything' }}
                    </h3>
                    <div class="dialog-actions">
                      <button class="button" @click="resetGame">
                        {{ currentlang === 'nl' ? 'Opnieuw starten' : 'Restart' }}
                      </button>
                      <button class="button" @click="closePopup">
                        {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="betAmount == aggregateWinnings && newBrand" class="dialog">
                  <div class="dialog-content">
                    <p>
                      {{ currentlang === 'nl' ? 'We hebben het geraden!' : 'We guessed it!' }}
                    </p>
                    <p>
                      {{ currentlang === 'nl' ? 'Je inzet is break even' : 'Your bet is break even' }}
                    </p>
                    <p>
                      {{ currentlang === 'nl'
                        ? 'Maar laten we doorgaan met spelen; je bent niet de enige met een top-of-mind. Tijd om ons netwerk grondig te verkennen om meer originele merken te ontdekken!'
                        : "But let’s keep playing; you’re not the only one with a predictive top of mind 😉. Time to deep dive our network to discover more original brands!" }}
                    </p>
                    <h4 v-if="difficulty === 'Starter'">
                      {{ currentlang === 'nl' ? 'Bedenk een nieuw merk' : 'Think of a new brand' }}
                    </h4>
                    <h4 v-else>
                      {{ currentlang === 'nl'
                        ? 'Bedenk een nieuw merk geassocieerd met de gecombineerde trefwoord(en):'
                        : 'Think of a new brand associated with the keyword(s) combined:' }}
                    </h4>
                    <h4 v-if="difficulty !== 'Starter'">
                      <strong>{{ formattedHints }}</strong>
                    </h4>
                    <button class="close-button" @click="closeNewBrandDialog">
                      ✖ {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
                    </button>
                  </div>
                </div>
                <div v-else-if="currentRound <= maxRounds && currentRound >= 6 && newBrand" class="dialog">
                  <div class="dialog-content">
                    <p>
                      {{ currentlang === 'nl' ? 'We hebben het geraden!' : 'We guessed it!' }}
                    </p>
                    <p>
                      {{ currentlang === 'nl' ? 'Je hebt nog een kans om alles terug te winnen' : 'You still have a chance to win it all back' }}
                    </p>
                    <p>
                      {{ currentlang === 'nl'
                        ? 'Maar laten we doorgaan met spelen; je bent niet de enige met een top-of-mind. Tijd om ons netwerk grondig te verkennen om meer originele merken te ontdekken!'
                        : "But let’s keep playing; you’re not the only one with a predictive top of mind 😉. Time to deep dive our network to discover more original brands!" }}
                    </p>
                    <h4 v-if="difficulty === 'Starter'">
                      {{ currentlang === 'nl' ? 'Bedenk een nieuw merk' : 'Think of a new brand' }}
                    </h4>
                    <h4 v-else>
                      {{ currentlang === 'nl'
                        ? 'Bedenk een nieuw merk geassocieerd met de gecombineerde trefwoord(en):'
                        : 'Think of a new brand associated with the keyword(s) combined:' }}
                    </h4>
                    <h4 v-if="difficulty !== 'Starter'">
                      <strong>{{ formattedHints }}</strong>
                    </h4>
                    <button class="close-button" @click="closeNewBrandDialog">
                      ✖ {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
                    </button>
                  </div>
                </div>
                <div v-else-if="currentRound <= maxRounds && newBrand" class="dialog">
                  <div class="dialog-content">
                    <p>
                      {{ currentlang === 'nl' ? 'We hebben het geraden!' : 'We guessed it!' }}
                    </p>
                    <p>
                      {{ currentlang === 'nl'
                        ? 'Maar laten we doorgaan met spelen; je bent niet de enige met een top-of-mind. Tijd om ons netwerk grondig te verkennen om meer originele merken te ontdekken!'
                        : "But let’s keep playing; you’re not the only one with a predictive top of mind 😉. Time to deep dive our network to discover more original brands!" }}
                    </p>
                    <h3 v-if="difficulty === 'Starter'">
                      {{ currentlang === 'nl' ? 'Bedenk een nieuw merk' : 'Think of a new brand' }}
                    </h3>
                    <h3 v-else>
                      {{ currentlang === 'nl'
                        ? 'Bedenk een nieuw merk geassocieerd met de gecombineerde trefwoord(en):'
                        : 'Think of a new brand associated with the keyword(s) combined:' }}
                    </h3>
                    <h3 v-if="difficulty !== 'Starter'">
                      <strong>{{ formattedHints }}</strong>
                    </h3>
                    <button class="close-button" @click="closeNewBrandDialog">
                      ✖ {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
                    </button>
                  </div>
                </div>
                <div v-if="isFinalModalVisible" class="dialog" style="width: 50%;">
                  <div class="dialog-content">
                    <h3 v-if="isBrandCheck">
                      {{ currentlang === 'nl' ? 'Spel beëindigd' : 'Game Ended' }}
                    </h3>
                    <h3 v-else-if="!isBrandCheck">
                      {{ currentlang === 'nl' ? 'Spel voorbij' : 'Game Over' }}
                    </h3>
                    <p v-if="isBrandCheck && aggregateWinnings > 0">
                      {{ currentlang === 'nl'
                        ? ('Gefeliciteerd, je hebt ' + aggregateWinnings + ' Chips gewonnen!')
                        : ('Congratulations, you won ' + aggregateWinnings + ' Chips!') }}
                    </p>
                    <p v-else-if="isBrandCheck && aggregateWinnings <= 0">
                      {{ currentlang === 'nl'
                        ? ('Je hebt ' + aggregateWinnings + ' Chips gewonnen!')
                        : ('You won ' + aggregateWinnings + ' Chips!') }}
                    </p>
                    <p v-if="isBrandCheck && aggregateWinnings < 2 * betAmount" style="font-size: small;">
                      <li v-for="(entry, index) in aggregateRounds" :key="index">
                        <span v-if="entry.round < 6">
                          {{ currentlang === 'nl'
                            ? ('Mindspeller won in ronde ' + (entry.round - 1) + '. Herstartte met een inzet van ' + (getPenalty(entry.round - 1) - 1) + ' Chips. Verloor ' + (entry.winning - 1) + ' chips.')
                            : ('Mindspeller won in round ' + (entry.round - 1) + '. Restarted with bet ' + (getPenalty(entry.round - 1) - 1) + ' Chips. Lost ' + (entry.winning - 1) + ' chips.') }}
                        </span>
                        <span v-else>
                          {{ currentlang === 'nl'
                            ? ('Mindspeller won in ronde ' + (entry.round - 1) + '. Herstartte met winsten van ' + getPenalty(entry.round - 1) + ' Chips.')
                            : ('Mindspeller won in round ' + (entry.round - 1) + '. Restarted with winnings ' + getPenalty(entry.round - 1) + ' Chips.') }}
                        </span>
                      </li>
                    </p>
                    <p v-else-if="!isBrandCheck">
                      {{ currentlang === 'nl'
                        ? ('Je inzet van ' + betAmount + ' Chips wordt teruggegeven.')
                        : ('Your bet amount ' + betAmount + ' Chips is given back.') }}
                    </p>
                    <div v-if="isSystemBeaten && isBrandCheck">
                      <p v-if="aggregateWinnings == 2 * betAmount">
                        {{ currentlang === 'nl'
                          ? 'Je hebt het systeem te slim afgedaan—indrukwekkend! Aan welk merk dacht je?'
                          : 'You outsmarted the system—impressive work! What brand were you thinking of?' }}
                      </p>
                      <p v-else>
                        {{ currentlang === 'nl' ? 'Aan welk merk dacht je?' : 'What brand were you thinking of?' }}
                      </p>
                      <input v-model="userBrand" type="text" class="brand-field" />
                      <button class="button" @click="submitUserBrand">
                        {{ currentlang === 'nl' ? 'Verzend merk' : 'Submit Brand' }}
                      </button>
                      <p v-if="isBrandSubmitted">
                        {{ currentlang === 'nl'
                          ? 'Bedankt voor het delen van je top-of-mind merk! Nog een kans wagen? Speel nog een ronde om je merkherinnering te verbeteren of duik dieper in ons netwerk om meer originele merken te ontdekken!'
                          : "Thanks for sharing your top of mind brand! Feeling lucky? Play another round to improve your brand recall skills or Time to deep dive our network to discover more original brands!" }}
                      </p>
                    </div>
                    <div v-if="!isBrandCheck && brandInHistory">
                      <p>
                        {{ currentlang === 'nl'
                          ? 'Het reeds geraden merk is al ingediend. Probeer het opnieuw!'
                          : 'Already guessed brand was submitted. Try again!' }}
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        {{ currentlang === 'nl'
                          ? ('Hints ingevoerd sinds ronde ' + lastEntryRound + ' en het ingediende merk kwamen niet overeen. Probeer het opnieuw!')
                          : ('Hints entered since round ' + lastEntryRound + ' and the brand submitted did not match. Try again!') }}
                      </p>
                    </div>
                    <div class="dialog-actions">
                      <button class="button" @click="closePopup" :disabled="!isBrandSubmitted && isSystemBeaten">
                        {{ currentlang === 'nl' ? 'Afsluiten' : 'Exit' }}
                      </button>
                      <button class="button" @click="resetGame" :disabled="!isBrandSubmitted && isSystemBeaten">
                        {{ currentlang === 'nl' ? 'Opnieuw spelen' : 'Play Again' }}
                      </button>
                    </div>
                    <div style="gap: 5px; display: flex; justify-content: space-between;">
                      <button v-if="aggregateWinnings <= 0 && !brandInHistory && isBrandCheck" class="button" @click="resetGame" style="margin-right: auto;">
                        {{ currentlang === 'nl' ? 'Opnieuw spelen' : 'Play Again' }}
                      </button>
                      <button v-if="aggregateWinnings <= 0 && !brandInHistory && isBrandCheck" class="button" @click="closePopup">
                        {{ currentlang === 'nl' ? 'Stoppen met spelen' : 'Stop Playing' }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="hint-table">
                  <table>
                    <thead>
                      <tr>
                        <th>{{ currentlang === 'nl' ? 'Ronde' : 'Round' }}</th>
                        <th>{{ currentlang === 'nl' ? 'Associatie' : 'Association' }}</th>
                        <th>{{ currentlang === 'nl' ? 'Geraden Merk' : 'Guessed Brand' }}</th>
                        <th>{{ currentlang === 'nl' ? 'Winst' : 'Winnings' }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(entry, index) in history" :key="index">
                        <td>{{ entry.round }}</td>
                        <td>{{ entry.hint }}</td>
                        <td>{{ entry.brand }}</td>
                        <td>{{ entry.penalty }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <!-- Coin Popup -->
    <div v-if="showCoinPopup" class="overlay" @click.self="closeCoinPopup">
    <div class="coin-popup" style="width: 70%;">
      <div class="popup-header" style="display: flex; justify-content: space-between; align-items: center;">
        <h2 style="font-size: large;">
          {{ currentlang === 'nl' ? 'Opmerking' : 'Notice' }}
        </h2>
        <button style="background-color: red; color: white; border-radius: 50%;" @click="closeCoinPopup">
          ✖
        </button>
      </div>
      <div class="popup-content">
        <p style="line-height: 2vh;">
          {{ currentlang === 'nl' ? 'Je chips zijn alleen voor oefening. Meld je aan voor een Mindspeller-account om met echte Mindchips te beginnen inzetten!' : 'Your chips are for practice only. Sign up for a Mindspeller account to start betting with real Mindchips!' }}
        </p>
        <button class="button" @click="handleLoginClick">
          {{ currentlang === 'nl' ? 'Aanmelden' : 'Signup' }}
        </button>
        <button
          class="button"
          style="margin-left: 5px;"
          @click="closeCoinPopup"
        >
          {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
        </button>
      </div>
    </div>
  </div>
    <!-- Instruction Popup -->
    <div v-if="showInstructionPopup" class="overlay" @click.self="closeInstructionPopup">
      <div class="coin-popup" style="width: 50%; height: 100%; overflow-y: auto; line-height: normal;">
        <div class="popup-header">
          <h2>{{ currentlang === 'nl' ? 'Instructies' : 'Instructions' }}</h2>
          <button class="close-button" @click="closeInstructionPopup">✖</button>
        </div>
        <div class="popup-content">
          <ul class="instructions-list">
            <li v-for="(instruction, index) in instructions" :key="index" class="instruction-item">
              <span>{{ instruction }}</span>
            </li>
            <div class="popup-header">
              <h2>{{ currentlang === 'nl' ? 'Regels' : 'Rules' }}</h2>
            </div>
            <li v-for="(rule, index) in rules" :key="index">
              <span>{{ rule }}</span>
            </li>
          </ul>
          <button class="button" style="margin-left: 5px;" @click="closeInstructionPopup">
            {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import GameLives from "./GameLives.vue";
export default {
  components: {
    GameLives,
  },
  props: {
    showBrandGame: Boolean,
  },
  emits: ["close", "brandGameOver"],
  data() {
    return {
      gameStarted: false,
      betPlaced: false,
      selectedMode: "",
      currentRound: 1,
      lastEntryRound: 1,
      maxRounds: 10,
      gameOver: false,
      betAmount: 5,
      difficulty: "Starter",
      hints: [],
      aggregateRounds: [],
      history: [],
      newBrand: false,
      hintMessage: "",
      lastHint: "",
      currentPenalty: 0,
      isModalVisible: false,
      currentGuess: "",
      aggregateWinnings: 0,
      isFinalModalVisible: false,
      isSystemBeaten: false,
      userBrand: "",
      savedData: [],
      duplicateGuess: [],
      showSameHintWarning: false,
      showBetAmountWarning: false,
      showLostLifeWarning: false,
      lives: 3,
      loading: false,
      isBrandSubmitted: false,
      isBrandCheck: true,
      showGamePopup: false,
      showCoinPopup: false,
      showInstructionPopup: false,
      tenthround: false,
      collapsed: true,
      play: false,
      roundReady: false,
      brandInHistory: false,
      gptHints: [],
      currentlang: "",
      instructionsEn: [
        "STEP 1: Get ready to begin the game! You have 10 rounds to give subtle hints that reflect spontaneous associations with the brand you’re thinking of. If you make it past round 5 without Mindspeller guessing your brand, you’ll start earning back your wager with a return.",
        "STEP 2: Set the Stakes – Your wager determines the challenge and potential reward as you guide Mindspeller through your hints in the next rounds.",
        "STEP 3: Keep the brand that popped in your mind during the previous steps. Provide one hint per round—starting with subtle associations and gradually moving to more obvious ones.",
      ],
      rulesEn: [
        "During each round, input your most subtle yet spontaneous brand association. Challenge Mindspeller by avoiding hints that make it too easy to guess your brand. Every brand association that leads Mindspeller to an incorrect guess increases your potential to earn more chips.",
        "Keep playing until all 10 rounds are completed, even if Mindspeller wins before the final round.",
        "- Before Round 5: If Mindspeller wins, the remaining bet will be carried forward to the upcoming rounds.",
        "- After Round 5: If Mindspeller wins, the winnings will be used as the bet for the remaining rounds.",
        "At the end of 10 rounds, a quality review will be conducted to ensure the associations provided accurately reflect the brand. If the associations are deemed ambiguous or unclear, the earned bet amount will not be credited.",
      ],
      instructionsNl: [
        "STAP 1: Maak je klaar om het spel te beginnen! Je hebt 10 rondes om subtiele hints te geven die spontane associaties met het merk dat je in gedachten hebt weerspiegelen. Als je voorbij ronde 5 komt zonder dat Mindspeller je merk raadt, begin je je inzet terug te verdienen met een rendement.",
        "STAP 2: Zet de inzet – Je inzet bepaalt de uitdaging en potentiële beloning terwijl je Mindspeller door je hints in de volgende rondes leidt.",
        "STAP 3: Houd het merk dat in je opkwam tijdens de vorige stappen. Geef één hint per ronde—beginnend met subtiele associaties en geleidelijk overgaand naar meer voor de hand liggende.",
      ],
      rulesNl: [
        "Voer tijdens elke ronde je meest subtiele maar spontane merkassociatie in. Daag Mindspeller uit door hints te vermijden die het te gemakkelijk maken om je merk te raden. Elke merkassociatie die Mindspeller naar een verkeerde gok leidt, vergroot je potentieel om meer chips te verdienen.",
        "Blijf spelen totdat alle 10 rondes zijn voltooid, zelfs als Mindspeller wint voor de laatste ronde.",
        "- Voor ronde 5: Als Mindspeller wint, wordt de resterende inzet doorgeschoven naar de komende rondes.",
        "- Na ronde 5: Als Mindspeller wint, worden de winsten gebruikt als inzet voor de resterende rondes.",
        "Aan het einde van 10 rondes wordt een kwaliteitsbeoordeling uitgevoerd om ervoor te zorgen dat de verstrekte associaties het merk nauwkeurig weerspiegelen. Als de associaties dubbelzinnig of onduidelijk worden bevonden, wordt het verdiende inzetbedrag niet gecrediteerd.",
      ],
    };
  },
  watch: {
    currentRound(newVal) {
      if (newVal > this.maxRound) {
        this.checkGameOver();
      }
    },
  },
  computed: {
    relevantHints() {
      const hintCount =
        this.difficulty === "Pro" ? 3 : this.difficulty === "Medium" ? 2 : 1;
      return this.hints.slice(-hintCount).reverse();
    },
    formattedHints() {
      return this.relevantHints
        .map((hint) => hint.charAt(0).toUpperCase() + hint.slice(1))
        .join(", ");
    },
    ...mapState(["coinTotal"]),
    normalizedCoinTotal() {
      return Math.max(1, Math.min(10, this.coinTotal));
    },
    instructions() {
      return this.currentlang === "nl"
        ? this.instructionsNl
        : this.instructionsEn;
    },
    rules() {
      return this.currentlang === "nl" ? this.rulesNl : this.rulesEn;
    },
  },
  created() {
      if (window.location.href.includes("cas-nl")) {
      this.currentlang = "nl";
    } else {
      this.currentlang = "en";  
    }  
  },
  methods: {
    ...mapActions(["decrementCoins", "updateCoins", "incrementCoins"]),
    ...mapGetters(["getCoinTotal"]),
    getPenalty(round) {
      const historyEntry = this.history.find((h) => h.round === round);
      return historyEntry ? historyEntry.penalty : null;
    },
    closePopup() {
      this.$emit("close");
      if (this.currentRound == 11) {
        this.$emit("brandGameOver");
      }
      if (this.showCoinPopup) {
        this.showCoinPopup = false;
      }
      this.selectedMode = "";
      this.resetGame();
    },
    openCoinPopup() {
      this.showCoinPopup = true;
    },
    closeCoinPopup() {
      this.showCoinPopup = false;
    },
    openInstructionPopup() {
      this.showInstructionPopup = true;
    },
    closeInstructionPopup() {
      this.showInstructionPopup = false;
    },
    handleCoinClick() {
      this.openCoinPopup();
    },
    handleInstructionClick() {
      this.openInstructionPopup();
    },
    handleLoginClick() {
      window.open(this.$router.resolve("/respondent_signup").href, "_blank");
    },
    toggleInstructions() {
      this.collapsed = !this.collapsed;
    },
    startGame() {
      this.selectedMode = "single";
      this.play = true;
    },
    backGame() {
      this.selectedMode = false;
      this.roundReady = false;
      this.play = false;
    },
    step3Ready() {
      this.roundReady = true;
    },
    handleSameHintWarningClose() {
      this.showSameHintWarning = false;
    },
    handleBetAmountWarningClose() {
      this.showBetAmountWarning = false;
    },
    handleLostLifeWarningClose() {
      this.showLostLifeWarning = false;
    },
    resetGame() {
      this.$emit("brandGameOver");
      this.gameStarted = false;
      this.betPlaced = false;
      this.currentRound = 1;
      this.lastEntryRound = 1;
      this.maxRounds = 10;
      this.gameOver = false;
      // this.betAmount = 0;
      this.difficulty = "Starter";
      this.hints = [];
      this.gptHints = [];
      this.history = [];
      this.newBrand = null;
      this.hintMessage = "";
      this.lastHint = "";
      this.isFinalModalVisible = false;
      this.isModalVisible = false;
      this.isSystemBeaten = false;
      this.userBrand = "";
      this.duplicateGuess = [];
      this.showSameHintWarning = false;
      this.showLostLifeWarning = false;
      this.showBetAmountWarning = false;
      this.selectedMode = "single";
      this.isBrandSubmitted = false;
      this.brandInHistory = false;
      this.tenthround = false;
      this.play = false;
      this.roundReady = false;
      this.brandInHistory = false;
      this.aggregateRounds = [];
      this.showInstructionPopup = false;
      this.startGame();
    },
    async submitUserBrand() {
      this.loading = true;
      const brandcheck = await this.submittedbrandcheck(
        this.userBrand,
        this.gptHints
      );
      const isBrandInHistory = this.history.some(
        (entry) => entry.brand.toLowerCase() === this.userBrand.toLowerCase()
      );
      this.loading = false;
      if (isBrandInHistory) {
        this.brandInHistory = true;
      }
      if (brandcheck > 5 && !isBrandInHistory) {
        this.savedData.push({
          brand: this.userBrand,
          associations: this.hints,
        });
        this.isBrandSubmitted = true;
        this.isBrandCheck = true;
      } else {
        this.isBrandSubmitted = true;
        this.isBrandCheck = false;
        this.decrementCoins(this.aggregateWinnings - this.betAmount);
        this.aggregateWinnings = this.aggregateWinnings - this.betAmount;
      }
    },

    async submittedbrandcheck(brand, hints) {
      const prompt = `
        Return a score based on the average semantic association score (on a scale from 0 to 10, 10 being the highest) for all hints entered based on its relevancy to the brand. Strictly only provide the score.

        Hints:
        ${hints.map((hint, index) => `${index + 1}. ${hint}`).join("\n")}

        Entered brand: ${brand}
      `;

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [{ role: "user", content: prompt }],
            max_tokens: 10,
          }),
        }
      );

      const data = await response.json();
      return data.choices[0].message.content.trim();
    },
    placeBet() {
      this.betAmount = 5;
      if (this.betAmount <= this.getCoinTotal()) {
        this.betPlaced = true;
        this.decrementCoins(this.betAmount);
        this.gameStarted = true;
        this.showBetAmountWarning = false;
      } else {
        this.showBetAmountWarning = true;
      }
      this.$emit("brandGameOver");
    },
    closeGuessConfirmationDialog() {
      if (this.currentRound == 11) {
        this.isSystemBeaten = true;
        this.checkGameOver();
      }
      this.duplicateGuess.push(this.currentGuess);
      this.isModalVisible = false;
    },
    confirmGuessConfirmationDialog() {
      this.isModalVisible = false;
      this.savedData.push({
        brand: this.currentGuess,
        associations: this.gptHints,
      });
      // this.duplicateGuess=[]; //reason for commenting: We shouldn’t guess the same brand if we just instructed user to think of a “new” brand.
      this.duplicateGuess.push(this.currentGuess);
      this.newBrand = true;
      if (
        this.currentRound === this.maxRounds + 1 &&
        this.betAmount > this.aggregateWinnings
      ) {
        this.tenthround = true;
      }
    },
    closeNewBrandDialog() {
      this.aggregateWinnings = Math.round(Number(this.currentPenalty) + 1);
      this.lastEntryRound = this.currentRound;
      this.aggregateRounds.push({
        round: this.currentRound,
        winning: this.aggregateWinnings,
      });
      if (this.difficulty !== "Starter") {
        this.gptHints = this.relevantHints; // for ambiguity check. if mindspeller won inbetween we refresh the hints till round 10 (incase user wins).
      } else {
        this.gptHints = [];
      } // this.hints=this.relevantHints; //reason for commenting: We don’t want to show the user the hints they used to guess throughout the game.
      this.newBrand = false;
    },
    closePlayAgainDialog() {
      this.isFinalModalVisible = false;
      this.$emit("brandGameOver");
      this.$emit("close");
    },
    async submitHint() {
      this.loading = true;
      const hint = this.lastHint;
      if (this.hints.includes(hint)) {
        this.loading = false;
        this.showSameHintWarning = true;
        this.lastHint = "";
        return true;
      }
      const gibCheck = await this.checkBrandHint(hint);
      if (gibCheck === "No") {
        this.lives = this.lives - 1;
        this.showLostLifeWarning = true;
        if (this.lives == -1) {
          this.checkGameOver();
        }
        this.loading = false;
        return true;
      }
      this.gptHints.push(hint);
      this.hints.push(hint);
      const guess = await this.getBrandGuess(this.gptHints);
      this.currentGuess = guess;
      this.history.push({
        round: this.currentRound,
        hint: hint,
        brand: guess,
        penalty: this.aggregateRounds
          ? this.calculatePenalty(this.currentRound) - this.aggregateWinnings
          : this.calculatePenalty(this.currentRound) - this.betAmount,
      });
      this.lastHint = "";
      this.isModalVisible = true;
      this.checkGameOver();
    },
    async getBrandGuess(inputHints) {
      const prompt =
        this.currentlang === "nl"
          ? `
        Raad het merk op basis van deze hints: ${inputHints}. Retourneer strikt slechts één gok die niet een van de volgende is: ${this.duplicateGuess}      `
          : `
        Guess the brand based on these hints: ${inputHints}. Return only one guess that is not one of the: ${this.duplicateGuess}
      `;
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [{ role: "user", content: prompt }],
            max_tokens: 10,
          }),
        }
      );
      const data = await response.json();
      return data.choices[0].message.content.trim().replace(/\.$/, ""); // remove trailing period
    },
    async checkBrandHint(hint) {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [
              {
                role: "user",
                content: `Is this a valid keyword in English or Dutch: ${hint}. Return yes or no answer.`,
              },
            ],
            max_tokens: 10,
          }),
        }
      );
      const data = await response.json();
      return data.choices[0].message.content;
    },
    calculatePenalty(roundNumber) {
      if (roundNumber <= 5) {
        this.currentPenalty =
          Number(this.betAmount) -
          (this.maxRounds / 2 - roundNumber) * 0.2 * Number(this.betAmount);
      } else {
        this.currentPenalty =
          Number(this.betAmount) +
          (roundNumber - this.maxRounds / 2) * 0.2 * Number(this.betAmount);
      }
      return this.currentPenalty.toFixed(0);
    },

    async generateHints() {
      const geolocation = await this.getGeolocation();
      const prompt =
        this.currentlang === "nl"
          ? `
        Genereer één trefwoordassociatie voor een merk dat relevant is voor de speler in ${geolocation.city}, ${geolocation.country}:
      `
          : `
        Generate one keyword association for a brand relevant to the player located in ${geolocation.city}, ${geolocation.country}:
      `;

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: [{ role: "user", content: prompt }],
            max_tokens: 50,
          }),
        }
      );
      const data = await response.json();
      return data.choices[0].message.content
        .trim()
        .split("\n")
        .filter((hint) => hint);
    },
    async getGeolocation() {
      const response = await fetch("https://ipapi.co/json/");
      return response.json();
    },
    checkGameOver() {
      if (this.currentRound > this.maxRounds) {
        this.gameOver = true;
        this.aggregateWinnings = Math.round(
          Number(this.currentPenalty) - Number(this.aggregateWinnings)
        );
        this.incrementCoins(this.aggregateWinnings);
        this.isFinalModalVisible = true;
        this.loading = false;
        return;
      }
      if (this.lives == -1) {
        this.gameOver = true;
        this.aggregateWinnings = 0;
        this.incrementCoins(this.aggregateWinnings);
        this.isFinalModalVisible = true;
        this.loading = false;
        return;
      }
      this.currentRound++;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.p-height {
  line-height: 13px;
}
.game-cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.cta-section {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.instructions {
  display: flex;
  width: 70%;
  flex-direction: column;
  margin-top: 20px;
  text-align: justify;
  text-align-last: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #555;
  line-height: 1.6;
  /* background-color: #f8f9fa; */
  padding: 15px;
  /* border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.round-tracker-instructions {
  margin-top: 10px auto;
  text-align: center;
  /* width: 80%; */
  clear: both;
  justify-content: center;
}

.round-tracker-instructions p {
  text-align: left;
  margin: 10px auto;
  font-size: 15px;
}
.round-tracker-instructions-button {
  background-color: #000000;
  color: white;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.round-tracker-instructions-button:hover {
  background-color: #7c7c7c;
}
.button {
  background-color: green;
  color: white;
  font-weight: bold;
  border-radius: 5%;
  border: 0;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
}
.loader-overlay {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  z-index: 9999;
}
.instructions-popup {
  width: 70%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}

.coin-popup {
  width: 30%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000000;
  background-color: white;
  padding-bottom: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.popup-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}
.popup-header p {
  font-size: 0.9rem;
  font-weight: 400;
  width: 80%;
  color: #333;
  margin: 0;
  width: auto;
}
.close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  margin-left: 15px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}
.close-button:hover {
  color: #000;
}

.coins {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.coins p {
  margin: 0;
  font-size: 0.8rem;
  width: 100%;
  font-weight: bold;
  color: #333;
}

.font-awesome-icon {
  margin-right: 0;
}

.alert {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
  margin: 15px 0;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.alert-error {
  border-color: red;
  background-color: #ffe6e6;
  color: red;
}
.alert-warning {
  border-color: orange;
  background-color: #fff4e5;
  color: orange;
}
.dialog {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 20px;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 500px;
  max-width: 80%;
}
.dialog-content {
  text-align: center;
}
.dialog-content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  /* margin-bottom: 5px; */
}
.dialog-content h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.dialog-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.hint-table {
  width: 100%;
  overflow-x: auto;
  margin: 0px 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
.hint-table h2 {
  font-size: 3em;
}
.hint-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.game-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-top: 0%;
  margin-left: 1%;
  margin-right: 1%;
  height: 70%;
  width: 100%;
}
.play-game-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin: 0 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.round-tracker {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
  padding: 20px;
  /* background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.round-counter {
  text-align: center;
  font-size: 1.1em;
  width: 100%;
  font-weight: bold;
  margin-left: 6%;
  color: #333;
  flex: 1;
}

.round-tracker h2 {
  font-size: 3em;
}

.round-counter h2 {
  font-size: 3em;
}

.fixed-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.game-lives-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}

.place-bet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 20px auto;
  gap: 20px;
}
.place-bet-container h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.bet-input,
.difficulty-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}
.bet-input label,
.difficulty-select label {
  font-weight: bold;
  margin-bottom: 5px;
}
.bet-field,
.difficulty-field {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
}
.button {
  background-color: #28a745;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  padding: 15px 25px;
  transition: background-color 0.3s;
}
.button:hover {
  background-color: #218838;
}
.cta-section {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

/* Instructions Box */
.instructions-box {
  background-color: #f9fbfd;
  border: 1px solid #7878e9;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  transition: max-height 0.3s ease;
  margin-left: auto;
  margin-right: auto;
}

.instructions-header,
.info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 15px; */
  font-size: 1em;
  font-weight: bold;
  border: 1px solid #eef3fa;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.instructions-header:hover,
.info-header:hover {
  background-color: #a2c9ff;
}

.instructions-list,
.info-content {
  padding: 15px;
  line-height: 20px;
}

.instructions-list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.instruction-item {
  display: flex;
  align-items: center;
  background-color: #eef3fa;
  border-radius: 5px;
  padding: 10px;
  transition: background 0.3s;
}

.instruction-item:hover {
  background-color: #e2ebf6;
}

.instruction-item span {
  font-size: 1em;
  color: #333;
}

.sticky-header {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 10;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  flex-direction: column-reverse;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}
.gap-1 {
  gap: 1px; /* for small vertical spacing */
}
.space-between {
  justify-content: space-between;
}

.heading-06 {
  font-size: 0.6rem;
}
.heading-09 {
  font-size: 0.9rem;
}
.heading-12 {
  font-size: 1.2rem;
}
.no-margin {
  margin: 0;
}
.line-22 {
  line-height: 2.2;
}

.btn-black-outline {
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  color: black;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}

.btn-red {
  background-color: red;
  color: black;
  border: 1px solid black;
  border-radius: 10px;
}

.btn-full-width-large {
  width: 100%;
  font-size: large;
  padding-left: 40px;
  padding-right: 40px;
}

.btn-fixed-bottom-left {
  position: relative;
  /* bottom: 10px; */
  /* left: 10px; */
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  cursor: pointer;
  /* z-index: 1000; */
}

@media screen and (min-width: 1600px) {
  .hint-table {
    width: 80%;
  }
  .round-tracker {
    width: 40%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .hint-table {
    width: 70%;
  }
  .round-tracker {
    width: 40%;
  }
  .instructions-popup {
    width: 85%;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .hint-table {
    width: 60%;
  }
  .round-tracker {
    width: 30%;
  }
  .instructions-popup {
    width: 80%;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .hint-table {
    width: 55%;
  }
  .round-tracker {
    width: 30%;
  }
  .instructions-popup {
    width: 80%;
  }
}

/* =========================
   MOBILE (<= 768px)
   ========================= */
   @media only screen and (max-width: 768px) {
  /* Popups & Overlays */
  .heading-06 {
    font-size: 0.3rem; /* Example: bigger for mobile if you want */
  }
  .heading-09 {
    font-size: 0.4rem;
  }
  
  /* etc. */
  /* .btn-full-width-large {
    /* font-size: 0.4rem; */
    /* maybe reduce padding on mobile? */
  /* } */
  .instructions-popup,
  .coin-popup {
    width: 90% !important;
    max-height: 90%;
    font-size: 0.5rem;
    padding: 0 10px;
    border-radius: 8px;
    overflow-y: auto;
    /* top: -30px; */
  }
  .popup-header h2 {
    font-size: 0.5rem;
  }
  .popup-header p {
    font-size: 15px !important;
    line-height: 20px;
  }
  .dialog {
    width: 90% !important;
    padding: 15px;
    max-width: none;
    top: 50%;
  }
  .dialog-content {
    line-height: 2;
    overflow: visible;
  }
  .dialog-content h3 {
    font-size: 1rem !important;
    line-height: 1.5;
  }
  .dialog-content h4 {
    font-size: 0.9rem !important;
    line-height: 1.5;
  }
  .dialog-content p {
    font-size: 0.8rem !important;
    line-height: 1.5;
  }

  .game-lives-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
  }

  /* Container Layouts */
  .play-game-container {
    flex-direction: column;
    align-items: center;
  }
  .cta-section {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .round-tracker {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
  }
  .round-tracker h2,
  .round-counter h2 {
    font-size: 1.5em;
  }
  /* .hint-table {
    width: 90%;
    margin: 0 auto;
    font-size: 0.5rem;
    padding: 10px;
  } */

  /* Buttons & Form Fields */
  .button {
    margin: 5px 0;
    /* width: auto; */
    /* font-size: 0.5rem; */
    padding: 0px 15px;
  }
  .bet-input,
  .difficulty-select {
    width: 100%;
    margin-bottom: 10px;
  }
  .bet-field,
  .difficulty-field {
    font-size: 1rem;
    padding: 8px;
  }
  .place-bet-container {
    width: 90%;
    margin: 10px auto;
    padding: 15px;
  }

  /* Misc Font Resizing */
  .instructions-popup,
  .coin-popup {
    font-size: 0.9rem;
  }
  .coins p {
    font-size: 0.4rem;
  }
  .alert {
    font-size: 0.9rem;
    padding: 8px;
  }
  .instructions,
  .round-tracker-instructions {
    width: 90%;
    margin: 0 auto;
    font-size: 0.9rem;
  }
  .round-tracker-instructions p {
    font-size: 0.85rem;
  }
  .round-tracker-instructions-button {
    font-size: 0.85rem;
    padding: 8px;
  }
  .instructions-box {
    width: 90%;
    margin: 10px auto;
  }
  .instructions-header,
  .info-header {
    font-size: 0.9rem;
    padding: 8px;
  }
  .instructions-list,
  .info-content {
    font-size: 0.9rem;
    line-height: 20px;
  }
  .instruction-item span {
    font-size: 0.85rem;
  }
  .sticky-header {
    padding: 0px !important;
  }
  .hint-table {
    width: 100%; /* Full container width */
    max-width: 100% !important;
    margin: 0 auto;
    font-size: 0.7rem;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: visible; /* Remove horizontal scrolling */
  }

  .hint-table table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Force columns to share available width equally */
  }

  .hint-table th,
  .hint-table td {
    padding: 4px; /* Reduce padding for a tighter fit */
    font-size: 0.7rem;
    text-align: left;
    word-wrap: break-word;
    white-space: normal; /* Allow wrapping */
  }
}
@media only screen and (max-width: 480px) and (min-width: 377px) {
  .btn-full-width-large {
    width: 100px;
  }
  .mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hint-table {
    width: 100%; /* Full container width */
    max-width: 100% !important;
    margin: 0 auto;
    font-size: 0.4rem;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: visible; /* Remove horizontal scrolling */
  }

  .hint-table table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Force columns to share available width equally */
  }

  .hint-table th,
  .hint-table td {
    padding: 4px; /* Reduce padding for a tighter fit */
    font-size: 0.4rem;
    text-align: left;
    word-wrap: break-word;
    white-space: normal; /* Allow wrapping */
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .btn-full-width-large {width: 100px; }
  .hint-table {
    width: 100%; /* Full container width */
    max-width: 100% !important;
    margin: 0 auto;
    font-size: 0.4rem;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: visible; /* Remove horizontal scrolling */
  }

  .hint-table table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Force columns to share available width equally */
  }

  .hint-table th,
  .hint-table td {
    padding: 4px; /* Reduce padding for a tighter fit */
    font-size: 0.4rem;
    text-align: left;
    word-wrap: break-word;
    white-space: normal; /* Allow wrapping */
  }
}

/* 320px and below */
@media only screen and (max-width: 320px) {
  .btn-full-width-large { width: 100px; }
  .hint-table {
    width: 100%; /* Full container width */
    max-width: 100% !important;
    margin: 0 auto;
    font-size: 0.4rem;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: visible; /* Remove horizontal scrolling */
  }

  .hint-table table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Force columns to share available width equally */
  }

  .hint-table th,
  .hint-table td {
    padding: 4px; /* Reduce padding for a tighter fit */
    font-size: 0.4rem;
    text-align: left;
    word-wrap: break-word;
    white-space: normal; /* Allow wrapping */
  }
}
</style>
