<template>
  <div class="popup-overlay" @click.self="$emit('close')">
    <div class="popup-container">
      <!-- Header -->
      <div class="redirectpopup-header">
        {{ t.header }}
      </div>
      <q-btn style="margin-left: 10%;" icon="close" flat round dense @click="$emit('close')" />

      <!-- Give & Take Section -->
      <div class="redirectpopup-body">
        <div class="popup-give-take">
          <h4 style="font-weight: bold;">{{ t.sectionTitle }}</h4>
          <div class="reward-section">
            <!-- Mindspeller Rewards -->
            <div class="reward-box">
              <h6>{{ t.consumerTitle }}</h6>
              <h7>{{ t.consumerSubtitle }}</h7>
              <hr />
              <div class="reward-content">
                <div>
                  <font-awesome-icon :icon="['fas', 'users-line']" alt="Login" />
                  <font-awesome-icon :icon="['fas', 'comment-dots']" alt="Login" style="margin-left: 0; margin-bottom: 18px; font-size: 14px;" />
                </div>
                <div class="reward-content">
                  <font-awesome-icon :icon="['fas', 'arrow-right']" class="icon" />
                </div>
                <div class="coins-logo">
                  <img src="../../assets/mindchips_black.png" alt="Mindchips" />
                </div>
              </div>

              <div class="reward-content" style="gap: 80px">
                <p v-html="t.consumerShare"></p>
                <p v-html="t.consumerGet"></p>
              </div>
            </div>

            <!-- Mindspeller CAS (Highlighted in Yellow) -->
            <div class="reward-box mindspeller-cas">
              <h6>{{ t.advertiserTitle }}</h6>
              <h7>{{ t.advertiserSubtitle }}</h7>
              <hr />
              <div class="reward-content">
                <div>
                  <font-awesome-icon :icon="['fas', 'coins']" class="icon" />
                </div>
                <div class="reward-content">
                  <font-awesome-icon :icon="['fas', 'arrow-right']" class="icon" />
                </div>
                <div class="coins-logo">
                  <font-awesome-icon :icon="['fas', 'tools']" class="icon" />
                </div>
              </div>

              <div class="reward-content">
                <p v-html="t.advertiserOffer"></p>
                <p v-html="t.advertiserGet"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CTA Button -->
      <button class="cta-button" @click="redirectToMindspeller">
        {{ t.cta }}
      </button>
    </div>
  </div>
  <RedirectCasPopup v-if="showRedirectCas" @close="closeRedirectCas"></RedirectCasPopup>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from '@/eventBus.js';
export default {
  data() {
    return {
      showRedirectCas: false,
      currentlang: 'en', // default language
    };
  },
  created() {
    // Set the language from environment variables (defaults to 'nl' if not set)
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  },
  computed: {
    t() {
      return this.currentlang === 'nl'
        ? {
            header: "VOEG JE EIGEN CONCEPTEN TOE (SLEUTELWOORDEN, MERKEN, ADVERTENTIES, AFBEELDINGEN, VIDEO'S...)!",
            sectionTitle: "WAT GEEF EN NEEM JE WEG ALS...",
            consumerTitle: "CONSUMENT / RESPONDENT",
            consumerSubtitle: "MINDSPELLER BELONINGEN",
            consumerShare: "Je deelt<br>Gedachten",
            consumerGet: "Je krijgt<br>Beloningen",
            advertiserTitle: "ADVERTENTIE / MARKETEER",
            advertiserSubtitle: "MINDSPELLER ADVERTENTIES",
            advertiserOffer: "Je biedt<br>Kortingen",
            advertiserGet: "Je krijgt<br>Gekwalificeerde leads",
            cta: "Klik hier om toegang te krijgen tot Mindspeller Ads en VOEG JE EIGEN CONCEPTEN TOE",
          }
        : {
            header: "ADD YOUR OWN CONCEPTS (KEYWORDS, BRANDS, ADS, IMAGES, VIDEO’S..)!",
            sectionTitle: "WHAT DO YOU GIVE AND TAKE AS...",
            consumerTitle: "CONSUMER / RESPONDENT",
            consumerSubtitle: "MINDSPELLER REWARDS",
            consumerShare: "You Share<br>Thoughts",
            consumerGet: "You Get<br>Rewards",
            advertiserTitle: "ADVERTISER / MARKETER",
            advertiserSubtitle: "MINDSPELLER ADS",
            advertiserOffer: "You Offer<br>Discounts",
            advertiserGet: "You Get<br>Qualified Leads",
            cta: "Click here to access Mindspeller Ads and ADD YOUR OWN CONCEPTS",
          };
    },
  },
  emits: ["close"],
  methods: {
    ...mapActions(['toggleAutoRedirection']),
    redirectToMindspeller() {
      // Save credentials and log the user out before redirecting
      EventBus.emit('save-user-credentials');
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('jwt_refresh_token');
      localStorage.removeItem('is_anonymous');
      this.$store.dispatch('toggleAutoRedirection', true);
      this.$router.push({ name: 'Login' });
    },
    closeRedirectCas() {
      this.showRedirectCas = false;
    },
  },
};
</script>

<style scoped>
/* Base Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 0%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background: white;
  padding: 20px;
  border-radius: 15px;
  max-width: 900px;
  width: 90%;
  overflow: auto;
  max-height: 80%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Custom Scrollbar */
.popup-container::-webkit-scrollbar {
  width: 10px;
}
.popup-container::-webkit-scrollbar-track {
  background: #a7a7d4;
  border-radius: 10px;
}
.popup-container::-webkit-scrollbar-thumb {
  background: #7878e9;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}
.popup-container::-webkit-scrollbar-thumb:hover {
  background: #a7a7d4;
}

/* Header */
.redirectpopup-header {
  background: #7c77e5;
  color: white;
  font-weight: bold;
  margin: auto;
  padding: 15px;
  max-width: 80%;
  border-radius: 15px;
  font-size: 15px;
}

/* Body */
.redirectpopup-body {
  background: #b3a9f3;
  color: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
}

/* Reward Section */
.popup-give-take {
  background: #b3a9f3;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  color: black;
  margin-top: 10px;
}

.reward-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  margin-top: 10px;
}

/* Reward Boxes */
.reward-box {
  background: white;
  padding: 15px;
  flex: 1 1 300px;

  border-radius: 15px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 5px;
}
.reward-box hr {
  border: none;
  height: 1px;
  background-color: black;
  margin: 5px 0;
}
.mindspeller-cas {
  background: yellow;
}

/* Reward Content & Icons */
.reward-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 5px;
}
.reward-content p {
  font-size: 15px;
  text-align: center;
}
.icon {
  font-size: 28px;
  color: black;
}

/* CTA Button */
.cta-button {
  background: #0000ff;
  color: white;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 15px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}
.cta-button:hover {
  background: #5e5ad3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .popup-container {
    padding: 15px;
    max-width: 95%;
  }
  .redirectpopup-header {
    font-size: 14px;
    padding: 10px;
  }
  .redirectpopup-body {
    font-size: 14px;
    padding: 10px;
  }
  .reward-section {
    flex-direction: column;
    gap: 10px;
  }
  .reward-box {
    flex: 1 1 100%;
  }
  .cta-button {
    font-size: 16px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .popup-container {
    padding: 10px;
  }
  .redirectpopup-header {
    font-size: 12px;
  }
  .redirectpopup-body {
    font-size: 12px;
  }
  .reward-content p {
    font-size: 12px;
  }
  .icon {
    font-size: 24px;
  }
  .cta-button {
    font-size: 14px;
    padding: 6px;
  }
}
</style>
