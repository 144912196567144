<template>
  <section class="middle-section">
    <div class="container">
      <div class="inspiration-pg association-wheel-pg brand-campaign-pg">
        <div class="inspir-lft-sec ">
          <!-- <div class="brd-crm">Drive</div> -->
          <div class="headingbar">
            <span @click="$router.push('/content_tuner')"></span>Brand Campaign
            <span class="info-tooltip"
              ><img src="../../assets/info-tooltip.png" />
              <div class="tooptip-text">
                After having researched and refined your brand identity /
                strategy, you are now ready to test your campaign content.
                Contact your Mindspeller account manager to explore your options
              </div>
            </span>
          </div>

          <div class="flex-col">
            <div class="campaing-col">
              <div class="sml-col">
                Configure, automate and monitor your campaign
              </div>
              <ul class="check-list-brand">
                <li>
                  <span class="green-circl"
                    ><img src="../../assets/icon20.png"
                  /></span>
                  Engage your Audience
                </li>
                <li>
                  <span class="green-circl"
                    ><img src="../../assets/icon20.png"
                  /></span>
                  Interactive Ads
                </li>
                <li>
                  <span class="green-circl"
                    ><img src="../../assets/icon20.png"
                  /></span>
                  Real-time Ad Measurement
                </li>
              </ul>
              <div class="btn-div">
                <a @click="handleBookACall" class="btn3">Contact Us </a>
              </div>
            </div>

            <div class="campaing-img-col">
              <div class="img-col-4">
                <img src="../../assets/brand-campaign1.png" />
              </div>
              <div class="img-col-4">
                <img src="../../assets/brand-campaign2.png" />
              </div>
              <div class="img-col-4">
                <img src="../../assets/brand-campaign3.png" />
              </div>
              <div class="img-col-4">
                <img src="../../assets/brand-campaign4.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BrandCampaign',
  methods: {
    handleBookACall: function() {
      window.open('https://ads.mindspeller.com/contact/');
    },
  },
};
</script>
