<template>
    <section class="order-summary-section">
        <div class="container" style="display: block; padding: 3vw;">
            <div class="row justify-content-center">
                <div class="col-12 text-left">
                    <h6 class="order-summary-title">Order Summary</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="plan-details-box">
                        <div class="row align-items-center">
                            <div class="col-md-4 text-center">
                                <div class="plan-label">High Level</div>
                            </div>
                            <div class="col-md-5">
                                <select class="form-select">
                                    <option selected>1 month</option>
                                </select>
                                <span class="plan-renew">Renew March 1, 2023 for €800/month</span>
                            </div>
                            <div class="col-md-3 mt-2 text-center">
                                <div class="plan-price">€800/month <br /><span>1600 Mindcoins</span></div>
                            </div>
                        </div>
                        <hr>
                        <h5 class="plan-includes-title">Plan Includes</h5>
                        <ul class="plan-includes-list">
                            <li><a href="#">Track your brand image</a></li>
                            <li><a href="#">Track your competitor</a></li>
                            <li><a href="#">Track your associations</a></li>
                            <li><a href="#">Pretest your creatives</a></li>
                            <li><a href="#">Tune your creatives</a></li>
                            <li><a href="#">Impress your audience</a></li>
                            <li><a href="#">Monitor brand equity</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="summary-box">
                        <h6 class="summary-title">Order Summary</h6>
                        <div class="summary-details">
                            <span class="summary-label">Subtotal (€)</span>
                            <span class="summary-value">€1880</span>
                        </div>
                        <p class="summary-text">Subtotal includes all applicable taxes</p>
                        <p class="summary-highlight">You’re getting the best price we have got</p>
                        <div class="text-center">
                            <a @click="$router.push('/payment_method')" class="btn btn-primary pay-btn mt-3 mb-3" style="text-decoration: none !important; font-weight: 600;
">I’m Ready to Pay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OrderSummary"
};
</script>



<style>
.plan-label,
.plan-select {
    height: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-summary-section {
    padding: 5vw 0;
}

.order-summary-title {
    font-weight: 700;
    font-size: 1.5vw;
    color: #1B2F3F;
    margin-bottom: 2vw;
    margin-top: 2vw;
    text-align: left;
}

.plan-details-box {
    border: 1px solid #D1DAE7;
    border-radius: 0.8vw;
    padding: 2vw;
    margin-bottom: 2vw;
    background: #fff;
}

.plan-label {
    background: #F0F3F6;
    border: 1px solid #115dce;
    padding: 1.4vw;
    margin-bottom: 20px;
    border-radius: 3px;
    font-weight: 600;
    color: #115dce;
}

.plan-select {
    width: 100%;
    border-radius: 0.5vw;
    font-size: 1.1vw;
}

.plan-renew {
    display: block;
    color: #405261;
    /* font-size: 1vw; */
}

.form-select {
    text-align: center;
    padding: 13px !important;
}

.plan-price {
    font-weight: 600;
    font-size: 1.2vw;
    text-align: center;
    margin-bottom: 23px;
}

.plan-includes-title {
    font-weight: 600;
    font-size: 1.2vw;
    margin-top: 1.5vw;
}

.plan-includes-list {
    list-style-type: none;
    padding: 0;
    color: black;
}

.plan-includes-list li,
.plan-includes-list a {
    font-size: 16px;
    color: black;
}

.summary-box {
    background: #F0F3F6;
    border-radius: 1vw;
    padding: 2vw;
}

.summary-title {
    font-weight: 700;
    font-size: 1.1vw;
    margin-bottom: 1vw;
}

.summary-details {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 1.2vw;
    margin-bottom: 2vw;
}

.summary-text {
    font-size: 1vw;
    color: #405261;
}

.summary-highlight {
    font-size: 0.8vw;
    font-weight: 600;
}

.pay-btn {
    width: 100%;
    padding: 1vw;
    font-size: 1.2vw;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    text-decoration: none !important;
}

.pay-btn:hover {
    background-color: #115dce !important;
    border-color: #115dce !important;
}

/* Media Queries for different devices */

/* For tablets and smaller desktops */
@media (max-width: 1024px) {
    .order-summary-title {
        font-size: 2.3vw;
    }


    .plan-price,
    .summary-details,
    .pay-btn {
        font-size: 2.5vw;
    }

    .summary-highlight {
        font-size: 1.4vw;
    }

    .summary-details {
        font-size: 2vw;
        padding-bottom: 20px;
    }

    .plan-select {
        font-size: 2vw;
    }

    .form-select {
        text-align: center;
    }

    .plan-label {
        padding: 2.4vw;
    }

    .plan-price {
        font-size: 1.7vw;
        padding-bottom: 10px;
    }

    .plan-renew,
    .summary-text {
        font-size: 1.5vw;
    }

    .plan-includes-title {
        font-size: 2vw;
    }

    .plan-includes-list li,
    .plan-includes-list a {
        font-size: 1.8vw;
    }
}

/* For mobile devices */
@media (max-width: 768px) {
    .order-summary-title {
        font-size: 2.7vw;
        margin-top: 6vw;
    }

    .plan-price {
        font-size: 2vw;
        padding-bottom: 15px;
    }

    .plan-details-box,
    .summary-box {
        padding: 5vw;
    }

    .plan-label,

    .summary-details {
        font-size: 2vw;
        padding: 2.4vw;
    }

    .form-select {
        padding: 7px !important;
    }

    .summary-title {
        font-size: 1.8vw;
    }

    .summary-highlight {
        font-size: 1.4vw;
    }

    .pay-btn {
        font-size: 2vw;
        padding: 20px;
    }

    .plan-select {
        font-size: 2.0vw;
        padding: 20px;
    }

    .form-select {
        text-align: center;
    }


    .plan-includes-title {
        font-size: 2vw;
    }

}

/* For very small mobile devices */
@media (max-width: 480px) {
    .order-summary-title {
        font-size: 4vw;
        margin-top: 20vw;
    }

    .plan-details-box,
    .summary-box {
        padding: 6vw;
    }

    .plan-label {
        padding: 20px;
        font-size: 3vw;
    }

    .plan-price,
    .summary-details,
    .pay-btn {
        font-size: 4vw;
    }

    .plan-select {
        font-size: 3.5vw;
    }

    .summary-title {
        font-size: 3.5vw;
    }

    .summary-highlight {
        font-size: 3.0vw;
    }

    .form-select {
        text-align: center;
    }

    .plan-renew,
    .summary-text {
        font-size: 3vw;
    }

    .plan-includes-title {
        font-size: 2.7vw;
    }

    .plan-includes-list li,
    .plan-includes-list a {
        font-size: 2.5vw;
    }

}
</style>
