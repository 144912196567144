<template>
  <div class="logo">
    <a @click="handleClick"><img src="../../assets/logo-with-text.png" /></a>
  </div>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    handleClick : function () {
            this.$router.push('/dashboard').then(() => {
                window.location.reload();
            });
        },
  },

};
</script>

<style scoped>
.logo {
  width: 175px;
  height: 75px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  background-color: 7878e9;
}
</style>
