<template>
    <div class="tooltip-container">
        <btn class="button_book_tour" @click="launchBecomeMindspeller">TURN PRO</btn>
        <!-- <div class="tooltip" v-html="$t('new_asset.test_concept_tooltip')">
        </div> -->
    </div>
    <BecomeMindspeller @close=closeBecomeMindspeller v-if="showBecomeMindspeller"/>

</template>
  
<script>
import BecomeMindspeller from '@/components/respondent_panel/BecomeMindspeller.vue';

    export default 
    {   
        components: {
            BecomeMindspeller
        },
        name: 'TurnPro',
        data() {
            return {
                showBecomeMindspeller: false,
            };
        },
        methods: 
            {
                launchBecomeMindspeller() {
                    this.showBecomeMindspeller = true;
                },
                closeBecomeMindspeller() {
                    this.showBecomeMindspeller = false;
                },
            },
    };
</script>

<style scoped>
    .button_book_tour {
        padding: 0.9vw 4vw;
        border-radius: 2vw;
        font-weight: 600;
        font-size: 14px;
        font-style: normal;
        color: #000000;
        background: linear-gradient(to right, #ffffff, #e5ff22);
        cursor: pointer;
        transition: all 1s;
    }

    .button_book_tour:hover {
        background-color: #ffffff;
        transform: scale(1.02);
    }

    .button_book_tour a {
        color: #ffffff !important;
    }
    .icon{
        width: 20px;
        margin-right: 10px;
        color: #74C0FC;
    }

    .tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .button_book_tour {
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #7878e9;
  color: #fff;
  text-align: center;
  border: solid 1px #000;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -150px; /* Use half of the tooltip width to center it */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>

  