<template>
  <div class="game-lives-container" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
    {{ currentlang === 'nl' ? 'Resterende levens:' : 'Remaining Lives:' }}
    <font-awesome-icon :icon="['fas', 'heart']" size="2xl" :style="{ color: iconColors[0] }" />
    <font-awesome-icon :icon="['fas', 'heart']" size="2xl" :style="{ color: iconColors[1] }" />
    <font-awesome-icon :icon="['fas', 'heart']" size="2xl" :style="{ color: iconColors[2] }" />
    <!-- Tooltip -->
    <div v-if="showTooltip" class="tooltip">
      <ul>
        <li>
          {{ currentlang === 'nl'
            ? 'Voer geen wartaal in. Je verliest een leven.'
            : 'Do not enter gibberish. You will lose a life.' }}
        </li>
        <li>
          {{ currentlang === 'nl'
            ? 'Je verdient 2 chips voor elke invoer.'
            : 'You earn 2 chips for each entry.' }}
        </li>
        <li>
          {{ currentlang === 'nl'
            ? 'Chips worden niet toegekend als de associatie leeg is.'
            : 'Chips will not be provided if the association is empty.' }}
        </li>
        <li>
          {{ currentlang === 'nl'
            ? 'Als alle levens grijs zijn, worden de verdiende chips ingenomen.'
            : 'If all lives are gray, the earned chips will be taken back.' }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameLivesSurvey",
  props: {
    remainingLives: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      showTooltip: false,
      currentlang: '',
    };
  },
  created() {
    this.currentlang = this.$store.getters.getEnvVar("VUE_APP_DEFAULT_LANGUAGE") || "nl";
  },
  computed: {
    iconColors() {
      switch (this.remainingLives) {
        case 3:
          return ["red", "red", "red"];
        case 2:
          return ["gray", "red", "red"];
        case 1:
          return ["gray", "gray", "red"];
        case 0:
          return ["gray", "gray", "gray"];
        default:
          return ["red", "red", "red"];
      }
    },
  },
};
</script>
  
  <style scoped>
  .game-lives-container {
    display: flex;
    gap: 10px;
    margin-right: 8%;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  /* Tooltip Styling */
  .tooltip {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1005;
    white-space: nowrap;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    width: max-content;
  }
  
  .tooltip ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .tooltip ul li {
    margin-bottom: 5px;
  }

  .game-lives-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
  </style>
  