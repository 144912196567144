const shared_translation = {
    en: {
        message: {
            settings: 'Settings',
            username: 'Username',
            firstname: 'First name',
            password: 'Password',
            email: 'Email',
            organization: 'Organization',
            save: 'Save',
            open: 'Open',
            cancel: 'Cancel',
            name: 'Name',
            type: 'Type',
            select: 'Select',
            enter_valid_email_address: 'Please enter a valid email address.',
            active: 'Active',
            inactive: 'Inactive',
            your_mindcoins: 'Your Mindcoins',
            sign_out: 'Sign out',
            help: 'Help',
            new: 'New',
            generate: 'Generate',
            new_document: 'New document',
            version: 'Version',
            characters: 'Characters',
            word: 'Word',
            score: 'I.A. recall score',
            click_here: 'Click here',
            association: 'Association',
            associations: 'Associations',
            purchase: 'Purchase',
            add: 'Add',
            asset_creator_add: 'Add',
            continue: 'Continue',
            close: 'Close',
            your_documents: 'Your documents',
            no_match: 'Concept not surveyed',
            library_no_match: 'Concept not yet in your Library. Try searching in Mindspeller Library concepts and add to your Library.',
            loading: 'Loading',
            queued:"Queued",
            in_survey:"Surveying",
            in_cleaning:"In Review",
            in_ingestion:"Updating",
            done:"Ready",
            queued_description:"Your concept has been added to a survey queue",
            in_survey_description:"Your concept is being surveyed",
            in_cleaning_description:"Your concept survey results are in review for Quality Assurance",
            in_ingestion_description:"The network is being updated with your concept survey results",
            done_description:"You can now test your concept using the tools",
            not_positioned: 'Not mapped',
            being_positioned: 'Being mapped',
            being_repositioned: 'Being remapped',
            positioned: 'Mapped',
            audio: 'Audio',
            video: 'Video',
            image: 'Image',
            delete: 'Delete',
            reset: 'Reset',
            reset_color:'Reset color',
            export: 'Export',
            change: 'Change',
            workspace_empty: 'Your {app_name} results will appear here',
            no_access_to_items: 'You do not have access to selected items.',
            general_server_error: 'Something has gone wrong on our end. Try refreshing your browser or contact your customer success manager',// CHECKUI
            your_results: 'Your results',
            start_date: 'Start date',
            end_date: 'End date',
            quick_scan: 'Quick scan',
            select_asset_and_ia: 'Please select an Asset and Intended Association.',
            select_two_assets: 'Please select two Assets.',
            responses: 'Responses',
            percentage: 'Percentage',
            percentages: 'Show percentages',
            statistics: 'Show statistics',
            open_in: 'Open in',
            upload: 'Upload',
            rows_per_page: 'Rows per page',
            intended_associations: 'Intended Associations',
            search: 'Search',
            duplicate_asset: "You cannot add the same asset twice!",
            yes: "Yes",
            no: "No",
            logo: "Logo",
            photo: "Photo",
            other: "Other",
            status: "Status",
            tracking: "Tracking",
            track: "Track",
            launch: "Launch",
            map: "Map",
            branding: "Branding",
            sort_by: "Sort by",
            all: "All",
            results: "Results",
            dont_show_again: "Don't show again",
            copy_to_clipboard: "copy to clipboard",
            category: "Category",
            watch_instructions_video: "Watch instructions video",
            date: 'Date',
            survey: "Review the human associations entered after exposure to your Asset. Assess if they require editing, deletion or removal",
            edit:"Click on the entries that you would like to edit and replace by another keyword entry with similar meaning",
            delete:"Select the boxes next to the entries that reflect a definition, opinion or meaningless response that you would like to delete (i.e. remove from the dataset that will be used to position this Asset in the network). Don’t forget to submit your batch selection at the bottom of the page",
            noIdea:"Select the boxes next to the entries that reflect a ‘no idea’ type response (eg. ‘?’, ‘???’, ‘no idea’, ‘x’, ‘1,2,4’, etc. ) to cluster them into one single ‘no idea category’. Don’t forget to submit your batch selection at the bottom of the page",
            proportion_no_idea:"The proportion of no idea is: ",
            respondentpanel_title: "Choose an option in the right side bar to earn Mindchips and access premium rewards. Scroll down to explore more! ",
            respondentpanel_title_mobile: "Choose an option to earn Mindchips and access premium rewards. Scroll down to explore more! ",
            respondentpanel_phoneverification: "Click here to verify your Phone Number in 'My Account' before you can earn Mindchips and access premium rewards.",
            respondentpanel_title2:"Earn More Mindchips to Hit Your Redemption Milestones",
            respondentpanel_title3: "Every game or survey brings you closer to exclusive discounts, product upgrades, and real earnings!",
            respondentpanel_milestone1: "HIT YOUR 1<SUP>st</SUP> MILESTONE",
            respondentpanel_milestone1_description: "Gather 1,000 Mindchips to unlock coupons for your favorite category.",
            respondentpanel_milestone2: "HIT YOUR 2<SUP>nd</SUP> MILESTONE",
            respondentpanel_milestone2_description: "Collect 10,000 Mindchips and maximize your rewards!",
            respondentpanel_invite_friends: "Invite {count} Friends",
            respondentpanel_redeem_coupon: "REDEEM YOUR COUPON <br> <small>AND ADD YOUR BRAND DISCOVERY</small>",
            respondentpanel_select_category: "Select Your Product Category of Interest",
            respondentpanel_another_action: "Another Action",
            respondentpanel_score_discounts: "SCORE DISCOUNTS ON YOUR FAVOURITE BRAND DISCOVERIES",
            respondentpanel_double_mindchips: "DOUBLE YOUR MINDCHIPS",
            respondentpanel_play_game: "PLAY GAME<br /><small><small>(1 Mindchip per thought)</small></small>",
            respondentpanel_share_thoughts: "SHARE THOUGHTS<br /><small><small>(2 Mindchips per thought)</small></small>",
            respondentpanel_monitor_health: "MONITOR MENTAL HEALTH",
            respondentpanel_CTA1: "DOUBLE YOUR MINDCHIPS",
            respondentpanel_CTA2: "PLAY GAME",
            respondentpanel_CTA3: "SHARE THOUGHTS",
            respondentpanel_CTA4: "MONITOR MENTAL HEALTH",
            step1_title: "STEP 1: Think of a Brand",
            step1_description1: "Get ready to play! You have 10 rounds to drop subtle hints—each reflecting a spontaneous association with your chosen brand.",
            step1_description2: "Survive past round 5 without Mindspeller guessing your brand, and you’ll start earning back your wager with a return!",
            step2_title: "STEP 2: Select Difficulty & Place Your Bet",
            step2_description: "Set the stakes! Your wager determines the challenge level and your potential reward. The higher the risk, the greater the payoff as you guide Mindspeller through your hints in the next rounds.",
            step3_title: "Earn MindChips by challenging Mindspeller in a guessing game!",
            step3_description1: "Put your intuition to the test! Think of a brand, place your bet, and see if we can guess it in just three simple steps.",
            step3_description2: "The more subtle your hints (associations), the bigger your reward! Ready to challenge the AI? ",
            step4_title: "Keep the brand that popped in your mind during the previous steps.",
            step4_title2: "Provide one hint per round—starting with subtle associations and gradually moving to more obvious ones.",
            oops_description: 'Oops! That looks like a typo or non-English keyword. Remember: proper nouns are CASE-SENSITIVE, and misspelled hints cost a life. You have 3 lives—use them wisely!',
            hint_warning1: 'You already gave this association!',
            hint_warning2: 'Please provide a new one',
            chips_warning: 'You do not have enough chips to bet!',
            preround_text1: 'Enter your brand association (hint):',
            preround_text2: 'The more subtle your hints (associations), the bigger your reward!',
            game_dialog1: 'Is this the brand you are thinking of?',
            game_dialog2: 'You did not win anything',
            game_dialog3: 'We guessed it!',
            game_dialog4: 'Your bet is break even',
            game_dialog5: 'But let’s keep playing; you’re not the only one with a predictive top of mind 😉. Time to deep dive our network to discover more original brands!',
            game_dialog6: 'Think of a new brand',
            game_dialog7: 'Think of a new brand associated with the keyword(s) combined:',
            game_dialog8: 'You still have a chance to win it all back',
            game_dialog9: 'You outsmarted the system—impressive work! What brand were you thinking of?',
            game_dialog10: 'Thanks for sharing your top of mind brand! Feeling lucky? Play another round to improve your brand recall skills or Time to deep dive our network to discover more original brands!',
            game_dialog11: 'Already guessed brand was submitted. Try again!',
            game_dialog12: 'Hints entered since round',
            game_dialog13: 'and the brand submitted did not match. Try again!',
            game_dialog14: 'What brand were you thinking of?',
            play_again: 'Play again',
            stop_playing: 'Stop playing',

        },
    },
    nl: {
        message: {
            settings: 'Instellingen',
            username: 'Gebruikersnaam',
            firstname: 'Voornaam',
            password: 'Wachtwoord',
            email: 'E-mail',
            organization: 'Organisation',
            save: 'Opslaan',
            open: 'Open',
            cancel: 'Annuleer',
            continue: 'Doorgaan',
            name: 'Naam',
            type: 'Type',
            select: 'Selecteer',
            enter_valid_email_address: 'Gelieve een geldig email adres in te geven.',
            active: 'Actief',
            inactive: 'Inactief',
            your_mindcoins: 'Jouw Mindcoins',
            sign_out: 'Afmelden',
            help: 'Help',
            new: 'Nieuwe',
            generate: 'Genereer',
            new_document: 'Naamloos document',
            version: 'Versie',
            characters: 'Karakters',
            word: 'Woord',
            launch: 'Lanceer',
            score: 'G.A. recall score',
            click_here: 'Klik hier',
            association: 'Association',
            associations: 'Associaties',
            purchase: 'Kopen',
            your_documents: 'Jouw documenten',
            add: 'Toevoegen',
            asset_creator_add: 'Voeg toe',
            close: 'Sluiten',
            no_match: 'Geen zoekresultaten',
            library_no_match: "Concept nog niet beschikbaar in uw Library. Zoek in de Publieke concepten en voeg toe in je Library.",
            loading: 'Laden',
            queued:"In wachtrij",
            in_survey:"Surveying",
            in_cleaning:"In Review",
            in_ingestion:"Updating",
            done:"Klaar",
            queued_description:"Je concept is toegevoegd aan een survey wachtrij",
            in_survey_description:"Je concept wordt bevraagd",
            in_cleaning_description:"De resultaten van je concept responses worden gecontroleerd op kwaliteit.",
            in_ingestion_description:"Het netwerk wordt geüpdate met de resultaten van je survey resultaten",
            done_description:"Je kunt je concept nu testen met de tools",
            not_positioned: 'Niet gemapped',
            being_positioned: 'Wordt gemapped',
            being_repositioned: 'Wordt opnieuw gemapped',
            positioned: 'Gemapped',
            audio: 'Audio',
            video: 'Video',
            image: 'Afbeelding',
            delete: 'Verwijderen',
            reset: 'Reset',
            export: 'Exporteer',
            change: 'Aanpassen',
            workspace_empty: 'Jouw {app_name} resultaten zullen hier verschijnen',
            no_access_to_items: 'Je hebt geen toegang tot de geselecteerde items.',
            general_server_error: 'Er is iets misgegaan aan onze kant. Probeer uw browser te herladen of contacteer uw customer success manager',
            your_results: 'Jouw resultaten',
            start_date: 'Startdatum',
            end_date: 'Einddatum',
            quick_scan: 'Snelle scan',
            select_asset_and_ia: 'Gelieve een Asset en Gewenste Associatie te selecteren.',
            select_two_assets: 'Gelieve twee Assets te selecteren.',
            responses: 'Antwoorden',
            percentage: 'Percentage',
            percentages: 'Toon percentages',
            statistics: 'Toon statistieken',
            open_in: 'Openen in',
            upload: 'Upload',
            rows_per_page: 'Rijen per pagina',
            intended_associations: 'Gewenste Associaties',
            search: 'Zoek',
            duplicate_asset: "Je kan dezelfde Asset geen tweemaal toevoegen",
            yes: "Ja",
            no: "Nee",
            logo: "Logo",
            photo: "Foto",
            other: "Ander",
            status: "Status",
            tracking: "Tracking",
            track: "Track",
            map: "Map",
            branding: "Branding",
            sort_by: "Sorteer als",
            all: "Alles",
            results: "Resultaten",
            dont_show_again: "Toon niet meer opnieuw",
            copy_to_clipboard: "kopieer naar klembord",
            category: "Categorie",
            watch_instructions_video: "Bekijk instructie video",
            respondentpanel_title: "Kies een optie in de rechter zijbalk om Mindchips te verdienen en toegang te krijgen tot premium beloningen. Scroll naar beneden om meer te ontdekken",
            respondentpanel_title_mobile: "Kies een optie om Mindchips te verdienen en toegang te krijgen tot premium beloningen. Scroll naar beneden om meer te ontdekken",
            date: 'Datum',
            respondentpanel_phoneverification: "Klik hier om je telefoonnummer te verifiëren in 'Mijn Account' voordat je Mindchips kunt verdienen en toegang krijgt tot premium beloningen.",
            respondentpanel_title2:"Verdien meer Mindchips om je Redemption Milestones te bereiken",
            respondentpanel_title3: "Elk spel of elke enquête brengt je dichter bij exclusieve kortingen, productupgrades en echte inkomsten!",
            respondentpanel_milestone1: "BEREIK JE 1<SUP>ste</SUP> MIJLPAAL",
            respondentpanel_milestone1_description: "Verzamel 1.000 Mindchips om kortingsbonnen te ontgrendelen voor je favoriete categorie.",
            respondentpanel_milestone2: "BEREIK JE 2<SUP>de</SUP> MIJLPAAL",
            respondentpanel_milestone2_description: "Verzamel 10.000 Mindchips en maximal!",
            respondentpanel_invite_friends: "Nodig {count} Vrienden Uit",
            respondentpanel_redeem_coupon: "VERZILVER JE COUPON <br> <small>EN VOEG JE MERKONTDEKKING TOE</small>",
            respondentpanel_select_category: "Selecteer je productcategorie van interesse",
            respondentpanel_another_action: "Een Andere Actie",
            respondentpanel_score_discounts: "SCORE KORTINGEN OP JE FAVORIETE MERKONTDEKKINGEN",
            respondentpanel_double_mindchips: "VERDUBBEL JE MINDCHIPS",
            respondentpanel_play_game: "SPEEL SPEL<br /><small><small>(1 Mindchip per gedachte)</small></small>",
            respondentpanel_share_thoughts: "DEEL GEDACHTEN<br /><small><small>(2 Mindchips per gedachte)</small></small>",
            respondentpanel_monitor_health: "MONITOR JE MENTALE GEZONDHEID",
            respondentpanel_CTA1: "VERDUBBEL JE MINDCHIPS",
            respondentpanel_CTA2: "SPEEL SPEL",
            respondentpanel_CTA3: "DEEL GEDACHTEN",
            respondentpanel_CTA4: "MONITOR JE MENTALE GEZONDHEID",
            step1_title: "STAP 1: Denk aan een merk",
            step1_description1: "Maak je klaar om te spelen! Je hebt 10 rondes om subtiele hints te geven - elk reflecterend een spontane associatie met je gekozen merk.",
            step1_description2: "Overleef ronde 5 zonder dat Mindspeller je merk raadt, en je begint je inzet terug te verdienen met een rendement!",
            step2_title: "STAP 2: Selecteer moeilijkheidsgraad & plaats je inzet",
            step2_description: "Stel de inzet in! Je inzet bepaalt het uitdagingsniveau en je potentiële beloning. Hoe hoger het risico, hoe groter de beloning terwijl je Mindspeller door je hints in de volgende rondes leidt.",
            step3_title: "Verdien MindChips door Mindspeller uit te dagen in een gokspel!",
            step3_description1: "Test je intuïtie! Denk aan een merk, plaats je inzet en kijk of we het in slechts drie eenvoudige stappen kunnen raden.",
            step3_description2: "Hoe subtieler je hints (associaties), hoe groter je beloning! Klaar om de AI uit te dagen?",
            step4_title: "Houd het merk dat in je opkwam tijdens de vorige stappen.",
            step4_title2: "Geef één hint per ronde - beginnend met subtiele associaties en geleidelijk overgaand naar meer voor de hand liggende.",
            oops_description: 'Oeps! Dat lijkt op een typfout of een niet-Engels trefwoord. Onthoud: eigen namen zijn hoofdlettergevoelig, en verkeerd gespelde hints kosten een leven. Je hebt 3 levens—gebruik ze verstandig!',
            hint_warning1: 'Je hebt deze associatie al gegeven!',
            hint_warning2: 'Je hebt deze hint al gebruikt in deze ronde.',
            chips_warning: 'Je hebt niet genoeg chips om in te zetten!',
            preround_text1: 'Voer uw merkassociatie in (tip):',
            preround_text2: 'Hoe subtieler je hints (associaties), hoe groter je beloning!',
            game_dialog1: 'Is dit het merk waar je aan denkt?',
            game_dialog2: 'Je hebt niets gewonnen',
            game_dialog3: 'We hebben het geraden!',
            game_dialog4: 'Je inzet is break even',
            game_dialog5: 'Maar laten we doorgaan met spelen; jij bent niet de enige met een voorspellende top of mind 😉. Tijd om ons netwerk te verkennen en meer originele merken te ontdekken!',
            game_dialog6: 'Denk aan een nieuw merk',
            game_dialog7: 'Denk aan een nieuw merk geassocieerd met de gecombineerde trefwoorden:',
            game_dialog8: 'Je hebt nog een kans om alles terug te winnen',
            game_dialog9: 'Je hebt het systeem te slim af geweest - indrukwekkend werk! Aan welk merk dacht je?',
            game_dialog10: 'Bedankt voor het delen van je top of mind merk! Voel je je gelukkig? Speel nog een ronde om je merkherinneringsvaardigheden te verbeteren of tijd om ons netwerk te verkennen en meer originele merken te ontdekken!',
            game_dialog11: 'Al geraden merk was ingediend. Probeer het opnieuw!',
            game_dialog12: 'Hints ingevoerd sinds ronde',
            game_dialog13: 'en het ingediende merk kwam niet overeen. Probeer het opnieuw!',
            game_dialog14: 'Aan welk merk dacht je?',             
            play_again: 'Speel opnieuw',
            stop_playing: 'Stop met spelen',
        },
    }
};

export { shared_translation };
