<template>
  <div
    v-if="showSurveyPopup"
    class="trial-survey-popup-overlay"
    @click.self="closePopup"
  >
    <div class="trial-survey-popup-container">
      
      <div class="survey-header-container">
        <div class="lives-container" v-if="gameStarted">
          <game-lives-survey :remaining-lives="lives" />
        </div>
        <div class="button-group">
          <button class="coins" @click="handleCoinClick">
            <div class="coins-logo">
              <img src="../../assets/mindchips.png" alt="" />
            </div>
            {{ coinTotal }} Chips
          </button>
          <button
            class="close-button"
            style="border: 1px solid black; border-radius: 10px;margin-left: 10px; background-color: red; color: black;"
            @click="closePopup"
          >
            ✖
          </button>
        </div>
      </div>

      
      <div class="survey-content-container">
        <h2>Enter 3 <strong>SPONTANEOUS</strong> associations</h2>

        <div class="image-container">
          <div v-if="steps.length > currentStep && steps[currentStep].image">
            <img
              :src="steps[currentStep].image"
              alt="Step Image"
              class="brand-image"
            />
          </div>
          <div
            v-else-if="steps.length > currentStep && steps[currentStep].video"
          >
            <video
              controls
              class="brand-video"
              autoplay
              @loadeddata="handleVideoLoaded"
            >
              <source :src="steps[currentStep].video" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        
        <div class="input-container">
          <div
            v-for="(thought, index) in steps.length > currentStep
              ? steps[currentStep].data
              : []"
            :key="index"
            class="input-wrapper"
          >
            <input
              type="text"
              v-model="steps[currentStep].data[index]"
              :placeholder="
                `Enter SPONTANEOUS keyword Association ${index + 1} here`
              "
              :disabled="isInputDisabled[index]"
              class="input-field"
              @blur="handleInputValidation(index)"
              @focus="enableNextInput(index + 1)"
            />
            <div class="coins-logo">
              <img
                v-if="steps[currentStep].data[index] !== ''"
                src="../../assets/mindchips.png"
                alt=""
              />
            </div>
          </div>
        </div>
        
        <div class="survey-footer-container">
          <button class="submit-button" @click="handleSubmit">Submit</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showCoinPopup" class="overlay" @click.self="closeCoinPopup">
    <div class="coin-popup" style="width: 70%;">
      <div class="popup-header" style="display: flex; justify-content: space-between; align-items: center;">
        <h2 style="font-size: large;">
          {{ currentlang === 'nl' ? 'Opmerking' : 'Notice' }}
        </h2>
        <button style="background-color: red; color: white; border-radius: 50%;" @click="closeCoinPopup">
          ✖
        </button>
      </div>
      <div class="popup-content">
        <p style="line-height: 2vh;">
          {{ currentlang === 'nl' ? 'Je chips zijn alleen voor oefening. Meld je aan voor een Mindspeller-account om met echte Mindchips te beginnen inzetten!' : 'Your chips are for practice only. Sign up for a Mindspeller account to start betting with real Mindchips!' }}
        </p>
        <button class="button" @click="handleLoginClick">
          {{ currentlang === 'nl' ? 'Aanmelden' : 'Signup' }}
        </button>
        <button
          class="button"
          style="margin-left: 5px;"
          @click="closeCoinPopup"
        >
          {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import GameLivesSurvey from "./GameLivesSurvey.vue";
import axios from "axios";

export default {
  name: "ShareThoughtsPopup",
  components: { GameLivesSurvey },
  props: { showSurveyPopup: Boolean },
  emits: ["survey-submitted", "close"],
  data() {
    return {
      showCoinPopup: false,
      gameStarted: true,
      currentStep: 0,
      totalChips: 0,
      lives: 3,
      isInputDisabled: [false, true, true],
      validatedInputs: [false, false, false],
      validAssociations: true,
      loading: false,
      showLostLifeWarning: false,
      invalidResponses: [],
      steps: [
        {
          type: "input",
          header: "Enter 3 spontaneous associations",
          image: require("../../assets/energy.png"),
          data: ["", "", ""],
          completed: [false, false, false],
        },
        {
          type: "input",
          header: "Enter 3 spontaneous associations",
          image:
            "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_2506b3e3-fe84-408e-af5d-a8bb73ddadc8",
          data: ["", "", ""],
          completed: [false, false, false],
        },
        {
          type: "input",
          header: "Enter 3 spontaneous associations",
          video:
            "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_73019a07-1a2e-4df2-8f5d-7b9e38237919",
          data: ["", "", ""],
          completed: [false, false, false],
        },
      ],
    };
  },
  computed: {
    ...mapState(["coinTotal"]),
  },
  methods: {
    ...mapActions(["decrementCoins", "updateCoins", "incrementCoins"]),

    playCoinSound() {
      const coinSound = new Audio("/sounds/sonic-coin-sound.mp3");
      coinSound.volume = 0.5;
      coinSound
        .play()
        .catch((error) => console.error("Audio play failed:", error));
    },

    enableNextInput(index) {
      if (index < this.isInputDisabled.length) {
        this.isInputDisabled[index] = false;
      }
    },

    async handleInputValidation(index) {
      if (index < 2 && !this.validatedInputs[index]) {
        this.loading = true;
        const userInput = this.steps[this.currentStep].data[index].trim();
        if (userInput) {
          this.incrementCoins(2);
          this.playCoinSound();
          this.validatedInputs[index] = true;
          this.loading = false;
        }
        this.loading = false;
      }
    },

    async handleSubmit() {
      const lastIndex = this.steps[this.currentStep].data.length - 1;
      if (!this.validatedInputs[lastIndex]) {
        this.loading = true;
        const lastInput = this.steps[this.currentStep].data[lastIndex].trim();
        if (lastInput) {
          this.incrementCoins(2);
          this.playCoinSound();
          this.validatedInputs[lastIndex] = true;
        }
        this.loading = false;
      }

      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        this.isInputDisabled = [false, true, true];
        this.validatedInputs = [false, false, false];
      } else {
        this.$nextTick(() => {
          this.$emit("survey-submitted", this.steps);
          this.closePopup();
        });
      }
    },

    closePopup() {
      this.$emit("close");
    },

    closeCoinPopup() {
      this.showCoinPopup = false;
    },

    handleCoinClick() {
      this.showCoinPopup = true;
    },

    handleLoginClick() {
      window.open(this.$router.resolve("/respondent_signup").href, "_blank");
    },

    handleVideoLoaded(event) {
      event.target.play();
    },
  },
};
</script>

<style>
/* General Styling */

@media screen and (max-width: 376px) {
  .survey-content-container h2 {
    font-size: 15px !important;
  }
  .survey-coins-button {
    width: 170px !important;
  }
  .game-lives-container{
    gap:4px !important;
  }
  .trial-survey-popup-container{
    max-width: 100% !important;
    top: -30px;
  }
}

/* @media screen and (max-width: 480px) {

} */

@media screen and (max-width: 480px) {

  .button-group{
    width: 100%;
    justify-content: space-around;
  }

  .trial-survey-popup-container {
    top: -30px;
  }


  .survey-coins-button {
    width: 170px;
  }
  .game-lives-container {
    margin-right: 0px !important;
    padding: 0px;
  }
  .survey-header-container {
    flex-direction: column-reverse;
    gap: 4px;
  }
  .input-container {
    width: 100% !important;
  }
  .input-field {
    font-size: 10px !important;
  }
  .survey-content-container h2 {
    font-size: 16px !important;
    margin-top: 10px !important;
    line-height: normal;
  }
  .submit-button {
    width: 100px;
    padding: 0px !important;
  }
}
.trial-survey-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.911);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 40px;
  overflow: auto;
  height: 100vh;
}

.input-fields {
  width: 60%;
}

.trial-survey-popup-container {
  position: relative;
  flex: auto;
  background: #6c63ff;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  max-width: 80%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

}

.survey-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  width: 100%;
}

.survey-content-container h2 {
  flex-grow: 1;
  font-size: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.button-group {
  display: flex;
  gap: 10px;
  cursor: default;
}

.survey-coins-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ddd;
  cursor: default;
}

.survey-coins-button:hover {
  background-color: transparent;
}

.coins {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: #7878e9;
  border-radius: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.coins p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.coin-popup {
  width: 30%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}
.survey-coins-logo {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.survey-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.stimulus-container {
  width: auto;
  height: 250px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: no-wrap;
  border-radius: 10px;
  font-size: 1.5rem;
  color: black;
  text-align: center;
  white-space: no-wrap;
  padding: 10px;
}

.image-container {
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden; 
}

.brand-image {
  width: 100%;
  height: 100%; 
  object-fit: contain;
  border-radius: 14px;
}
.brand-video {
  width: 50%;
  height: 50%;
  object-fit: fill;
}

.input-container {
  margin-top: 5px;
  
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.placeholder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.placeholder-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}

.placeholder-text {
  font-size: 1.2rem;
  color: white;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.coin-icon {
  font-size: 1.5rem;
  color: gold;
}

.footer-container {
  margin-top: 10px;
}

.submit-button {
  background: white;
  color: #6c63ff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background: #554fb8;
  color: white;
  transform: scale(1.05);
}

.footer-text {
  text-align: center;
  font-size: 0.9rem;
  color: white;
  margin-top: 10px;
}

.alert-survey {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
  margin: 15px 0;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.alert-warning {
  border-color: orange;
  background-color: #fff4e5;
  color: orange;
  padding: 0;
}

.lives-container {
  width: fit-content;
  white-space: nowrap;
  height: 35px;
  padding: 5px 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
}

.close-button {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-button:hover {
  background-color: transparent;
}
</style>
