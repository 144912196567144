<template>
  <div class="asset-card">
    <!-- Image Section -->
    <div class="asset-image" v-if="asset_and_positioning.asset.type_.isImage">
      <img
        :src="asset_and_positioning.asset.urls.thumb_small"
        alt="Asset Thumbnail"
      />
    </div>

    <!-- Name & Concept -->
    <h5 class="asset-name">
      {{ isRestrictedDisplayName ? '' : asset_and_positioning.asset.display_name }}
    </h5>
    <span class="asset-concept">Concept</span>

    <!-- Delete Icon -->
    <button
      v-if="show_delete_btn"
      class="delete-button"
      @click.prevent="removeAsset"
      aria-label="Delete"
    >
      <img src="../../../assets/delete-icon.png" alt="Delete" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    asset_and_positioning: { type: Object, required: true },
    show_delete_btn: { type: Boolean, default: true },
  },
  emits: ['removeAsset'],
  computed: {
    isRestrictedDisplayName() {
      const restrictedDisplayNames = [
        'shutterstock_1284088828',
        '583571431',
        'shutterstock_1938550555',
        'shutterstock_635611256',
        '373369408',
        '1320625385',
        'Verzorging van kamerplanten_1667571547',
        'Fietsen_558086152',
        'mixdrankjes_635611256',
        'meditatie_605622212',
        '1911329839',
        '1033938352',
      ];
      return restrictedDisplayNames.includes(
        this.asset_and_positioning.asset.display_name
      );
    },
  },
  methods: {
    removeAsset() {
      this.$emit('removeAsset', this.asset_and_positioning);
    },
  },
};
</script>

<style scoped>
/* 
  Basic "card" layout that centers the content vertically.
  Adjust max-width, padding, and font sizes as desired.
*/
.asset-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 8px auto;
  text-align: center;
}

/* Image styling */
.asset-image img {
  width: 80px;          /* Adjust to fit your design */
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

/* Asset name styling */
.asset-name {
  margin: 8px 0 4px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  /* Truncate text if too long */
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Concept label */
.asset-concept {
  font-size: 12px;
  color: #666;
}

/* Delete button styling */
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  outline: none;
}

.delete-button img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/* 
  Responsive adjustments for smaller screens:
  - Make the card full width
  - Possibly reduce font/image sizes
*/
@media (max-width: 768px) {
  .asset-card {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    min-height: auto;
  }
  .asset-image img {
    width: 60px;
  }
  .asset-name {
    margin: 0 8px;
    font-size: 13px;
    flex: 1;
  }
  .asset-concept {
    font-size: 11px;
    margin-right: 8px;
  }
  .delete-button {
    margin-top: 0;
  }
}
</style>
