<template>
  <div class="instrc-col" v-if="!asset_empty">

    <common ref="common" :asset="asset" :positioning_surveys="positioning_surveys" :show_positionings="show_positionings"
      :isMyLibrary="assetType == 'owned'" :AssetMonitoringQueue="AssetMonitoringQueue" :StoreAssetType = assetType :showAdditionalOptions=showAdditionalOptions :onStoreAssetPurchased="onStoreAssetPurchased"
      @positioningSelected="onPositioningSelected" @showLifecycle="emitShowLifecycle" :fromAssetPicker="fromAssetPicker" @showRatingExplanation="emitShowRatingExplanation" @ratingExplanationData="emitRatingExplanationData">
    </common>

  </div>
  <div class="instrc-col" v-if="asset_empty" v-bind:style="{ marginTop: value === 'modal' ? '15.8vw' : '16.8vw' }">
    <img src="../../../assets/icon17.png" />
    <span>Select a concept to view</span>
  </div>
</template>

<script>
import axios from 'axios';
import Common from './Common.vue';
import OwnedSpecific from './OwnedSpecific.vue';
import StoreSpecific from './StoreSpecific.vue';
import EmotionalAnalysis from '@/components/content_tuner/EmotionalAnalysis';

export default {
  components: {
    Common,
    OwnedSpecific,
    StoreSpecific,
    EmotionalAnalysis
  },
  emits: [
    'positioningSelected',
    'storeAssetPurchased',
    'onPositioningSelected',
    'showLifecycle',
    'showRatingExplanation',
    'ratingExplanationData'
  ],
  props: {
    asset: {
      required: true,
      type: Object,
    },
    assetType: {
      required: true,
      type: String,
    },
    show_positionings: {
      default: false,
      type: Boolean,
    },
    showAdditionalOptions: {
      default: false,
      type: Boolean,
    },
    value: {
      default: false,
      type: String,
    },
    fromAssetPicker:{
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      positioning_surveys: [],
      selectedPositioning: {},
      AssetMonitoringQueue: [],
      showLifecycle: null,
      showRatingExplanation: null,
      ratingExplanationData: {}
    };
  },
  mounted: async function () {
    try {
      const res = await axios.get('/api/cas/assets/AssetMonitoringQueue');
      this.AssetMonitoringQueue = res.data.asset_monitoring_queue;
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    asset_empty: function () {
      return Object.keys(this.asset).length === 0;
    },
    positioningsEmpty: function () {
      return this.positioning_surveys.length === 0;
    },
  },
  methods: {
    onPositioningSelected: function (positioning) {
      this.selectedPositioning = positioning;
      this.$emit('positioningSelected', positioning);
    },
    emitShowLifecycle: function(data){
      this.$emit('showLifecycle', data);
    },
    emitShowRatingExplanation: function(data){
      this.$emit('showRatingExplanation', data);
    },
    emitRatingExplanationData: function(data){
      this.$emit('ratingExplanationData', data);
    },
    onStoreAssetPurchased: function (asset) {
      this.$emit('storeAssetPurchased', asset);
    },
  },
  watch: {
    asset: {
      deep: true,
      immediate: true,
      handler: function (asset) {
        if (Object.keys(asset).length === 0) {
          return;
        }
        let apiUrl = `/api/cas/assets/positionings/${asset.id}`;
        axios
          .get(apiUrl)
          .then((response) => {
            this.positioning_surveys = response.data;
            this.$emit('positioningSelected', this.positioning_surveys[0]);
          });
      },
    },
  },
};
</script>

<style>
.asset-data>div {
  width: 100%;
}

.inner {
  display: flex;
  flex-flow: column;
}

.instrc-col{
    margin-top: 0vw;
}

.inner div label {
  text-align: right;
  width: 50%;
  font-size: 0.8vw;

  @media (max-width: 1600px) {
    margin-right: 5px;
  }

  @media (min-width: 1601px) {
    margin-right: 30px;
  }
}

.inner div p {
  text-align: left;
  width: 50%;
  text-transform: capitalize;
  color: #000 !important;
  opacity: 1 !important;
}
</style>

