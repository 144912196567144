<template>
  <div class="popup-container" @click.self="$emit('close')">
    <div class="popup-content">
      <q-btn style="margin-left: 10%;" icon="close" flat round dense @click="$emit('close')" />
      <h2>{{ t.title }}</h2>
      <p class="highlight">
        {{ t.description }}
      </p>
      <p class="highlight" v-html="t.highlights"></p>
      <button class="cta-button" @click="scrollToForm">{{ t.cta }}</button>
      <br>
      <img src="../../assets/mindspeakerheadset.png" class="img" alt="Step 1 Image" style="max-width: 265px; max-height: 200px; margin: auto;" />
      <p class="spots-limited"><strong></strong></p>

      <!-- Form Section -->
      <div class="form-container" ref="formContainer">
        <input type="text" v-model="name" :placeholder="t.namePlaceholder" />
        <input type="tel" v-model="email" :placeholder="t.emailPlaceholder" />
        <input type="tel" v-model="phone" :placeholder="t.phonePlaceholder" />
        <textarea v-model="address" :placeholder="t.addressPlaceholder"></textarea>
        <button class="submit-button" @click="submitForm">{{ t.submit }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
import axios from 'axios';
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      address: "",
      currentlang: 'en', // default language
    };
  },
  created() {
    // Set currentlang based on environment variable (defaulting to 'nl' if not set)
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  },
  computed: {
    t() {
      return this.currentlang === 'nl'
        ? {
            title: "💡 Blijf Vooroplopen op je Mentale Welzijn",
            description:
              "Doe mee aan het Mental Health Monitoring Program van MindSpeller en krijg vroegtijdige inzichten in je cognitieve gezondheid. Door je aan te melden, krijg je bovendien exclusieve toegang tot preklinische proefmogelijkheden, waarmee baanbrekend neurowetenschappelijk onderzoek wordt bevorderd.",
            highlights:
              "🔹Volg cognitieve veranderingen in de loop van de tijd<br>🔹Krijg vroege toegang tot innovatieve mentale gezondheidsinstrumenten<br>🔹Draag moeiteloos bij aan wetenschappelijke ontdekkingen",
            cta: "🔽Meld je vandaag nog aan en zet de eerste stap naar een gezondere geest!  🔽",
            namePlaceholder: "Naam",
            emailPlaceholder: "E-mailadres",
            phonePlaceholder: "Telefoonnummer",
            addressPlaceholder: "Verzendadres",
            submit: "Verstuur",
            fillFields: "Vul alle velden in.",
            submissionFailed: "Het is niet gelukt om het formulier te versturen. Probeer het opnieuw.",
            errorOccurred: "Er is een fout opgetreden. Probeer het later opnieuw.",
            successMessage: "Je wordt binnenkort gecontacteerd om je pre-order te bevestigen. Dank je wel!",
          }
        : {
            title: "💡 Stay Ahead of Your Mental Well-Being",
            description:
              "Join MindSpeller’s Mental Health Monitoring Program and gain early insights into your cognitive health. By signing up, you’ll also get exclusive access to pre-clinical trial opportunities, helping advance groundbreaking neuroscience research.",
            highlights:
              "🔹Track cognitive changes over time<br>🔹Get early access to innovative mental health tools<br>🔹Contribute to scientific discovery—effortlessly",
            cta: "🔽Sign up today and take the first step toward a healthier mind!  🔽",
            namePlaceholder: "Name",
            emailPlaceholder: "Email Address",
            phonePlaceholder: "Phone Number",
            addressPlaceholder: "Shipping Address",
            submit: "Submit",
            fillFields: "Please fill in all the fields.",
            submissionFailed: "Failed to submit the form. Please try again.",
            errorOccurred: "An error occurred. Please try again later.",
            successMessage: "You will be contacted shortly to confirm your pre-order. Thank you!",
          };
    },
  },
  emits: ["close"],
  methods: {
    scrollToForm() {
      const formContainer = this.$refs.formContainer;
      formContainer.scrollIntoView({ behavior: 'smooth' });
    },
    async submitForm() {
      if (!this.name || !this.phone || !this.email || !this.address) {
        alert(this.t.fillFields);
        return;
      }

      const formData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        address: this.address,
      };

      try {
        const response = await axios.post('/api/cas/clinicaltrial_enroll', formData);

        if (response.status === 200) {
          toast_success(this.t.successMessage);
        } else {
          alert(this.t.submissionFailed);
        }
      } catch (error) {
        alert(this.t.errorOccurred);
        console.error(error);
      } finally {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>


.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  border-radius: 0%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #ffffff;
  padding: 30px;
  padding-right: 38px; /* Add padding to the right to account for the scrollbar */
  border-radius: 15px;
  /* max-width: 60%; */
  width: 90%;
  max-height: 85%;
  overflow-y: auto; /* Enable vertical scrolling */
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
  position: relative; /* Ensure the scrollbar stays within the box */
}

h2 {
  background-color: #7878e9;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* margin-top: 5%; */
  width: 85%;
  color: white;
  margin: auto;
  margin-bottom: 3%;
  padding: 1px;
  font-size: 18px;
  text-align: center;
}

.popup-content::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  margin-right: 10%;
}

.popup-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track */
}

.popup-content::-webkit-scrollbar-thumb {
  background: #d9e1ec; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar thumb */
  border: 2px solid transparent; /* Optional: Space around the thumb */
  background-clip: padding-box; /* Ensures thumb respects border */
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #b8c7d9; /* Thumb color when hovered */
}

.popup-content .highlight {
  font-size: 18px;
  color: #333333;
  /* margin-bottom: 15px; */
  line-height: 1.6;
  background-color: #f9f9f9;
  padding: 15px;
  border-left: 5px solid #7878e9;
  border-radius: 5px;
}

.benefits {
  text-align: left;
  margin: 15px 0;
  padding-left: 20px;
  color: #4a4a4a;
  font-size: 16px;
}

.benefits li {
  margin-bottom: 10px;
}

.how-it-works {
  margin: 20px 0;
}

.how-it-works h3 {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}

.how-it-works ol {
  text-align: left;
  /* padding-left: 20px; */
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.6;
}

.spots-limited {
  font-size: 16px;
  color: #d9534f;
  margin-top: 15px;
}

.cta-button {
  background-color: #6a5acd;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #5940b3;
}

/* Form Section */
.form-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.form-container textarea {
  resize: none;
  height: 80px;
}

.submit-button {
  background-color: #28a745;
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #218838;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@media screen and (max-width: 768px) {
  h2 {
  background-color: #7878e9;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* margin-top: 5%; */
  width: 85%;
  color: white;
  margin: auto;
  margin-bottom: 3%;
  padding: 2px;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
}
.cta-button {
  background-color: #6a5acd;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out;
}
.img{
  width: 200px;
  height: 150px;
}
}

@media screen and (max-width: 426px) {
  .popup-content {
  background: #ffffff;
  padding: 30px;
  /* padding-right: 38px; Add padding to the right to account for the scrollbar */
  border-radius: 15px;
  
  width: 90%;
  max-height: 85%;
  overflow-y: auto; /* Enable vertical scrolling */
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
  position: relative; /* Ensure the scrollbar stays within the box */
}
  h2 {
  background-color: #7878e9;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* margin-top: 5%; */
  width: 100%;
  color: white;
  margin: auto;
  margin-bottom: 3%;
  padding: 1px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.popup-content .highlight {
  font-size: 18px;
  color: #333333;
  margin-bottom: 15px;
  line-height: 1.4;
  background-color: #f9f9f9;
  padding: 12px;
  border-left: 5px solid #7878e9;
  border-radius: 5px;
}

.cta-button {
        background-color: #6a5acd;
        color: #ffffff;
        font-size: 15px;
        font-size: 8px !important;
        font-weight: bold;
        padding: 4px 8px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 10px;
        transition: background-color 0.3s ease-in-out;
    }
    .form-container input, .form-container textarea[data-v-ea35ded0] {
    width: 100%;
    padding: 3px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 7px;
}
.submit-button {
  background-color: #28a745;
  color: #ffffff;
  font-size: 15px;
  /* padding: 8px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
}
@media screen and (max-width: 376px) {
  .popup-content {
  background: #ffffff;
  padding: 30px;
  /* padding-right: 38px; Add padding to the right to account for the scrollbar */
  border-radius: 15px;
  
  width: 90%;
  max-height: 85%;
  overflow-y: auto; /* Enable vertical scrolling */
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
  position: relative; /* Ensure the scrollbar stays within the box */
}
    h2[data-v-ea35ded0] {
        background-color: #7878e9;
        border: 1px solid #ddd;
        border-radius: 10px;
        /* margin-top: 5%; */
        width: 100%;
        color: white;
        margin: auto;
        margin-bottom: 3%;
        padding: 4px;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
    }
    .popup-content .highlight {
        font-size: 18px;
        color: #333333;
        margin-bottom: 15px;
        line-height: 1.4;
        background-color: #f9f9f9;
        padding: 10px;
        border-left: 3px solid #7878e9;
        border-radius: 5px;
    }
}
</style>