<template>
  <div class="milestone">
    <div class="milestone-header">
      <h3>
        <slot name="title">{{ title }}</slot>
      </h3>
    </div>

    <!-- Table Structure -->
    <table class="milestone-table">
      <tbody>
        <!-- Row 1: Dropdown and Check -->
        <tr>
          <td class="milestone-description">
            <slot />
          </td>
          <td class="divider-cell drop"></td>
          <td class="milestone-checks">
            <font-awesome-icon
              icon="check-circle"
              class="check-icon"
              :class="{ completed: category !== '' }"
            />
          </td>
        </tr>
        <tr class="divider-row"></tr>
        <!-- Row 2: Description and Check -->
        <tr>
          <td class="milestone-description">
            <p style="font-size: 15px ;">{{ description }}</p>
          </td>
          <td class="divider-cell"></td>
          <td class="milestone-checks">
            <font-awesome-icon
              icon="check-circle"
              class="check-icon"
              :class="{ completed: mindcoin_count >= 1000 }"
            />
          </td>
        </tr>
        <tr class="divider-row"></tr> <!-- Single Divider -->



        <!-- Row 3: Invite Friends Button -->
        <tr>
          <td class="milestone-description">
            <slot name="button1" />
          </td>
          <td class="divider-cell"></td> <!-- Single Divider -->
          <td class="milestone-checks">
           
              <font-awesome-icon
                icon="check-circle"
                class="check-icon"
                :class="{ completed: friendsCount == 3 }"
              />
            
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    category: { type: String, required: false },
    friendsCount: { type: Number, required: true },
  },
  computed: {
    mindcoin_count() {
      return this.$store.state.mindchips;
    },
  },
};
</script>

<style scoped>
/* Milestone Box */

@media only screen and (max-width: 376px) {
  .milestone {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 15px 15px 15px 15px !important;
  background-color: #c9c9f0b2;
}
}

@media only screen and (max-width: 769px) {
  .milestone-description {
  padding: 15px 0px !important;
  font-size: 12px;
  text-align: left;
  width: 70%;
}

.milestone {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  padding: 20px;
  border-radius: 15px 15px 15px 15px !important;
  background-color: #c9c9f0b2;
}
}

@media only screen and (max-width: 480px) {
  .milestone-header h3 {
  background-color: #6469ce;
  border-radius: 15px;
  color: white;
  font-size: 15px !important;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 2.5rem !important;
}

p{
  line-height: normal;
  margin: 0px;
}

.milestone {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  padding: 20px;
  border-radius: 15px 15px 15px 15px !important;
  background-color: #c9c9f0b2;
}

}
.milestone {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  padding: 20px;
  border-radius: 15px 0px 0px 15px;
  background-color: #c9c9f0b2;
}

/* Header */
.milestone-header h3 {
  background-color: #6469ce;
  border-radius: 15px;
  color: white;
  font-size: 20px;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
}

/* Table Layout */
.milestone-table {
  width: 100%;
  /* border: solid 1px #000;
  border-radius: 15px; */
  border-collapse: collapse;
}

/* Table Cells */
.milestone-description {
  padding: 15px;
  font-size: 12px;
  text-align: left;
  width: 70%;
}

.milestone-checks {
  width: 20%;
  text-align: center;
}

/* Divider Cell */
.divider-cell {
  /* width: 0.2%; */
  border-right: solid 2px #6469ce;

  /* background-color: #ccc; */
}

.divider-row {
  /* width: 0.2%; */
  border: solid 2px #6469ce;

  /* background-color: #ccc; */
}

/* Dropdown */
.category-dropdown {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
}

/* Check Icons */
.check-icon {
  font-size: 34px;
  color: #6c757d; /* Default Gray */
}

.check-icon.completed {
  color: #28a745; /* Green for completed */
}
</style>
