<template>
    <main>
      <section style="height: 100vh;">
        <div class="container-fluid full-width-pg">
          <div class="row">
            <div class="col-md-7"
            style="background-color: blue; padding: 40px; height: 100vh; display: flex; flex-direction: column; justify-content: space-between;">
            <!-- Image -->
            <div style="display: flex; justify-content: center; align-items: center; height: 70%;">
              <img src="../../assets/banner-img1.jpg" style="max-height: 100%; width: auto;" />
            </div>

            <!-- Testimonial Text -->
            <div class="testimo-box text-white mt-3" style="height: 30%;">
              <p class="mt-5">
                We use Mindspeller to test our creative campaign concepts.
                Mindspeller's brand equity autopilot helps keep our brand on
                track.
              </p>
              <p>
                ~ Wendy M. Head of Marketing Communication Research, City of
                Antwerp
              </p>
            </div>
          </div>
            <div class="col-md-5 ">
              <div class="row">
                <div class="col-md-12 p-3">
                  <div
                    class=" d-flex justify-content-center align-items-center mt-4 margin"
                  >
                    <img
                    class="logo"
                      src="../../assets/logo-with-text.png"
                      alt=""
                      style="width: auto; height: auto;"
                      
                    />
                  </div>
  
                  <form class="p-3 p-lg-5 form-spacing row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="pb-1">Log in to:</label>
                        <div class="dropdown-chevron-container">
                        <select
                          v-model="currentlang"
                          @change="updateIframeSrcBasedOnLang"
                          class="form-control dropdown-select"
                        >
                          <option value="" disabled>{{$t('new_asset.choose_language')}}</option>
                          <option value="en">USA (EN)</option>
                          <option value="nl">Europe (NL)</option>
                        </select>
                    </div>
                      </div>
                    </div>
  
                    <div class="col-12">
                      <div class="form-group mt-3">
                        <label for="inputEmail4" class=" impot">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="inputEmail4"
                          :disabled="!selectedNavigation"
                          :placeholder="
                            selectedNavigation
                              ? 'Enter a Valid Email Address'
                              : $t('new_asset.choose_network_condition')
                          "
                          v-model="loginSet.email"
                          @click="isEmailNotValid = null"
                        />
                        <p class="matchingPasswordText" v-if="isEmailNotValid">
                          Please enter a valid email
                        </p>
                      </div>
                    </div>
  
                    <div class="col-12">
                      <div class="form-group mt-3">
                        <label for="inputPassword4" class=" impot"
                          >Password</label
                        >
                        <div class="password-div">
                          <input
                            v-bind:type="getTypePwd()"
                            class="form-control"
                            id="inputPassword4"
                            :disabled="!selectedNavigation"
                            :placeholder="
                              selectedNavigation
                                ? 'Enter Password'
                                : $t('new_asset.choose_network_condition')
                            "
                            v-model="loginSet.password"
                          />
                          <span
                            class="eye-icon"
                            @click="this.showPassword = !this.showPassword"
                          >
                            <img
                              v-if="showPassword"
                              src="../../assets/password-eye-open.png"
                            />
                            <img v-else src="../../assets/password-eye.png" />
                          </span>
                        </div>
                      </div>
                    </div>
                    
  
                    <div class="row mb-3 forgot-pass mt-1 p-0">
                      <div
                        class="col d-flex justify-content-center align-items-center remember p-0"
                      >
                        <div
                          class="form-check"
                          style="display: inline-flex; align-items: center;"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                             v-on:input="handleUser"
                          />
                          <label class="form-check-label" for="form1Example3">
                            Remember me
                          </label>
                        </div>
                        <div class="col forgot-link cursor-p">
                          <a  @click="forgotPassword" class="text-decoration-none"
                            >Forgot password?</a
                          >
                        </div>
                      </div>
                    </div>
  
                    
                    <div class="btn-col mb-0 mt-0">
                      <button
                        class="w-25 h-auto p-1"
                        type="button"
                        v-bind:class="getClass()"
                        @click="login"
                        :disabled="
                          !this.loginSet.email || !this.loginSet.password
                        "
                      >
                        Log In
                      </button>
                    </div>
  
                    <div class="google-login text-center mt-3">
                    <p class="mb-lg-2 mb-2" style="line-height: normal;">
                        {{ $t('new_asset.no_account') }}
                                <router-link :to="{ name: 'Free Trial' }">
                                    Start Trial
                                </router-link>                    
                            </p>
                  </div>
                  <div class="google-login text-center mt-3">
                    <p class="mb-lg-2 mb-2" style="line-height: normal;">
                        {{ $t('new_asset.login_consumer') }}
                            <router-link :to="{ name: 'RespondentLogin' }">{{ $t('new_asset.login_here') }}</router-link>
                    </p>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <WorldMapModal
        v-if="this.mapModalVisible"
        :respondent="respondentCAS"
        @close="toggleMapModal"
      />
    </main>
    <loading-app-wide v-if="loading" />
  </template>
  
  <script>
  import axios from "axios";
  import WorldMapModal from "@/components/library/WorldMapModal.vue";
  import MapBrand from "./Common/MapBrand.vue";
  import { mapGetters, mapActions } from "vuex";
  import { changeLanguage } from "@/helpers/language_helper.js";
  import { isLoggedIn } from "@/helpers/login_helper.js";
  import AlternativeLangLink from "@/components/common/AlternativeLangLink";
  import { alert_error } from "@/helpers/alert_helper.js";
  import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
  
  export default {
    name: "Login",
    created() {
        // Clear tokens when visiting the login page
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('jwt_refresh_token');
        localStorage.removeItem('is_anonymous');
        
        // Use our custom getCookie method (fallback to localStorage if necessary)
        const manualLang = this.getCookie('manualLangSelection') || localStorage.getItem('manualLangSelection');
        if (manualLang) {
            this.currentlang = manualLang;
            // Create the selectedNavigation string
            const selectedNav = `CAS-${this.currentlang.toUpperCase()} Network`;
            localStorage.setItem('selectedNavigation', selectedNav);
            this.setCookie('selectedNavigation', selectedNav, 7);
        } else {
            this.currentlang = '';
        }
    },
    components: {
        MapBrand,
      AlternativeLangLink,
      WorldMapModal,
      LoadingAppWide,
    },
    data() {
      return {
        loginSet: {
          username: "",
          email: "",
          password: "",
          showPassword: false,
          loginFailed: false,
        },
        remember: false,
        showPassword: false,
        isEmailNotValid: null,
        loading: false,
        mapModalVisible: false,
        respondentCAS: false,
      };
    },
    computed: {
      passwordInputType: function() {
        return this.loginSet.showPassword ? "text" : "password";
      },
      selectedNavigation() {
        return this.getCookie('selectedNavigation') || '';
      },
    },
    emits: ["close"],
    methods: {
      ...mapActions(["setSelectedNavigation"]),
      setCookie(name, value, days, domain = '.mindspeller.com') {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            let cookieStr = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
            cookieStr += ` domain=${domain};`;
            document.cookie = cookieStr;
        },
        getCookie(name) {
        const cookieArr = document.cookie.split(";");
        for (let cookie of cookieArr) {
            let [key, value] = cookie.split("=");
            if (key.trim() === name) {
            return value;
            }
        }
        return null;
        },
        updateIframeSrcBasedOnLang() {
        // First, clear out any previous selections
        localStorage.removeItem('manualLangSelection');
        localStorage.removeItem('selectedNavigation');
        this.setCookie('manualLangSelection', "", -1);
        this.setCookie('selectedNavigation', "", -1);

        // Now, update manualLangSelection in both storage methods
        localStorage.setItem('manualLangSelection', this.currentlang);
        this.setCookie('manualLangSelection', this.currentlang, 7);

        // Build selectedNavigation string and update it as well
        const selectedNav = `CAS-${this.currentlang.toUpperCase()} Network`;
        localStorage.setItem('selectedNavigation', selectedNav);
        this.setCookie('selectedNavigation', selectedNav, 7);

        // (If needed, also update your Vuex state using setSelectedNavigation action)

        // Determine if we are on a respondent route
        const currentPath = this.$route.path;
        const isRespondentRoute = currentPath === '/respondent_login';
        let targetUrl = '';
        if (this.currentlang === 'en') {
            targetUrl = isRespondentRoute
            ? 'https://www.mindspeller.com/#/respondent_login'
            : 'https://www.mindspeller.com/#/login';
        } else if (this.currentlang === 'nl') {
            targetUrl = isRespondentRoute
            ? 'https://cas-nl.mindspeller.com/#/respondent_login'
            : 'https://cas-nl.mindspeller.com/#/login';
        }

        // If already on the target URL reload to fetch new state; otherwise, redirect.
        if (window.location.href === targetUrl) {
            window.location.reload();
        } else {
            window.location.assign(targetUrl);
        }
        },
      openNetworkPage() {
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("jwt_refresh_token");
        localStorage.removeItem("is_anonymous");
        localStorage.removeItem("pageText");
        localStorage.removeItem("pageName");
        this.$router.push({ name: "Mindspeller_Network" });
        return axios
          .post("/api/cas/token/refresh_for_network", {})
          .then((response) => {
            localStorage.setItem(
              "jwt_token",
              response.data["x-jwt-access-token"]
            );
            localStorage.setItem(
              "jwt_refresh_token",
              response.data["x-jwt-refresh-token"]
            );
            localStorage.setItem("is_anonymous", "true");
          });
      },
      forgotPassword() {
        this.$router.push({
          name: "Forgot Password",
        });
      },
      getTypePwd() {
        if (this.showPassword) {
          return "text";
        } else {
          return "password";
        }
      },
      getClass() {
        return {
          btn3: this.loginSet.email && this.loginSet.password,
          "disabled-btn": !this.loginSet.email || !this.loginSet.password,
        };
      },
      handleUser() {
        const user = {
          email: `${this.loginSet.email}`,
          password: `${this.loginSet.password}`,
          username: `${this.loginSet.username}`,
        };
        $cookies.set("user", user);
      },
      login: async function() {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
        if (!this.loginSet.email || !this.loginSet.password) {
          return;
        } else if (!this.loginSet.email.toLowerCase().match(emailRegex)) {
          return (this.isEmailNotValid = "Please enter a valid email");
        }
        this.loading = true;
        try {
          this.isEmailNotValid = null;
          const user = {
            username: this.loginSet.username,
            password: this.loginSet.password,
            email: this.loginSet.email,
            remember: this.remember,
            respondent_retour_cas: true,
          };
  
          await this.$store.dispatch("loginWe", user);
          await this.$store.dispatch("load_user_data");
          changeLanguage(this.$i18n, this.$store.state.current_user.language);
          const saved_user = {
            email: `${this.loginSet.email}`,
            password: `${this.loginSet.password}`,
            username: `${this.loginSet.username}`,
          };
          $cookies.set("resp_user", saved_user);
          this.$router.push({
            name: "Library",
          });
          // if (this.$store.state.current_user.onboarding_step === null || this.$store.state.current_user.onboarding_step === 0) {
          //     this.$router.push({
          //         name: 'Archetypes',
          //         params: {
          //             userId: this.$store.state.current_user.id,
          //             onboarding_step: this.$store.state.current_user.onboarding_step,
          //         },
          //     });
          // } else if (this.$store.state.current_user.onboarding_step === 1) {
          //     this.$router.push({
          //         name: 'Passions',
          //         params: {
          //             onboarding_step: this.$store.state.current_user.onboarding_step,
          //         },
          //     });
          // } else if (this.$store.state.current_user.onboarding_step === 2) {
          //     this.$router.push({
          //         name: 'Brand Value',
          //         params: {
          //             onboarding_step: this.$store.state.current_user.onboarding_step,
          //         },
          //     });
          // } else {
          // this.$router.push({
          //     name: 'Dashboard',
          // });
        } catch (error) {
          if (error.response.status === 500) {
            alert_error(this.$t("message.general_server_error"));
          } else {
            this.loginFailed = true;
            alert_error("Wrong username or password");
          }
        }
        this.loading = false;
      },
      toggleMapModal() {
        this.mapModalVisible = !this.mapModalVisible;
      },
      ...mapActions(["toggleAutoRedirection"]),
    },
    beforeMount: function() {
      // if the user is not logged in we choose the language based on the
      // browser settings since we don't know their preferred language (once the
      // user logs in we get their preferred language from the API)
      if (!isLoggedIn()) {
        const lang = this.$store.getters.getEnvVar("VUE_APP_DEFAULT_LANGUAGE");
        if (lang !== "") {
          changeLanguage(this.$i18n, lang);
        } else {
          const browserLang = navigator.language;
          changeLanguage(this.$i18n, browserLang);
        }
      }
    },
    mounted: function() {
      const user = $cookies.get("user");
      const resp_user = $cookies.get("resp_user");
      if (user) {
        this.loginSet.email = user.email;
        this.loginSet.password = user.password;
        this.loginSet.username = user.username;
      } else if (resp_user) {
        this.loginSet.email = resp_user.email;
        this.loginSet.password = resp_user.password;
        this.loginSet.username = resp_user.username;
      }
      const autoRetour = this.$store.state.autoRedirection;
      if (resp_user && autoRetour) {
        this.login();
        this.$nextTick(() => {
          this.$store.dispatch("toggleAutoRedirection", false);
        });
      }
    },
  };
  </script>
  
  <style scoped>
  @media only screen and (min-width: 769px) {
    .form-check-input[type="checkbox"] {
      width: 1.5vw;
      height: 1.5vw;
      margin-top: 2px;
      margin-right: 0.7vw;
      margin-left: 0;
      margin-bottom: 0;
    }
  
    button {
      width: 50% !important;
      /* padding: 10px !important; */
      font-size: 16px !important;
    }
  }
  
  @media only screen and (max-width: 768px) {
    /* .margin {
      margin: 40px !important;
    } */
    .form-check-input[type="checkbox"] {
      width: 1.4vw;
      height: 1.4vw;
      margin-top: 1px;
      margin-right: 0.7vw;
      margin-left: 0;
      margin-bottom: 0;
    }
    .remember {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .forgot-pass .forgot-link {
      padding: 0;
      font-size: 1.0vw;
      text-align: right;
      line-height: 1.9vw;
      padding-top: 0px;
    }
    button {
      width: 50% !important;
      padding: 10px !important;
      font-size: 16px !important;
    }
  }
  
  @media only screen and (max-width: 426px) {
  
      .user-icon-wrapper {
    position: fixed;
    top: 5%; /* Adjust the distance from the top edge */
    right: 3%; /* Adjust distance from the right edge */
    background-color: black; /* Background color of the circle */
    border-radius: 50%; /* Make it circular */
    width: 25px !important; /* Circle size */
    height: 25px !; /* Circle size */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
    .col-md-7 {
      display: none !important; /* Hide the left column */
    }
    .form-control {
      font-size: 3.1vw !important;
      line-height: 5vw !important;
    }
    .forgot-pass .forgot-link {
          padding: 0;
          font-size: 3.2vw !important;
          text-align: right;
          line-height: 1.9vw;
          padding-top: 0px;
      }
    .form-check-input[type="checkbox"] {
      width: 4.6vw;
      height: 4.6vw;
      margin-top: 0;
      margin-right: 0.7vw;
      margin-left: 0;
      margin-bottom: 0;
    }
    .remember {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .forgot-pass .forgot-link {
      padding: 0;
      font-size: 4vw;
      text-align: right;
      line-height: 1.9vw;
      padding-top: 0px;
    }
  
    button {
      height: 38px !important;
      width: 50% !important;
    }
  }
  
  @media only screen and (max-width: 320) {
    .margin {
      margin: 0px !important;
    }
  }
  .matchingPasswordText {
    text-align: left;
    color: red;
    margin-top: 14px;
  }
  .login-logo-imgbx {
    /* width: 80%; */
    margin: 0 0 2.5vw;
  }
  .user-icon-wrapper {
    position: fixed;
    top: 5%; /* Adjust the distance from the top edge */
    right: 3%; /* Adjust distance from the right edge */
    background-color: black; /* Background color of the circle */
    border-radius: 50%; /* Make it circular */
    width: 40px; /* Circle size */
    height: 40px; /* Circle size */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .dropdown-chevron-container {
  position: relative;
  display: inline-block;
  width: 100%; /* Adjust width as needed */
}

.dropdown-select {
  appearance: none; /* Remove default browser styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%; /* Full width */
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.dropdown-chevron-container::after {
  content: "▼"; /* Unicode for the chevron */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust spacing from the right */
  transform: translateY(-50%);
  pointer-events: none; /* Prevent interaction with the chevron */
  font-size: 0.8rem;
  color: #666;
}

  .user-icon {
    color: white; /* Icon color */
    font-size: 20px; /* Adjust size as needed */
  }
  
  .login-text {
    position: absolute;
    bottom: -50px; /* Position the text below the user icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333; /* Dark background for the text */
    color: white; /* Text color */
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 0.8em;
    opacity: 0; /* Initially hidden */
    pointer-events: none; /* Ignore pointer events on the text */
    transition: opacity 0.3s ease; /* Smooth transition for appearing/disappearing */
  }
  
  .logintotext {
    display: block;
    text-align: center;
    width: 30%;
    color: #000185;
    font-weight: 700;
    font-size: 1.8vw;
    /* line-height: 2vw; */
    text-transform: capitalize;
    color: #1b2f3f;
    margin-top: 1.5vw;
  }
  
  .user-icon-wrapper:hover .login-text {
    opacity: 1; /* Show the text on hover */
  }
  
  .map-btn-container select {
    background: white;
    border-radius: 8px;
    background-color: #0a00ff;
    border: 1px solid #000;
    font-size: 0.9em;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-left: 10px;
    animation: bounce 1.5s infinite;
    font-weight: 600;
  }
  
  .map-btn-container select:hover {
    font-weight: bold;
    background-color: #423bffb7;
  }
  
  .login-header {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 1.5vw; */
  }
  
  .map-toggle-btn {
    background-color: #0a00ff;
    color: white;
    font-weight: 600;
    font-size: 1vw;
    padding: 15px 10px;
    border: solid 2px #0d3a69;
    border-radius: 10px;
    margin-bottom: 1.5vw;
    animation: bounce 1.5s infinite;
    cursor: pointer;
  }
  
  .map-toggle-btn-selected {
    background-color: #0a00ff;
    color: white;
    font-weight: 600;
    font-size: 1vw;
    padding: 15px 10px;
    border: solid 2px #0d3a69;
    border-radius: 10px;
    margin-bottom: 1.5vw;
    cursor: pointer;
  }
  
  .map-toggle-btn:hover {
    background-color: #423bffb7;
    transform: scale(1.1);
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
  }
  </style>
  