<template>
    <div class="loading-overlay" :class="{ 'in-app': isInApp }">
      <div class="ms-spinner ms-spinner-md">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    isInApp: { default: true, type: Boolean },
  },
}
</script>

<style scoped>
.loading-overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; /* Stay in place */
  z-index: 9000; /* Sit on top of the workspace and sidebar without obscuring header */
  left: 0;
  background: rgba(0, 0, 0, 0.85); /* Darker background with opacity */
  top: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
}

.in-app {
  background-color: rgba(0, 0, 0, 0.85); /* Darker background with opacity */
}

.ms-spinner>div {
  background-color: #ffffff;
}
.ms-spinner-md {
    width: 500px !important;
}
.ms-spinner-md > div {
    width: 50px !important;
    height: 50px !important;
}

.ms-spinner > div {
    background-color: #000000;
    margin: 0 20px;
}

.loading-overlay {
  background-color: transparent !important;
}

.ms-spinner {
    margin: 70px auto 1rem;
    width: 70px;
    text-align: center;
}

.ms-spinner > div {
    width: 18px;
    height: 18px;
    background-color: black;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ms-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.ms-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.ms-spinner-md {
    margin: 30px auto 1rem;
    width: 50px;
}

.ms-spinner-md > div {
    width: 12px;
    height: 12px;
}

.ms-spinner-sm {
    margin: 0 auto 0;
    width: 50px;
}

.ms-spinner-sm > div {
    width: 7px;
    height: 7px;
    margin-left: 3px;
}
</style>
