<template>
  <div>
    <div v-if="showPopup" class="overlay" @click.self="closePopup">
      <div class="instructions-popup">

        <!-- <div class="popup-header">
          <p>
            Explore this interactive visualization to uncover Mindspeller's B2C brand recommendations tailored to your brand category.</p>
            
        </div> -->
        <div class="step-container" style="font-size: 10px;">
          <p style="font-size: 12px;">For the best experience, please open this app in <strong>Google Chrome / Brave / Edge / Firefox</strong> on a desktop or laptop computer.</p>
          <p style="font-size: 12px;">👉 Need help? <a href="mailto:contact@mindspeller.com">Let us know!</a></p>

        </div>
        <div class="popup-header">
          <h2>A Few Simple Steps to Get Started!</h2>
          <button class="close-button" @click="closePopup">✖</button>
        </div>
        <div class="step-container">
          <video
              controls
              :src="instructionsVideoUrl"
              playsinline autoplay loop
              class="instructions-video">
            </video>        
          </div>
        <div class="step-container">
          <h3>Interactive Controls</h3>
          <p><strong>"PAN": </strong>Right Mouse Button | <strong>"ROTATE": </strong>Left Mouse Button HOLD + Move |</p>
          <p><strong>"ZOOM": </strong>Mouse wheel |  <strong>"RESET ZOOM/ROTATION": </strong>Zoom out icon on top right |  </p>
        </div>
        <div class="popup-content">
          <!-- Step 1 -->
          <div class="step-container">
            <h3>Step 1: Enter a keyword in the My Search box</h3>
            <img src="../../assets/b2c_step1.png" alt="Step 1 Image" class="step-image" />
            <p>
              Use the My Search box to find a specific concept in the network. Suggestions will appear as you type.
              Click on the concept of interest from your search result list to proceed. <strong>The selected concept will be highlighted as a bigger text in the network (eg. Beer in the image above).</strong>
            </p>
            <strong>Caution:</strong>  The concept you select/click will drive the Mindspeller B2C brand recommendations in step 5. 
          </div>
          <!-- Step 2 -->
          <div class="step-container">
            <h3>Step 2: Isolate your concept of interest</h3>
            <img src="../../assets/b2c_step2.png" alt="Step 2 Image" class="step-image" />
            <p>
              Click the <strong>"ISOLATE"</strong> button to focus on the node. Adjust the "Neighbors" slider to refine your selection.
            </p>
          </div>
          <!-- Step 3 -->
          <div class="step-container">
            <h3>Step 3: Adjust the number of visible "Neighbors"</h3>
            <img src="../../assets/b2c_step3.png" alt="Step 3 Image" class="step-image" />
            <p>
              To adjust the number of  "Neighbors" in real-time after isolating, you can only reduce the value. To increase it, click the home button and repeat the isolation process.
            </p>
          </div>
            <!-- Step 4 -->
            <div class="step-container">
            <h3>Step 4: Explore Concept Details.</h3>
            <img src="../../assets/b2c_step6.png" alt="Step 4 Image" class="step-image" />
            <p>
              Click on any concept in the network to view its mapping date, concept type, and URL.
            </p>
            <p>
              <strong>Caution:</strong>  The concept you see here will drive the Mindspeller B2C brand recommendations. If you cannot see your keyword of interest, click the home button on the rightside bar and go back to Step 1.
            </p>
          </div>
          <!-- Step 5 -->
          <div class="step-container">
            <h3>Step 5: Select your Value</h3>
            <img src="../../assets/b2c_step4.png" alt="Step 4 Image" class="step-image" />
            <p>
              Open the dropdown to select your desired value and uncover brand recommendations tailored to the combination of your keyword and selected value.
            </p>
            <p>
              <strong>Tip:</strong> Add new values to personalize your brand recommendations.
            </p>
          </div>

          <!-- <div class="step-container">
            <h3>Step 5: Confirm Selection</h3>
            <img src="../../assets/step_5.png" alt="Step 5 Image" class="step-image" />
            <p>
              Choose one value from 3 default words or 6 default image options.
            </p>
          </div> -->
          <!-- Step 6 -->
          <div class="step-container">
            <h3>Step 6: Explore Mindspeller Associations</h3>
            <img src="../../assets/b2c_step5.png" alt="Step 6 Image" class="step-image" />
            <p>
              Explore top ranking Mindspeller B2C brand recommendations related to the product category ('My Search' keyword) and the chosen Value. Click on the link icon to view the related brand website.
            </p>
          </div>
        </div>
        <div class="popup-footer">
          <button class="primary-button" @click="closePopup">Got it!</button>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    data() {
      return {
        showPopup: false,
        currentlang: '',
      };
    },
    computed: {
    ...mapGetters(["showInstructionPopup", "getInstructionalVideoUrl"]),
    instructionsVideoUrl() {
      return this.$store.getters.getInstructionalVideoUrl('btoc_instructions_main');
    }
    },
    methods: {
      ...mapActions(['initializeInstructionPopup', 'dismissInstructionPopup']),
      openPopup() {
        this.showPopup = true;
        this.$store.dispatch('dismissInstructionPopup');

      },
      closePopup() {
        this.showPopup = false;
      },
    },
    created() {
      let defapp_lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
      this.currentlang = defapp_lang; // Default to English if no language is set
    },
  };
  </script>
  
  <style scoped>
  /* Popup Container */
  .instructions-popup {
    width: 50%;
    max-height: 80%;
    background: #ffffff;
    border: 1px solid black;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
    scrollbar-width: thin;
    scrollbar-color: #7878e9 transparent;
  }
  
  .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Ensures the overlay is above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}
  /* Header */
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #000000;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
  
  .popup-header h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #555;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #000;
  }
  
  /* Content */
  .popup-content {
    overflow-y: auto;
  }
  
  .step-container {
    margin-bottom: 30px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    background: #f9fbfd;
  }
  
  .step-container h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
  }
  
  .step-image {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    margin: 10px auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .step-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* Footer */
  .popup-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .primary-button {
    background: #4caf50;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .primary-button:hover {
    background: #45a049;
  }
  
  .instructions-video {
  width: 100% !important;
  height: auto !important;
}
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -55%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (min-width: 1600px) {
    .step-image {
        width: 100%;
    }

}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
    .step-image {
        width: 100%;
    }

}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
    .step-image {
        width: 80%;
    }
}
  </style>
  