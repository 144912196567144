<template>
  <div class="maintenance p-5 container-fluid d-flex justify-content-center align-items-center vh-100">
    <div class="row">
      <div class="col-12">
        <div class="steps-container text-center">
          <h4>We are currently performing maintenance work...</h4>
          <div class="step p-3 my-3 mx-auto">
            <p class="mb-0">Please try reloading the page and clearing your browser cache/cookies.</p>
          </div>
          <div class="step p-3 my-3 mx-auto">
            <p class="mb-0">If you don't have access within the next 30 minutes, please try <a href="https://www.mindspeller.com/#/login" target="_blank">this link for English</a> / <a href="https://cas-nl.mindspeller.com/#/login" target="_blank">this link for Dutch</a>.</p>
          </div>
          <div class="step p-3 my-3 mx-auto">
            <p class="mb-0">If you still don't have access, please contact your <a href="mailto:contact@mindspeller.com">account manager</a>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 1
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
    }
  }
};
</script>

<style>
.maintenance {
  background-color: #f8f9fa;
}

.steps-container {
  max-width: 800px;
  margin: 0 auto;
}

.step {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 15px; /* Increased padding for better spacing */
  min-height: 60px; /* Ensure a minimum height for the step */
}

.step.active {
  border-color: #007bff;
  background-color: #e9f7ff;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Default font size for smaller screens */
.step p {
  font-size: 11px;
  line-height: 16px;
}

/* Font size for larger screens (laptops and higher) */
@media (min-width: 992px) {
  .step p {
    font-size: 13px;
    line-height: 18px; /* Adjusted line height for better readability */
  }
}
</style>