<template>
  <!-- We wrap the modal in a transition for a fade/zoom effect -->
  <transition name="modal-fade">
    <div
      class="modal-overlay"
      v-if="show"
      @click.self="handleClose"
    >
      <transition name="modal-zoom">
        <div class="modal-container" v-if="show">
          <!-- <button class="close-btn" @click="handleClose">×</button> -->
          <h3 class="modal-heading">{{ heading }}</h3>
          <p class="modal-message">{{ msg }}</p>
          <div class="modal-actions">
            <button class="btn-cancel" @click="handleClose">{{ btnText1 }}</button>
            <button class="btn-confirm" @click="handleConfirm">{{ btnText2 }}</button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LogoutConfirmBox',
  props: {
    msg: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    btnText1: {
      type: String,
      default: 'Cancel'
    },
    btnText2: {
      type: String,
      default: 'Logout'
    },
    show: {
      // Use Boolean for show/hide if possible
      type: Boolean,
      required: true,
    },
    handleClose: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    handleConfirm() {
      // Sample logout logic
      if (!this.$store.state.is_respondent) {
        this.$store.dispatch('logout');
        this.$router.push({ path: '/login' });
      } else {
        this.$store.dispatch('logout');
        this.$router.push({ path: '/respondent_login' });
      }
    },
    handleClose() {
      this.handleClose();
    }
  }
};
</script>

<style scoped>
/* 
 * Animations for fade and zoom effects.
 * The transition names match those used in <transition name="...">
 */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-zoom-enter-active,
.modal-zoom-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.modal-zoom-enter,
.modal-zoom-leave-to {
  transform: scale(0.9);
  opacity: 0;
}

/* 
 * Overlay that dims the background 
 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure the modal is above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
 * Main modal container 
 */
.modal-container {
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* 
 * Close button (X) 
 */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  line-height: 1;
  color: #333;
}

/* 
 * Headings and text 
 */
.modal-heading {
  margin-top: 10px;
  font-size: 1.5rem;
  color: #333;
}
.modal-message {
  font-size: 1rem;
  line-height: 2vh;
  margin: 20px 0;
  color: #555;
}

/* 
 * Buttons 
 */
.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
}

.btn-cancel {
  background: #6c757d;
  margin: auto;
  color: #fff;
}

.btn-confirm {
  background: #007bff;
  margin: auto;
  color: #fff;
}

/* 
 * Responsive breakpoints 
 */
@media (max-width: 768px) {
  .modal-container {
    width: 80%;
  }
  .modal-heading {
    font-size: 1.2rem;
  }
  .modal-message {
    font-size: 0.95rem;
  }
  button {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .modal-container {
    width: 90%;
    padding: 15px;
  }
  .modal-actions {
    flex-direction: column;
  }
  button {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
