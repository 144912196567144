export function login(jwt_token, jwt_refresh_token) {
  localStorage.removeItem('jwt_token');
  localStorage.removeItem('jwt_refresh_token');
  localStorage.removeItem('pageText');
  localStorage.removeItem('pageName');
  localStorage.removeItem('is_anonymous');
  localStorage.setItem('jwt_token', jwt_token);
  localStorage.setItem('jwt_refresh_token', jwt_refresh_token);
}

export function logout(jwt_token) {
  localStorage.removeItem('jwt_token');
  localStorage.removeItem('jwt_refresh_token');
  localStorage.removeItem('pageText');
  localStorage.removeItem('pageName');
  localStorage.removeItem('autoRedirection');
}

export function isLoggedIn() {
  return localStorage.getItem("jwt_token") !== null;
}

export function isRespondent() {
  return localStorage.getItem("is_respondent");
}

export function respondent_retour_cas() {
  return localStorage.getItem("respondent_retour_cas");
}