<template>
  <div>
    <div v-if="showWelcomePopup" class="overlay" @click.self="closePopup">
      <div class="instructions-popup">
        <div class="popup-header">
          <h2 class="popup-title">{{ welcomeText.title }}</h2>
          <button class="close-button" @click="closePopup">✖</button>
        </div>
        <div class="step-container">
          <p>{{ welcomeText.instruction }}</p>
          <p>{{ welcomeText.notAvailable }}</p>
          <p>
            {{ welcomeText.help }}
            <a href="mailto:contact@mindspeller.com">contact@mindspeller.com</a>
          </p>
        </div>
        <div class="popup-header secondary-header">
          <h3 class="popup-subtitle">{{ welcomeText.watchVideo }}</h3>
        </div>
        <div class="video-container">
          <video
            controls
            :src="instructionsVideoUrl"
            playsinline
            autoplay
            loop
            muted
            class="instructions-video"
          ></video>        
        </div>
        <div class="popup-footer">
          <button class="primary-button" @click="closePopup">{{ welcomeText.gotIt }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showPopup: false, // Control the visibility of the popup
    };
  },
  computed: {
    ...mapGetters(["showWelcomePopup", "getInstructionalVideoUrl"]), // Access popup state and video URL from Vuex
    instructionsVideoUrl() {
      return this.$store.getters.getInstructionalVideoUrl('btoc_instructions_main');
    },
    welcomeText() {
      const translations = {
        en: {
          title: "Welcome to Mindspeller, the search engine that pays off!",
          instruction: "For the best experience, please open this app in Google Chrome / Brave / Edge / Firefox on a desktop or laptop computer.",
          notAvailable: "At the moment, our app isn’t yet available on smartphones or supported in certain browsers like Safari, Opera (on iOS). We’re working hard to bring you full compatibility soon—thanks for your patience!",
          help: "👉 Need help? Let us know!",
          watchVideo: "Please watch the instruction video below!",
          gotIt: "Got it!"
        },
        nl: {
          title: "Welkom bij Mindspeller, de zoekmachine die loont!",
          instruction: "Voor de beste ervaring, open deze app in Google Chrome / Brave / Edge / Firefox op een desktop of laptop.",
          notAvailable: "Op dit moment is onze app nog niet beschikbaar op smartphones of ondersteund in bepaalde browsers zoals Safari, Opera (op iOS). We werken hard om je binnenkort volledige compatibiliteit te bieden—bedankt voor je geduld!",
          help: "👉 Hulp nodig? Laat het ons weten!",
          watchVideo: "Bekijk de instructievideo hieronder!",
          gotIt: "Begrepen!"
        }
      };
      return translations[this.currentlang];
    }
  },
  methods: {
    ...mapActions(['initializeWelcomePopup', 'dismissWelcomePopup']),
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.$store.dispatch('dismissWelcomePopup');
    },
  },
  created() {
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  }
};
</script>

<style scoped>
/* Base Styles for Popup Container and Overlay */
.instructions-popup {
  width: 50%;
  max-height: 70%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Header Styles */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.popup-title {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 22px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000;
}

/* Secondary Header for Subtitle */
.secondary-header {
  margin-bottom: 10px;
}

.popup-subtitle {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  color: #333;
}

/* Step Container */
.step-container {
  margin-bottom: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  background: #f9fbfd;
}

.step-container p {
  font-size: 0.9rem;
  line-height: 1.6;
  color: #555;
}

/* Video Styles */
.video-container {
  margin-bottom: 20px;
}

.instructions-video {
  width: 100% !important;
  height: auto !important;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Footer Styles */
.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.primary-button {
  background: #4caf50;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.primary-button:hover {
  background: #45a049;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -55%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Responsive Styles for screens up to 768px */
@media only screen and (max-width: 768px) {
  .instructions-popup {
    width: 90%;
    max-height: 80%;
    padding: 15px;
  }
  .popup-title {
    font-size: 18px;
  }
  .close-button {
    font-size: 18px;
  }
  .step-container {
    padding: 10px;
  }
  .step-container p {
    font-size: 0.8rem;
  }
  .popup-subtitle {
    font-size: 0.9rem;
  }
  .primary-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
</style>
