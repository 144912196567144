<template>
    <div>
      <!-- Popup Modal -->
      <div class="popup-overlay" @click.self="$emit('close')">
        <div class="popup-content">
        <q-btn style="margin-left: 10%;" icon="close" flat round dense @click="$emit('close')" />
          <h2>Invite Friends</h2>
          <div class="form">
            <div class="form-section-desc">
                <p style="line-height: 2vh;">{{ $t("message.provide_user_details") }}</p>
            </div>

            <div class="form-group">
                <label class="form-label">{{ $t("message.firstname") }}</label>
                <input class="form-control" type="text" v-model="username" required>
            </div>

            <!-- <div class="form-group">
                <label class="form-label">{{ $t("message.user_type") }}</label>
                <select class="form-control" v-model="selected_category">
                <option disabled selected value="">{{ $t("message.select") }}</option>
                <option v-for="c in user_categories" :value="c.id" :key="c.id">
                    {{ c.name }}
                </option>
                </select>
            </div> -->

            <div class="form-group">
                <label class="form-label">{{ $t("message.email") }}</label>
                <input class="form-control" type="email" v-model="email" required>
            </div>

            <div class="button-group" style="text-align: center; margin: auto;">
                <button class="btn btn-primary" @click="send_invite">{{ $t("message.send_invitation") }}</button>
                <button class="btn btn-outline btn-frame" @click="cancel_invite">{{ $t("message.cancel") }}</button>
            </div>

            </div>  
            <div class="how-it-works">
                <h3>How does it work?</h3>
                <div class="invite-instructions">
                <div class="column">
                    <h4>What you have to do:</h4>
                    <ul>
                    <li>Enter your friend’s valid email address above</li>
                    <li>Ask your friend to login using the credentials in the email</li>
                    <li>Make sure your friend completes all the steps</li>
                    </ul>
                </div>
                <div class="column">
                    <h4>What your friend has to do:</h4>
                    <ul>
                    <li>Step 1: Login and choose their preferred category</li>
                    <li>Step 2: Complete ‘My Account’ (Mobile phone number verification)</li>
                    <li>Step 3: Earn 200 Mindchips</li>
                    </ul>
                </div>
                </div>
            </div>
            
          <div v-if="friends.length >= 1" class="scrollable-container">  
          <table class="referral-status">
            <thead>
              <tr>
                <th>Your Friend</th>
                <th>Step 1</th>
                <th>Step 2</th>
                <th>Step 3</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="friend in friends" :key="friend.email">
                <td>{{ friend.email }}</td>
                <td>
                  <span v-if="friend.step1" class="status success"><font-awesome-icon style="color: green;" :icon="['fas', 'circle-check']" /></span>
                  <span v-else class="status pending"><font-awesome-icon style="color: red;" :icon="['fas', 'circle-xmark']" /></span>
                </td>
                <td>
                  <span v-if="friend.step2" class="status success"><font-awesome-icon style="color: green;" :icon="['fas', 'circle-check']" /></span>
                  <span v-else class="status pending"><font-awesome-icon style="color: red;" :icon="['fas', 'circle-xmark']" /></span>
                </td>
                <td>
                  <span v-if="friend.step3" class="status success"><font-awesome-icon style="color: green;" :icon="['fas', 'circle-check']" /></span>
                  <span v-else class="status pending"><font-awesome-icon style="color: red;" :icon="['fas', 'circle-xmark']" /></span>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import {toast_success, alert_error} from '@/helpers/alert_helper.js';
  export default {
    data() {
      return {
        showPopup: false,
        selected_category: 5,
        email: '',
        username: '',
        usernameExists: true,
        emailExists: true,
        friends: [],
      };
    },
    props:{
        friends: Array,
        inviteCount: Number,
    },
    emits: ["close", "friendsFetched"],
    computed: {
    // we take the user categories from the Vuex store (they only get loaded
    // once on beforeCreate of App.vue since they are constant and are used
    // in multiple places)
    user_categories: function () {
      var ignore = [3]
      return this.$store.state.user_categories.filter(cat => ignore.indexOf(cat.id) === -1);
    }
  },
    methods: {

    cancel_invite: async function () {
      this.$emit('close');
      this.$router.push({ name: 'RespondentPanel' });
    },
    send_invite: async function () {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.username.length < 3) {
            const msg = this.$t("message.username_less_than_3_chars");
            alert(msg);
            return;
        }

        if (!re.test(this.email)) {
            const msg = this.$t("message.enter_valid_email_address");
            alert(msg);
            return;
        }

        if (this.selected_category === 0) {
            const msg = this.$t("message.select_user_category");
            alert(msg);
            return;
        }

        await axios.post('/api/cas/users/exist/by_email', { 'email': this.email })
            .then(response => {
            this.emailExists = response.data;
            });

        await axios.post('/api/cas/users/exist/by_name', { username: this.username })
            .then(response => {
            this.usernameExists = response.data;
            });


        const payload = {
            'username': this.username,
            'category': this.selected_category,
            'email': this.email,
            'is_respondent': this.$store.state.is_respondent,
        };

        if (this.usernameExists && !this.emailExists) {
            const msg = "Username exists";
            alert(msg);
        }
        else if (this.emailExists && !this.usernameExists) {
            const msg = "Email exists";
            alert(msg);
        }
        else if (!this.usernameExists && !this.emailExists) {
            try {
                const response = await axios.post('/api/cas/respondents/invite', payload);
                toast_success(this.$t("message.invitation_sent"));
                this.$emit('close');
            } catch (error) {
                const msg = this.$t("message.invitation_error");
                alert_error(msg);
            }
        } else if (this.usernameExists && this.emailExists) {
            const msg = "Username and Email exists";
            alert(msg);
        }

        },
    },
  };
  </script>
  
  <style scoped>
  /* Popup Overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup Content */
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 900px;
    max-height: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1001;
    overflow: auto;
  }
  
  /* Custom Scrollbar for WebKit Browsers */
.popup-content::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  margin-right: 10%;

}

.popup-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track */
}

.popup-content::-webkit-scrollbar-thumb {
  background: #d9e1ec; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar thumb */
  border: 2px solid transparent; /* Optional: Space around the thumb */
  background-clip: padding-box; /* Ensures thumb respects border */
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #b8c7d9; /* Thumb color when hovered */
}

  h2 {
  background-color: #7878e9;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* margin-top: 5%; */
  width: 85%;
  color: white;
  margin: auto;
  margin-bottom: 3%;
  padding: 5px;
  font-size: 22px;
  text-align: center;
}

  /* Close Button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .open-popup-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .open-popup-btn:hover {
    background-color: #0056b3;
  }
  
  .form-section-desc {
  margin-bottom: 20px;
}


.form {
  width: 100%;

  max-width: 400px;
  margin-top: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
}

.input-group {
  margin-bottom: 20px;
  /* display: flex; */
  /* align-items: center; */
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  display: block;
  font-weight: bold;
}

.input-group input {
  flex-grow: 1;
  width: 80%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.input-group select {
  flex-grow: 1;
  width: 80%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-control {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-close {
  margin-left: auto;
}

.btn-frame {
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
}
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .scrollable-container {
    width: 100%;
    border: 3px solid #7e85ff;
    border-radius: 6px;
    margin-top: 20px;
    }

  .referral-status {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-radius: 15px;

  }
  
  .referral-status th,
  .referral-status td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
  }
  
  .referral-status th {
    background-color: #7e85ff;
  }
  .status.success {
    color: green;
  }
  
  .status.pending {
    color: red;
  }

  .how-it-works {
  background-color: #7e85ff; /* Purple background color */
  padding: 10px;
  margin-top: 10px;
  border-radius: 12px;
  color: white;
  font-family: Arial, sans-serif;
  text-align: center;
}

.how-it-works h3 {
  /* margin-bottom: 5px; */
  font-size: 1.5rem;
  font-weight: bold;
}

.invite-instructions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  color: white;
  width: 100%;
  text-align: center;
}

.column {
  flex: 1;
  text-align: center;
}

.column h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.column ul {
  list-style-type: disc;
  padding-left: 50px;
}

.column li {
  margin-bottom: 5px;
  font-size: 0.85rem;
  text-align: left;
}
  </style>
  