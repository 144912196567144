<template>
    <div class="wheel-container" style="width: 100%; height: 100%;">
        <div class="wheel-graph">
            <loading-app-wide v-if="loading" />
            <div v-if="!loading || afterloading" style="display: flex; justify-content: center; align-items: center; gap: 5px; white-space: nowrap; margin-bottom: 10px;">
                <q-btn round icon="eva-list-outline" @click="openAssociationList" />
                <a class="btn btn-outline btn3" style="font-size: 1vw;" @click="openImageModal">
                    Reveal image
                </a>
                <a class="btn btn-outline btn3" style="font-size: 1vw;" @click="openClusteringModal">
                    Reveal clustering
                </a>
            </div>
            <div :id="uniqueId" />
        </div>
        <q-dialog v-model="imageModalVisible" ref="myDialog" @show="onShow" @hide="onHide">
            <q-card>
                <q-bar class="bg-primary text-white" :class="draggable ? 'cursor-move' : ''">
                    <q-space />
                </q-bar>
                <q-card-section>
                    <p style="text-align: center; font-size: larger; font-weight: bolder;">Add This New Image To Your
                        Library? </p>
                    <p style="font-weight: lighter; font-size: small;">(This will add your concept to the upcoming mapping
                        survey, <br>
                        you will get notified when your concept is ready for testing)</p>
                </q-card-section>
                <!-- display the images below -->
                <!-- <div style="display: flex; justify-content: flex-end; margin-right: 1.3vw;margin-top: -1vw;">
                    <a class="download-link" @click="exportImage">Download</a>
                </div> -->
                <div style="display: flex; flex-direction: column;margin: 0 auto; margin-bottom: 2vw;">
                    <a class="download-link" @click="exportImage" style="text-align: end;margin-right: 65px;">Download</a>
                    <div style="text-align: center;">
                        <img :src="image" style="width:280px;" id="image">
                    </div>
                </div>

                <q-card-actions>
                    <div style="display: flex; justify-content: flex-end; margin-right: 1.3vw;">
                        <btn style="background-color:#92a0b3 !important;margin-right: 1vw;" class="button_pay"
                            @click="closeImageModal">Cancel​</btn>
                        <btn class="button_pay" @click="addToLibrary(imageURL)">Add Concept</btn>
                    </div>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- !-- Clustering Modal -->
            <div
                v-if="clusteringModalVisible"
                class="modal-overlay"
                @click.self="closeClusteringModal"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Clustering Visualization</h3>
                        <button class="close-btn" @click="closeClusteringModal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div id="sunburst-container" style="width: 100%; height: 90%;"></div>
                    </div>
                    <div class="modal-footer">
                        <p>
                            <strong>Stability:{{ data.stability.toFixed(2) }}, 
                            Number of Responses: {{ data.nbResponses }} 
                            ({{ data.proportion }}% no idea),
                            Date: {{ data.date }}</strong> 
                        </p>
                    </div>
                    <div class="modal-note"  style="background-color: transparent;">
                        <button class="button_pay" @click="exportToCSV">Export results as CSV</button>
                    </div>
                    <div class="modal-note">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="note-icon" />
                        <p>This dynamic visualization reveals the semantic categories of spontaneous human associations with your concept. We recommend to save this data by exporting the graph (click the burger icon in the top-right corner).</p>
                    </div>
                </div>
            </div>
        <color-picker ref="color_picker" @colorSelected="onColorSelected">
        </color-picker>
        <association-list ref="association-list" :data="d3_wheel.data">
        </association-list>
    </div>
</template>

<script>
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import axios from 'axios';
import jsPDF from "jspdf";
import Wheel from './wheel.js';
import AssociationList from './AssociationList.vue';
import AwInformation from './AwInformation.vue';
import ColorPicker from './ColorPicker.vue';
import {
    alert_error,
    genericDialog,
    toast_success
} from '@/helpers/alert_helper.js';
import {
    v4 as uuidv4
} from 'uuid';
import { getTransitionRawChildren } from 'vue';

export default {
    components: {
        AssociationList,
        AwInformation,
        LoadingAppWide,
        ColorPicker
    },
    emits: ['wordColorPicked', 'chartError'],
    props: {
        asset_and_positioning: {
            required: true,
            type: Object
        },
        options: {
            required: true,
            type: Object
        },
        draggable: { type: Boolean, default: true },
        modelDialog: { type: Boolean, default: false },
    },
    data: function () {
        return {
            swDialog: false,
            target: null,
            nodeDragg: null,
            imageURL: '',
            image: '',
            afterloading: false,
            imageModalVisible: false,
            clusteringModalVisible: false,
            proportion: null,
            uniqueId: '',
            data: {},
            clusteringData: [],
            d3_wheel: {
                data: {}
            },
            loading: true,
            showInformation: true,
            brandExperiencePercentage: 0.0,
            descriptionTranslations: {
                stability: this.$t('message.stability'),
                responses_no: this.$t('message.number_of_responses'),
                date: this.$t('message.date'),
            },
            currentlang: '',
            wordIdsToDivide: [3861, 8957, 19965, 98557, 117770, 141506, 262332, 269484, 290899, 326230, 327011, 327012, 327013, 327014]
        };
    },
    created() {
        let defapp_lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
        this.currentlang = defapp_lang || 'nl';
    },
    methods: {
        onShow() {
            let dialogElements = document.getElementsByClassName("q-dialog");
            this.target = dialogElements[0].querySelector(".q-card");
            this.nodeDragg = this.target.querySelector(".q-bar");
            if (this.draggable) {
                this.nodeDragg.addEventListener("mousedown", this.onGrab);
            }

            this.$emit("onShow");
        },
        onHide() {
            if (this.draggable) {
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.onLetGo);
                this.nodeDragg.removeEventListener("mousedown", this.onGrab);
            }
            this.$emit("onHide");
        },
        onDrag(e) {
            let originalStyles = window.getComputedStyle(this.target);
            this.target.style.left =
                parseInt(originalStyles.left) + e.movementX + "px";
            this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";
        },

        onLetGo() {
            document.removeEventListener("mousemove", this.onDrag);
            document.removeEventListener("mouseup", this.onLetGo);
        },

        onGrab() {
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.onLetGo);
        },
        async addToLibrary(imageURL) {
            const dialog_options = {
                title: "Upload this image to Your Library",
                text: this.$t('new_asset.this_will_cost', {
                    price: 200
                }),
                confirmButtonText: this.$t("message.asset_creator_add"),
                cancelButtonText: this.$t("message.cancel"),
            };

            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                return;
            }
            if (dialog_result.isConfirmed) {
                // next, check if the user has enough credits
                await this.$store.dispatch('get_mindcoin_balance');
                if (this.$store.state.mindcoins < 200) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                }
                try {
                    this.loading = true;
                    this.imageModalVisible = false;
                    const response = await axios.post('/api/cas/assets/story_creator', { "imageURL": imageURL });
                    this.loading = false;
                    if (response.status == 204) {
                        toast_success(`Concept ordered successfully, you will be notified when your concept(s) is analysed`);
                        this.$store.dispatch('get_mindcoin_balance');
                    }
                } catch (error) {
                    if (error.response.status === 500) {
                        alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                        
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
        },

        async exportImage() {
            try {
                var a = document.createElement("a"); //Create <a>
                a.href = this.image //Image Base64 Goes here
                a.download = "Image.png"; //File name Here
                a.click(); //Downloaded file
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        },

        async openImageModal() {
            const dialog_options = {
                title: 'Generate a new image',
                text: "Image costs 10 Mindcoins",

                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                return;
            }
            if (dialog_result.isConfirmed) {
                // next, check if the user has enough credits
                await this.$store.dispatch('get_mindcoin_balance');
                if (this.$store.state.mindcoins < 10) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                }
                try {
                    this.loading = true;
                    const words = this.data.children
                        .slice(0, 20)
                        .map((child) => `${child.word}: ${child.percentViz}`)
                        .join(', ');
                    const payload = { 'description': words };
                    const response = await axios.post('/api/cas/association_wheels/image_generator', payload);
                    this.image = response.data.image;
                    this.imageURL = response.data.imageURL;
                    this.loading = false;
                    await this.$store.dispatch('get_mindcoin_balance');
                    this.imageModalVisible = true;
                } catch (error) {
                    if (error.response.status === 500) {
                        alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
                
                /*
                catch (error) {
                    console.log(error)
                }*/
            }
        },

        closeImageModal() {
            this.imageModalVisible = false;
        },

        async openClusteringModal() {
            const dialog_options = {
                title: 'Generate association clustering',
                text: "Clustering requires an investment of 10 Mindcoins",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            };
            const dialog_result = await genericDialog(dialog_options);

            if (!dialog_result.isConfirmed) {
                return; // If the user cancels, stop execution
            }

            // Check if the user has enough Mindcoins
            await this.$store.dispatch('get_mindcoin_balance');
            if (this.$store.state.mindcoins < 10) {
                alert_error(this.$t('new_asset.insufficient_credits'));
                return;
            }

            try {
                this.loading = true; // Show loading state
                const payload = { wheel_data: this.data.children, language: this.currentlang }; // Payload structure
                const response = await axios.post('/api/cas/association_wheels/clustering', payload);

                // Assuming the response includes clustering data
                const clusteringData = response.data.clustering;
                this.clusteringData = clusteringData;
                // Display the clustering results (modal or console for now)
                await this.$store.dispatch('get_mindcoin_balance');
                this.clusteringModalVisible = true;
                this.$nextTick(() => {
                this.renderSunburstChart(clusteringData, this.data.url, this.data.word);
                });            
            } catch (error) {
                console.error("Error fetching clustering data:", error);
                if (error.response && error.response.status === 500) {
                    alert_error("Failed to generate clustering. Please try again later.");
                } else {
                    alert_error(this.$t('message.general_server_error'));
                }
            } finally {
                this.loading = false; // Hide loading state
            }
        },
        
        exportToCSV() {
            const rows = [];
            const seenAssociations = new Set(); // To track unique associations

            // Add header row
            rows.push(["Category", "Category Total Percentage", "Associations", "Associations Percentage"]);

            // Process each category
            this.clusteringData.forEach(category => {
                let categoryTotalPercentViz = 0;

                // Calculate category total percentViz directly from fas values
                const responseData = category.responses.map(response => {
                    const percentViz = parseFloat(response.fas) * 100; // Convert fas to percentViz
                    categoryTotalPercentViz += percentViz;
                    return { word: response.word, percentViz };
                });

                // Add rows for each response
                responseData.forEach(({ word, percentViz }, index) => {
                    if (!seenAssociations.has(word)) { // Check for duplicates
                        rows.push([
                            index === 0 ? `"${category.category}"` : "", // Encapsulate category name in quotes
                            index === 0 ? categoryTotalPercentViz.toFixed(2) : "",
                            `"${word}"`, // Encapsulate response in quotes
                            percentViz.toFixed(2)
                        ]);
                        seenAssociations.add(word); // Mark this association as seen
                    }
                });
            });

            // Convert to CSV format
            const csvContent = rows.map(row => row.join(",")).join("\n");

            // Create a downloadable CSV file
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const filename = `clustering_report_${this.data.word || "default"}.csv`;
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        prepareSunburstData(clusteringData, imageUrl) {
            const compSunburstData = [];

            // Add the root node
            const centralNode = {
                id: 'root',
                parent: '',
                name: '',
                image: imageUrl,
                value: 1 // The entire circle represents 1
            };
            compSunburstData.push(centralNode);

            // Helper function to normalize strings for comparison
            const normalizeString = (str) => str.toLowerCase().trim();

            // Step 1: Remove duplicates from Miscellaneous
            const miscCategory = clusteringData.find(category => category.category === "Miscellaneous");
            if (miscCategory) {
                const otherResponsesSet = new Set(
                    clusteringData
                        .filter(category => category.category !== "Miscellaneous")
                        .flatMap(category => category.responses.map(response => normalizeString(response.word)))
                );

                // Filter Miscellaneous responses to remove duplicates
                miscCategory.responses = miscCategory.responses.filter(
                    response => !otherResponsesSet.has(normalizeString(response.word))
                );
            }

            // Step 2: Ensure no duplicates within Miscellaneous itself
            if (miscCategory) {
                const seenResponses = new Set();
                miscCategory.responses = miscCategory.responses.filter(response => {
                    const normalizedResponse = normalizeString(response.word);
                    if (seenResponses.has(normalizedResponse)) {
                        return false; // Skip duplicate
                    }
                    seenResponses.add(normalizedResponse);
                    return true;
                });
            }

            // Step 3: Calculate the total value from all categories for normalization
            const totalValue = clusteringData.reduce((sum, category) => {
                return sum + category.responses.reduce((catSum, response) => {
                    return catSum + parseFloat(response.fas); // Use fas value directly
                }, 0);
            }, 0);

            // Step 4: Process each category
            clusteringData.forEach((category, categoryIndex) => {
                const categoryId = `category-${categoryIndex}`;
                let categoryTotalPercentViz = 0;

                // Calculate the total percentViz for the category
                const responseData = category.responses.map(response => {
                    const percentViz = parseFloat(response.fas) * 100; // Convert fas to percentViz
                    categoryTotalPercentViz += percentViz;
                    return { word: response.word, percentViz };
                });

                // Normalize category value (proportion of the total wheel)
                const categoryValue = categoryTotalPercentViz / totalValue;

                // Add category node
                compSunburstData.push({
                    id: categoryId,
                    parent: 'root',
                    name: `${category.category} (${categoryTotalPercentViz.toFixed(2)}%)`,
                    value: categoryValue,
                    description: category.description
                });

                // Add responses under the category
                responseData.forEach((responseDataItem, responseIndex) => {
                    const responseId = `response-${categoryIndex}-${responseIndex}`;
                    const normalizedResponseValue = responseDataItem.percentViz / totalValue;

                    compSunburstData.push({
                        id: responseId,
                        parent: categoryId,
                        name: `${responseDataItem.word} (${responseDataItem.percentViz.toFixed(2)}%)`,
                        value: normalizedResponseValue // Normalize response value
                    });
                });
            });

            return compSunburstData;
        },
        renderSunburstChart(clusteringData, imageUrl, conceptName) {
            // Transform the clustering data into Highcharts-compatible format
            const plotData = this.prepareSunburstData(clusteringData, imageUrl);
            // Create the Highcharts chart
            Highcharts.chart('sunburst-container', {
                chart: {
                    type: 'sunburst',
                    height: '65%',
                    events: {
                        render: function () {
                            const chart = this;
                            const centerX = chart.plotLeft + chart.series[0].center[0];
                            const centerY = chart.plotTop + chart.series[0].center[1];
                            const imageSize = 80; // Adjust as necessary

                            if (chart.customImage) {
                                chart.customImage.destroy();
                                chart.customImage = null; // Ensure reference is cleared
                            }
                            if (chart.customText) {
                                chart.customText.destroy();
                                chart.customText = null; // Ensure reference is cleared
                            }

                            // Add the custom image or text to the center
                            if (imageUrl) {
                                chart.customImage = chart.renderer
                                    .image(imageUrl, centerX - imageSize / 2, centerY - imageSize / 2, imageSize, imageSize)
                                    .attr({
                                        preserveAspectRatio: 'xMidYMid meet' // Maintain aspect ratio
                                    })
                                    .add();
                            } else {
                                chart.customText = chart.renderer.text(conceptName, centerX, centerY)
                                    .css({
                                        color: '#333333',
                                        fontSize: '30px',
                                        fontFamily: 'Times New Roman, serif'
                                    })
                                    .attr({
                                        zIndex: 5
                                    })
                                    .add();

                                // Center the text after adding it to get accurate dimensions
                                let bbox = chart.customText.getBBox();
                                chart.customText.attr({
                                    x: centerX - bbox.width / 2,
                                    y: centerY - bbox.height / 2 + bbox.height  // Adjust Y to align by the top of the text
                                });
                            }
                        }

                    }
                },
                colors: ['transparent'].concat(Highcharts.getOptions().colors),
                title: {
                    text: 'Clustering of first order associations',
                    align: 'center',
                    verticalAlign: 'top',
                    margin: 1,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#1177ba'
                    }
                },
                series: [{
                    type: 'sunburst',
                    width: '100%',
                    name:'Root',
                    data: plotData,
                    allowDrillToNode: true,
                    cursor: 'pointer',
                    dataLabels: {
                        crop: false,
                        overflow: 'allow',
                        formatter: function () {
                        if (this.point.id === 'root') {
                            return ''; 
                        }
                        return this.point.name; 
                        },
                        style: {
                        fontSize: '10px',
                        textoverflow: 'ellipsis',
                        },
                        minFontSize: 8, 
                        maxFontSize: 20,
                        filter: {
                        property: 'innerArcLength',
                        operator: '>',
                        value: 0
                        }
                    },
                    levels: [{
                        level: 0, 
                        colorByPoint: true,
                        dataLabels: {
                        enabled: false
                        }
                    }, {
                        level: 1, 
                        colorByPoint: true,
                        levelIsConstant: false,
                        dataLabels: {
                        filter: {
                            property: 'outerArcLength',
                            operator: '>',
                            value: 1 
                        }
                        },style: {
                        fontSize: '12px',
                        },
                    }, {
                        level: 2,
                        colorByPoint: true,
                        colorVariation: {
                            key: 'brightness', // Subtle brightness variations
                            to: -0.5
                        },
                        dataLabels: {
                            style: {
                                fontSize: '9px'
                            }
                        }
                    },]
                    }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<b>{point.name}</b><br>{point.description}'
                },
            });
        },
        closeClusteringModal() {
            this.clusteringModalVisible = false;
        },

        openAssociationList: function () {
            this.$refs['association-list'].open();
        },

        wordClicked: async function (word, depth) {
            try {
                const color = await this.$refs.color_picker.pickColor();
                this.$emit('wordColorPicked', word, color, depth);
            } catch (e) { }
        },

        onColorSelected: function (color) { },
        setWordColor: function (word, color, depth) {
            this.d3_wheel.setWordColor(word, color, depth);
        },
        clearWordColor: function () {
            this.d3_wheel.clearAllColors();
        },
        setPercentage: function (value) {
            this.d3_wheel.setPercentage(value);
        },
        setWheelInformation: function (value) {
            this.showInformation = value;
        },
        /*
            Apply the provided color scheme to the chart. Returns a list of objects
            specifying which word received which color.
            */
        applyScheme: function (scheme) {
            const words = this.getFirstOrderWords();
            const colors = this.d3_wheel.getColorScheme(this.data, scheme);
            for (let i = 0; i < words.length; i++) {
                this.setWordColor(words[i], colors[i], 1);
            }
            return words.map(function (word, index) {
                return {
                    word: word,
                    color: colors[index]
                };
            });
        },
        getFirstOrderWords: function () {
            return this.data.children.map((child) => child.word);
        },
        exportChart: function () {
            const svg = document.querySelector(`#${this.uniqueId} svg`);
            this.$exportD3.saveSvgAsPng(svg, 'Association Wheel.png', {
                scale: 2
            });
        },
    },
    watch: {
        // we need a deep watch (since we're watching an object) of the options
        // prop so that any time options change we reinitialize the wheel
        options: {
            handler(val, oldVal) {
                this.d3_wheel.init(
                    this.data,
                    this.options,
                    this.descriptionTranslations
                );
            },
            deep: true,
        },
        showInformation: {
            handler(val, oldVal) {
                this.$props.options.display_info = val;
                this.d3_wheel.init(
                    this.data,
                    this.options,
                    this.descriptionTranslations
                );
            },
            deep: true,
        },
        modelDialog(val) {
            this.swDialog = val;
        }
    },
    beforeMount: async function () {
        this.uniqueId = `aw-graph-${uuidv4()}`;
        const asset = this.asset_and_positioning.asset;
        const positioning = this.asset_and_positioning.positioning;

        let payload = {
            proportion: 0.0,
            asset: asset,
            filters: [],
            from_date: '',
            to_date: ''
        };
        if (Object.keys(positioning).length > 0) {
            payload['from_date'] = positioning.from_;
            payload['to_date'] = positioning.to;
        }
        if ('mock' in this.asset_and_positioning) {
            payload['mock'] = true;
        }

        try {
            // we get the brand experience data first; this should be fast
            const brandExperienceUrl = `/api/cas/assets/brand_experience/total/${asset.id}`;
            const brandExperienceResponse = await axios.get(brandExperienceUrl);
            this.brandExperiencePercentage = brandExperienceResponse.data.percentage;

            // next we get the wheel data
            const url = '/api/cas/association_wheels/analyze';
            const response = await axios.post(url, payload);
            this.data = response.data[0];
            this.loading = false;
            this.afterloading = true;
            // last we get the no idea proportion and add to the data sent to wheel
            const no_idea_proportion = await axios.post('/api/cas/association_wheels/no_idea', asset);
            this.proportion = no_idea_proportion.data.toFixed(1);
            this.data.proportion = this.proportion
             // Check if word_id is in wordIdsToDivide
            // if (this.wordIdsToDivide.includes(this.data.word_id)) {
            //     this.data.nbResponses /= 3;
            //     this.data.proportion /= 3;
            // }
            this.data.currentlang = this.currentlang;
            this.d3_wheel = new Wheel(this.uniqueId, this);
            this.d3_wheel.init(this.data, this.options, this.descriptionTranslations);


        } catch (error) {
            this.$emit('chartError', this.asset_and_positioning);
            // TODO delete this AwChart if there's an error
            if (error.response.status === 403) {
                alert_error(this.$t('message.no_access_to_items'));
            } else {
                alert_error(this.$t('message.general_server_error'));
            }
        }
    },
};
</script>

<style scoped>
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.aw-button {
    position: absolute;
    width: 50px;
    z-index: 100;
}

.wheel-graph {
    height: 100%;
    width: 100%;
    position: relative;
}

.wheel-graph svg {
    width: 100%;
    height: 100%;
    font: 15px sans-serif;
    position: absolute;
}

/* i.q-icon.eva.eva-list-outline {
  width: 40px;
} */

.wheel-container {
    width: 92%;
    height: 76%;
}

svg:not(:root) {
    overflow: hidden;
    width: 84%;
}

text {
    font-size: 1.4vw !important;
}

.download-link {
    text-decoration: none;
    color: grey;
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: grey;
    }
}

/* 
.q-btn .q-icon, .q-btn .q-spinner {
    font-size: 1.715em;
    margin-right: 0.5vw;
} */

.button_pay {
    padding: 0.5vw 1.0vw;
    background: #0A00FF;
    border-radius: 2vw;
    font-style: normal;
    font-weight: 1000;
    font-size: 0.8vw;
    color: #ffffff !important;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 1s;
}

.button_pay a {
    color: #ffffff !important;
}

.button_pay:hover {
    background-color: #92a0b3;
}

.cursor-move {
    cursor: move;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    padding: 2px;
    border-bottom: 1px solid #ddd;
}
.modal-footer p {
    margin: 0;
    font-size: 8px;
    text-align: center;
    color: #333;
}
.modal-content {
    background: white;
    width: 90%;
    max-width: 1000px;
    max-height: 95%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0A00FF;
    color: white;
    padding: 5px;
    
}

.modal-header h3 {
    margin: 0;
    margin-left: 10px;
    font-size: 24px;
}

.modal-note {
  margin-top: 2px;
  text-align: center;
  background-color: #dbe916;
  color: red;
  text-align: center;
}

.note-icon {
  margin-right: 10px;
}

.modal-note p {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 10px;
    text-align: center;
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.modal-body {
    padding: 10px;
    /* height: calc(100% - 100px); */
    overflow: auto;
}
</style>
