<template>
<section class="middle-section">

        <div class="inspiration-pg association-wheel-pg">
            <div class="inspir-lft-sec scroll">
                    <workspace ref="workspace" @assetAdded="onAssetAdded" @assetRemoved="onAssetRemoved" @reset="reset"></workspace>
            </div>

        </div>
</section>
</template>

<script>
import Workspace from './Workspace.vue';

export default {
    name: 'RespondentPanel',
    components: {
        Workspace,
    },
    data() {
        return {
            show: 'hide',
            display_loading_overlay: false,
        };
    },
    methods: {
        getClass(value) {
            this.show = value;
        },
        reset: function () {
            this.$refs['workspace'].reset();
        },

        onAssetRemoved: function ({asset_and_positioning, canvasId}) {
            this.$refs.workspace.removeWheel({asset_and_positioning, canvasId});
        },
    },
};
</script>

<style scoped>

.inspiration-pg {
    display: flex;
    justify-content: start;
    align-items: stretch;
}
.inspir-lft-sec {
    width: 100%;
    padding: 1.5vw;
    min-height: 45.2vw;
}
</style>
