<template>
  <div class="popup-overlay" v-if="show" @click.self="$emit('exit')">
    <div class="popup-container">
      <q-btn icon="close" flat round dense @click="$emit('exit')" />

      <h2 class="popup-title">{{ $t('new_asset.choose_track') }}</h2>
      <div class="options-container">
        <!-- OPTION 1 -->
        <div class="option-card" @click="$emit('goToCheckoutbox')">
          <h3 class="option-title">{{ $t('new_asset.option') }} 1</h3>
          <h4 class="option-subtitle" style="margin-bottom: 0;">OMNIBUS PANEL</h4>
          <h4 class="option-subtitle" title="Open survey schedule"><Calendar @click.stop="onCalendarClick" /></h4>
          <div style="font-size: 10px;" class="option-info">
            {{ $t('new_asset.track_1_info_a') }}
            <br /><br />
            {{ $t('new_asset.track_1_info_b') }} 
            <br /><br />
            <strong>{{ $t('new_asset.track_1_info_c') }}</strong> 
            {{ $t('new_asset.track_1_info_d') }}
            <br />
            {{ $t('new_asset.track_1_info_e') }}
            <br />
            {{ $t('new_asset.track_1_info_f') }}
            <br /><br />
            <span>
            {{ $t('new_asset.track_1_info_g') }} 
            </span>
          </div>
        </div>
        <!-- OPTION 2 -->
        <div class="option-card-2">
          <h3 class="option-title">{{ $t('new_asset.option') }} 2</h3>
          <h4 class="option-subtitle" style="margin-bottom: 0;">DIY SURVEY</h4>
          <h4 class="option-subtitle"><font-awesome-icon :icon="['fas', 'tools']" class="calendar-icon" /></h4>
          <div class="option-info">
            <p style="font-size: 8px;">{{ $t('new_asset.track_2_title') }}</p>
            <form @submit.prevent="submitSelection" class="survey-form">
              <div class="checkbox-columns">
                <div class="column">
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Facebook"
                    />
                    Facebook
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="LinkedIn"
                    />
                    LinkedIn
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Instagram"
                    />
                    Instagram
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="TikTok"
                    />
                    TikTok
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Twitter"
                    />
                    Twitter
                  </label>
                </div>
                <div class="column">
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Usersnap"
                    />
                    Usersnap
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Userback"
                    />
                    Userback
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Quanny"
                    />
                    Quanny
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Qualaroo"
                    />
                    Qualaroo
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="channel"
                      v-model="selectedChannel"
                      value="Marker.io"
                    />
                    Marker.io
                  </label>
                </div>
              </div>
              <label class="other-label">

                <input
                  type="radio"
                  name="channel"
                  v-model="selectedChannel"
                  value="Other"
                />

                Other:
                <input
                  type="text"
                  v-if="selectedChannel === 'Other'"
                  v-model="otherChannel"
                  placeholder="Specify other channel..."
                />
              </label>
              <button type="submit" class="submit-button">SUBMIT</button>
            </form>
          </div>
        </div>

        <!-- OPTION 3 -->
        <div class="option-card-3">
          <h3 class="option-title">{{ $t('new_asset.option') }} 3</h3>
          <h4 class="option-subtitle" style="margin-bottom: 0px;">CUSTOM SURVEY PANEL</h4>
          <img src="../../assets/add_option_3.png" alt="Step 1 Image" class="step-image" />
          <div class="option-info" style="font-size: 10px;">
            <br />
            {{ $t('new_asset.track_3_info') }}
            <br /><br />
            <button @click="openContactPage" class="submit-button">CONTACT</button>
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <button class="back-button" @click="$emit('goBack')">
          {{ $t('new_asset.back') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
import Calendar from '@/components/header/Calendar.vue';

export default {
  data() {
    return {
      show: false,
      selectedChannel: null, // Holds the selected channel
      otherChannel: "", // Holds the value if "Other" is selected
    };
  },
  components: {
    Calendar,
  },
  emits: ['goBack', 'resetSearch', 'goToCheckoutbox', 'exit', 'openCalendar'],
  methods: {
    openContactPage(){
      window.open("mailto:contact@mindspeller.com", "_blank");
    },
    onCalendarClick(){
      this.$emit('openCalendar');
    },
    async submitSelection() {
      const selectedValue =
        this.selectedChannel === "Other" ? this.otherChannel : this.selectedChannel;

      if (!selectedValue) {
        alert("Please select a channel!");
        return;
      }
      // Send the selected value to the backend
      axios
        .post("/api/cas/assets/selected_channel", { channel: selectedValue })
        .then((response) => {
          console.log("Channel submitted successfully:", response.data);
          
        })
        .catch((error) => {
          console.error("Error submitting channel:", error);
          alert("There was an error submitting your selection.");
        });
        this.$emit("exit");
        const dialog_options = {
        title: this.$t('new_asset.thank_you'),
        text: this.$t('new_asset.track_2_submit'),
        cancelButtonText: this.$t("message.close"),
        confirmButtonText: this.$t("message.help"),
      };
      const dialog_result = await genericDialog(dialog_options);
      if (dialog_result.isConfirmed) {
        window.open("https://ads.mindspeller.com/contact/", "_blank");
        this.$emit('exit');
        return;
        
      }
      else{
        this.$emit('exit');
        return;
      }
    },
    open: function () {
      this.show = true;
    },
    close: function () {
      this.show = false;
      this.selectedChannel = null;
      this.otherChannel = '';
    },
  },
};
</script>

  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .popup-container {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    /* height: 85%; */
    max-width: 1100px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 30px;
    line-height: 30px;
    cursor: pointer;
  }
  
  .popup-title {
    font-size: 1.675rem;
    font-family: 'Open Sans';
    font-weight: bold;
    font-weight: 800;
    text-transform: capitalize;
    text-align: center;
  }
  
  .options-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch; /* Ensures all cards stretch to the same height */
  }
  
  .option-card, .option-card-2, .option-card-3 {
    background: linear-gradient(to bottom right, #bfb0f7, #7878e9);
    border-radius: 8px;
    padding: 20px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    max-width: 3000px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .option-card:hover {
    background: linear-gradient(to bottom right, #7878e9, #b8a7f8); /* Inverted gradient */
    }
  /* Different background for card 2 & 3 */
  .option-card-2 {
    background: linear-gradient(to bottom right, #c7c7ca, #6a6a6d);
    cursor:default;
  }
  .option-card-3 {
    background: linear-gradient(to bottom right, #c7c7ca, #6a6a6d);
    cursor:default;
  }
  
  .option-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 10px;
  }
  
  .option-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: rgba(255,255,255,0.5);
}

  .option-subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .option-subtitle[data-tooltip] {
  position: relative;
}

.option-subtitle[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.option-subtitle[data-tooltip]:hover::after {
  opacity: 1;
}

  .coming-soon {
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .option-info {
  background: rgba(170, 170, 170, 0.2);
  border: solid 1px #8d8d8d;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  font-size: 9px; /* Increased for better readability */
  text-align: left;
  color: #fff; /* Ensure this has enough contrast with the background */
  flex: 1; /* Allows equal height among sibling elements */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.6; /* Add line height for readability */
}

.option-info ul {
  padding-left: 20px; /* Indent for bullet points */
  margin: 10px 0; /* Add space around the list */
}

.option-info li {
  font-size: 5px; /* Ensure list items match main font size */
  line-height: 1.6;
}

.option-info strong {
  font-weight: bold;
  color: #f0f0f0; /* Slightly lighter than white for differentiation */
}

.option-info br {
  margin-bottom: 10px; /* Add spacing after line breaks */
}

.option-info p {
  font-size: 6px; /* Standard paragraph size */
  margin: 10px 0; /* Add spacing between paragraphs */
}

.step-image {
    width: 30%;
    max-height: 100px;
    object-fit: contain;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

/* Two-column layout for checkboxes */
.checkbox-columns {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.checkbox-columns::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: rgba(255,255,255,0.5);
}

.column {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 8px;
  z-index: 1;
}

.survey-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  font-size: 10px;
}

.survey-form label {
  display: flex;
  align-items: center;
}

.survey-form input[type="checkbox"] {
  margin-right: 10px;
}

.other-label {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  align-items: center;
}

.other-label input[type="text"] {
  margin-left: 10px;
  padding: 5px;
  font-size: 10px;
  border: none;
  border-radius: 4px;
}

.submit-button {
  margin-top: 5px;
  padding: 4px 8px;
  background: #fff;
  color: #333;
  border: none;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  align-self: center;
}

.submit-button:hover{
    background-color: #7878e9;
}

.popup-footer {
  margin-top: 20px;
  text-align: left;
}

.back-button {
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
}
.back-button:hover {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #777777; /* Slightly different shade, if desired */
}

.calendar-icon{
  font-size: 20px;
  margin-left: 5px;
  vertical-align: middle;
  cursor: default;
}
  </style>
  