<template>
    <div class="header-css">
      <div class="main-menu">
        <v-sheet v-if="!respondent" class="first-main-menu-item"
          ><Logo
        /></v-sheet>
        <v-sheet v-if="respondent" class="main-menu-item"><LogoMDSP /></v-sheet>
        <v-sheet
          v-if="respondent"
          class="divider"
          style="border-right: 1px solid #000;"
        ></v-sheet>
        <v-sheet v-if="respondent" class="main-menu-item tab" style="color: #000;"
          >Breaking Routine, Connecting Minds
          <v-sheet v-if="respondent" class="tooltip-container">
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              style="color: #000; cursor: pointer; font-size: 14px; margin-left: 5px; margin-bottom: 5px;"
            />
            <span class="tooltip-text">
              Mindspeller is a revolutionary, human-powered search engine that
              responds to your thoughts. By wearing an EEG headset, you can train
              it to generate results effortlessly—no typing required. Sign in to
              reclaim control of your data, explore your subconscious, and gain
              deeper insights into your mental well-being
            </span>
          </v-sheet>
        </v-sheet>
        <v-sheet class="main-menu-item"><Home /></v-sheet>
        <v-sheet v-if="respondent" class="main-menu-item"><Rewards /></v-sheet>
        <v-sheet v-if="!respondent" class="main-menu-item"><Library /></v-sheet>
        <v-sheet v-if="!respondent" class="main-menu-item"><MyBrand /></v-sheet>
        <v-sheet v-if="!respondent" class="main-menu-item"><Tools /></v-sheet>
      </div>
      <div class="user-related-menu">
        <v-sheet v-if="!respondent" class="main-menu-item"
          ><Organization
        /></v-sheet>
        <!-- <v-sheet v-if="!respondent" class="main-menu-item"><Calendar /></v-sheet> -->
        <v-sheet v-if="!respondent && autoRedirect" class="main-menu-item"
          ><RedirectToRespondentPanel
        /></v-sheet>
        <v-sheet v-if="!respondent" class="main-menu-item"
          ><TestConcepts
        /></v-sheet>
        <v-sheet v-if="respondent" class="main-menu-item"
          ><RedirectToCAS
        /></v-sheet>
  
        <v-sheet v-if="!respondent" class="main-menu-item"
          ><TrackConcepts
        /></v-sheet>
        <v-sheet v-if="!respondent" class="main-menu-item"><Help /></v-sheet>
        <v-sheet class="main-menu-item"><UserIcon /></v-sheet>
              <v-sheet v-if="!respondent" class="main-menu-item"><Credits /></v-sheet>
              <v-sheet v-if="respondent" class="main-menu-item"><Mindchips /></v-sheet>
      </div>
    </div>
  
    <div class="d-flex align-items-center side" style="background-color: #7878e9;">
      <div class="">
        <button class="bg-transparent pt-0" @click="toggleSidebar">
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g id="Menu / Menu_Alt_03">
                <path
                  id="Vector"
                  d="M5 17H13M5 12H19M5 7H13"
                  stroke="#000000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div class="mx-auto text-center">
        <img src="../../assets/logo-with-text.png" alt="" class="w-50" />
      </div>
      <div>
        <button class="bg-transparent" @click="handleClickHome">
          <img src="../../assets/search-icon.png" alt="" class="search" />
        </button>
      </div>
    </div>
    <div class="sidebar" :class="{ 'sidebar-open': isSidebarOpen }">
      <div class="sidebar-content flex flex-column gap-3">
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="" @click="handleClickHome">
          <a class="text-black text">Home</a>
          <hr class="m-0" />
        </div>
        <div v-if="respondent" class="" @click="handleClickRewards">
          <a class="text-black text">Rewards</a>
          <hr class="m-0" />
        </div>
        <div class="" @click="handleClickAccount">
          <a class="text-black text">My Account</a>
          <hr class="m-0" />
        </div>
        <div v-if="!respondent" class="" @click="handleClickLibrary">
          <a class="text-black text">Library</a>
          <hr class="m-0" />
        </div>
        <div @click="logout">
          <a class="text-black text">Logout</a>
          <hr class="m-0" />
        </div>
      </div>
    </div>
    <logout-confirm-box
    v-if="confirm"
    :show="confirm ? true : false"
    msg="Are you sure you want to logout the session?"
    heading="Logout Confirmation"
    btnText1="Cancel"
    btnText2="Logout"
    :handleClose="handleClose"
  ></logout-confirm-box>
  <logout-confirm-box v-if="confirm" :show="confirm ? true : false" msg="Are you sure you want to logout the session?"
    heading="Logout Confirmation" btnText1="Cancel" btnText2="Logout" :handleClose="handleClose"></logout-confirm-box>
    <!-- Overlay (optional) -->
    <!-- <div v-if="isSidebarOpen" class="overlay" @click="toggleSidebar"></div> -->
  </template>
  
  <script>
  import Home from "./Home.vue";
  import Rewards from "./Rewards.vue";
  import Calendar from "./Calendar.vue";
  import Help from "./Help.vue";
  import Credits from "./Credits.vue";
  import Mindchips from "./Mindchips.vue";
  import Library from "./Library.vue";
  import Logo from "./Logo.vue";
  import LogoMDSP from "./LogoMDSP.vue";
  import MyBrand from "./MyBrand.vue";
  import Organization from "./Organization.vue";
  import Tools from "./Tools.vue";
  import UserIcon from "./UserIcon.vue";
  import TestConcepts from "./TestConcepts.vue";
  import TurnPro from "./TurnPro.vue";
  import RedirectToCAS from "./RedirectToCAS.vue";
  import RedirectToRespondentPanel from "./RedirectToRespondentPanel.vue";
  import TrackConcepts from "./tracking/Tracking.vue";
  import LogoutConfirmBox from '../common/LogoutConfirmBox.vue';

  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: "Header",
    components: {
      Home,
      Rewards,
      Logo,
      LogoMDSP,
      Tools,
      Library,
      MyBrand,
      Organization,
      Help,
      UserIcon,
      Credits,
      Mindchips,
      TestConcepts,
      TurnPro,
      RedirectToCAS,
      RedirectToRespondentPanel,
      Calendar,
      TrackConcepts,
      LogoutConfirmBox,
    },
    props: {
      showHeaderDropDown: Boolean,
    },
    data() {
      return {
        isSidebarOpen: false,
        confirm: null,
      };
    },
    computed: {
      respondent() {
        return this.$store.state.is_respondent;
      },
      autoRedirect() {
        const autoRedirection = localStorage.getItem("autoRedirection");
        if (autoRedirection) {
          return true;
        }
      },
    },
    methods: {
        handleClickHome : function () {
            this.$router.push('/dashboard').then(() => {
                window.location.reload();
            });
        },
        handleClickAccount() {
      window.open(this.$router.resolve('/my-account').href, '_blank');
    },
    handleClickRewards() {
      window.open(this.$router.resolve('/respondentpanel').href, '_blank');
    },
        handleClickLibrary: function () {
            window.open(this.$router.resolve('/library').href, '_blank');
    },
    handleClickHome: function () {
            window.open(this.$router.resolve('/dashboard').href, '_blank');
    },
    logout() {
      this.confirm = 'logout';
    },
    handleClose() {
      this.confirm = null;
    },
      toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen; // Toggle sidebar state
      },
    },
    // watch: {
    //     '$store.state.respondent_retour_cas': function(newVal) {
    //         localStorage.setItem('respondent_retour_cas', newVal);
    //         console.log('app respondent', newVal);
    //         this.respondent = newVal;
    //     }
    // }
  };
  </script>
  
  <style scoped>
  @media only screen and (max-width: 768px) {
      .button_book_tour{
          font-size: x-small !important;
      }
      .tab{
          display: none !important;
      }
      .calendar-icon {
      font-size: 20px !important;
      cursor: pointer;
      color: #ffffff;
      transition: color 0.3s ease;
  }
  .home-menu-item {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5vw;
      color: white;
      position: relative;
      cursor: pointer;
  }
  .library-menu-item {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5vw;
      color: white;
      position: relative;
      cursor: pointer;
  }
  .brand-menu-item {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5vw;
      color: white;
      position: relative;
      cursor: pointer;
  }
  .dropdown-menu-ul li {
      display: flex;
      align-items: center;
      vertical-align: middle;
      font-weight: 600;
      font-size: 12px !important;
      line-height: 1.5vw;
      position: relative;
      cursor: pointer;
  }
  }
  
  @media only screen and (min-width: 1439px) {
  
    .main-menu[data-v-ed558de2] {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      height: 75px;
      position: fixed;
      top: 0;
      left: 0;
      align-items: center;
      /* margin-left: 10px ; */
    }
  
    .user-related-menu{
      margin-right: 30px !important;
    }
  }
  
  
  @media only screen and (min-width: 767px) {
    .side {
      display: none !important;
    }
    .main-menu[data-v-ed558de2] {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      height: 75px;
      position: fixed;
      top: 0;
      left: 0;
      align-items: center;
    }
    /* .main-menu-item {
  
  } */
    .first-main-menu-item {
      margin-right: 0px !important;
    }
    .logo {
      margin: 0px !important;
    }
    .main-menu-item {
      flex-basis: auto;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  
    .logo {
      margin-right: 0px !important;
      /* width: 15px !important; */
    }
  }
  
  @media only screen and (max-width: 426px) {
    .header-css {
      display: none !important;
    }
    .search {
      height: 16px;
      width: 90px;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .search {
      height: 13px;
      width: 110px;
    }
    .header-css {
      display: none !important;
    }
  }
  
  /* @media only screen and (max-width: 375px) {
    .search {
      height: 15px;
      width: 100px;
    }
    .header-css {
      display: none !important;
    }
  } */
  .sidebar {
    position: fixed;
    top: 0;
    left: -300px; /* Hide sidebar off-screen by default */
    width: 300px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-in-out; /* Smooth transition */
  }
  
  .sidebar-open {
    left: 0; /* Bring sidebar into view */
    background-color: #FAF9F6; /* Change background color when sidebar is open */
    z-index: 100;
  }
  
  /* Sidebar Content */
  .sidebar-content {
    padding: 20px;
  }
  
  /* Overlay Styles */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100; /* Ensure it's above other content */
  }
  
  /* Ensure header is above the overlay */
  .d-flex {
    position: relative;
    z-index: 101;
  }
  .header-css {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 75px;
    background-color: #7878e9;
    box-shadow: 0 0.1vw 0.7vw #d3d3d3d9;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    color: white;
  }
  .main-menu {
    display: flex;
    justify-content: flex-start;
    gap: 7px;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
  }
  .main-menu-item {
    flex-basis: auto;
    margin-right: 10px;
    /* margin-left: 10px; */
  }
  
  .first-main-menu-item {
    flex-basis: auto;
    margin-right: 10px;
  }
  
  .user-related-menu {
    display: flex;
    justify-content: flex-end;
    gap: 0px;
    height: 75px;
    position: fixed;
    top: 0;
    right: 0;
    align-items: center;
    margin-right: 10px;
  }
  .divider {
    border-right: 1px solid #fff;
    height: 50%;
    margin: 0;
  }
  
  .tooltip-container {
    position: relative;
    display: inline-block;
    z-index: 5;
  }
  
  .tooltip-container .tooltip-text {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    right: 5%;
    width: 550px;
    transform: translateX(15%); /* Moves tooltip slightly to the left */
    white-space: normal;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Arrow pointing down */
  .tooltip-container .tooltip-text::after {
    content: "";
    position: absolute;
    bottom: 100%; /* Moves arrow above the box */
    right: 13%;
    transform: translateX(-50%);
    border-width: 0 8px 8px 8px; /* Creates an upward-facing triangle */
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent; /* Matches background */
  }
  
  .text{
      font-size: 12px;
  }
  
  /* Show tooltip on hover */
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  </style>
  