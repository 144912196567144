<template>
    <!-- Image -->
    <div>
      <div
        style="display: flex; justify-content: center; align-items: center; height: 70%;"
      >
        <img src="../../../assets/banner-img1.jpg" style=" width: auto; height: 70vh;" />
      </div>
  
      <!-- Testimonial Text -->
      <div class="testimo-box text-white mt-3" style="height: 30%;">
        <p>
          We use Mindspeller to test our creative campaign concepts. Mindspeller's
          brand equity autopilot helps keep our brand on track.
        </p>
        <p>
          ~ Wendy M. Head of Marketing Communication Research, City of Antwerp
        </p>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "Brand",
  };
  </script>
  