<template>
    <div class="tooltip-container">
        <btn class="button_book_tour" @click="goToLibrary">Test Concept</btn>
        <div class="tooltip" v-html="$t('new_asset.test_concept_tooltip')">
        </div>
    </div>

</template>
  
<script>
    export default 
    {
        name: 'TestConcepts',
        methods: 
            {
                goToLibrary: function(){
                    this.$router.push({name:"Library"});
                },
            },
    };
</script>

<style scoped>
@media only screen and (max-width: 768px) {
    .button_book_tour{
        font-size: x-small !important;
    }
    .calendar-icon {
    font-size: 20px !important;
    cursor: pointer;
    color: #ffffff;
    transition: color 0.3s ease;
}
}

    .button_book_tour {
        padding: 0.5vw 1vw;
        background: #FFFFFF;
        border-radius: 2vw;
        font-weight: 600;
        font-style: normal;
        color: #000000;
        cursor: pointer;
        transition: all 1s;
    }

    .button_book_tour a {
        color: #ffffff !important;
    }
    .icon{
        width: 20px;
        margin-right: 10px;
        color: #74C0FC;
    }

    .tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .button_book_tour {
  cursor: pointer;
  margin-right: 3px;
}

.tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #7878e9;
  color: #fff;
  text-align: center;
  border: solid 1px #000;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -150px; /* Use half of the tooltip width to center it */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>

  