<template>
  <div class="maximize-reward-box reward">
    <h3>{{ title }}</h3>

    <div class="maximize-reward-cards-container">
      <!-- Render existing reward cards -->
      <div
        class="maximize-reward-card"
        v-for="(image, index) in images"
        :key="index"
      >
        <div class="image-container">
          <img :src="image" alt="Reward" class="blurred-image" />
          <div class="maximize-overlay"></div>
        </div>
        <p class="maximize-discount">{{ discountValues[index] }}</p>
        <button disabled class="redeem-button">
          <p style="font-size: 10px; padding:5px; line-height: 2vh; text-align:center; align-items: center">
            {{ t.redeemButton }} {{ cost }} Mindchips
          </p>
        </button>
      </div>

      <!-- Placeholder for missing offers (if fewer than 2 images exist) -->
      <div
        v-for="index in placeholdersNeeded"
        :key="'placeholder-' + index"
        class="maximize-reward-card placeholder-card"
      >
        <p class="placeholder-text">{{ t.placeholderText }}</p>
      </div>

      <!-- Default third box for hidden brand -->
      <div class="maximize-reward-card hidden-brand-card">
        <img
          @click="navigateToDashboard"
          style="margin-bottom: 4%;  cursor: pointer; width: 40px;"
          src="../../assets/mindspeller-network.png"
          alt="Flag Icon"
        />
        <p class="offer-value">-?%</p>
        <button @click="redirectToCustomBrand" class="offer-value-button">
          
            {{ t.hiddenBrandButton }}
          
        </button>
      </div>
    </div>

    <div :class="{ 'center-button': images.length === 0 }">
      <button class="pro-cta-button" @click="launchBecomeMindspeller">
        {{ t.turnProButton }}
      </button>
    </div>
  </div>
  <RedirectCasPopup
    v-if="showRedirectCas"
    @close="closeRedirectCas"
  ></RedirectCasPopup>
</template>

<script>
import RedirectCasPopup from "@/components/respondent_panel/RedirectCasPopup.vue";
export default {
  props: {
    title: String,
    images: {
      type: Array,
      default: () => [],
    },
  },
  components: { RedirectCasPopup },
  emits: ["launchPro"],
  data() {
    return {
      cost: 1000,
      discountValues: ["-1%", "-1%", "-1%"], // Initial discount display
      showRedirectCas: false,
      currentlang: "en", // default language
    };
  },
  created() {
    // Set currentlang based on environment variable (defaults to 'nl' if not set)
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  },
  computed: {
    placeholdersNeeded() {
      return Math.max(2 - this.images.length, 0);
    },
    t() {
      return this.currentlang === "nl"
        ? {
            redeemButton: "Verzilver voor",
            placeholderText: "Selecteer uw product categorie van interesse",
            hiddenBrandButton: "Positioneer je favoriete verborgen merk",
            turnProButton: "WORD PRO",
          }
        : {
            redeemButton: "Redeem for",
            placeholderText: "Select Your Product Category of Interest",
            hiddenBrandButton: "Position Your Favourite Hidden Brand",
            turnProButton: "TURN PRO",
          };
    },
  },
  mounted() {
    this.startDiscountAnimation();
  },
  methods: {
    navigateToDashboard() {
      this.$router.push("/dashboard");
    },
    startDiscountAnimation() {
      let value = -1; // Start from -1%
      setInterval(() => {
        if (value <= -20) {
          this.discountValues = this.discountValues.map(() => "?");
          setTimeout(() => {
            value = -1;
            this.discountValues = this.discountValues.map(() => "-1%");
          }, 1000);
        } else {
          value -= 2;
          this.discountValues = this.discountValues.map(() => `${value}%`);
        }
      }, 500);
    },
    launchBecomeMindspeller() {
      this.$emit("launchPro");
    },
    redirectToCustomBrand() {
      this.showRedirectCas = true;
    },
    closeRedirectCas() {
      this.showRedirectCas = false;
    },
  },
};
</script>


<style scoped>
/* 📌 Main Reward Box */

/* @media only screen and (max-width: 376px) {
  .maximize-reward-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin-top: 10%;
    background-color: #b1b1ec;
    border-radius: 15px 15px 15px 15px !important;
    text-align: center;
  }
} */
.reward {
  width: 100% !important;
}

@media only screen and (max-width: 480px) {

  .hidden-brand-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 2px dashed #6c63ff;
  border-radius: 12px;
  padding: 12px;
  width: 100px !important;
  height: 190px;
  justify-content: center;
}

.offer-value-button {
  max-width: 100%;
  height: fit-content;
  font-size: 4px;
}

  .center-button {
  display: flex;
  justify-content: center;
  margin-top: 45px !important;
}
  .maximize-reward-box {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    max-width: 560px;
    padding: 20px;
    margin-top: 10%;
    background-color: #b1b1ec;
    border-radius: 15px 15px 15px 15px !important;
    text-align: center;
  }
  .placeholder-text {
    font-size: 15px;
    color: #2e2d2d;
    text-align: center;
    margin: 20px;
    line-height: normal !important;
  }
  
  h3 {
    background-color: blue;
    border-radius: 15px;
    color: white;
    font-size: 15px !important;
    padding: 10px;
    line-height: 2.125rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .placeholder-text {
    font-size: 15px;
    color: #2e2d2d;
    text-align: center;
    margin: 4px !important;
  }
  .pro-cta-button {
    background-color: #e5ff22;
    color: black;
    font-weight: bold;
    border: none;
    border-radius: 15px;
    font-size: 18px;
    width: 75% !important;
    margin-top: 5%;
    padding: 0px !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .maximize-reward-card button {
    max-width: 100%;
  height: fit-content;
  font-size: 10px !important;
  }

}

@media only screen and (max-width: 768px) {

  .maximize-reward-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  margin-top: 10%;
  background-color: #b1b1ec;
  border-radius: 15px 15px 15px 15px !important;
  text-align: center;
}
}

.maximize-reward-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  margin-top: 10%;
  background-color: #b1b1ec;
  border-radius: 0 15px 15px 0;
  text-align: center;
}

/* 📌 Title */
h3 {
  background-color: blue;
  border-radius: 15px;
  color: white;
  font-size: 20px;
  padding: 10px;
  line-height: 2.125rem;
  text-align: center;
  margin-bottom: 10px;
}


.offer-value-button {
  max-width: 100%;
  height: fit-content;
  font-size: 10px;
}
/* 📌 Reward Cards Container */
.maximize-reward-cards-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
  flex-wrap: wrap;
}

/* 📌 Individual Reward Cards */
.maximize-reward-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 6px;
  width: 110px;
  height: 190px;
  justify-content: space-evenly;
}

.maximize-reward-card button {
  background-color: #5940b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  max-width: 100%;
  height: fit-content;
  font-size: 10px;
}


/* 📌 Placeholder Card */
.placeholder-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  width: 100px;
  height: 190px;
  padding: 12px;
}

.placeholder-text {
  font-size: 15px;
  color: #2e2d2d;
  text-align: center;
  margin: 20px;
}

/* 📌 Hidden Brand Box */
.hidden-brand-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 2px dashed #6c63ff;
  border-radius: 12px;
  padding: 12px;
  width: 102px;
  height: 190px;
  justify-content: center;
}

.hidden-brand-text {
  font-size: 12px;
  font-weight: bold;
  color: #6c63ff;
  text-align: center;
  margin-bottom: 5px;
}

/* 📌 Image Container */
.image-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

/* 📌 Blurred Image Effect */
.blurred-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
  border-radius: 10px;
}

/* 📌 Semi-Transparent Overlay */
.maximize-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

/* 📌 Discount Animation */
.maximize-discount {
  font-size: 10px;
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 0;
  animation: smoothDiscountAnimation 4.5s infinite ease-in-out;
}

.offer-value {
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin: 5px;
  padding: 0;
}

/* 📌 Disabled Redeem Button */
.redeem-button {
  background-color: #918e8e;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 10px;
  font-size: 6px;
  margin-top: 5px;
  cursor: not-allowed;
  width: 100%;
}

/* 📌 Redirect Button */
.redirect-button {
  background-color: #5940b3;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 10px;
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.redirect-button:hover {
  background-color: #d9534f;
}

/* 📌 Centered Button Container */
.center-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* 📌 TURN PRO Button */
.pro-cta-button {
  background-color: #e5ff22;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  width: 100%;
  margin-top: 5%;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.pro-cta-button:hover {
  background-color: #e5ff22c5;
  transform: scale(1.05);
}

/* 📌 Discount Animation */
@keyframes smoothDiscountAnimation {
  0% {
    transform: scale(1);
    color: #4c70af;
  }
  50% {
    transform: scale(1.2);
    color: #e5ff22;
  }
  100% {
    transform: scale(1.5);
    color: #117f0d;
  }
}
</style>
