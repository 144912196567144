<template>
    <div class="custom-purple-outline">
      <!-- Header with dropdown toggle -->
      <div class="info-header" @click="toggleDropdown">
        <font-awesome-icon :icon="['fas', 'lightbulb']" class="header-icon" />
        <span class="dropdown-title">{{ dropdownTitle }}</span>
        <font-awesome-icon 
          :icon="isCollapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" 
          class="toggle-icon" 
        />
      </div>
  
      <!-- Dropdown Content -->
      <div v-show="!isCollapsed" class="dropdown-content">
        <!-- If assets are selected, show asset-checkbox components -->
        <div v-if="selected_assets_and_positionings.size > 0">
          <asset-checkbox 
            v-for="ap in selected_assets_and_positionings" 
            :key="ap" 
            :asset_and_positioning="ap" 
            :show_positionings="show_positionings" 
            @removeAsset="onRemoveAsset" 
          />
        </div>
        <!-- Otherwise show search, default words, buttons and image grid -->
        <div v-else>
          <div class="search-container" v-if="!$store.state.is_respondent">
            <q-icon name="search" class="search-icon" />
            <q-select 
              use-input 
              ref="select_widget" 
              v-model="selectedWord" 
              :options="options" 
              :loading="loading"
              :hint="hint" 
              @filter="filter" 
              placeholder="Search" 
              class="q-select-no-padding"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    {{ $t('message.no_match') }}
                  </q-item-section>
                </q-item>
              </template>
              <template v-slot:loading>
                <q-item>
                  <q-item-section class="loading-container">
                    <q-spinner size="30px" color="primary" />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="search-container" v-else>
            <word-picker-mobile-sidebar 
              @dictWordSelected="handleWordSelected" 
              @wordRemoved="onRemoveAsset" 
            />
          </div>
          <div class="default-words-list">
            <div 
              v-for="word in defaultWords" 
              :key="word.word_id" 
              class="default-word-item" 
              @click="selectDefaultWord(word)"
            >
              <q-icon name="check" class="circle-check-icon" />
              <span class="default-word-text">{{ word.word }}</span>
            </div>
          </div>
          <div class="lib-btn-container">
            <div v-if="!$store.state.is_respondent" class="lib-btn">
              <a class="lib-btn-small" @click="openAssetPicker">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
            <div v-else class="lib-btn">
              <a class="lib-btn-small" @click="openRedirectCas">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
          </div>
          <div class="divider"></div>
          <div class="image-grid-container">
            <div class="image-grid">
              <div 
                v-for="(payload, index) in payloads" 
                :key="payload.key" 
                class="image-grid-item"
                @click="emitAddAsset(payload)"
              >
                <img 
                  :src="payload.asset.urls.thumb_medium" 
                  :alt="payload.asset.display_name" 
                  class="grid-image" 
                />
              </div>
            </div>
          </div>
          <div class="lib-btn-container">
            <div v-if="!$store.state.is_respondent" class="lib-btn">
              <a class="lib-btn-small" @click="openAssetPicker">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
            <div v-else class="lib-btn">
              <a class="lib-btn-small" @click="openRedirectCas">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RedirectCasPopup v-if="showRedirectCas" @close="closeRedirectCas" />
  </template>
  
  <script>
  import axios from 'axios';
  import AddNewAssetDialog from '@/components/add_asset/AddNewAssetDialog.vue';
  import AssetCreator from '@/components/asset_library/AssetCreatorDashboard.vue';
  import WordPickerMobileSidebar from './WordPickerMobileSidebar.vue';
  import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckboxDashboard.vue';
  import RedirectCasPopup from '@/components/respondent_panel/RedirectCasPopup.vue';
  import { alert_error, genericDialog } from '@/helpers/alert_helper.js';
  
  export default {
    components: { AddNewAssetDialog, AssetCreator, AssetCheckbox, RedirectCasPopup, WordPickerMobileSidebar },
    emits: ['wordSelected', 'wordChanged', 'assetClick', 'addNewAsset', 'addAsset', 'wordRemoved'],
    props: {
      clearAfterSelection: { type: Boolean, default: true },
      hint: { type: String, default: '' },
      standalone: { type: Boolean, default: false },
      selected_assets_and_positionings: { type: Set, required: true },
      show_positionings: { type: Boolean, required: true },
      openAssetPicker: Function,
      onAddNewAsset: Function,
      onRemoveAsset: Function,
      allowed_types: { type: Array, default: () => ['images', 'words', 'multimedia'] },
      isModal: { type: String },
      background: { type: String, default: 'img-icon.png' },
    },
    data() {
      return {
        selectedWord: null,
        options: [],
        isCollapsed: true,
        loading: false,
        showRedirectCas: false,
        last_search: '',
        defaultWordData: {
                MOED: {
                    value: {
                        word: "MOED",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 3896,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false,
                        },
                        image_url: "",
                        has_access: false,
                    },
                    label: "MOED",
                },
                SMAAK: {
                    value: {
                        word: "SMAAK",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 6595,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false,
                        },
                        image_url: "",
                        has_access: false,
                    },
                    label: "SMAAK",
                },
                AUTHENTIEK: {
                    value: {
                        word: "AUTHENTIEK",
                        created_at: "2020-12-03T14:45:15",
                        updated_at: "2020-12-03T14:45:18",
                        asset_mime_type: null,
                        file_identifier: null,
                        external: 0,
                        word_id: 1342,
                        type_: {
                            id: 1,
                            title: "text",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: false,
                            isImage: false,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: "",
                        has_access: false
                    },
                    label: "AUTHENTIEK"
                }
            },
            // List of default words to display in the UI
            defaultWords: [
                { word: 'AUTHENTIEK', word_id: 1342 },
                { word: 'SMAAK', word_id: 6595 },
                { word: 'MOED', word_id: 3896 },
            ],
            payloads: [
            {
                asset: {
                    id: 2182,
                    state: 2,
                    brand: 0,
                    display_name: "1033938352",
                    top_associations: "SPELEN, KIND, GELUK, PLEZIER, GEZIN, LIEFDE, MOEDER, SAMEN, TURNEN, YOGA, SPEELS, FAMILIE, SPEL, ONTSPANNING, LEUK, GENIETEN, MAMA, GEZELLIG, DOCHTER, MOEDER DOCHTER",
                    labels: null,
                    passion: 0,
                    link_word_id: null,
                    map_date: null,
                    intended_association: null,
                    blocked: 0,
                    lifecycle: 4,
                    expedite: null,
                    created_at: "2024-12-13T14:58:40",
                    tracking_auto_tag: 0,
                    tracking: 0,
                    brand_website: null,
                    clarity_score: 5.7416,
                    total_count: 9,
                    word: {
                    word: "1033938352",
                    created_at: "2021-04-13T13:33:31",
                    updated_at: "2021-04-13T13:33:31",
                    asset_mime_type: "image/jpeg",
                    file_identifier: "42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    external: 1,
                    word_id: 295678,
                    type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                    },
                    image_url: ""
                    },
                    candidate_word: [],
                    asset_mime_type: "image/jpeg",
                    file_identifier: "42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    type_: {
                    id: 2,
                    title: "image / logo",
                    created_at: "0000-00-00 00:00:00",
                    updated_at: "0000-00-00 00:00:00",
                    isMultimedia: true,
                    isImage: true,
                    isAudio: false,
                    isVideo: false
                    },
                    urls: {
                    thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_42971213-aeac-43a5-ad28-1b59cc8f2e5a",
                    file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_42971213-aeac-43a5-ad28-1b59cc8f2e5a"
                    }
                }
                },
                    {
                    asset: {
                        id: 2563,
                        state: 2,
                        brand: 0,
                        display_name: "1911329839",
                        top_associations: "LAPTOP, WERK, BUREAU, DRUK, SPREIDSTAND, WERKEN, KANTOOR, STRESS, FLEXIBEL, LENIG, MULTITASKEN, BUREAUSTOEL, COMPUTER, STOELEN, THUISWERK, JOB, BURN-OUT, FLEXIBILITEIT, SPLIT, MAN",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 5.35955,
                        total_count: 23,
                        word: {
                        word: "1911329839",
                        created_at: "2021-12-21T11:29:39",
                        updated_at: "2021-12-21T11:29:39",
                        asset_mime_type: "image/png",
                        file_identifier: "c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        external: 1,
                        word_id: 302358,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/png",
                        file_identifier: "c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_c6639929-7ba2-4739-9ff0-ea1a0d06b9e9"
                        }
                    }
                    },

                    {
                    asset: {
                        id: 2533,
                        state: 2,
                        brand: 0,
                        display_name: "meditatie_605622212",
                        top_associations: "YOGA, RUST, ZEN, MEDITATIE, MEDITEREN, NATUUR, ONTSPANNEN, STILTE, RELAX, ONTSPANNING, ZON, JOGA, VROUW, MINDFULNESS, GENIETEN, BEZINNEN, KALMTE, STRAND, ALLEEN, RUSTGEVEND",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 8.08459,
                        total_count: 1,
                        word: {
                        word: "MEDITATIE_605622212",
                        created_at: "2021-11-30T15:51:00",
                        updated_at: "2021-11-30T15:51:00",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "de294953-92ac-43ff-90c4-01280a0c4b86",
                        external: 1,
                        word_id: 301941,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "de294953-92ac-43ff-90c4-01280a0c4b86",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_de294953-92ac-43ff-90c4-01280a0c4b86",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_de294953-92ac-43ff-90c4-01280a0c4b86",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_de294953-92ac-43ff-90c4-01280a0c4b86",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_de294953-92ac-43ff-90c4-01280a0c4b86"
                        }
                    }
                    },
                    {
                    asset: {
                        id: 2535,
                        state: 2,
                        brand: 0,
                        display_name: "mixdrankjes_635611256",
                        top_associations: "COCKTAIL, BAR, DRANK, ALCOHOL, LEKKER, DRINKEN, BARMAN, COCKTAILS, CAFÉ, APERITIEF, HORECA, OBER, UITGAAN, GENIETEN, GEZELLIG, PLEZIER, cocktailbar, VRIENDEN, BARTENDER, FEEST",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 6.93774,
                        total_count: 1,
                        word: {
                        word: "MIXDRANKJES_635611256",
                        created_at: "2021-11-30T15:51:00",
                        updated_at: "2021-11-30T15:51:00",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        external: 1,
                        word_id: 301943,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_17a3fd81-9c86-4735-842d-2de0dffe2a86",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_17a3fd81-9c86-4735-842d-2de0dffe2a86"
                        }
                    }
                    },
                    {
                    asset: {
                        id: 2514,
                        state: 2,
                        brand: 0,
                        display_name: "Fietsen_558086152",
                        top_associations: "WIELRENNEN, SPORT, FIETSEN, KOERS, FIETS, WIELRENNER, BERG, BERGEN, SPORTIEF, NATUUR, KLIMMEN, WEG, WEDSTRIJD, INSPANNING, SPORTEN, COUREUR, HELM, ONTSPANNING, RENNER, BERGOP",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 6.69222,
                        total_count: 8,
                        word: {
                        word: "FIETSEN_558086152",
                        created_at: "2021-11-15T23:18:53",
                        updated_at: "2021-11-15T23:18:53",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        external: 1,
                        word_id: 301713,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_75e3688d-72af-4d1a-98e5-f10fb95603f2",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_75e3688d-72af-4d1a-98e5-f10fb95603f2"
                        }
                    }
                    },
                    {
                    asset: {
                        id: 2534,
                        state: 2,
                        brand: 0,
                        display_name: "Verzorging van kamerplanten_1667571547",
                        top_associations: "PLANT, WATER, PLANTEN, GROEN, VERZORGEN, NATUUR, LEVEN, VERZORGING, GEZOND, BESPROEIEN, SPROEIEN, MOOI, WATER GEVEN, PLANTENSPUIT, ZUURSTOF, KAMERPLANT, ZORG, SPUIT, HOBBY, VERSTUIVEN",
                        labels: null,
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        expedite: null,
                        created_at: "2024-12-13T14:58:40",
                        tracking_auto_tag: 0,
                        tracking: 0,
                        brand_website: null,
                        clarity_score: 6.60729,
                        total_count: 57,
                        word: {
                        word: "VERZORGING VAN KAMERPLANTEN_1667571547",
                        created_at: "2021-11-30T15:51:00",
                        updated_at: "2021-11-30T15:51:00",
                        asset_mime_type: "image/jpeg",
                        file_identifier: "1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        external: 1,
                        word_id: 301942,
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false
                        },
                        image_url: ""
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        type_: {
                        id: 2,
                        title: "image / logo",
                        created_at: "0000-00-00 00:00:00",
                        updated_at: "0000-00-00 00:00:00",
                        isMultimedia: true,
                        isImage: true,
                        isAudio: false,
                        isVideo: false
                        },
                        urls: {
                        thumb_small: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wt_1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        thumb_medium: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wtt_1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        thumb_large: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wttt_1e99bb0d-4ca6-4e70-ad32-58937d9c564d",
                        file: "https://mindspeller-assets-prod-nl.s3.eu-central-1.amazonaws.com/wf_1e99bb0d-4ca6-4e70-ad32-58937d9c564d"
                        }
                    }
                    },
            ],
        };
    },
    computed: {
        // we only want to show the store in the standalone version (i.e. in
        // the Library app)
        showAddAssetButton: function () {
            return this.standalone;
        },
        dropdownTitle() {
            if (this.selected_assets_and_positionings.size > 0) {
                const firstAsset = this.selected_assets_and_positionings.values().next().value;
                if(!firstAsset.asset.file_identifier){
                    return "Value:  " + firstAsset.asset.display_name;
                }
                else{
                    return "Value: Image";
                }
            } else {
                return "Select your value";
            }
        },

    },

    methods: {
        reset: function () {
            this.selectedWord = null;
        },
        handleWordSelected: function (word) {
            this.selectedWord = word;
            this.$emit('wordSelected', word);
        },
        toggleDropdown() {
            this.isCollapsed = !this.isCollapsed;
        },
        filter: async function (val, update, abort) {
            await update(this.fetchWords(val));
        },
        openRedirectCas(){
            this.showRedirectCas = true;
        },
        closeRedirectCas(){
            this.showRedirectCas = false;
        },

        fetchWords: async function (val) {
            if (val === '') {
                this.options = [];
                this.last_search = val;
            } else {
                if (val !== this.last_search) {
                    this.loading = true;
                    const payload = {
                        draw: 1,
                        search: {
                            value: val.toLowerCase(),
                        },
                        start: 0,
                        length: 8,
                    };

                    const response = await axios.post('/api/cas/words/search', payload);
                    const options = response.data.data.map((w) => ({
                        value: w,
                        label: w.has_access ? `✔ ${w.word}` : w.word,
                    }));
                    this.options = options;
                    this.last_search = val;
                    this.$refs.select_widget.showPopup();
                    this.loading = false;

                }
            }
        },

        selectDefaultWord(word) {
            const wordData = this.defaultWordData[word.word];
            if (wordData) {
                // Directly call the watcher function with the entire `new_value` structure
                this.selectedWord = wordData; // This simulates triggering the watcher
                this.toggleDropdown();
            }
        },
        onAddNewAsset: function () {
            this.$refs['add-new-asset-dialog'].open();
        },
        emitAddAsset(payload) {
            this.$emit("addAsset", payload);
        },

        iaSelect: async function (word_object) {
            const wordKey = word_object.word;

            if (this.defaultWordData[wordKey]) {
                // Use embedded data for default words without API call
                this.$emit('wordSelected', word_object);
            } else {
                const payload = {
                    word_ids: [word_object.word_id],
                };
                // here we remove the charging part
                const access = await axios.post('/api/cas/words/check_access', payload);
                if (access.data[word_object.word_id]) {
                    this.$emit('wordSelected', word_object);
                } else {
                    const price = this.$store.getters.get_price('public_word_asset');
                    const dialog_options = {
                        title: this.$t('message.purchase_ia', {
                            word: word_object.word,
                        }),
                        text: this.$t('message.ia_cost', {
                            price: price,
                        }),
                        confirmButtonText: this.$t('message.add'),
                        cancelButtonText: this.$t('message.cancel'),
                    };
                    const dialog_result = await genericDialog(dialog_options);
                    if (dialog_result.isConfirmed) {
                        const url = `/api/cas/words/request_access_new`;
                        const response = await axios.post(url, {
                            word_ids: [word_object.word_id],
                        });
                        if (response.data.access_granted) {
                            this.$emit('wordSelected', word_object);
                            this.$store.dispatch('get_mindcoin_balance');
                        }
                        if (response.data.insufficient_credits) {
                            alert_error(this.$t('message.insufficient_credits_for_ia'));
                        }
                        if (response.data.read_only) {
                            alert_error(this.$t('message.read_only_user_no_ia'));
                        }
                    }
                }
            }
        },
    },
    computed: {
      dropdownTitle() {
        if (this.selected_assets_and_positionings.size > 0) {
          const firstAsset = this.selected_assets_and_positionings.values().next().value;
          return firstAsset.asset.file_identifier ? "Value: Image" : "Value: " + firstAsset.asset.display_name;
        } else {
          return "Select your value";
        }
      },
    },
    methods: {
      reset() { this.selectedWord = null; },
      handleWordSelected(word) { this.selectedWord = word; this.$emit('wordSelected', word); },
      toggleDropdown() { this.isCollapsed = !this.isCollapsed; },
      filter: async function (val, update, abort) { await update(this.fetchWords(val)); },
      openRedirectCas() { this.showRedirectCas = true; },
      closeRedirectCas() { this.showRedirectCas = false; },
      fetchWords: async function (val) {
        if (!val) { this.options = []; this.last_search = val; }
        else if (val !== this.last_search) {
          this.loading = true;
          const payload = { draw: 1, search: { value: val.toLowerCase() }, start: 0, length: 8 };
          const response = await axios.post('/api/cas/words/search', payload);
          this.options = response.data.data.map(w => ({
            value: w,
            label: w.has_access ? `✔ ${w.word}` : w.word,
          }));
          this.last_search = val;
          this.$refs.select_widget.showPopup();
          this.loading = false;
        }
      },
      selectDefaultWord(word) {
        const wordData = this.defaultWordData[word.word];
        if (wordData) { this.selectedWord = wordData; this.toggleDropdown(); }
      },
      onAddNewAsset() { this.$refs['add-new-asset-dialog'].open(); },
      emitAddAsset(payload) { this.$emit("addAsset", payload); },
      iaSelect: async function (word_object) {
        const wordKey = word_object.word;
        if (this.defaultWordData[wordKey]) {
          this.$emit('wordSelected', word_object);
        } else {
          const payload = { word_ids: [word_object.word_id] };
          const access = await axios.post('/api/cas/words/check_access', payload);
          if (access.data[word_object.word_id]) {
            this.$emit('wordSelected', word_object);
          } else {
            const price = this.$store.getters.get_price('public_word_asset');
            const dialog_options = {
              title: this.$t('message.purchase_ia', { word: word_object.word }),
              text: this.$t('message.ia_cost', { price: price }),
              confirmButtonText: this.$t('message.add'),
              cancelButtonText: this.$t('message.cancel'),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (dialog_result.isConfirmed) {
              const url = `/api/cas/words/request_access_new`;
              const response = await axios.post(url, { word_ids: [word_object.word_id] });
              if (response.data.access_granted) {
                this.$emit('wordSelected', word_object);
                this.$store.dispatch('get_mindcoin_balance');
              }
              if (response.data.insufficient_credits) {
                alert_error(this.$t('message.insufficient_credits_for_ia'));
              }
              if (response.data.read_only) {
                alert_error(this.$t('message.read_only_user_no_ia'));
              }
            }
          }
        }
      },
    },
    watch: {
      selectedWord(new_value) {
        if (new_value !== null) {
          this.iaSelect(new_value.value);
          if (this.clearAfterSelection) { this.reset(); }
        } else {
          this.$emit('wordChanged', new_value);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Mobile-first Base Styles */
  .custom-purple-outline {
    background-color: #f9fbfd;
    border: 1px solid #7878e9;
    border-radius: 8px;
    padding: 5px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px;
    transition: max-height 0.3s ease;
  }
  
  .info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 1em;
    font-weight: bold;
    border: 1px solid #eef3fa;
    border-radius: 8px;
    color: #333;
    background-color: #eef3fa;
    cursor: pointer;
  }
  
  .header-icon {
    color: #7878e9;
  }
  
  .toggle-icon {
    color: #000;
    transition: transform 0.3s;
  }
  
  .info-header:hover {
    background-color: #a2c9ff;
  }
  
  .dropdown-title {
    font-size: 0.9em;
    flex: 1;
    text-align: center;
    margin: 0 10px;
  }
  
  .dropdown-content {
    margin-top: 10px;
  }
  
  /* Search Container */
  .search-container {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  /* Default Words List */
  .default-words-list {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  
  .default-word-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .default-word-item:hover {
    background-color: #a2c9ff;
    transform: scale(1.02);
  }
  
  .default-word-text {
    flex: 1;
    font-size: 0.8em;
    color: #333;
  }
  
  .circle-check-icon {
    background-color: black;
    color: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 0.6em;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Lib Buttons */
  .lib-btn-container {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .lib-btn-small {
    color: black;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 0.9em;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .lib-btn-small:hover {
    background-color: #a2c9ff;
  }
  
  .lib-icon-circle {
    font-size: 1em;
    color: black;
    border: 2px solid black;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
  
  .dots {
    margin-left: 10px;
    color: black;
  }
  
  /* Divider */
  .divider {
    height: 1px;
    background-color: #ddd;
    width: 100%;
    margin: 15px 0;
  }
  
  /* Image Grid */
  .image-grid-container {
    overflow-x: auto;
    padding: 5px;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    width: 100%;
  }
  
  .image-grid-item {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 12px;
    overflow: hidden;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .image-grid-item:hover {
    transform: scale(1.1);
  }
  
  .grid-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Media Queries */
  @media screen and (min-width: 1024px) {
    .custom-purple-outline {
      max-width: 800px;
    }
  }
  
  @media screen and (min-width: 1440px) {
    .custom-purple-outline {
      max-width: 1000px;
    }
  }
  
  /* Adjust grid for larger screens */
  @media screen and (min-width: 1600px) {
    .image-grid {
      grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
    }
  }
  </style>
  