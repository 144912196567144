<template>
    <div class="game-lives-container">
        {{ currentlang === 'nl' ? 'Resterende levens:' : 'Remaining Lives:' }}
        <font-awesome-icon :icon="['fas', 'heart']" size="2xl" :style="{color: iconColors[0]}" />
        <font-awesome-icon :icon="['fas', 'heart']" size="2xl" :style="{color: iconColors[1]}" />
        <font-awesome-icon :icon="['fas', 'heart']" size="2xl" :style="{color: iconColors[2]}" />
    </div>
</template>

<script>
export default {
    name: 'GameLives',
    props: {
      remainingLives: {
        type: Number,
        default: 3
      }
    },
    data() {
        return {
            currentlang: '',
        }
    },
    created() {
        this.currentlang =
        this.$store.getters.getEnvVar("VUE_APP_DEFAULT_LANGUAGE") || "nl";
    },
    computed: {
        iconColors() {
            switch (this.remainingLives) {
                case 3:
                    return ["red", "red", "red"];
                case 2:
                    return ["gray", "red", "red"];
                case 1:
                    return ["gray", "gray", "red"];
                case 0:
                    return ["gray", "gray", "gray"];
                default:
                    return ["red", "red", "red"];
            }
        }
    }
}
</script>

<style scoped>
.game-lives-container{
    display: flex;
    gap: 10px;
    align-items: center;
}
</style>