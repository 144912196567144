<template>
  <div class="respondent-img-box d-flex justify-content-center align-items-center" style="height: 100%;">
    <a href="https://www.youtube.com/watch?v=c66QqSHDSNI" target="_blank">
      <img src="../../../assets/respondentmap.png" style="max-height: 100%; width: auto; object-fit: contain;" />
    </a>
  </div>
</template>

  <script>
  export default {
      name: "Macrotellect"
  }
  </script>