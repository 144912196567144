<template>
    <div class="popup-overlay">
      <div class="popup-container">
        <!-- Close Button -->
        <q-btn icon="close" flat round dense class="close-button" @click="$emit('exit')" />
  
        <!-- Header -->
        <div class="popup-header">
          <h2 class="popup-title">CUSTOMIZATION - Summary</h2>
        </div>
  
        <div class="content-container">
          <!-- Left Panel: Summary -->
          <div class="left-panel">
            <div class="summary-details">
              <p class="summary-title">
                You're <strong>One Step Away</strong> from Unlocking Powerful Brand Insights!
              </p>
  
              <ul class="summary-list">
                <li>
                  <font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" />
                  <strong>Gain a competitive edge </strong> with a fully customized brand network.
                </li>
                <li>
                  <font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" />
                  <strong>Discover hidden connections </strong> and brand perceptions with the Mindspeller Network.
                </li>
                <li>
                  <font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" />
                  <strong>Make data-backed decisions </strong> with confidence.
                </li>
              </ul>
  
              <!-- Orders Section -->
              <div v-if="notEnoughFunds" class="order-box">
                <p>
                  <strong>You do not have enough Mindcoins to checkout your order.</strong>
                </p>
                <p>
                  <strong>
                    Click <button style="padding: 0.2vw 1vw" class="yes-button" @click.prevent="redirectToSubscription">
                      HERE
                    </button> 
                    to add more mindcoins.
                  </strong>
                </p>
              </div>
  
              <div v-if="!notEnoughFunds" class="order-box">
                <p class="order-header">Orders:</p>
                <p>
                  <font-awesome-icon :icon="['fas', 'truck']" class="order-icon" />
                  <strong>Your Personalized Network</strong> should be available at the earliest within approximately
                  <strong>{{ formattedETA }}</strong> depending on your place in the ORDER QUEUE.
                </p>
                <p>
                  <font-awesome-icon :icon="['fas', 'envelope']" class="order-icon" />
                  <strong>Notification</strong> will be sent to your email when the network is ready.
                </p>
              </div>
            </div>
          </div>
  
          <!-- Right Panel: Checkout -->
          <div class="right-panel">
            <div class="checkout-box">
              <h3>CHECKOUT</h3>
              <div class="line-item">
                <span class="tooltip-container">
                  Setup Fee (one-time)
                  <font-awesome-icon :icon="['fas', 'info-circle']" class="info-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false"/>
                  <span v-if="showTooltip" class="tooltip">
                    Setup Fee: Covers setup, customization, and secure integration of your private concepts with the Mindspeller network.
                  </span>
                </span>
                <span>+{{ setupFee }}</span>
              </div>
              <div class="line-item">
                <span>Per Concept Fee (one-time)</span>
                <span>+{{ totalCharge }}</span>
              </div>
              <hr />
              <div class="line-item total">
                <span>Total Mindcoins</span>
                <span>{{ totalMindcoins }}</span>
              </div>
              <button :disabled="notEnoughFunds" @click="customNetworkOrder" class="customize-button">
                ORDER
              </button>
            </div>
          </div>
        </div>
  
        <!-- Navigation Buttons -->
        <div class="popup-footer">
          <button class="back-button" @click="$emit('back')">Back</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';


  export default {
    name: "SummaryPopup",
    emits: ["exit", "back"],
    props: {
      assetCount: Number,
      setupFee: Number,
      totalCharge: Number,
      totalMindcoins: Number
    },
    data() {
      return {
        showTooltip: false,
      };
    },
    computed: {
      notEnoughFunds() {
        return this.totalMindcoins > this.$store.state.mindcoins;
      },
      additionalMinutes() {
      return this.assetCount * 5; // 5 minutes per concept
    },
      etaDays() {
        const totalMinutes = this.additionalMinutes;
        const totalHours = Math.floor(totalMinutes / 60);
        const additionalDays = Math.floor(totalHours / 24);
        const remainingHours = totalHours % 24;

        // If remaining hours are more than 4, consider it as an additional day
        return 2 + additionalDays + (remainingHours > 4 ? 1 : 0);
      },
      formattedETA() {
        return `${this.etaDays} Business days`;
      }
    },
    methods: {
      async redirectToSubscription() {
        if (
          this.$store.state.current_license.monthly_fee > 0 &&
          this.$store.state.current_license.name !== "150 credits per month per user"
        ) {
          // Show the generic dialog for subscription confirmation
          const dialogOptions = {
            title: "Subscription Confirmation",
            text: "You are currently under a subscription model. Please contact your customer success manager.",

            confirmButtonText: "Contact",
            cancelButtonText: "Cancel",
          };

          const dialogResult = await genericDialog(dialogOptions);
          if (dialogResult.isConfirmed) {
            // Redirect to the library if confirmed
            window.open("https://ads.mindspeller.com/contact/", "_blank")
          }
        } else {
          // Redirect to subscription plans if the user isn't under a subscription
          window.open(this.$router.resolve('/subscription_plans').href, '_blank');
        }
      },
      async customNetworkOrder() {
        await this.$store.dispatch('get_mindcoin_balance');
        if (this.$store.state.mindcoins < this.totalMindcoins) {
          alert_error(this.$t('new_asset.insufficient_credits'));
          return;
        }
        try {
          const response = await axios.post('/api/cas/assets/custom_network_order', {total: this.totalMindcoins, ETA: this.formattedETA});
          if (response.status === 200) {
            toast_success(`Your order has been placed. Your personalized network will be delivered in ${this.formattedETA}.`);
            this.$store.dispatch('get_mindcoin_balance');
          }
        } catch (error) {
          console.error('Error processing order:', error);
          alert_error(this.$t('message.general_server_error'));
        } finally {
          this.$emit('exit');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Background Overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Popup Container */
  .popup-container {
    background: white;
    width: 80%;
    max-width: 1000px;
    padding: 20px;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Header */
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    position: relative;
  }
  
  .popup-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  /* Content Layout */
  .content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Left Panel */
  .left-panel {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .summary-details {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .summary-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .summary-list {
    list-style: none;
    padding: 0;
  }
  
  .summary-list li {
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  /* Orders Box */
  .order-box {
    background: white;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    margin-top: 15px;
  }

  .order-box p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .order-header {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Right Panel */
  .right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .checkout-box {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 20px;
    width: 300px;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .checkout-box h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  .line-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .line-item.total {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-top: 20px;
  }
  
  /* Buttons */
  .customize-button {
    background: #0000ff;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 15px;
    width: 100%;
    cursor: pointer;
  }
  
  .customize-button:hover {
    background: #0000cc;
  }
  
  .customize-button:disabled {
  background: #cccccc; /* Lighter grey background */
  color: #666666; /* Darker grey text */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Make the button more transparent */
}

  .yes-button {
  background: #0a00ff;
  color: #fff;
  border: none;
  border-radius: 2vw;
  padding: 0.5vw 2vw;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.yes-button:hover{
  background: #0800ff8f;
}

/* Tooltip */
.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    bottom: 130%;
    left: 40%;
    width: 200px;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    font-size: 12px;
    padding: 8px;
    border-radius: 6px;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
    z-index: 10;
  }
  
  .info-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #7878e9;
  }

  /* Navigation Buttons */
  .popup-footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .back-button {
    background: #000000;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background: #3d3d3d;
  }
  
  /* Icons */
  .check-icon {
    color: #5a67d8;
    margin-right: 8px;
  }
  
  .order-icon {
    margin-right: 8px;
  }
  
  </style>
  