<template>
  <section class="middle-section">
    <div class="container-fluid">
      <div class="dashboard-page">
        <div class="ac-info-div">
          <div class="container-account">
            <div class="ac-info-col-div">
              <!-- Flexible container for columns -->
              <div class="flex-container">
                <!-- Account information -->
                <div class="column-account">
                  <h6>Account Details</h6>

                  <form class="row g-3 form-css-bx">
                    <div class="prof-imgbx col-12">
                      <label class="form-label">Upload Profile Pic</label>
                      <div
                        class="profimgsec"
                        :class="{ 'loading': isSelecting }"
                        @click="handleFileImport"
                      >
                        <img
                          v-if="!image"
                          src="../../assets/prof-icon.png"
                          alt="Default profile"
                        />
                        <img
                          v-else
                          :src="image.data['url']"
                          alt="Uploaded profile"
                        />
                      </div>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        :accept="acceptExtenstions"
                        @change="onFileChanged"
                      />
                    </div>

                    <div class="col-12">
                      <label for="inputEmail" class="form-label">Email</label>
                      <input
                        v-model="user.email"
                        type="email"
                        class="form-control"
                        id="inputEmail4"
                        placeholder="Enter Email"
                        disabled
                      />
                    </div>

                    <!-- password -->
                    <div class="col-12">
                      <label for="input-password">{{ $t("message.password") }}</label>
                      <input
                        class="form-control"
                        disabled
                        type="text"
                        value="**********"
                      />
                      <router-link
                        :to="{ name: 'Change password' }"
                        class="link"
                      >
                        {{ $t("message.change_password") }}
                      </router-link>
                    </div>

                    <div class="col-12">
                      <label class="form-label">Username</label>
                      <input
                        v-model="user.user_name"
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Enter Username"
                      />
                    </div>

                    <div class="col-12">
                      <label class="form-label">First Name</label>
                      <input
                        v-model="user.first_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter First Name"
                      />
                    </div>

                    <div class="col-12">
                      <label class="form-label">Last Name</label>
                      <input
                        v-model="user.last_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Last Name"
                      />
                    </div>

                    <div class="col-12">
                      <label class="form-label">Brand Name</label>
                      <input
                        v-model="user.brand_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Brand Name"
                      />
                    </div>

                    <div class="col-12">
                      <label class="form-label">Phone Number</label>
                      <input
                        v-model="user.phone_no"
                        type="tel"
                        class="form-control"
                        placeholder="+[Country Code]XXXXXXXXX"
                      />
                      <div id="recaptcha-container"></div>
                      <button
                        type="button"
                        class="btn3 mt-2"
                        @click="sendOTP"
                        :disabled="isVerifying"
                      >
                        Verify Phone
                      </button>
                      <span class="text-danger" v-if="isPhoneNotValid">
                        Please enter a valid phone number
                      </span>
                    </div>

                    <div v-if="otpSent" class="col-12">
                      <label class="form-label">Enter OTP</label>
                      <input
                        v-model="otpCode"
                        type="text"
                        class="form-control"
                        placeholder="Enter OTP"
                      />
                      <button
                        type="button"
                        class="btn3 mt-2"
                        @click="verifyOTP"
                        :disabled="isVerifying || !otpCode"
                      >
                        Confirm OTP
                      </button>
                      <span v-if="otpVerified" class="text-success">
                        Phone number verified!
                      </span>
                      <span v-if="otpError" class="text-danger">
                        Invalid OTP. Try again.
                      </span>
                    </div>

                    <div class="col-12">
                      <label for="inputCountry4" class="form-label">Country</label>
                      <input
                        v-model="user.country"
                        type="text"
                        class="form-control"
                        id="inputCountry4"
                        placeholder="Enter Country"
                      />
                    </div>

                    <div class="col-12">
                      <label class="form-label">Address</label>
                      <input
                        v-model="user.address"
                        type="text"
                        class="form-control"
                        placeholder="Enter Address"
                      />
                    </div>

                    <!-- asset update -->
                    <div
                      v-if="!$store.state.is_respondent"
                      class="col-12 d-flex align-items-center"
                    >
                      <input
                        type="checkbox"
                        id="positioning-emails"
                        class="settings-checkbox"
                        :checked="$store.state.current_user.notify_asset_positioning"
                        @change="positioning_updates_change"
                      />
                      <label
                        class="form-label-select small-font ms-2"
                        for="positioning-emails"
                      >
                        {{ $t("message.receive_asset_updates") }}
                      </label>
                    </div>

                    <!-- product update -->
                    <div class="col-12 d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="product-updates"
                        class="settings-checkbox"
                        :checked="$store.state.current_user.consent_general_communication"
                        @change="product_updates_change"
                      />
                      <label
                        class="form-label-select small-font ms-2"
                        for="product-updates"
                      >
                        {{ $t("message.receive_product_updates") }}
                      </label>
                    </div>

                    <!-- save button -->
                    <div class="col-12">
                      <div class="btn-div">
                        <button
                          type="button"
                          class="btn3"
                          @click="onSaveHandler"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- End of account information -->

                <!-- Team -->
                <div
                  v-if="!$store.state.is_respondent"
                  class="column-team"
                >
                  <div
                    v-if="$store.state.current_user.category.name === 'Mindspeller user' ||
                           $store.state.current_user.category.name === 'Organisation admin'"
                  >
                    <Team />
                  </div>
                </div>
                <!-- End team -->

                <!-- License -->
                <div
                  v-if="!$store.state.is_respondent"
                  class="column-license"
                >
                  <License />
                </div>
                <!-- End license -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <loading-app-wide v-if="loading" />
</template>

<script>
import axios from 'axios';
import { alert_error, toast_success, alert_success } from '@/helpers/alert_helper.js';
import { app, auth, RecaptchaVerifier, signInWithPhoneNumber, browserLocalPersistence, } from "@/plugins/firebase.js";
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import License from './license.vue';
import Team from './team.vue';



export default {
  name: 'MyAccount',
  components: {
    LoadingAppWide, LoadingSpinner,
    License, Team
  },

  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      image: '',
      user: {
        first_name: '',
        last_name: '',
        brand_name: '',
        email: '',
        country: '',
        address: '',
        phone_no: '',
        user_name: '',
      },
      brandValue: [],
      total_asset: '',
      isPhoneNotValid: null,
      archetypes: {
        first_archetype: '',
        second_archetype: '',
        third_archetype: '',
      },
      passions: {
        first_passion: '',
        second_passion: '',
        third_passion: '',
      },
      loading: false,
      otpSent: false,
      otpCode: "",
      otpVerified: false,
      otpError: false,
      isVerifying: false,
    };
  },

  computed: {
    acceptExtenstions: function () {
      const extensions = ['png', 'jpg', 'jpeg'];
      return extensions.map((extension) => `.${extension}`).join(',');
    },
  },

  watch: {
    'user.phone_no': function (newVal) {
      this.isPhoneNotValid = !this.validatePhoneNumber(newVal);
    }
  },

  methods: {
    validatePhoneNumber(phoneNumber) {
    // This pattern allows optional "+" followed by 1–3 digits of country code,
    // then up to 14 more digits, for a total of up to 15 digits.
      const phoneRegex = /^\+?[1-9]\d{0,2}\d{6,14}$/;
      return phoneRegex.test(phoneNumber);
    },
    product_updates_change: function () {
      const checkbox = document.getElementById('product-updates');
      this.$store.dispatch('set_product_updates', checkbox.checked);
    },

    positioning_updates_change: function () {
      const checkbox = document.getElementById('positioning-emails');
      this.$store.dispatch('set_positioning_updates', checkbox.checked);
    },

    redirectToTeamPage() {
      this.$router.push('/team');
    },

    redirectToChangePasswordPage() {
      this.$router.push('/change_password');
    },

    handleEdit(value) {
      localStorage.setItem('pageText', 'account');
      if (value === 'passions') {
        this.$router.push({
          name: 'Passions',
        });
      } else if (value === 'archetypes') {
        this.$router.push({
          name: 'Archetypes',
        });
      } else if (value === 'brand') {
        this.$router.push({
          name: 'Brand Value',
        });
      }
    },

    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        {
          once: true,
        }
      );
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },

    onFileChanged: async function (e) {
      this.selectedFile = e.target.files[0];
      let fd = new FormData();
      fd.append('file', this.selectedFile);
      const data = await axios.post('/api/cas/users/upload_picture', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (data) {
        const result = await axios.get('/api/cas/users/get_picture');
        if (result) {
          this.image = result;
        }
      }
      location.reload();
    },

    async sendOTP() {


      try {
        this.message = "Sending OTP...";

        // Ensure the reCAPTCHA container exists in the DOM
        let recaptchaContainer = document.getElementById("recaptcha-container");
        if (!recaptchaContainer) {
          console.error("reCAPTCHA container not found! Creating dynamically...");
          recaptchaContainer = document.createElement("div");
          recaptchaContainer.id = "recaptcha-container";
          document.body.appendChild(recaptchaContainer); // Append to body if missing
        }

        // Destroy any existing reCAPTCHA instance before initializing a new one
        if (window.recaptchaVerifier) {
          try {
            window.recaptchaVerifier.clear();
          } catch (err) {
            console.warn("Failed to clear reCAPTCHA instance:", err);
          }
          window.recaptchaVerifier = null;
        }

        // Initialize reCAPTCHA
        try {
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth, // Pass the auth instance
            "recaptcha-container",
            {
              size: "invisible",
              callback: (response) => {
              },
              "expired-callback": () => {
                console.warn("reCAPTCHA expired. Please try again.");
              },
            }
          );
        } catch (error) {
          console.error("Error initializing reCAPTCHA:", error);
          return;
        }

        // Wait before sending OTP to ensure reCAPTCHA is fully initialized
        setTimeout(async () => {
          try {
            this.loading = true;
            const confirmationResult = await signInWithPhoneNumber(
              auth,
              this.user.phone_no,
              window.recaptchaVerifier
            );

            window.confirmationResult = confirmationResult;
            this.otpSent = true;
            this.otpError = false;
            alert("OTP Sent Successfully");
          } catch (error) {
            this.otpError = true;
            console.error("Error sending OTP:", error);
            alert(`Error sending OTP: ${error.message}`);
          } finally {
            this.isVerifying = false;
            this.loading = false;
          }
        }, 1000);
      } catch (error) {
        console.error("Error in sendOTP:", error);
      } finally {
        this.loading = false;
      }
    },

    async verifyOTP() {
      try {
        this.loading = true;
        this.isVerifying = true;
        const result = await window.confirmationResult.confirm(this.otpCode);
        this.otpVerified = true;
        this.otpError = false;
        this.onSaveHandler();

      } catch (error) {
        this.otpError = true;
        alert("Retry OTP verification");
        console.error("Error verifying OTP:", error);
      } finally {
        this.isVerifying = false;
        this.loading = false;
      }
    },

    onSaveHandler: async function () {
      // if (
      //   this.user.phone_no &&
      //   !this.user.phone_no.match(
      //     /^((\+\d{1,2}|1)[\s.-]?)?\(?[2-9](?!11)\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^$/
      //   )
      // ) {
      //   this.isPhoneNotValid = true;
      //   return;
      // }
      if (!this.otpVerified) {
        alert("Please verify your phone number before saving.");
        return;
      }
      this.loading = true;
      try {
        // update the news/product receive part first
        const id = this.$store.state.current_user.id;
        const payload_news = {
          'consent_general_communication': this.$store.state.current_user.consent_general_communication,
          'notify_asset_positioning': this.$store.state.current_user.notify_asset_positioning,
        };
        axios.post(`/api/cas/users/update/${id}`, payload_news)
          .then(response => {
            // toast_success(this.$t("message.user_updated"));
          })
          .catch(error => {
            alert_error(this.$t("message.error_user_update"));
          });

        // then update the rest user's information
        const payload = {
          username: this.user.user_name,
          email: this.user.email,
          phone: this.user.phone_no,
          country: this.user.country,
          address: this.user.address,
          brand_name: this.user.brand_name,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
        };

        const result = await axios.post(
          '/api/cas/users/update_user_details',
          payload
        );

        if (result.status === 200) {
          await this.$store.dispatch('load_user_data');
          toast_success('Details updated successfully');
          if(this.$store.state.is_respondent) {
            this.$router.push({ name: 'RespondentPanel' });
          }
        } else {
          alert_error(this.$t('message.general_server_error'));
        }
      } catch (error) {
        alert_error(this.$t('message.general_server_error'));
      }
      this.loading = false;
    },

    handleAssets: function () {
      window.open(`${window.location.origin}/?#/library`);
    },

    return_my_acount: function () {
      this.$router.push({ name: 'My Account' });
    },
  },

  mounted: async function () {
    document.getElementById('mindspeller').style.overflow = 'auto';
    localStorage.removeItem('pageText');
    localStorage.removeItem('pageName');

    try {
      const result = await axios.get('/api/cas/users/get_picture');
      if (result.status === 200) {
        this.image = result;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const result = await axios.get('/api/cas/users/current_user');
      if (result.status === 200) {
        this.user.user_name = result.data.username;
        this.user.brand_name = result.data.brand_name;
        this.user.email = result.data.email;
        this.user.phone_no = result.data.phone;
        this.user.country = result.data.country;
        this.user.address = result.data.address;
        this.user.first_name = result.data.first_name;
        this.user.last_name = result.data.last_name;
        this.user.password = result.data.password;
      }
    } catch (error) {
      console.log(error);
      alert_error('The Phone number already exists');
    }

    try {
      const archetypes = await axios.get('/api/cas/users/get_archetypes');
      if (archetypes.status === 200) {
        this.archetypes.first_archetype = archetypes.data.first_archetype;
        this.archetypes.second_archetype = archetypes.data.second_archetype;
        this.archetypes.third_archetype = archetypes.data.third_archetype;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const passions = await axios.get('/api/cas/users/get_passions');
      if (passions.status === 200) {
        this.passions.first_passion = passions.data.first_passion;
        this.passions.second_passion = passions.data.second_passion;
        this.passions.third_passion = passions.data.third_passion;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const brandData = await axios.get('/api/cas/users/get_brand_description');
      if (brandData.status === 200) {
        this.brandValue.push(brandData.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const investData = await axios.get('/api/cas/users/get_investor');
      if (investData.status === 200) {
        this.brandValue.push(investData.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const assets = await axios.get('/api/cas/assets/get_assets_count');

      if (assets.status === 200) {
        this.total_asset = assets.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
/* General Container Styling */
.middle-section {
  padding: 20px;
}

.ac-info-div {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
}

.container-account {
  margin: 0 auto;
  max-width: 1400px;
}

/* Flexible container for columns */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

/* Columns: account, team, license */
/* Remove min-width so they can shrink below 300px if needed */
.column-account,
.column-team,
.column-license {
  flex: 1;
  /* Let them share space evenly on large screens */
  /* You can also use width: 100% if you want them stacked by default */
}

/* Form Layout */
.form-css-bx {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-css-bx .col-12 {
  width: 100%;
}

/* Profile Image Box */
.prof-imgbx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.profimgsec {
  width: 80px;
  height: 80px;
  margin-top: 0.5rem;
  border: 2px dashed #ccc;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.profimgsec img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Buttons */
.btn3 {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0rem 1.25rem 1.25rem 1.25rem; 
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

/* Hover state */
.btn3:hover {
  background-color: #0069d9;
}

/* Media Queries for Responsive Design */

/* 
  Below 992px (typical tablet breakpoint),
  let each column go 100% wide. 
*/
@media (max-width: 992px) {
  .column-account,
  .column-team,
  .column-license {
    flex: 1 1 100%;
  }
}

/*
  Below 768px (small tablets and large phones),
  ensure columns stack vertically and the form
  remains nicely spaced.
*/
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }

  .column-account,
  .column-team,
  .column-license {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .profimgsec {
    width: 60px;
    height: 60px;
  }

  .btn3 {
    width: 100%;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    padding: 0.75rem 1.25rem 1.25rem 1.25rem; 

  }
}
</style>
