<template>
  <div v-if="showPopup" class="popup-overlay" @click.self="closePopup">
    <div class="popup-container">
      <!-- Header Row -->
      <div class="header-container">
        <h2>{{ currentlang === 'nl' ? 'WAT WIL JE DOEN OM BELONINGEN TE VERDIENEN?' : 'WHAT DO YOU WANT TO DO TO EARN REWARDS?' }}</h2>
        <div class="button-group">
          <button class="coins" @click="handleCoinClick">
            <div class="coins-logo">
              <img src="../../assets/mindchips.png" alt="" />
            </div>
            {{ coinTotal }} Chips
          </button>
          <button
            class="close-button"
            style="border: 1px solid black; border-radius: 10px;margin-left: 10px; background-color: red; color: black;"
            @click="closePopup"
          >
            ✖
          </button>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="action-buttons">
        <div class="button-wrapper">
          <button
            @click="startGame"
            class="action-button"
            :disabled="this.brandGameStatus"
            :class="{ 'disabled-button': this.brandGameStatus }"
          >
            {{ currentlang === 'nl' ? 'SPEEL SPEL' : 'PLAY GAME' }} <br/>
            {{ currentlang === 'nl' ? '(1 Chip per gedachte)' : '(1 Chip per thought)' }}
            <div v-if="this.brandGameStatus" class="checkmark">
              <font-awesome-icon :icon="['fas', 'circle-check']" />
            </div>
          </button>
        </div>
        <div class="button-wrapper">
          <button
            @click="shareThoughts"
            class="action-button"
            :disabled="this.surveyStatus"
            :class="{ 'disabled-button': this.surveyStatus }"
          >
            {{ currentlang === 'nl' ? 'DEEL GEDACHTEN' : 'SHARE THOUGHTS' }} <br/>
            {{ currentlang === 'nl' ? '(2 Chips per gedachte)' : '(2 Chips per thought)' }}
            <div v-if="this.surveyStatus" class="checkmark">
              <font-awesome-icon :icon="['fas', 'circle-check']" />
            </div>
          </button>
        </div>
      </div>

      <!-- Footer Text -->
      <p style="margin-top: 20px; font-size: 18px; line-height: 2vh; text-align: center;">
        {{ currentlang === 'nl' ? 'Ontdek hoe je kunt beginnen met chips verdienen door een testrit te maken!' : 'Discover how you can start earning chips by taking a test drive!' }}
      </p>
      <p style="margin-top: 20px; font-size: 18px; line-height: 2vh; text-align: center;">
        {{ currentlang === 'nl' ? 'Wissel de chips in voor beloningen! Klik op de chips om meer te weten!' : 'Redeem the chips for rewards! Click on the chips to learn more!' }}
      </p>
      <button
        v-if="!this.brandGameStatus || !this.surveyStatus"
        class="login-action-button"
        @click="openLoginPage"
      >
        {{ currentlang === 'nl' ? 'Heb je al een account? Klik hier om in te loggen' : 'Already have an account? Click here to Login' }}
      </button>

      <button
        v-if="this.brandGameStatus || this.surveyStatus"
        @click="openSignupPage"
        class="footer-action-button"
      >
        {{ currentlang === 'nl' ? 'MELD JE AAN OM BELONINGEN TE VERDIENEN VIA MINDCHIPS ( = INWISSELBARE CHIP)' : 'SIGN UP TO EARN REWARDS VIA MINDCHIPS ( = REDEEMABLE CHIP)' }}
      </button>
    </div>
  </div>
  <div v-if="showCoinPopup" class="overlay" @click.self="closeCoinPopup">
    <div class="coin-popup" style="width: 70%;">
      <div class="popup-header" style="display: flex; justify-content: space-between; align-items: center;">
        <h2 style="font-size: large;">
          {{ currentlang === 'nl' ? 'Opmerking' : 'Notice' }}
        </h2>
        <button style="background-color: red; color: white; border-radius: 50%;" @click="closeCoinPopup">
          ✖
        </button>
      </div>
      <div class="popup-content">
        <p style="line-height: 2vh;">
          {{ currentlang === 'nl' ? 'Je chips zijn alleen voor oefening. Meld je aan voor een Mindspeller-account om met echte Mindchips te beginnen inzetten!' : 'Your chips are for practice only. Sign up for a Mindspeller account to start betting with real Mindchips!' }}
        </p>
        <button class="button" @click="handleLoginClick">
          {{ currentlang === 'nl' ? 'Aanmelden' : 'Signup' }}
        </button>
        <button
          class="button"
          style="margin-left: 5px;"
          @click="closeCoinPopup"
        >
          {{ currentlang === 'nl' ? 'Sluiten' : 'Close' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BrandGamePopup",
  emits: ["start-game", "share-thoughts"],
  data() {
    return {
      showPopup: false,
      showCoinPopup: false,
      currentlang: "",
    };
  },
  computed: {
    ...mapState(["coinTotal"]),
  },
  created() {
      if (window.location.href.includes("cas-nl")) {
      this.currentlang = "nl";
    } else {
      this.currentlang = "en";  
    }  
  },
  methods: {
    openPopup({ brandGameStatus, surveyStatus }) {
      this.showPopup = true;
      this.brandGameStatus = brandGameStatus;
      this.surveyStatus = surveyStatus;
    },
    openLoginPage() {
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("jwt_refresh_token");
      localStorage.removeItem("is_anonymous");
      localStorage.removeItem("pageText");
      localStorage.removeItem("pageName");
      this.$router.push({ name: "RespondentLogin" });
    },
    closePopup() {
      this.showPopup = false;
    },
    openCoinPopup() {
      this.showCoinPopup = true;
    },
    closeCoinPopup() {
      this.showCoinPopup = false;
    },
    handleCoinClick() {
      this.openCoinPopup();
    },
    startGame() {
      this.$emit("start-game");
      this.closePopup();
    },
    shareThoughts() {
      this.$emit("share-thoughts");
      this.closePopup();
    },
    handleLoginClick() {
      window.open(this.$router.resolve("/respondent_signup").href, "_blank");
    },
    openSignupPage() {
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("jwt_refresh_token");
      localStorage.removeItem("is_anonymous");
      localStorage.removeItem("pageText");
      localStorage.removeItem("pageName");
      this.$router.push({ name: "RespondentSignup" });
    },
  },
};
</script>

<style>
@media only screen and (max-width: 376px) {
  .popup-container {
    width: 300px !important;
  }

  .button-group {
    justify-content: space-between;
  }
  .action-buttons{
    flex-direction: column;
    gap: 4px;
  }
  .login-action-button {
        width: 250px !important;
        padding: 0px !important;
        height: auto;
    }
}

@media only screen and (max-width: 480px) and (min-width: 377px) {
  .popup-container {
    width: 400px !important;
  }
  .login-action-button {
    width: 350px !important;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .header-container h2 {
    font-size: 20px !important;
  }
  /* .popup-container {
    width:400px !important;
  } */
  .header-container h2 {
    margin-top: 5px !important;
  }
  
  .action-button {
    padding: 0px !important;
    width: 160px;
    height: 60px;
  }
  .button-group {
    justify-content: space-between !important;
  }
  .footer-action-button{
    height: auto;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .popup-container {
    width: 600px !important;
  }
  .action-button {
    font-size: 18px !important;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 1000px;
}

.header-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column-reverse;
}

.header-container h2 {
  flex-grow: 1;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  /* margin-left: 15%; */
  line-height: 30px;
}

.button-group {
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.coins {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: #7878e9;
  border-radius: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.coins p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.coin-popup {
  width: 70%;
  max-height: 90%;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 12px;
  line-height: 2vh;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
}
.coins-logo {
  color: #7878e9; /* If the SVG inherits color */
  width: 25px;
  height: 25px;
  margin-right: 10px;
  /* margin-left: 10px; */
}
.close-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.action-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 255, 0);
  font-size: 24px;
  font-weight: bold;
}

.button-wrapper {
  position: relative;
  text-align: center;
}

.action-button {
  background: #7878e9;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  /* width: 300px; */
  /* height: 150px; */
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.footer-action-button {
  background: #3737e6;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  /* width: 800px; */
  /* height: 150px; */
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: flash 2s infinite;
}

.login-action-button {
  background: #7878e9;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  width: 500px;
  /* height: 150px; */
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* animation: flash 2s infinite; */
}

@keyframes flash {
  0%,
  100% {
    background-color: #7878e9; /* Original color */
  }
  50% {
    background-color: #3737e6; /* Slightly lighter color for flashing effect */
  }
}

.action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.footer-action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.footer-text {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #666;
  line-height: normal;
}

.login-cta-text {
  /* margin-top: 20px; */
  text-align: center;
  font-size: 18px;
  color: #666;
}

.disabled-button {
  background-color: grey;
  cursor: not-allowed;
}
</style>
